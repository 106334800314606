import AutoComplete from '@clinintell/components/AutoComplete';
import { OrgTreeTypes } from '@clinintell/modules/orgTree';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useControlledEntitySearch from './logic/useControlledEntitySearch';

type EntityNavigationSearchProps = {
  minCharactersToSearch: number;
  maxCharactersToSearch: number;
  orgTreeSearchType: OrgTreeTypes;
  searchFieldInputStyles?: React.CSSProperties;
  onSearchResultSelection: (id: number, name: string, parentId: number) => void;
  showStartIcon?: boolean;
};

const EntityNavigationSearch: React.FC<EntityNavigationSearchProps> = ({
  minCharactersToSearch,
  maxCharactersToSearch,
  orgTreeSearchType,
  children,
  searchFieldInputStyles,
  onSearchResultSelection,
  showStartIcon = false
}) => {
  const [showEntitySearchDropDown, setShowEntitySearchDropDown] = useState(false);

  const {
    selectedEntity,
    debouncedSearchValue,
    renderOption,
    getOptionLabel,
    isSearching,
    handleInputChange,
    handleTextChange,
    selectOptions,
    searchValue
  } = useControlledEntitySearch({
    minCharactersToSearch,
    maxCharactersToSearch,
    useSAF: orgTreeSearchType === 'saf'
  });

  useEffect(() => {
    if (selectedEntity === undefined || (selectedEntity && !selectedEntity.childName)) {
      return;
    }

    onSearchResultSelection(selectedEntity.childId, selectedEntity.childName, selectedEntity.parentId);
    // If entity changes, the tree node will be updated (which is a depdendancy of the parent onSearchResultSelection method, so this should be fine)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity]);

  return (
    <Box mb={1} display="flex" flexWrap="nowrap" alignContent="center" justifyContent="flex-start" alignItems="center">
      {children}
      <AutoComplete
        openDropDown={showEntitySearchDropDown && !isSearching && debouncedSearchValue.length >= minCharactersToSearch}
        loading={isSearching}
        onTextChangeHandler={handleTextChange}
        onChangeHandler={handleInputChange}
        inputShouldUseFullWidth
        inputValue={searchValue}
        inputLabel="Search"
        options={{
          options: selectOptions,
          renderOption,
          getOptionLabel
        }}
        inputStyles={{ ...searchFieldInputStyles }}
        notchedOutlineWidth="55px"
        onOpenHandler={(): void => setShowEntitySearchDropDown(true)}
        onCloseHandler={(): void => setShowEntitySearchDropDown(false)}
        testID="treeSearchComponent"
        showStartIcon={showStartIcon}
      />
    </Box>
  );
};

export default EntityNavigationSearch;
