import { Box, MenuItem, SxProps, Theme } from '@mui/material';
import React from 'react';

type PopupMenuItemProps = {
  label: string;
  icon?: JSX.Element | React.ReactNode;
  onClick: () => void;
  sx?: SxProps<Theme>;
};

const PopupMenuItem: React.FC<PopupMenuItemProps> = ({ label, icon, onClick, sx }) => {
  return (
    <MenuItem
      sx={{
        height: '40px',
        '&:hover': {
          backgroundColor: 'grey.50'
        },
        ...sx
      }}
      onClick={onClick}
      onTouchEnd={onClick}
    >
      {icon || null}
      <Box component="span" sx={{ typography: 'p2' }}>
        {label}
      </Box>
    </MenuItem>
  );
};

export default PopupMenuItem;
