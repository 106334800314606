import React, { useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Typography, useTheme } from '@mui/material';
import Popper from '@mui/material/Popper';
import { AccountIcon } from '@clinintell/components/index';
import useSignOut from '@clinintell/containers/authentication/useSignOut';
import { useMenuNavigationState, useUser } from '@clinintell/modules/store';

const AccountMenuButton: React.FC = () => {
  const theme = useTheme();
  const { menuIsExpanded } = useMenuNavigationState();
  const { signOut } = useSignOut();
  const { name, email } = useUser();
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLElement>(null);

  const handleSignOutClick = () => {
    signOut();
  };

  const handlePopupMenuClick = () => setMenuOpen(val => !val);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    } else if (event.key === 'Escape') {
      setMenuOpen(false);
    }
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setMenuOpen(false);
  };
  const prevOpen = React.useRef(menuOpen);
  useEffect(() => {
    if (prevOpen.current === true && menuOpen === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = menuOpen;
  }, [menuOpen]);

  return (
    <>
      <Box
        ref={anchorRef}
        onClick={handlePopupMenuClick}
        sx={{
          py: 1.5,
          px: menuIsExpanded ? 2 : 3,
          ml: menuIsExpanded ? 0 : -0.75,
          display: 'flex',
          justifyContent: 'center',
          borderRadius: '20px',
          gap: 1,
          backgroundColor: menuOpen ? 'grey.200' : 'grey.100',
          color: 'grey.500',
          textDecoration: 'none',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'grey.200'
          }
        }}
      >
        <AccountIcon
          sx={{
            height: 22,
            width: 22,
            fill: 'transparent',
            stroke: theme.palette.grey[500],
            transition: 'color 100ms linear'
          }}
        />
        {menuIsExpanded && (
          <Typography variant={'body1'} sx={{ fontWeight: 'bold' }}>
            Account
          </Typography>
        )}
      </Box>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="right-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                  <Box sx={{ p: 1.5 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body1">{name}</Typography>
                      <Typography variant="body2">{email}</Typography>
                    </Box>
                  </Box>
                  <MenuItem
                    onClick={handleSignOutClick}
                    sx={{
                      borderTop: `solid 1px ${theme.palette.grey[300]}`,
                      '&:hover': { backgroundColor: 'grey.50' }
                    }}
                  >
                    Sign Out
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AccountMenuButton;
