import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconPropType } from '@clinintell/types/common';

const Download = (props: SvgIconPropType): JSX.Element => {
  return (
    <SvgIcon sx={{ fill: 'none', ...props.sx }} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 12.5002V15.8336C17.5 16.2756 17.3244 16.6995 17.0118 17.0121C16.6993 17.3246 16.2754 17.5002 15.8333 17.5002H4.16667C3.72464 17.5002 3.30072 17.3246 2.98816 17.0121C2.67559 16.6995 2.5 16.2756 2.5 15.8336V12.5002"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83331 8.33362L9.99998 12.5003L14.1666 8.33362"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5002V2.50024"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Download;
