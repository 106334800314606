import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconPropType } from '@clinintell/types/common';

const TrainingApproveIcon = (props: SvgIconPropType): JSX.Element => {
  const colorOverride = props.sx.color ?? 'inherit';
  return (
    <SvgIcon sx={{ fill: 'none', ...props.sx }} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_861_5951)">
        <path
          d="M16.5 8.81V9.5C16.4991 11.1173 15.9754 12.691 15.007 13.9864C14.0386 15.2817 12.6775 16.2294 11.1265 16.6879C9.57557 17.1465 7.91794 17.0914 6.40085 16.5309C4.88376 15.9705 3.58849 14.9346 2.70822 13.5778C1.82795 12.221 1.40984 10.616 1.51626 9.00224C1.62267 7.38842 2.24791 5.85225 3.29871 4.62281C4.34951 3.39337 5.76959 2.53654 7.34714 2.18011C8.92469 1.82368 10.5752 1.98675 12.0525 2.645"
          stroke={colorOverride}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 3.5L9 11.0075L6.75 8.7575"
          stroke={colorOverride}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_861_5951">
          <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default TrainingApproveIcon;
