import React from 'react';
import useIsMobileView from './useIsMobileView';

const MobileView: React.FC = ({ children }) => {
  const isMobileView = useIsMobileView();

  return isMobileView ? <>{children}</> : null;
};

export default MobileView;
