import React, { useEffect, useState } from 'react';
import { ApplicationAPI } from '@clinintell/utils/api';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WidgetJSON from '@clinintell/containers/dashboard/widgetTypes';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { ReorderList } from '@clinintell/utils/resources';
import {
  useUser,
  useMetricsNavigation,
  useMetricsNavigationDispatch,
  useDashboard,
  useDashboardDispatch,
  useHospitals,
  useHospitalsDispatch,
  useDashboard2,
  useDashboard2Dispatch
} from '@clinintell/modules/store';
import { initializeDashboard, fetchDashboardList, setDashboardList } from '@clinintell/modules/dashboard';
import { initializeDashboard2 } from '@clinintell/modules/dashboard2';
import { Metrics as MetricTypes, setDefaultDates } from '@clinintell/modules/metricsNavigation';
import Widget from './Widget';
import { isIE, browserVersion } from 'react-device-detect';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { TreeJSON } from '@clinintell/modules/orgTree';
import { DefaultDatesJSON } from '@clinintell/containers/metrics/typings/metricTypes';
import useAlert from '@clinintell/components/alert/logic/useAlert';
import { containerBorderRadius } from '@clinintell/theme/theme';
import DashboardUpdate from '@clinintell/containers/dashboard/DashboardUpdate';
const hash = require('object-hash');

interface NoDataProps {
  message?: string;
}

export const NoDataMessage: React.FC<NoDataProps> = ({ message = 'No data to display' }) => {
  return (
    <Box padding={5}>
      <Typography variant="subheading">{message}</Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    '& .box': {
      overflow: 'auto',
      '& @media screen and (-webkit-min-device-pixel-ratio:0)': {
        overflow: 'scroll'
      },
      '& .MuiTableContainer-root': {
        overflow: 'inherit'
      }
    },
    '& table td:not(:last-child)': {
      color: theme.palette.common.black,
      cursor: 'auto'
    },
    '& tr[class*="footerRowClass"] td': {
      color: theme.palette.common.black
    }
  },
  gridItem: {
    padding: theme.spacing(1),
    '& > div': {
      border: `1px solid ${theme.palette.grey[300]}`,
      boxShadow: 'none',
      borderRadius: containerBorderRadius
    }
  },
  gridItemMove: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    border: `dashed 1px`,
    borderColor: `${theme.palette.grey[300]}`
  },
  closeIcon: {
    float: 'right',
    color: theme.palette.common.black,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  addWidgetButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.blue.main,
    '&:hover': {
      color: theme.palette.blue.main,
      backgroundColor: theme.palette.grey[50]
    }
  },
  cancelButton: {
    textDecoration: 'underline',
    marginRight: theme.spacing(1),
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  popupMd: {
    maxWidth: '880px'
  }
}));

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const { pushAlert } = useAlert();
  const { id: userId } = useUser();

  const { widgetList, isLoading, hasError, errorMessage } = useDashboard();

  if (hasError) {
    throw new Error(errorMessage);
  }

  const [widgetListLoading, setWidgetListLoading] = useState<boolean>(isLoading);
  const [activeWidgetList, setActiveWidgetList] = useState<WidgetJSON[]>(widgetList);
  const dashboardDispatch = useDashboardDispatch();

  const { container, gridItem } = useStyles();
  const [updateDashboard, setUpdateDashboard] = useState(false);

  useEffect(() => {
    if (updateDashboard) {
      // Use a timeout to give the server enough time to update
      window.setTimeout(() => {
        dashboardDispatch(fetchDashboardList(userId));
        setUpdateDashboard(false);
        setWidgetListLoading(false);
      }, 1000);
    }
  }, [dashboardDispatch, updateDashboard, userId]);

  const handleOnDragEnd = (result: DropResult): void => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const cloneWidgetList = [...activeWidgetList];
    const newList = ReorderList(cloneWidgetList, source.index, destination.index);
    setActiveWidgetList(newList);
    ApplicationAPI.put({
      endpoint: `dashboard/${userId}`,
      data: JSON.stringify(newList)
    }).then(res => {
      if (res.status === 200) {
        pushAlert({ message: 'Dashboard updated successfully', variant: 'success' });
        dashboardDispatch(setDashboardList(res.data as WidgetJSON[]));
      } else {
        pushAlert({ message: 'Dashboard not updated', variant: 'error' });
        setActiveWidgetList(cloneWidgetList);
      }
    });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    window.scrollTo(0, 0);
    setActiveWidgetList(widgetList);
  }, [isLoading, widgetList]);

  if (!activeWidgetList || widgetListLoading) {
    return <ClinIntellSkeleton variant="rectangular" height="30rem" width="100%" />;
  }

  const isIE11 = isIE && browserVersion === '11';
  const providerWidgets = activeWidgetList.filter(
    widget => widget.name === 'progress' || widget.name === 'd3speedometer'
  );
  const remainingWidgets = activeWidgetList.filter(
    widget => widget.name !== 'progress' && widget.name !== 'd3speedometer'
  );

  return (
    <>
      {providerWidgets.length > 0 ? (
        <Grid container className={container} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {providerWidgets.map((widget: WidgetJSON, index: number) => {
            const hashId = hash(widget);
            return (
              <Grid
                item
                key={`grid-${hashId}`}
                md={widget.size === 'xs' ? 1 : 2}
                xs={widget.name === 'd3speedometer' ? 12 : 6}
                sx={{
                  padding: 1,
                  minWidth: widget.name === 'd3speedometer' ? 350 : 200,
                  backgroundColor: 'grey.100'
                }}
                className={gridItem}
              >
                <Widget index={index} hashId={hashId} {...widget} actionPanelOpen={false} isDragDropDisabled={true} />
              </Grid>
            );
          })}
        </Grid>
      ) : null}
      {remainingWidgets.length > 0 ? (
        <>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="list">
              {(provided): JSX.Element => (
                <Grid
                  container
                  className={container}
                  style={{ margin: '0 auto' }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {remainingWidgets &&
                    remainingWidgets.map((widget: WidgetJSON, index: number) => {
                      const { size } = widget;
                      const hashId = hash(widget);
                      return (
                        <Grid
                          item
                          key={`grid-${hashId}`}
                          md={
                            size === 'xl' || size === 'xlarge' || size === 'l' || size === 'large'
                              ? 12
                              : size === 'm' || size === 'medium'
                              ? 6
                              : size === 's' || size === 'small'
                              ? 2
                              : 1
                          }
                          sm
                          xs={12}
                          sx={{
                            padding: 1,
                            backgroundColor: 'grey.100'
                          }}
                          className={gridItem}
                        >
                          <Widget
                            index={index}
                            hashId={hashId}
                            metric={widget.metric ?? MetricTypes.cmi}
                            {...widget}
                            actionPanelOpen={false}
                            isDragDropDisabled={true}
                          />
                        </Grid>
                      );
                    })}
                  {isIE && browserVersion === '11' ? (
                    <Box minHeight={theme.spacing(0.5)} mb={theme.spacing(0.5)}>
                      &nbsp;
                    </Box>
                  ) : null}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : null}
      {isIE11 ? (
        <Box minHeight={theme.spacing(1)} mb={theme.spacing(1)}>
          &nbsp;
        </Box>
      ) : null}
    </>
  );
};

const DashboardController: React.FC = () => {
  const user = useUser();
  const { role } = useUser();
  const { roleName } = role;
  const { totalCount: hospitalCount, isLoading: isHospitalLoading } = useHospitals();
  const hospitalDispatch = useHospitalsDispatch();
  const dashboardDispatch = useDashboardDispatch();
  const dashboard2Dispatch = useDashboard2Dispatch();
  const metricsNavDispatch = useMetricsNavigationDispatch();
  const { isInitialized } = useMetricsNavigation();
  const { isInitialized: isDashboardInitialized, isLoading: isDashboardLoading } = useDashboard();
  const { isDashboard2Initialized, isHospitalsWithSystemInitialized, isLoading: isDashboard2Loading } = useDashboard2();

  // Default dates API call should only be made when entity changes - there could possibly be new dates and/or intervention period
  const { output: defaultDates } = useGetAPICAll<DefaultDatesJSON>({
    endpoint: `DefaultDates/${user.rootId}`,
    isWaiting: false
  });

  const { output: orgOutput } = useGetAPICAll<TreeJSON>({
    endpoint: `org/${user.rootId}`,
    isWaiting: false
  });

  if (defaultDates && defaultDates.error) {
    throw new Error(defaultDates.error);
  }

  if (orgOutput && orgOutput.error) throw new Error(orgOutput.error);
  const isSaf = orgOutput?.data?.name.toLowerCase().includes('saf');

  const useOldDashboard = ['provider', 'clinicalleader'].includes(roleName.toLowerCase()) || isSaf;

  useEffect(() => {
    if (useOldDashboard === undefined) return; // prevents the dashboard dispatches until we know which one to call
    if (useOldDashboard) {
      if (!isDashboardInitialized && !isDashboardLoading) {
        dashboardDispatch(initializeDashboard({ id: user.id, rootId: user.rootId }));
      }
    } else {
      if (!isDashboard2Initialized && !isHospitalsWithSystemInitialized && !isDashboard2Loading) {
        dashboard2Dispatch(initializeDashboard2());
      }
    }

    if (!defaultDates || isInitialized) {
      return;
    }

    const {
      historicalMinMonth,
      historicalMaxMonth,
      currentMinMonth,
      currentMaxMonth,
      absoluteMinMonth,
      interventionMonth
    } = defaultDates.data as DefaultDatesJSON;

    metricsNavDispatch(
      setDefaultDates({
        defaultPeriodStart: currentMinMonth,
        defaultPeriodEnd: currentMaxMonth,
        defaultComparisonPeriodStart: historicalMinMonth,
        defaultComparisonPeriodEnd: historicalMaxMonth,
        absoluteMinPeriod: absoluteMinMonth,
        interventionPeriod: interventionMonth
      })
    );

    // prevent multiple calls to the same endpoint by checking the loading state
    if (hospitalCount === 0 && !isHospitalLoading) {
      hospitalDispatch({ type: 'HOSPITAL_FETCH_ALL_REQUESTED', payload: { itemCount: 50 } });
    }
  }, [
    useOldDashboard,
    defaultDates,
    metricsNavDispatch,
    dashboardDispatch,
    user,
    isInitialized,
    isDashboardInitialized,
    hospitalCount,
    isHospitalLoading,
    hospitalDispatch,
    isDashboardLoading,
    roleName,
    isDashboard2Initialized,
    isHospitalsWithSystemInitialized,
    dashboard2Dispatch,
    isSaf,
    isDashboard2Loading
  ]);

  // prevent prematurely loading a dashboard
  if (useOldDashboard === undefined && (!isDashboardInitialized || !isDashboard2Initialized)) return null;

  return useOldDashboard ? <Dashboard /> : <DashboardUpdate />;
};

export default DashboardController;
