import { useState } from 'react';

type ReportSchedulerSidePanelProps = {
  isOpen: boolean;
  isBusy: boolean;
  setOpened: (setOpened?: boolean) => void;
  setBusy: (setBusy?: boolean) => void;
};

const ReportSchedulerSidePanel = (): ReportSchedulerSidePanelProps => {
  const [isOpen, setIsOpen] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const setOpened = (setOpened?: boolean) => {
    setIsOpen(setOpened ?? !isOpen);
  };

  const setBusy = (setBusy?: boolean) => {
    setIsBusy(setBusy ?? !isBusy);
  };

  return {
    isOpen,
    isBusy,
    setOpened,
    setBusy
  };
};

export default ReportSchedulerSidePanel;
