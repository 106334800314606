import React from 'react';
import { ButtonGroup, Button, Typography } from '@mui/material';
import Tooltip from '../tooltip/Tooltip';

export interface ClinIntellButtonGroupType {
  /**
   * Callback method to indicate which button was pressed
   */
  action(id: number): void;
  /**
   * Id as Number to help in the callback
   */
  id: number;
  /**
   * Determines if the button is set or active
   */
  isActive: boolean;
  /**
   * Text label for the button. Text rendered in the case sent.
   */
  label?: string;
  /**
   * JSX icon element
   */
  startIcon?: JSX.Element;
  /**
   * Indicates if the button is disabled and not to be used
   */
  isDisabled: boolean;
  /**
   * Message indicating why the button is disabled
   */
  disabledTooltip?: string;
  /**
   * Size of the font for the label
   */
  size?: 'small' | 'large';
}

interface ClinintellButtonGroupProps {
  /**
   * List of buttons to display as a group
   */
  buttons: Array<ClinIntellButtonGroupType>;
  /**
   * ID used for testing with React-Testing-Library
   */
  testId?: string;
}

// https://github.com/mui-org/material-ui/issues/16431 makeStyles has no return type
// eslint-disable-next-line
// @ts-ignore
const renderButton = (props: ClinIntellButtonGroupType, idx: number): JSX.Element => {
  const handleButtonClick = (): void => {
    if (!props.isDisabled && !props.isActive) {
      props.action(props.id);
    }
  };
  const toolTipText = props.isDisabled && props.disabledTooltip ? props.disabledTooltip : '';

  return (
    <Tooltip key={idx} content={toolTipText}>
      <Button
        onClick={handleButtonClick}
        key={`${props.id}-${props.label}`}
        variant="outlined"
        color="secondary"
        startIcon={props.startIcon}
        size="small"
        sx={theme => ({
          flexGrow: 1,
          height: 38,
          cursor: props.isDisabled || props.isActive ? 'default' : 'pointer',
          color: props.isDisabled
            ? theme.palette.grey[300]
            : props.isActive
            ? theme.palette.teal.main
            : theme.palette.grey[900],
          background: props.isDisabled || !props.isActive ? theme.palette.common.white : theme.palette.teal.light1,
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: '10px',
          ':hover': {
            color: props.isDisabled
              ? theme.palette.grey[300]
              : props.isActive
              ? theme.palette.teal.main
              : theme.palette.grey[900],
            backgroundColor: props.isDisabled
              ? theme.palette.common.white
              : props.isActive
              ? theme.palette.teal.light1
              : theme.palette.grey[50],
            borderColor: theme.palette.grey[300],
            '& p': {
              color: props.isDisabled ? theme.palette.grey[300] : 'inherit',
              fontWeight: props.isActive && !props.isDisabled ? 600 : 400
            }
          }
        })}
      >
        <Typography
          variant={!props.size || props.size === 'large' ? 'body2' : 'caption'}
          sx={{
            textTransform: 'none',
            fontWeight: props.isActive && !props.isDisabled ? 600 : 400
          }}
        >
          {props.label}
        </Typography>
      </Button>
    </Tooltip>
  );
};

const ClinIntellButtonGroup: React.FC<ClinintellButtonGroupProps> = (props: ClinintellButtonGroupProps) => {
  return (
    <ButtonGroup data-testid={props.testId} aria-label="choose one">
      {props.buttons.map((btn: ClinIntellButtonGroupType, idx: number) => renderButton(btn, idx))}
    </ButtonGroup>
  );
};

export default ClinIntellButtonGroup;
