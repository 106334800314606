import React from 'react';
import { DRGMixTableRow } from '@clinintell/containers/cmiComparison/typings/cmiComparisonDtos';
import { Box, useTheme } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { formatNumberWithCommas, formatToDecimalPlaces } from '@clinintell/utils/formatting';
import { IntegerCell, NumberCell, Table } from '@clinintell/components/table_v2';

type DRGImpactTableProps = {
  tableRows: DRGMixTableRow[];
  footerRow?: DRGMixTableRow;
  defaultSortDirection: 'asc' | 'desc';
  referenceLabel: string;
  comparerLabel: string;
  changeLabel: string;
};

const DRGImpactTable: React.FC<DRGImpactTableProps> = ({
  tableRows,
  defaultSortDirection,
  referenceLabel,
  comparerLabel,
  changeLabel
}) => {
  const theme = useTheme();

  let totalCmiImpact = 0;
  tableRows.forEach(row => {
    totalCmiImpact += Number(formatToDecimalPlaces(row.cmiImpact, 3));
  });

  const footerData: Omit<DRGMixTableRow, 'drgValue' | 'entity1' | 'entity2'> & {
    drgValue: string;
    entity1: undefined;
    entity2: undefined;
  } = {
    drgCode: -1,
    drgValue: '',
    description: '',
    entity1: undefined,
    entity2: undefined,
    shareChange: -1,
    cmiImpact: totalCmiImpact
  };

  return (
    <Table<DRGMixTableRow>
      rowData={tableRows}
      // Just doing this as a partial footer data to work with UI requirements
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      footerData={footerData}
      tableHeight={500}
      renderColumns={({ renderColumn }) => {
        return [
          renderColumn('drgCode', {
            headerName: 'DRG Code',
            cellRenderer: props => (Number(props.value) > 0 ? <IntegerCell value={Number(props.value)} /> : <></>),
            cellStyle: { justifyContent: 'center' },
            sortable: true
          }),
          renderColumn('drgValue', {
            headerName: 'Relative Value',
            cellRenderer: props =>
              props.value ? (
                <Box
                  component="span"
                  fontWeight="bold"
                  fontSize={theme.typography.p2.fontSize}
                  fontFamily={theme.typography.fontFamily}
                  color={props.value === 'high' ? theme.palette.green[700] : theme.palette.red[500]}
                  width="100%"
                  textAlign="center"
                >
                  {props.value.toUpperCase()}
                </Box>
              ) : (
                <></>
              ),
            sortable: true
          }),
          renderColumn('description', {
            headerName: 'Description',
            cellRenderer: props => props.value || '',
            sortable: true,
            headerAlignment: 'left',
            minWidth: 300
          }),
          renderColumn('entity1', {
            headerName: `${referenceLabel} Share`,
            cellRenderer: props => (props.value ? <NumberCell value={props.value.share} postfix="%" /> : <></>),
            valueFormatter: props => (props.value ? props.value.share : 0),
            sortable: true,
            cellStyle: {
              justifyContent: 'center'
            }
          }),
          renderColumn('entity2', {
            headerName: `${comparerLabel} Share`,
            cellRenderer: props => (props.value ? <NumberCell value={props.value.share} postfix="%" /> : <></>),
            valueFormatter: props => (props.value ? props.value.share : 0),
            sortable: true,
            cellStyle: {
              justifyContent: 'center'
            }
          }),
          renderColumn('shareChange', {
            headerName: changeLabel,
            sortable: true,
            cellRenderer: props =>
              props.value !== -1 ? (
                <Box width="100%" flexDirection="row" justifyContent="center" display="flex" alignItems="center">
                  <ArrowRightAltIcon
                    style={{
                      transform: Number(props.value) < 0 ? 'rotate(90deg)' : 'rotate(270deg)'
                    }}
                  />
                  <span>{formatNumberWithCommas(formatToDecimalPlaces(Number(props.value), 2))}%</span>
                </Box>
              ) : (
                <></>
              )
          }),
          renderColumn('cmiImpact', {
            headerName: 'CMI Impact',
            cellRenderer: props => (
              <Box width="100%" textAlign="center" component="span">
                {formatToDecimalPlaces(Number(props.value), 3)}
              </Box>
            ),
            sortable: true,
            sort: defaultSortDirection
          })
        ];
      }}
    />
  );
};

export default DRGImpactTable;
