import makeStyles from '@mui/styles/makeStyles';

// Used to heavily override the Auth0 widgets to conform to ClinIntell style standards. This must be used within the base site theme
// provider, as it references colors and such.
const useAuth0WidgetStyles = makeStyles(theme => ({
  auth0WidgetStyles: {
    // Remove certain Auth0 widget elements
    '& .auth0-lock-overlay, .auth0-lock-close-button, .auth0-lock-tabs-container, .auth0-lock-terms, .auth0-lock-header-logo, .auth0-lock.auth0-lock .auth0-lock-header-bg.auth0-lock-blur-support .auth0-lock-header-bg-blur, .auth0-label-submit > span': {
      display: 'none !important'
    },
    // Set fonts
    '& .auth0-lock.auth0-lock': {
      zIndex: 1000,
      fontFamily: theme.typography.fontFamily
    },
    // Height/width overrides
    '& .auth0-lock.auth0-lock .auth0-lock-widget': {
      width: '100% !important'
    },
    '& .auth0-lock-widget': {
      [theme.breakpoints.up('md')]: {
        maxWidth: '25rem'
      }
    },
    '& .auth0-lock.auth0-lock.auth0-lock-opened-in-frame': {
      marginLeft: '0 !important',
      marginRight: '0 !important',
      width: '100% !important'
    },
    // Get rid of all header backgrounds
    '& .auth0-lock.auth0-lock .auth0-lock-header-bg': {
      background: '#fff !important',
      backgroundColor: '#fff !important',
      opacity: 0
    },
    // Status message color overrides
    '& .auth0-lock.auth0-lock .auth0-lock-error .auth0-lock-input-wrap': {
      borderColor: theme.palette.red[500]
    },
    '& .auth0-lock-error-msg': {
      display: 'none'
    },
    '& .auth0-global-message': {
      [theme.breakpoints.down('md')]: {
        width: 'calc(90vw - 60px)',
        marginLeft: '20px',
        borderRadius: '2px'
      }
    },
    /* 
      Styling for the InputFields 
    */
    '& .auth0-lock-input-email': {
      width: '90%',
      margin: '0 auto'
    },
    '& .auth0-lock-input-password': {
      width: '90%',
      margin: '0 auto'
    },
    // Removes the Icons from the input fields
    '& .auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon': {
      paddingLeft: 0
    },
    // Adds the background color to the inputfield
    '& .auth0-lock-input-wrap .auth0-lock-input': {
      backgroundColor: theme.palette.grey[100]
    },
    // Adds the border radius to the inputfield
    '& .auth0-lock.auth0-lock .auth0-lock-input-wrap': {
      borderRadius: '8px'
    },
    '& .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input': {
      borderRadius: '8px'
    },
    '& .auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-focused': {
      borderColor: theme.palette.blue.main
    },
    '& .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error': {
      background: theme.palette.red[50],
      marginTop: theme.spacing(1),
      '& > span > span': {
        color: theme.palette.red[500]
      },
      textTransform: 'unset !important'
    },
    '& .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-success': {
      background: theme.palette.green[50],
      marginTop: theme.spacing(1),
      '& > span > span': {
        color: theme.palette.green[500]
      },
      textTransform: 'unset !important'
    },
    // Customizing the wrapper border radius
    '& .auth0-lock-widget-container': {
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)'
    },
    '& .auth0-lock.auth0-lock .auth0-lock-header': {
      borderRadius: '8px 8px 0 0'
    },
    '& .auth0-lock.auth0-lock .auth0-lock-cred-pane': {
      borderRadius: '8px'
    },
    // Error Banner styling calc 90% - 32px because the inputs have a width of 90% with a 16px margin left and right and additional padding
    '& .auth0-global-message-error': {
      display: 'flex',
      flexDirection: 'row',
      height: 'auto',
      width: 'calc(90% - 60px)',
      margin: '0 auto',
      marginTop: '15px',
      padding: '10px 16px',
      alignItems: 'left !important',
      borderRadius: '8px',
      background: theme.palette.red[100],
      color: theme.palette.red[700],
      textTransform: 'capitalize !important',
      '&::before': {
        content: `'⚠'`,
        fontSize: '20px',
        color: theme.palette.red[700]
      }
    },
    '& .error-banner': {
      display: 'flex',
      flexDirection: 'row',
      height: 'auto',
      width: 'calc(90% - 60px)',
      margin: '0 auto',
      marginTop: '15px',
      padding: '10px 14px',
      alignItems: 'center',
      borderRadius: '8px',
      background: theme.palette.red[100],
      color: theme.palette.red[700]
    },
    '& .error-banner .error-banner-icon': {
      fontSize: '1.5rem',
      fontWeight: '400',
      color: theme.palette.red[700]
    },
    '& .error-banner-msg': {
      padding: 0,
      paddingLeft: '10px',
      margin: 0,
      fontSize: theme.typography.caption.fontSize
    },
    '& .auth0-lock.auth0-lock .auth0-global-message': {
      display: 'flex',
      flexDirection: 'row',
      height: 'auto',
      width: 'calc(90% - 60px)',
      margin: '0 auto',
      marginTop: '15px',
      padding: '10px 14px',
      alignItems: 'center',
      borderRadius: '8px',
      textTransform: 'capitalize !important'
    },
    // Custom styling for the submit button
    '& .auth0-lock.auth0-lock .auth0-lock-submit': {
      width: '80%',
      height: 'auto',
      padding: 0,
      margin: '0 auto',
      marginBottom: '70px',
      borderRadius: '8px'
    },
    '& .auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative .auth0-lock-alternative-link': {
      color: theme.palette.blue.main
    },
    '& .auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative': {
      marginBottom: '1rem'
    },
    '& .auth0-lock-alternative': {
      position: 'absolute',
      left: '25%',
      bottom: '-70%',
      width: '50%'
    },
    '& .auth0-lock-alternative-link': {
      color: theme.palette.blue.main
    },
    // Custom Styling for the footer link
    '& .footer-support': {
      width: 'fit-content',
      margin: '0 auto',
      fontSize: theme.typography.p1.fontSize,
      color: theme.palette.grey[700]
    },
    // Styling the forgot password message
    '& .auth0-lock.auth0-lock .auth0-lock-form p': {
      color: theme.palette.grey[700],
      '& span': {
        display: 'flex',
        width: '80%',
        margin: '0 auto'
      }
    },
    // Custom styling
    '& .auth0-lock-header': {
      position: 'relative',
      backgroundColor: '#fff !important',
      color: `${theme.palette.blue.main} !important`,
      padding: `${theme.spacing(7)} 0 0 0 !important`,
      textAlign: 'left !important',
      fontSize: `${theme.typography.h4.fontSize} !important`,
      [theme.breakpoints.down('md')]: {
        background: `${theme.palette.blue.main} !important`
      }
    },
    '& .auth0-lock-alternative-link, .auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative .auth0-lock-alternative-link': {
      fontSize: '15px'
    },
    '& .auth0-lock-cred-pane-internal-wrapper': {
      [theme.breakpoints.down('md')]: {
        height: 'inherit !important'
      }
    },
    '& .auth0-lock.auth0-lock .auth0-lock-name': {
      marginTop: '.75rem',
      fontWeight: 600,
      fontSize: theme.typography.h6.fontSize,
      textAlign: 'center',
      color: theme.palette.grey[700],
      [theme.breakpoints.down('md')]: {
        padding: '0 !important'
      }
    },
    '& .auth0-lock.auth0-lock .auth0-lock-close-button': {
      boxShadow: '0 6px 3px rgba(0, 0, 0, 0.1)'
    },
    '& .auth0-lock.auth0-lock .auth0-lock-back-button': {
      height: '12px',
      width: '12px',
      [theme.breakpoints.up('md')]: {
        border: `2px solid ${theme.palette.blue.main}`,
        padding: '3px'
      },
      '&:hover': {
        boxShadow: '0 6px 3px rgba(0, 0, 0, 0.1)'
      }
    },
    '& .auth0-label-submit': {
      fontWeight: 700,
      textTransform: 'capitalize',
      fontSize: theme.typography.p1.fontSize,
      '& > svg': {}
    },
    // The mobile view uses a gradient background, so disable all internal background values by making them opaque
    '& .auth0-lock.auth0-lock .auth0-lock-header, .auth0-lock-cred-pane-internal-wrapper, .auth0-lock-content-wrapper': {
      [theme.breakpoints.down('md')]: {
        background: 'rgba(0,0,0,0) !important'
      }
    },
    '& .auth0-lock': {
      paddingBottom: '2rem'
    },
    '& .auth0-lock-header #undefined-back-button': {
      width: 'fit-content',
      height: 'fit-content',
      borderColor: 'transparent',
      padding: 0,
      boxShadow: 'none',
      '& > p': {
        fontSize: theme.typography.p2.fontSize,
        color: theme.palette.primary.main
      },
      '& > p::before': {
        content: '"\\2190"',
        paddingRight: '.5rem'
      }
    },
    '& .auth0-lock-password-strength': {
      position: 'fixed',
      bottom: '140px',
      [theme.breakpoints.down('md')]: {
        bottom: '110px',
        background: 'rgb(0, 0, 0)',
        boxShadow: '0 0 20px 0 rgba(0,0,0,0.5)',
        borderRadius: '3px',
        left: 0
      }
    },
    '& .auth0-lock-password-strength ul li ul li': {
      color: 'white',
      '&.auth0-lock-checked': {
        color: '#7ed321'
      }
    },
    '& .auth0-lock .auth0-sso-notice-container': {
      margin: '-10px -20px 20px',
      [theme.breakpoints.down('md')]: {
        margin: '-10px 0 20px',
        background: '#f1f1f1',
        borderRadius: '2px'
      }
    },
    '& .auth0-lock.auth0-lock .auth0-lock-center': {
      padding: '40px',
      height: '100%',
      display: 'flex'
    }
  }
}));

export default useAuth0WidgetStyles;
