import { SelectReportGroup, SelectReportHospitals } from '@clinintell/containers/common';
import { useDefaultDates, useDefaultDatesDispatch } from '@clinintell/modules/store';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { SelectOptionType } from '@clinintell/types/common';

type HospitalAndGroupFilterProps = {
  groupId?: number;
  hospitalId?: number;
  reportType?: string;
  onPhysicianGroupChange: (groupId: number | null) => void;
  onHospitalChange: (hospitalId: number | null) => void;
  width?: number | string;
};

const HospitalAndGroupFilter: React.FC<HospitalAndGroupFilterProps> = ({
  groupId,
  hospitalId,
  reportType,
  onPhysicianGroupChange,
  onHospitalChange,
  width = '100%'
}) => {
  const defaultDatesDispatch = useDefaultDatesDispatch();
  const defaultDates = useDefaultDates();

  const [groupArray, setGroupArray] = useState<SelectOptionType[]>([]);

  // Fetch default dates if needed for group
  useEffect(() => {
    if (groupId === undefined || groupId === -1 || defaultDates.byId[groupId]) {
      return;
    }
    defaultDatesDispatch({
      type: 'DEFAULT_DATES_FETCH_REQUESTED',
      payload: groupId
    });
  }, [defaultDates.byId, defaultDatesDispatch, groupId, reportType]);

  return (
    <>
      <Box p={1}>
        <SelectReportHospitals
          defaultValue={hospitalId}
          width={width}
          onHospitalChangeFn={(value, _, physicianGroups) => {
            onHospitalChange(value);
            setGroupArray(physicianGroups ?? []);
          }}
        />
      </Box>
      <Box p={1}>
        <SelectReportGroup
          hospitalId={hospitalId}
          onGroupChangeFn={onPhysicianGroupChange}
          defaultValue={groupId}
          width={width}
          physicianGroupData={groupArray}
        />
      </Box>
    </>
  );
};

export default React.memo(HospitalAndGroupFilter);
