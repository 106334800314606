import React from 'react';
import { Box, Card, useTheme, Typography } from '@mui/material';
import {
  ColumnView,
  getDefaultMetricTableColumns,
  getDefaultViewForMetric
} from '@clinintell/containers/metrics/typings/tableSchemas';
import {
  GraphInfo,
  QueryParams,
  TableInfo,
  WidgetType,
  WidgetSize
} from '@clinintell/containers/dashboard/widgetTypes';
import TableWidget from '@clinintell/components/Widgets/TableWidget';
import GraphWidget from '@clinintell/components/Widgets/GraphWidget';
import ProgressWidget from '@clinintell/components/Widgets/ProgressWidget';
import SpeedometerWidget from '@clinintell/components/Widgets/SpeedometerWidget';
import { ChartDataSetAverageTypes } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { Metrics as MetricTypes } from '@clinintell/modules/metricsNavigation';

interface Props {
  index: number;
  hashId: string;
  name: string;
  endpoint: string;
  title?: string;
  entity?: number;
  entityName?: string;
  division?: number;
  divisionName?: string;
  hospital?: number;
  hospitalName?: string;
  group?: number;
  groupName?: string;
  metric?: MetricTypes;
  widgetType: WidgetType;
  size: WidgetSize;
  queryString?: QueryParams;
  graphInfo?: GraphInfo;
  tableInfo?: TableInfo;
  customSettings?: QueryParams;
  actionPanelOpen: boolean;
  isDragDropDisabled: boolean;
}

const Widget: React.FC<Props> = props => {
  const theme = useTheme();

  const { hashId, name, title, widgetType, metric, graphInfo, tableInfo } = props;
  const defaultColumns: ColumnView = getDefaultMetricTableColumns(MetricTypes.cmi);

  if (name === 'progress' || name === 'd3speedometer') {
    return (
      <Card className={name}>
        {title ? (
          <Box component="div" padding={1} display="flex" alignItems="center" ml={1} style={{ minHeight: 45 }}>
            <Typography variant="h5" component="span">
              {title}
            </Typography>
          </Box>
        ) : null}
        <Box>
          {widgetType.toLowerCase() === 'progress' ? <ProgressWidget {...props} /> : <SpeedometerWidget {...props} />}
        </Box>
      </Card>
    );
  }

  return (
    <Card className={name} key={`card-${hashId}`}>
      {title && (
        <Box
          key={`content-title-${hashId}`}
          component="div"
          padding={1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 45
          }}
        >
          <Box sx={{ pl: 1 }}>
            <Box
              sx={{
                typography: 'h4',
                fontSize: '1.25rem',
                fontWeight: 600,
                color: theme.palette.primary.main
              }}
            >
              {title}
            </Box>
          </Box>
        </Box>
      )}
      <Box key={`content-${hashId}`}>
        {widgetType.toLowerCase() === 'table' ? (
          <TableWidget
            tableInfo={
              tableInfo
                ? tableInfo
                : {
                    columns: defaultColumns.columns,
                    defaultSort: defaultColumns.defaultSort,
                    sortDirection: 'desc',
                    limit: 10,
                    view: getDefaultViewForMetric(metric ?? MetricTypes.cmi)
                  }
            }
            metric={metric ?? MetricTypes.cmi}
            {...props}
          />
        ) : widgetType.toLowerCase() === 'progress' ? (
          <ProgressWidget {...props} />
        ) : graphInfo && graphInfo.graphType === 'speedometer' ? (
          <SpeedometerWidget {...props} />
        ) : (
          <GraphWidget
            metric={props.metric ?? MetricTypes.docScore}
            graphInfo={
              graphInfo
                ? graphInfo
                : {
                    graphType: 'line',
                    dataSetAverageTypes: ChartDataSetAverageTypes.ThreeMonth,
                    filteredDatasets: []
                  }
            }
            {...props}
          />
        )}
      </Box>
    </Card>
  );
};

export default Widget;
