import React, { createContext, useContext, useState } from 'react';

type ConfidentialityAgreementState = boolean;
type ConfidentialityAgreementDispatch = React.Dispatch<React.SetStateAction<boolean>>;

const ConfidentialityAgreementStateContext = createContext<ConfidentialityAgreementState>(false);
const ConfidentialityAgreementDispatchContext = createContext<ConfidentialityAgreementDispatch>(() => {
  /* empty function */
});

export const ConfidentialityAgreementProvider: React.FC = ({ children }) => {
  const [hasAgreed, setHasAgreed] = useState(false);

  return (
    <ConfidentialityAgreementStateContext.Provider value={hasAgreed}>
      <ConfidentialityAgreementDispatchContext.Provider value={setHasAgreed}>
        {children}
      </ConfidentialityAgreementDispatchContext.Provider>
    </ConfidentialityAgreementStateContext.Provider>
  );
};

export const useConfidentialityAgreementState = (): ConfidentialityAgreementState => {
  const context = useContext(ConfidentialityAgreementStateContext);

  if (context === undefined || context === null) {
    throw new Error('useConfidentialityAgreementState hook must be used within a ConfidentialityAgreementProvider');
  }

  return context;
};

export const useConfidentialityAgreementDispatch = (): ConfidentialityAgreementDispatch => {
  const context = useContext(ConfidentialityAgreementDispatchContext);
  if (!context) {
    throw new Error('useConfidentialityAgreementDispatch hook must be used within a ConfidentialityAgreementProvider');
  }

  return context;
};
