import { Box } from '@mui/material';
import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  useCMIAnalysisDispatch,
  useCMIAnalysisSelectionsDispatch,
  useCMIComparisonDispatch
} from '@clinintell/modules/store';
import { clearAnalysisType } from '@clinintell/modules/cmiAnalysisSelections';
import Button from '@clinintell/components/button/Button';
import { resetCMIAnalysis } from '@clinintell/modules/cmiAnalysis';
import { resetCMIComparison } from '@clinintell/modules/cmiComparison';

const GoBackToLandingPage: React.FC = ({ children }) => {
  const cmiAnalysisSelectionsDispatch = useCMIAnalysisSelectionsDispatch();
  const cmiAnalysisDispatch = useCMIAnalysisDispatch();
  const cmiComparisonDispatch = useCMIComparisonDispatch();

  const handleGoBackClick = () => {
    cmiAnalysisSelectionsDispatch(clearAnalysisType());
    // Reset data in CMI Analysis and CMI Comparison back to initial state - otherwise coming back to screens with new entity causes visual glitch with old data
    cmiAnalysisDispatch(resetCMIAnalysis());
    cmiComparisonDispatch(resetCMIComparison());
  };

  return (
    <>
      <Box sx={{ mt: 2, ml: 2 }}>
        <Button
          variant="text"
          onClick={handleGoBackClick}
          leftAdornment={<ChevronLeftIcon sx={{ mt: '4px' }} />}
          label="Back to CMI Analysis"
        />
      </Box>
      {children}
    </>
  );
};

export default GoBackToLandingPage;
