import { Dispatch, SetStateAction, useState } from 'react';

type UseTreeExpandedNodesOutput = {
  expandedNodes: string[];
  setExpandedNodes: Dispatch<SetStateAction<string[]>>;
};

const useExpandedTreeNodes = (defaultExpandedNodes: string[]): UseTreeExpandedNodesOutput => {
  const [expandedNodes, setExpandedNodes] = useState<string[]>(defaultExpandedNodes);

  return {
    expandedNodes,
    setExpandedNodes
  };
};

export default useExpandedTreeNodes;
