import { ChartDataSetJSON } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { OrgTreeTypes } from '@clinintell/modules/orgTree';
import { ApplicationAPI } from '@clinintell/utils/api';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import { useEffect, useState } from 'react';

type UseTimeSeriesDataProps = {
  minChartPeriod?: Date;
  maxChartPeriod?: Date;
  orgId?: number;
  metric: string;
  orgTreeType?: OrgTreeTypes;
};

type UseTimeSeriesDataOutput = {
  data?: ChartDataSetJSON;
  isLoading: boolean;
};

const useTimeSeriesData = ({
  minChartPeriod,
  maxChartPeriod,
  orgId,
  metric,
  orgTreeType = 'client'
}: UseTimeSeriesDataProps): UseTimeSeriesDataOutput => {
  const [chartData, setChartData] = useState<ChartDataSetJSON>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!minChartPeriod || !maxChartPeriod || !orgId) {
      return;
    }

    const fetchTimeSeriesData = async (): Promise<void> => {
      setIsLoading(true);

      const { error, data } = await ApplicationAPI.get<ChartDataSetJSON>({
        endpoint: `${orgTreeType === 'saf' ? 'saf/' : ''}Graph/${metric}/${orgId}?StartDate=${formatDateForAPI(
          minChartPeriod
        )}&EndDate=${formatDateForAPI(maxChartPeriod)}`
      });

      if (data) {
        setChartData(data);
      } else if (error) {
        throw new Error(`Error fetching time series data - ${error}`);
      }

      setIsLoading(false);
    };

    fetchTimeSeriesData();
    // don't want orgId changes causing this to refresh, it's glitchy and this gets re-executed anyways
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxChartPeriod, metric, minChartPeriod, orgId]);

  return {
    data: chartData,
    isLoading
  };
};

export default useTimeSeriesData;
