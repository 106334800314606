import PageTitle from '@clinintell/components/typography/pageTitle/PageTitle';
import { Box } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { containerBorderRadius } from '@clinintell/theme/theme';

type ReportContainerContextArgs<T> = {
  reportFilterState: T;
  setReportFilterState: React.Dispatch<React.SetStateAction<T>>;
};

// undefined! tells the context that there will be a default value at runtime
// type T still throws a TS error
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ReportContainerContext = createContext<ReportContainerContextArgs<T>>(undefined!);

type ReportContainerProps<T> = {
  title: string;
  children: JSX.Element[] | JSX.Element;
  defaultState: T;
};

type ReportContainerComposition = {
  Filters?: React.FC;
  Content?: React.FC;
};

const ReportContainer = <T,>({
  title,
  children,
  defaultState
}: ReportContainerProps<T> & ReportContainerComposition) => {
  const [reportFilterState, setReportFilterState] = useState<T>({ ...defaultState });
  const navigate = useNavigate();

  const onGoBack = () => {
    navigate('/reports');
  };

  return (
    <ReportContainerContext.Provider value={{ reportFilterState, setReportFilterState }}>
      <Box m={3}>
        {/* Hover effect for the Button in this container doesn't work, so simulating the button */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'blue.main',
            cursor: 'pointer',
            ml: -1.5,
            maxWidth: 155,
            fontWeight: 500,
            '&:hover': { backgroundColor: 'blue.light5', borderRadius: `${containerBorderRadius}px` }
          }}
          onClick={onGoBack}
        >
          <ChevronLeftIcon sx={{ mx: 1 }} />
          <Box>Back to Reports</Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <PageTitle title={title} />
        </Box>
        {children}
      </Box>
    </ReportContainerContext.Provider>
  );
};

const Filters = <T,>({ children }: { children: (context: ReportContainerContextArgs<T>) => JSX.Element }) => {
  const reportFilterContext = useContext(ReportContainerContext) as ReportContainerContextArgs<T>;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 5 }}>
      {children({ ...reportFilterContext })}
    </Box>
  );
};

const Content = <T,>({ children }: { children: (context: ReportContainerContextArgs<T>) => JSX.Element }) => {
  const reportFilterContext = useContext(ReportContainerContext);

  return <>{children({ ...reportFilterContext })}</>;
};

ReportContainer.Filters = Filters;
ReportContainer.Content = Content;

export default ReportContainer;
