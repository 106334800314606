import { formatNumberWithCommas, formatToDecimalPlaces } from '@clinintell/utils/formatting';
import React from 'react';

type NumberCellProps = {
  value: number;
  prefix?: string;
  postfix?: string;
};

export const NumberCell: React.FC<NumberCellProps> = ({ value, prefix, postfix }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '10px', transform: 'translateX(10px)' }}>
      <div>{`${prefix || ''}${formatNumberWithCommas(formatToDecimalPlaces(value, 2))}${postfix || ''}`}</div>
    </div>
  );
};
