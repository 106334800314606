import { format } from 'date-fns';
import React from 'react';

type DateCellProps = {
  date: string;
};

export const DateCell: React.FC<DateCellProps> = ({ date }) => {
  return <div>{format(new Date(date), 'MM/dd/yyyy')}</div>;
};
