import { useMetricsNavigation } from '@clinintell/modules/store';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type UseSessionOpportunityPreferencesOutput = {
  exmSessionWt: number;
  exrSessionWt: number;
  hccSessionWt: number;
  psiSessionWt: number;
  psi90SessionWt: number;
  psi04SessionWt: number;
  psi02SessionWt: number;
  psi07SessionWt: number;
  setExmSessionWt: Dispatch<SetStateAction<number>>;
  setExrSessionWt: Dispatch<SetStateAction<number>>;
  setHccSessionWt: Dispatch<SetStateAction<number>>;
  setPSISessionWt: Dispatch<SetStateAction<number>>;
  setPSI90SessionWt: Dispatch<SetStateAction<number>>;
  setPSI04SessionWt: Dispatch<SetStateAction<number>>;
  setPSI02SessionWt: Dispatch<SetStateAction<number>>;
  setPSI07SessionWt: Dispatch<SetStateAction<number>>;
  originalPSI90Wt: number;
  originalPSI04Wt: number;
  originalPSI02Wt: number;
  originalPSI07Wt: number;
};

const useSessionOpportunityPreferences = (): UseSessionOpportunityPreferencesOutput => {
  const {
    hccWeight,
    exmWeight,
    exrWeight,
    psiWeight,
    psi90Weight,
    psi04Weight,
    psi02Weight,
    psi07Weight
  } = useMetricsNavigation();

  const [exmSessionWt, setExmSessionWt] = useState(exmWeight);
  const [exrSessionWt, setExrSessionWt] = useState(exrWeight);
  const [hccSessionWt, setHccSessionWt] = useState(hccWeight);
  const [psiSessionWt, setPSISessionWt] = useState(psiWeight);
  const [psi90SessionWt, setPSI90SessionWt] = useState(psi90Weight);
  const [psi04SessionWt, setPSI04SessionWt] = useState(psi04Weight);
  const [psi02SessionWt, setPSI02SessionWt] = useState(psi02Weight);
  const [psi07SessionWt, setPSI07SessionWt] = useState(psi07Weight);

  const [originalPSI90Wt, setOriginalPSI90Wt] = useState(psi90Weight);
  const [originalPSI04Wt, setOriginalPSI04Wt] = useState(psi04Weight);
  const [originalPSI02Wt, setOriginalPSI02Wt] = useState(psi02Weight);
  const [originalPSI07Wt, setOriginalPSI07Wt] = useState(psi07Weight);

  useEffect(() => {
    setExmSessionWt(exmWeight);
  }, [exmWeight]);

  useEffect(() => {
    setExrSessionWt(exrWeight);
  }, [exrWeight]);

  useEffect(() => {
    setHccSessionWt(hccWeight);
  }, [hccWeight]);

  useEffect(() => {
    setPSISessionWt(psiWeight);
  }, [psiWeight]);

  useEffect(() => {
    setPSI90SessionWt(psi90Weight);
    setOriginalPSI90Wt(psi90Weight);
  }, [psi90Weight]);

  useEffect(() => {
    setPSI04SessionWt(psi04Weight);
    setOriginalPSI04Wt(psi04Weight);
  }, [psi04Weight]);

  useEffect(() => {
    setPSI02SessionWt(psi02Weight);
    setOriginalPSI02Wt(psi02Weight);
  }, [psi02Weight]);

  useEffect(() => {
    setPSI07SessionWt(psi07Weight);
    setOriginalPSI07Wt(psi07Weight);
  }, [psi07Weight]);

  return {
    exmSessionWt,
    exrSessionWt,
    hccSessionWt,
    psiSessionWt,
    psi90SessionWt,
    psi04SessionWt,
    psi02SessionWt,
    psi07SessionWt,
    setExmSessionWt,
    setExrSessionWt,
    setHccSessionWt,
    setPSISessionWt,
    setPSI90SessionWt,
    setPSI04SessionWt,
    setPSI02SessionWt,
    setPSI07SessionWt,
    originalPSI90Wt,
    originalPSI04Wt,
    originalPSI02Wt,
    originalPSI07Wt
  };
};

export default useSessionOpportunityPreferences;
