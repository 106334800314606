import useMyDefaultDates from '@clinintell/components/analysisSharedComponents/useMyDefaultDates';
import { Feature } from '@clinintell/containers/authentication/rules';
import CMIComparison from '@clinintell/containers/cmiComparison/CMIComparison';
import { setCMIComparisonSettings, setComparisonRecords } from '@clinintell/modules/cmiComparison';
import {
  useCMIAnalysisSelectionsState,
  useCMIComparisonDispatch,
  useCMIComparisonState
} from '@clinintell/modules/store';
import React, { useEffect } from 'react';

const CMIComparisonController: React.FC = () => {
  const { entityId, peerEntityId, entityName, peerEntityName, analysisType } = useCMIAnalysisSelectionsState();
  const { orgId, comparisonOrgId } = useCMIComparisonState();
  const cmiComparisonDispatch = useCMIComparisonDispatch();

  const orgTreeType = analysisType === 'nationalcomparison' ? 'saf' : 'client';
  const comparisonViewOfProvidersRole: Feature =
    analysisType === 'nationalcomparison'
      ? 'reportMedicareComparisonOfProvidersView'
      : 'reportCmiComparisonOfProvidersView';
  const defaultDates = useMyDefaultDates(entityId, orgTreeType);

  useEffect(() => {
    if (
      !defaultDates ||
      !defaultDates.currentMinMonth ||
      !defaultDates.currentMaxMonth ||
      entityId === undefined ||
      peerEntityId === undefined ||
      // If CMI Comparison has already been initialized with entities, no need to set intial records
      (orgId !== undefined && comparisonOrgId !== undefined)
    ) {
      return;
    }

    cmiComparisonDispatch(
      setCMIComparisonSettings({
        orgId: entityId,
        orgName: entityName,
        comparisonOrgId: peerEntityId,
        comparisonOrgName: peerEntityName,
        comparisonMinDate: defaultDates.currentMinMonth,
        comparisonMaxDate: defaultDates.currentMaxMonth,
        chartMinDate: defaultDates.historicalMinMonth || undefined,
        chartMaxDate: defaultDates.currentMaxMonth,
        orgTreeType,
        comparisonViewOfProvidersRole
      })
    );

    cmiComparisonDispatch(
      setComparisonRecords({
        orgId: entityId,
        comparisonOrgId: peerEntityId,
        comparisonMin: defaultDates.currentMinMonth,
        comparisonMax: defaultDates.currentMaxMonth,
        orgTreeType
      })
    );
  }, [
    analysisType,
    cmiComparisonDispatch,
    comparisonOrgId,
    comparisonViewOfProvidersRole,
    defaultDates,
    entityId,
    entityName,
    orgId,
    orgTreeType,
    peerEntityId,
    peerEntityName
  ]);

  return (
    <CMIComparison
      title={analysisType === 'nationalcomparison' ? 'National Medicare Comparison' : 'Entity Comparison'}
    />
  );
};

export default CMIComparisonController;
