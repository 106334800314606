import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

type ThreeLinesButtonProps = {
  /**
   * When the button is in a "clicked" state, it is considered open
   */
  isOpen: boolean;
  /**
   * Even that fires when the button is clicked
   */
  onClick: () => void;
  /**
   * Extra styles that will be added to the sx property
   */
  sx?: SxProps<Theme>;
};

const ThreeLinesButton: React.FC<ThreeLinesButtonProps> = ({ sx, isOpen, onClick }) => {
  return (
    <Box
      component="button"
      onClick={onClick}
      sx={{
        position: 'relative',
        width: '20px',
        height: '20px',
        border: 'none',
        backgroundColor: 'inherit',
        '&:hover': {
          cursor: 'pointer'
        },
        ...sx
      }}
    >
      <Box
        sx={{
          width: '20px',
          position: 'absolute',
          left: '-2px'
        }}
      >
        <Box
          sx={{
            borderBottom: '2px solid',
            borderColor: 'blue.main',
            position: 'relative',
            width: '100%',
            visibility: isOpen ? 'hidden' : 'visible',
            '&:before': {
              borderBottom: '2px solid',
              borderColor: 'blue.main',
              top: isOpen ? '50%' : '5px',
              position: 'absolute',
              content: '""',
              width: '100%',
              transition: 'transform 100ms ease-in-out',
              ...(isOpen ? { transform: 'rotate(-45deg)' } : null),
              visibility: 'visible',
              left: 0
            },
            '&:after': {
              borderBottom: '2px solid',
              borderColor: 'blue.main',
              top: isOpen ? '50%' : '-5px',
              position: 'absolute',
              content: '""',
              width: '100%',
              transition: 'transform 100ms ease-in-out',
              ...(isOpen ? { transform: 'rotate(45deg)' } : null),
              visibility: 'visible',
              left: 0
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default ThreeLinesButton;
