import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TrainingResume = (props: SvgIconProps): JSX.Element => (
  <SvgIcon style={{ fontSize: '2.5rem' }} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 18.625L18 13L10.5 7.375V18.625ZM13 0.5C6.1 0.5 0.5 6.1 0.5 13C0.5 19.9 6.1 25.5 13 25.5C19.9 25.5 25.5 19.9 25.5 13C25.5 6.1 19.9 0.5 13 0.5ZM13 23C7.4875 23 3 18.5125 3 13C3 7.4875 7.4875 3 13 3C18.5125 3 23 7.4875 23 13C23 18.5125 18.5125 23 13 23Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default TrainingResume;
