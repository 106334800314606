import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import React from 'react';
import { processComparisonChartData } from '../logic/processComparisonChartData';
import useChartDatasets from '../logic/useChartDatasets';
import ComparisonChart from './ComparisonChart';

const SeverityCaptureResults: React.FC = () => {
  const { comparisonOrgChartDataset, orgChartDataset, isLoading } = useChartDatasets({
    metric: 'docScore'
  });

  if (comparisonOrgChartDataset && orgChartDataset) {
    if (isLoading) {
      return <ClinIntellSkeleton variant="rectangular" width="100%" height="23rem" />;
    }

    const { combinedDatasets, hasChartData, filteredChartData } = processComparisonChartData(
      orgChartDataset,
      comparisonOrgChartDataset
    );

    if (!hasChartData) {
      return null;
    }

    return (
      <ComparisonChart
        chartDataSets={filteredChartData}
        chartPeriodType="Month"
        dataset={combinedDatasets}
        chartTitle="Documentation Score"
        metric="docScore"
      />
    );
  }

  return isLoading ? <ClinIntellSkeleton variant="rectangular" width="100%" height="18rem" /> : null;
};

export default SeverityCaptureResults;
