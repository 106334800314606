import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, DropDown, CIModal, CIModalButtonWrapper, InputLabel } from '@clinintell/components/index';
import { SelectHospital, SelectGroup } from '@clinintell/containers/common';
import { makeMonthYearList } from '@clinintell/utils/dates';
import useRootDefaultDates from '@clinintell/containers/systemReport/logic/useRootDefaultDates';
import { SelectOptionType } from '@clinintell/types/common';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import { ApplicationAPI } from '@clinintell/utils/api';
import { isMobile } from 'react-device-detect';

const QuarterlySummaryModal: React.FC = () => {
  const defaultDates = useRootDefaultDates();
  const [reportDate, setReportDate] = useState<string>('');
  const [dateOptions, setDateOptions] = useState<SelectOptionType[]>([]);
  const [filterSelected, setFilters] = useState(false);
  const [selectedHospitalId, setHospitalId] = useState<number | undefined>(undefined);
  const [selectedGroupId, setGroupId] = useState(-1);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleHospitalChange = (value: number | null): void => {
    setHospitalId(value as number);
    setGroupId(-1);
    setFilters(false);
  };

  const handleGroupChange = (value: number | null): void => {
    setGroupId(value as number);
    setFilters(true);
  };

  const resetFilters = (): void => {
    setHospitalId(undefined);
    setGroupId(-1);
    setFilters(false);
  };

  const handleDownloadReport = async (): Promise<void> => {
    const endpoint = `reports/group/quarterlysummary/${selectedGroupId}`;
    const payload = { ReportDate: formatDateForAPI(new Date(reportDate)) };
    setIsDownloading(true);

    const createReport = ApplicationAPI.post({
      endpoint,
      data: JSON.stringify(payload)
    });

    const result = await Promise.resolve(createReport);
    if (!result.data) return;

    const blob = new Blob([result.data as ArrayBuffer], {
      type: 'application/zip' // 'application/zip'
    });

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, `QuarterlySummary`);
      return;
    }
    const link = document.createElement('a');
    link.download = `QuarterlySummary`;
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsDownloading(false);
  };

  useEffect(() => {
    if (!defaultDates || !defaultDates.currentMinMonth || !defaultDates.currentMaxMonth) {
      return;
    }

    const monthYearList = makeMonthYearList(defaultDates.currentMinMonth, defaultDates.currentMaxMonth);
    setDateOptions(monthYearList);
    setReportDate(monthYearList[0].value as string);
  }, [defaultDates]);

  const defaultOption = dateOptions.find(option => option.value === reportDate);

  return (
    <CIModal modalBtn={<Button label={`Download Report`} type={'primary'} variant={'text'} />} onClose={resetFilters}>
      <CIModal.Header>
        <CIModalButtonWrapper>
          <Button
            label="Back to Reports"
            leftAdornment={<ChevronLeftIcon sx={{ mt: 0.5 }} />}
            type={'primary'}
            variant="text"
          />
        </CIModalButtonWrapper>
      </CIModal.Header>
      <CIModal.Content>
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: '.25fr .75fr'
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              paddingBottom: '25px'
            }}
            variant="h6"
          >
            Quarterly Summary Report
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mx: isMobile ? 'auto' : 0 }}>
            <SelectHospital
              onHospitalChangeFn={handleHospitalChange}
              labelPosition={`top`}
              width={isMobile ? 325 : '100%'}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <SelectGroup
                hospitalId={selectedHospitalId}
                defaultValue={selectedGroupId}
                onGroupChangeFn={handleGroupChange}
                labelPosition={`top`}
                width={isMobile ? 325 : '100%'}
              />
              <InputLabel label={`Report Date`} position={`top`}>
                <DropDown
                  options={dateOptions}
                  value={defaultOption}
                  onChange={(value): void => {
                    setReportDate(value as string);
                  }}
                  isDisabled={selectedGroupId === -1}
                  width={isMobile ? 325 : '100%'}
                  testId="reportDateSelector"
                />
              </InputLabel>
            </Box>
          </Box>
        </Box>
      </CIModal.Content>
      <CIModal.Footer>
        <Button
          label={`Download Report`}
          type={`primary`}
          variant={`contained`}
          isBusy={isDownloading}
          onClick={handleDownloadReport}
          disabled={!filterSelected}
        />
      </CIModal.Footer>
    </CIModal>
  );
};

export default QuarterlySummaryModal;
