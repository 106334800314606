import ResultSummaryTable from '@clinintell/components/analysisSharedComponents/ResultSummaryTable';
import { AnalysisRecord } from '@clinintell/modules/cmiComparison';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import React from 'react';

type SeverityCaptureComparisonTableProps = {
  docScoreSummary: AnalysisRecord;
  pooledSeverityCMISummary: AnalysisRecord;
  baseLabel: string;
  comparisonLabel: string;
};

const SeverityCaptureTable: React.FC<SeverityCaptureComparisonTableProps> = ({
  pooledSeverityCMISummary,
  docScoreSummary,
  baseLabel,
  comparisonLabel
}) => {
  const pooledSeverityCMIDifference =
    pooledSeverityCMISummary && pooledSeverityCMISummary.base !== null && pooledSeverityCMISummary.compare !== null
      ? Number(formatToDecimalPlaces(pooledSeverityCMISummary.base, 2)) -
        Number(formatToDecimalPlaces(pooledSeverityCMISummary.compare, 2))
      : '-';

  const docScoreDifference =
    docScoreSummary && docScoreSummary.base !== null && docScoreSummary.compare !== null
      ? Number(formatToDecimalPlaces(docScoreSummary.base * 100, 0)) -
        Number(formatToDecimalPlaces(docScoreSummary.compare * 100, 0))
      : '-';

  return (
    <ResultSummaryTable
      rows={[
        {
          columns: [
            {
              value: '',
              width: 2,
              align: 'center',
              bold: true,
              key: 'header1'
            },
            {
              value: baseLabel,
              width: 4,
              align: 'center',
              bold: true,
              key: 'headerHistorical'
            },
            {
              value: comparisonLabel,
              width: 4,
              align: 'center',
              bold: true,
              key: 'headerCurrent'
            },
            {
              value: 'Difference',
              width: 2,
              align: 'center',
              bold: true,
              key: 'headerChange'
            }
          ],
          key: 'headerRow'
        },
        {
          divider: 'solid',
          key: 'headerDivider'
        },
        {
          columns: [
            {
              value: 'Documentation Score',
              width: 2,
              align: 'left',
              bold: true,
              key: 'sevCaptureLabel'
            },
            {
              value: docScoreSummary.base !== null ? `${formatToDecimalPlaces(docScoreSummary.base * 100, 0)}%` : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'sevCaptureHist'
            },
            {
              value:
                docScoreSummary.compare !== null ? `${formatToDecimalPlaces(docScoreSummary.compare * 100, 0)}%` : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'sevCaptureCurr'
            },
            {
              value: docScoreDifference !== '-' ? `${formatToDecimalPlaces(docScoreDifference, 0)}%` : '',
              width: 2,
              align: 'left',
              type: 'number',
              offsetCenter: true,
              key: 'sevCaptureDiff'
            }
          ],
          key: 'sevCaptureRow'
        },
        {
          divider: 'solid',
          key: 'cmiDivider'
        },
        {
          columns: [
            {
              value: 'Severity Value',
              width: 2,
              align: 'left',
              bold: true,
              key: 'drgLabel'
            },
            {
              value: pooledSeverityCMISummary.base ? formatToDecimalPlaces(pooledSeverityCMISummary.base, 2) : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'drgHist'
            },
            {
              value: pooledSeverityCMISummary.compare
                ? formatToDecimalPlaces(pooledSeverityCMISummary.compare, 2)
                : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'drgCurr'
            },
            {
              value:
                pooledSeverityCMIDifference !== '-' ? `${formatToDecimalPlaces(pooledSeverityCMIDifference, 2)}` : '',
              width: 2,
              align: 'left',
              type: 'number',
              offsetCenter: true,
              key: 'drgDiff'
            }
          ],
          key: 'drgRow'
        }
      ]}
    />
  );
};

export default SeverityCaptureTable;
