import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Can from '@clinintell/components/Can';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import LoadingScreen from '@clinintell/components/LoadingScreen';

const MedicareComparison = lazy(() =>
  import(/* webpackChunkName: 'medicareComparison' */ '@clinintell/containers/medicareComparison/MedicareComparison')
);

const CMIAnalysisLanding = lazy(() =>
  import(/* webpackChunkName: 'cmiAnalysis' */ '@clinintell/containers/cmiAnalysisLanding/CMIAnalysisLanding')
);

const AnalysisRoutes = (): JSX.Element => {
  return (
    <Suspense fallback={<LoadingScreen loadingIndicatorSize={250} />}>
      <Routes>
        <Route
          path="medicarehistograms"
          element={
            <Can
              permissions={['safHistogramView']}
              yes={(): JSX.Element => {
                return <MedicareComparison />;
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
            />
          }
        />
        <Route
          path="cmianalysis"
          element={
            <Can
              permissions={['reportCmiAnalysisView', 'reportCmiComparisonView']}
              yes={(): JSX.Element => {
                return <CMIAnalysisLanding />;
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
            />
          }
        />
        <Route path="*" element={<RouteErrorPage error={new Error('404')} />} />
      </Routes>
    </Suspense>
  );
};

export default AnalysisRoutes;
