import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { TrainingProgress } from '@clinintell/containers/trainingTest/types/trainingProgressTypes';
import {
  ConditionReferenceWithLog,
  SubmittedAnswerResult,
  TestQuestionWithResult,
  TestSequence,
  TestSequencesComplete
} from '@clinintell/containers/trainingTest/types/testSequenceTypes';

export type TrainingStatusDataset = {
  progress: TrainingProgress;
};

export type TrainingDataset = {
  status?: TrainingProgress;
  currentSequence?: TestSequence | TestSequencesComplete;
  isLoading: boolean;
  errorMessage?: string;
};

// Actions
enum TrainingActions {
  FETCH_TRAINING_STATUS = 'FETCH_TRAINING_STATUS',
  FETCH_TRAINING_STATUS_BEGIN = 'FETCH_TRAINING_STATUS_BEGIN',
  FETCH_TRAINING_STATUS_SUCCESSFUL = 'FETCH_TRAINING_STATUS_SUCCESSFUL',
  FETCH_TRAINING_STATUS_FAIL = 'FETCH_TRAINING_STATUS_FAIL',
  FETCH_NEXT_SEQUENCE = 'FETCH_NEXT_SEQUENCE',
  FETCH_NEXT_SEQUENCE_BEGIN = 'FETCH_NEXT_SEQUENCE_BEGIN',
  FETCH_NEXT_SEQUENCE_SUCCESSFUL = 'FETCH_NEXT_SEQUENCE_SUCCESSFUL',
  FETCH_NEXT_SEQUENCE_FAIL = 'FETCH_NEXT_SEQUENCE_FAIL',
  POST_VIDEO_WATCHED = 'POST_VIDEO_WATCHED',
  POST_VIDEO_WATCHED_BEGIN = 'POST_VIDEO_WATCHED_BEGIN',
  POST_VIDEO_WATCHED_SUCCESSFUL = 'POST_VIDEO_WATCHED_SUCCESSFUL',
  POST_VIDEO_WATCHED_FAIL = 'POST_VIDEO_WATCHED_FAIL',
  POST_QUESTION_ANSWERED = 'POST_QUESTION_ANSWERED',
  POST_QUESTION_ANSWERED_BEGIN = 'POST_QUESTION_ANSWERED_BEGIN',
  POST_QUESTION_ANSWERED_SUCCESSFUL = 'POST_QUESTION_ANSWERED_SUCCESSFUL',
  POST_QUESTION_ANSWERED_FAIL = 'POST_QUESTION_ANSWERED_FAIL',
  CLEAR_TEST_SEQUENCE = 'CLEAR_TEST_SEQUENCE'
}

export type TrainingAction<T> = {
  type?: keyof typeof TrainingActions;
  payload?: T;
};

type FetchTrainingStatusPayload = string | number | TrainingProgress | TrainingDataset | SubmittedAnswerResult | null;

type PostAnswerPayload = {
  orgId: number;
  providerTrainingId: number;
  conditionTrainingId: number;
  questionId: number;
  selectedAnswerIds: number[];
  logDateTime: string;
};

type PostVideoPayload = {
  orgId: number;
  providerTrainingId: number;
  videoLinkId: number;
  conditionTrainingId: number;
  logDateTime: string;
};

export const fetchTrainingStatus = (physicianGroupId?: number): TrainingAction<number> => ({
  type: 'FETCH_TRAINING_STATUS',
  payload: physicianGroupId
});

export const fetchNextTestSequence = (physicianGroupId?: number): TrainingAction<number> => ({
  type: 'FETCH_NEXT_SEQUENCE',
  payload: physicianGroupId
});

export const postQuestionAnswered = ({
  orgId,
  providerTrainingId,
  conditionTrainingId,
  questionId,
  selectedAnswerIds,
  logDateTime
}: PostAnswerPayload): TrainingAction<PostAnswerPayload> => ({
  type: 'POST_QUESTION_ANSWERED',
  payload: {
    orgId,
    providerTrainingId,
    questionId,
    selectedAnswerIds,
    conditionTrainingId,
    logDateTime
  }
});

export const postVideoWatched = ({
  orgId,
  providerTrainingId,
  videoLinkId,
  conditionTrainingId,
  logDateTime
}: PostVideoPayload): TrainingAction<PostVideoPayload> => ({
  type: 'POST_VIDEO_WATCHED',
  payload: {
    orgId,
    providerTrainingId,
    videoLinkId,
    conditionTrainingId,
    logDateTime
  }
});

export const clearTestSequence = (): TrainingAction<null> => ({
  type: 'CLEAR_TEST_SEQUENCE'
});

export const initialState: TrainingDataset = {
  status: undefined,
  currentSequence: undefined,
  isLoading: false,
  errorMessage: undefined
};

// Reducer
const reducer = (
  state: TrainingDataset = initialState,
  action: TrainingAction<FetchTrainingStatusPayload>
): TrainingDataset => {
  switch (action.type) {
    case 'FETCH_TRAINING_STATUS_BEGIN': {
      return {
        ...state,
        status: undefined,
        isLoading: true,
        errorMessage: undefined
      };
    }
    case 'FETCH_TRAINING_STATUS_SUCCESSFUL': {
      return {
        ...state,
        status: action.payload as TrainingProgress,
        isLoading: false,
        errorMessage: undefined
      };
    }
    case 'FETCH_TRAINING_STATUS_FAIL': {
      return {
        ...state,
        status: undefined,
        isLoading: false,
        errorMessage: action.payload as string
      };
    }
    case 'FETCH_NEXT_SEQUENCE_BEGIN': {
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined
      };
    }
    case 'FETCH_NEXT_SEQUENCE_SUCCESSFUL': {
      const { currentSequence, status } = action.payload as TrainingDataset;

      // An undefined currentSequence is completely valid - it means there is nothing more to do in training
      if (currentSequence) {
        const testSequence = currentSequence as TestSequence;
        if (testSequence.isConditionReference && testSequence.conditionReference) {
          testSequence.conditionReference.videoLogged = false;
        } else if (testSequence.isTestQuestion && testSequence.question) {
          testSequence.question.result = null;
          testSequence.question.isPosting = false;
        }
      }

      return {
        ...state,
        status,
        currentSequence: currentSequence || { trainingIsComplete: true },
        isLoading: false,
        errorMessage: undefined
      };
    }
    case 'FETCH_NEXT_SEQUENCE_FAIL': {
      return {
        ...state,
        currentSequence: undefined,
        isLoading: false,
        errorMessage: action.payload as string
      };
    }
    case 'POST_QUESTION_ANSWERED_BEGIN': {
      const { question } = state.currentSequence as TestSequence;

      return {
        ...state,
        currentSequence: {
          ...(state.currentSequence as TestSequence),
          question: {
            ...(question as TestQuestionWithResult),
            result: null,
            isPosting: true
          }
        },
        isLoading: true,
        errorMessage: undefined
      };
    }
    case 'POST_QUESTION_ANSWERED_SUCCESSFUL': {
      const { question } = state.currentSequence as TestSequence;

      return {
        ...state,
        currentSequence: {
          ...(state.currentSequence as TestSequence),
          question: {
            ...(question as TestQuestionWithResult),
            result: action.payload as SubmittedAnswerResult,
            isPosting: false
          }
        },
        isLoading: false,
        errorMessage: undefined
      };
    }
    case 'POST_QUESTION_ANSWERED_FAIL': {
      const { question } = state.currentSequence as TestSequence;

      return {
        ...state,
        currentSequence: {
          ...(state.currentSequence as TestSequence),
          question: {
            ...(question as TestQuestionWithResult),
            result: null,
            isPosting: false
          }
        },
        isLoading: false,
        errorMessage: action.payload as string
      };
    }
    case 'POST_VIDEO_WATCHED_BEGIN': {
      const { conditionReference } = state.currentSequence as TestSequence;

      return {
        ...state,
        currentSequence: {
          ...(state.currentSequence as TestSequence),
          conditionReference: {
            ...(conditionReference as ConditionReferenceWithLog)
          }
        },
        isLoading: true,
        errorMessage: undefined
      };
    }
    case 'POST_VIDEO_WATCHED_SUCCESSFUL': {
      const { conditionReference } = state.currentSequence as TestSequence;

      return {
        ...state,
        currentSequence: {
          ...(state.currentSequence as TestSequence),
          conditionReference: {
            ...(conditionReference as ConditionReferenceWithLog),
            videoLogged: true
          }
        },
        isLoading: false,
        errorMessage: undefined
      };
    }
    case 'POST_VIDEO_WATCHED_FAIL': {
      const { conditionReference } = state.currentSequence as TestSequence;

      return {
        ...state,
        currentSequence: {
          ...(state.currentSequence as TestSequence),
          conditionReference: {
            ...(conditionReference as ConditionReferenceWithLog),
            videoLogged: false
          }
        },
        isLoading: false,
        errorMessage: action.payload as string
      };
    }
    case 'CLEAR_TEST_SEQUENCE': {
      return {
        ...state,
        currentSequence: undefined
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

// Sagas
export function* fetchTrainingStatusSaga({ payload }: TrainingAction<FetchTrainingStatusPayload>): SagaIterator {
  yield put({
    type: 'FETCH_TRAINING_STATUS_BEGIN'
  });

  const trainingStatus: AsyncOutput<TrainingProgress> = yield call(ApplicationAPI.get, {
    endpoint: `training/test/progress?orgId=${payload}`
  });

  if (trainingStatus.error) {
    yield put({
      type: 'FETCH_TRAINING_STATUS_FAIL',
      payload: trainingStatus.error
    });
  } else {
    yield put({
      type: 'FETCH_TRAINING_STATUS_SUCCESSFUL',
      payload: trainingStatus.data
    });
  }
}

export function* fetchNextTrainingSequenceSaga({ payload }: TrainingAction<FetchTrainingStatusPayload>): SagaIterator {
  yield put({
    type: 'FETCH_NEXT_SEQUENCE_BEGIN'
  });

  const [nextSequence, trainingStatus]: [AsyncOutput<TestSequence>, AsyncOutput<TrainingProgress>] = yield all([
    call(ApplicationAPI.get, {
      endpoint: `training/test/nextsequence?orgId=${payload}`
    }),
    call(ApplicationAPI.get, {
      endpoint: `training/test/progress?orgId=${payload}`
    })
  ]);

  // A 204 error is legit, it just means that there is no next sequence in the test
  if ((nextSequence.error && !nextSequence.error.includes('No Content')) || trainingStatus.error) {
    const errorArray: string[] = [];
    if (nextSequence.error) {
      errorArray.push(nextSequence.error);
    }

    if (trainingStatus.error) {
      errorArray.push(trainingStatus.error);
    }

    yield put({
      type: 'FETCH_NEXT_SEQUENCE_FAIL',
      payload: errorArray.join(' / ')
    });
  } else {
    yield put({
      type: 'FETCH_NEXT_SEQUENCE_SUCCESSFUL',
      payload: {
        currentSequence: nextSequence.data,
        status: trainingStatus.data
      }
    });
  }
}

export function* postTestQuestionSaga({ payload }: TrainingAction<PostAnswerPayload>): SagaIterator {
  yield put({
    type: 'POST_QUESTION_ANSWERED_BEGIN'
  });

  const status: AsyncOutput<SubmittedAnswerResult> = yield call(ApplicationAPI.post, {
    endpoint: 'training/test/log/answer',
    data: JSON.stringify({ ...payload })
  });

  if (status.error) {
    yield put({
      type: 'POST_QUESTION_ANSWERED_FAIL',
      payload: status.error
    });
  } else {
    yield put({
      type: 'POST_QUESTION_ANSWERED_SUCCESSFUL',
      payload: status.data
    });
  }
}

export function* postVideoWatchedSaga({ payload }: TrainingAction<PostVideoPayload>): SagaIterator {
  yield put({
    type: 'POST_VIDEO_WATCHED_BEGIN'
  });

  const status: AsyncOutput<string> = yield call(ApplicationAPI.post, {
    endpoint: 'training/test/log/video',
    data: JSON.stringify({ ...payload })
  });

  if (status.error) {
    yield put({
      type: 'POST_VIDEO_WATCHED_FAIL',
      payload: status.error
    });
  } else {
    yield put({
      type: 'POST_VIDEO_WATCHED_SUCCESSFUL'
    });
  }
}

export function* trainingStatusSaga(): SagaIterator {
  yield takeLatest('FETCH_TRAINING_STATUS', fetchTrainingStatusSaga);
}

export function* nextTrainingSequenceSaga(): SagaIterator {
  yield takeLatest('FETCH_NEXT_SEQUENCE', fetchNextTrainingSequenceSaga);
}

export function* testQuestionSaga(): SagaIterator {
  yield takeLatest('POST_QUESTION_ANSWERED', postTestQuestionSaga);
}

export function* videoWatchedSaga(): SagaIterator {
  yield takeLatest('POST_VIDEO_WATCHED', postVideoWatchedSaga);
}
