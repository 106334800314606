import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ClinIntellSkeleton } from '@clinintell/components/index';
import SeverityImpactTables from '@clinintell/components/analysisSharedComponents/SeverityImpactTables';
import { SevCMIGroupShare } from '@clinintell/containers/cmiAnalysis/types/SevCMIGroup';
import { useDashboard2 } from '@clinintell/modules/store';
import { CMIAnalysisWidgetData } from '@clinintell/modules/dashboard2';
import microscope from '@clinintell/assets/images/microscope.png';

const useStyles = makeStyles(theme => ({
  widgetImage: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    '& img': {
      margin: '0 auto',
      height: '110px',
      padding: '24px 40px'
    },
    '& p': {
      fontSize: '10px',
      width: '100%',
      textAlign: 'center',
      fontWeight: 400,
      lineHeight: '14px',
      letter: '2%'
    }
  },
  widgetErrMsgTitle: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    margin: '0 auto',
    marginBottom: '.7rem'
  },
  widgetErrMsg: {
    display: 'flex',
    margin: '0 auto',
    textAlign: 'center'
  }
}));

const SeverityValueModal: React.FC = () => {
  const { widgetImage, widgetErrMsgTitle, widgetErrMsg } = useStyles();
  const { isLoading, cmiAnalysisWidget } = useDashboard2();
  const severityCMI = (cmiAnalysisWidget.data as CMIAnalysisWidgetData).severityCMI;
  const severityAnalysis = (cmiAnalysisWidget.data as CMIAnalysisWidgetData).severityValue;

  if (isLoading && !cmiAnalysisWidget) {
    return <ClinIntellSkeleton variant="rectangular" height="20rem" width="100%" />;
  }

  const group = severityCMI.find(record => record.orgId === cmiAnalysisWidget.modalRecordId);
  const hasPositiveCMIDelta = group && group.current && group.historical ? group.current - group.historical >= 0 : true;

  if (!severityAnalysis) {
    return (
      <Box className={widgetImage}>
        <img src={microscope} alt={''} />
        <Typography variant="h4" className={widgetErrMsgTitle}>
          No Data
        </Typography>
        <Typography variant="p2" className={widgetErrMsg}>
          No DRG secondary conditions to display.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <SeverityImpactTables
        isLoading={false}
        hasPositiveDelta={hasPositiveCMIDelta}
        tableRows={severityAnalysis.table as SevCMIGroupShare[]}
      />
    </Box>
  );
};

export default SeverityValueModal;
