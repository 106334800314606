import React from 'react';
import ReactSpeedometer, { Transition } from 'react-d3-speedometer';

export interface Bucket {
  min: number;
  max: number;
}

interface D3SpeedometerProps {
  title?: string;
  score: number;
  buckets: Bucket[];
  colors: string[];
}

const D3Speedometer: React.FC<D3SpeedometerProps> = ({ score, buckets, colors }) => {
  const min = buckets[0].min;
  const max = buckets[buckets.length - 1].max;
  const customSegments = [min];

  buckets.forEach(bucket => {
    customSegments.push(bucket.max);
  });

  return (
    <ReactSpeedometer
      fluidWidth
      height={150}
      value={score}
      minValue={min}
      maxValue={max}
      needleTransitionDuration={2000}
      needleTransition={Transition.easeElastic}
      needleColor="black"
      needleHeightRatio={0.75}
      ringWidth={50}
      customSegmentStops={customSegments}
      segmentColors={colors}
    />
  );
};

export default D3Speedometer;
