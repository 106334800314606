import { useCallback, useState } from 'react';
import { MenuPlacement } from 'react-select';

type UseDropdownPositioningOutput = {
  placement: MenuPlacement;
  setPlacementHandler: () => void;
  maxHeight: number;
};

const BASE_MAX_HEIGHT = 450;
const BASE_MIN_HEIGHT = 300;

const useDropdownPositioning = (
  dropdownRef: React.MutableRefObject<HTMLDivElement | undefined>
): UseDropdownPositioningOutput => {
  const [placement, setPlacement] = useState<MenuPlacement>('bottom');
  const [maxHeight, setMaxHeight] = useState(BASE_MAX_HEIGHT);

  const setPlacementHandler = useCallback(() => {
    if (!dropdownRef.current) {
      return;
    }

    const rect = dropdownRef.current.getBoundingClientRect();
    const availableBottomSpace = window.innerHeight - rect.top - 60;

    if (availableBottomSpace < BASE_MIN_HEIGHT) {
      setPlacement('top');
      setMaxHeight(Math.min(rect.top - 60, BASE_MAX_HEIGHT));
    } else {
      setPlacement('bottom');
      setMaxHeight(Math.min(availableBottomSpace, BASE_MAX_HEIGHT));
    }
  }, [dropdownRef]);

  return {
    placement,
    setPlacementHandler,
    maxHeight
  };
};

export default useDropdownPositioning;
