import { YAxisSet, YAxisOptions } from '@clinintell/components/Chart/types';
import { calculateCeiling, calculateFloor, precision } from '@clinintell/utils/calculations';

interface YAxisIntervals {
  min: number;
  max: number;
  stepSize: number;
}

// 0.0001 accounts for Impact values that are incredibly small
export const decimalIntervals = [0.0001, 0.0002, 0.001, 0.002, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100];
export const percentageIntervals = [1, 2, 5, 10, 20, 50, 100, 200, 300];

export const calculateIntervals = (
  min: number,
  max: number,
  intervals: number[],
  targetPrecision: number | null = null
): YAxisIntervals => {
  let counter = 0;
  let ceilingMax = 0;
  let floorMin = 0;

  while (counter <= intervals.length) {
    ceilingMax = calculateCeiling(max, intervals[counter]);
    floorMin = calculateFloor(min, intervals[counter]);

    const gap = ceilingMax - floorMin;
    if (gap / intervals[counter] <= 9) {
      if (targetPrecision !== null) {
        // If there is special formatting of the y axis values, returning more precision may cause issues where the rounded
        // y axis values appear to be the same as each other. This check ensures that the step size will always be at least the same precision
        // as the given target precision
        if (precision(intervals[counter]) <= targetPrecision) {
          break;
        }
      } else {
        break;
      }
    }

    counter++;
  }

  return {
    min: floorMin,
    // There is an edge case where the ceiling and floor are exactly the same. Use case - actual doc score aligns perfectly at 100
    // along with CE, and no error band. Increment the max up by 1 just so that the graph renders
    max: ceilingMax === floorMin ? ceilingMax + intervals[counter] : ceilingMax,
    stepSize: intervals[counter]
  };
};

const overrideTicks = (min: number, max: number, stepSize: number): number[] => {
  const customTicks: number[] = [];
  let sequence = min;
  const decimals = precision(stepSize);

  if (max === 0) {
    return customTicks;
  }

  while (sequence <= max) {
    customTicks.push(sequence);
    sequence = parseFloat((sequence + stepSize).toFixed(decimals));
  }

  return customTicks;
};

const generateYAxes = (yAxesSet: YAxisSet[], gridlineColor: string): YAxisOptions[] => {
  const gridLineColors: string[] = [];

  const output: YAxisOptions[] = [];
  yAxesSet.forEach((yAxis, index) => {
    // If we are showing a 2nd y axis (for bar charts), we want to adjust the ticks so that the maximum bar chart is exactly 50% as tall as the chart itself
    const adjustedMax = index === 0 ? yAxis.max : yAxis.max + (yAxis.max - yAxis.min);

    // Explicitly set min of bar chart Y axis to 0
    const adjustedMin = index === 0 ? yAxis.min : 0;

    const { min, max, stepSize } =
      yAxis.dataType === 'percentage'
        ? calculateIntervals(adjustedMin, adjustedMax, percentageIntervals, yAxis.precision)
        : calculateIntervals(adjustedMin, adjustedMax, decimalIntervals, yAxis.precision);

    const intervalCount = ((max - min) / stepSize).toFixed(1) + 1;

    const tickMarks: YAxisOptions = {
      position: index === 0 ? 'left' : 'right',
      id: -1,
      gridLines: {
        display: true,
        drawOnChartArea: false,
        drawBorder: true,
        fontSize: 14,
        color: 'black',
        drawTicks: true,
        tickMarkLength: 10
      },
      ticks: {
        display: true,
        drawTicks: true,
        labelOffset: 1,
        fontSize: 14,
        fontColor: 'black',
        padding: 8,
        max,
        min,
        stepSize,
        callback(value: number): string | null {
          if (value > 1 && value > max && yAxis.dataType === 'percentage') {
            return null;
          }

          return yAxis.dataType === 'percentage'
            ? `${value.toFixed(yAxis.precision)}%`
            : value.toFixed(yAxis.precision);
        }
      },
      afterBuildTicks() {
        return overrideTicks(min, max, stepSize);
      }
    };

    // The first yAxis set will determine the grid lines displayed on the chart
    if (index === 0) {
      let counter = 1;

      while (counter < Number(intervalCount)) {
        gridLineColors.push(gridlineColor);
        counter += 1;
      }
    }

    const gridlines: YAxisOptions = {
      position: index === 0 ? 'left' : 'right',
      id: -1,
      // Every index has to be unique
      scaleLabel: {
        display: true,
        labelString: yAxis.title,
        fontSize: 16,
        fontColor: 'black'
      },
      // This gridlines and ticks creates another set of gridlines to draw the light gray lines on the chart.
      gridLines: {
        display: index === 0,
        drawOnChartArea: true,
        drawBorder: false,
        tickMarkLength: -1,
        color: gridLineColors
      },
      ticks: {
        display: false,
        beginAtZero: false,
        max,
        min,
        stepSize,
        fontSize: 14
      },
      afterBuildTicks() {
        return overrideTicks(min + stepSize, max, stepSize);
      }
    };

    // In order for the chart to correctly render 4 sets of y axes in the correct order, the ids of the gridlines and tick mark sets
    // have to be in a specific order.
    if (index === 0) {
      gridlines.id = 0;
      tickMarks.id = 2;

      output.push(gridlines, tickMarks);
    } else if (index === 1) {
      gridlines.id = 3;
      tickMarks.id = 1;

      output.push(tickMarks, gridlines);
    }
  });

  return [...output];
};

export default generateYAxes;
