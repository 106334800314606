import { ColDef, ColumnState } from 'ag-grid-community';

const PERSIST_COLUMN_STATE_ID = 'aggrid-table';
const PERSIST_QUICK_FILTER_ID = 'aggrid-quickfilter';
const PERSIST_FILTER_ID = 'aggrid-filter';
const PERSIST_ROW_SELECTION_ID = 'aggrid-rows-selected';

type PersistTypes = 'aggrid-table' | 'aggrid-filter' | 'aggrid-quickfilter' | 'aggrid-rows-selected';

type FilterModel = { [key: string]: unknown };

const setPersistence = <T>(typeId: PersistTypes, id: string, key: string, state: T) => {
  const currentState = sessionStorage.getItem(typeId);

  if (currentState) {
    const currentStateJSON = JSON.parse(currentState);

    sessionStorage.setItem(
      typeId,
      JSON.stringify({
        ...currentStateJSON,
        [id]: {
          ...currentStateJSON[id],
          [key]: state
        }
      })
    );

    return;
  }

  const savedState = { [id]: { [key]: state } };
  sessionStorage.setItem(typeId, JSON.stringify(savedState));
};

const getPersistence = <T>(typeId: PersistTypes, id: string, key: string): T | undefined => {
  const string = sessionStorage.getItem(typeId);

  if (!string) {
    return undefined;
  }

  const json = JSON.parse(string);
  return json[id] ? json[id][key] : undefined;
};

export const deletePersistence = (typeId: PersistTypes, id: string, key: string) => {
  const currentState = sessionStorage.getItem(typeId);

  if (!currentState) {
    return;
  }

  // If there is no persistence at the given id and key, just return
  const currentStateJSON = JSON.parse(currentState);
  if (!currentStateJSON[id] || !currentStateJSON[id][key]) {
    return;
  }

  delete currentStateJSON[id][key];
  // See if there are any other records associated with the given ID. If not
  // remove the ID record
  if (Object.keys(currentStateJSON[id]).length === 0) {
    delete currentStateJSON[id];
  }

  // See if there are any other quick filters at all
  if (Object.keys(currentStateJSON).length === 0) {
    sessionStorage.removeItem(typeId);
  } else {
    sessionStorage.setItem(typeId, JSON.stringify({ ...currentStateJSON }));
  }
};

/* 
  Shape: 
  PERSIST_FILTER_ID: {
    [id]: {
      [key]: ColumnState,
      [key]: ColumnState,
      ...
    },
    [id]: {
      ...
    }
  }
*/

export const getPersistedColumnState = (id: string, key: string): ColumnState[] | undefined => {
  return getPersistence<ColumnState[]>(PERSIST_COLUMN_STATE_ID, id, key);
};

export const setPersistedColumnState = (id: string, key: string, state: ColumnState[]): void => {
  setPersistence(PERSIST_COLUMN_STATE_ID, id, key, state);
};

/* 
  Shape: 
  PERSIST_FILTER_ID: {
    [id]: {
      [key]: string,
      [key]: string,
      ...
    },
    [id]: {
      ...
    }
  }
*/

export const getPersistedQuickFilter = (id: string, key: string): string | undefined => {
  return getPersistence<string>(PERSIST_QUICK_FILTER_ID, id, key);
};

export const setPersistedQuickFilter = (id: string, key: string, filterText: string): void => {
  setPersistence(PERSIST_QUICK_FILTER_ID, id, key, filterText);
};

export const deletePersistedQuickFilter = (id: string, key: string) => {
  deletePersistence(PERSIST_QUICK_FILTER_ID, id, key);
};

export const getPersistedFilter = (id: string, key: string): FilterModel | undefined => {
  return getPersistence<FilterModel>(PERSIST_FILTER_ID, id, key);
};

export const setPersistedFilter = (id: string, key: string, filterModel: FilterModel): void => {
  setPersistence(PERSIST_FILTER_ID, id, key, filterModel);
};

export const deletePersistedFilter = (id: string, key: string) => {
  deletePersistence(PERSIST_FILTER_ID, id, key);
};

export const setPersistedRowsSelection = (id: string, key: string, rows: unknown[]): void => {
  setPersistence(PERSIST_ROW_SELECTION_ID, id, key, rows);
};

export const getPersistedRowsSelection = (id: string, key: string): unknown[] | undefined => {
  return getPersistence<unknown[]>(PERSIST_ROW_SELECTION_ID, id, key);
};

export const mergePersistedColumnState = (
  persistedColumnState: ColumnState | undefined,
  defaultColumnState: Partial<ColDef>
): Partial<ColDef> => {
  if (!persistedColumnState) {
    return defaultColumnState;
  }

  return {
    ...defaultColumnState,
    sort: persistedColumnState.sort,
    width: persistedColumnState.width
  };
};
