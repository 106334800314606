/* Adds label to top of bars of bar chart */
import { ClinIntellChart, ClinIntellChartDataSets } from '@clinintell/components/Chart/types';
import { Easing } from 'chart.js';

type BarChartOptions = {
  font: string;
  fontSize: string;
};

export const shouldNotRender = (alreadyRendered: boolean | undefined, easingValue: number): boolean =>
  alreadyRendered || easingValue === 1;

const barLabelPlugin = {
  id: 'barLabelPlugin',
  rendered: false,
  options: {
    font: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    fontSize: '12px'
  },
  afterDatasetsDraw(chart: ClinIntellChart, easingValue: Easing, options: BarChartOptions): void {
    // This method is called many times, wait until it's been rendered and only run this method
    // one time. Only apply this plugin to bar charts.
    chart.renderedOnce = shouldNotRender(chart.renderedOnce, Number(easingValue));
    if (!chart.renderedOnce || chart.config.type !== 'bar') {
      return;
    }

    this.rendered = true;
    this.options = {
      ...this.options,
      ...options
    };

    const { datasets } = chart.data;
    if (datasets === undefined) {
      return;
    }

    // Loop through each dataset, only process sets that have error bars defined in the meta data
    datasets.forEach((dataset: ClinIntellChartDataSets, index) => {
      if (chart.scales === undefined) {
        return;
      }

      const metaData = chart.getDatasetMeta(index).data;

      const horizontalScales = chart.scales['y-axis-0'];
      const { ctx } = chart;

      if (ctx === null) {
        return;
      }

      ctx.save();

      // Loop through each point of the dataset, only process points that have number values. Points may have blank values
      // if used as a buffer of sorts
      metaData.forEach((point, jIndex) => {
        if (dataset.data === undefined || !dataset.visibleBarLabels) {
          return;
        }

        const value = horizontalScales.getRightValue(dataset.data[jIndex] as Chart.ChartPoint);
        if (Number.isNaN(value)) {
          return;
        }

        ctx.save();
        ctx.font = `${options.fontSize} ${options.font}`;

        // Draw bottom horizontal line, then the vertical error band line, and finally
        // the top horizontal line
        // -- disabling linter rule due to working with 3rd party API
        const model = point._model; // eslint-disable-line no-underscore-dangle
        const label = dataset.visibleBarLabels[jIndex];
        const numberOfHospitals = label.split(',');
        const labelWidth = ctx.measureText(label).width;

        ctx.fillStyle = 'white';
        ctx.fillRect(model.x - numberOfHospitals.length * 4, model.y - 15, labelWidth, parseInt(options.fontSize, 10));

        ctx.fillStyle = 'black';
        ctx.fillText(label, model.x - numberOfHospitals.length * 4, model.y - 5);

        ctx.restore();
      });
    });
  }
};

export default barLabelPlugin;
