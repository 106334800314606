import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Line } from 'react-chartjs-2';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';

type DashboardLineChartProps = {
  averageHistoric: number;
  averageCurrent: number;
  chartData: number[];
};

const useStyles = makeStyles(theme => ({
  ChartDataContainer: {
    display: 'grid',
    gridTemplateRows: '.25fr 65px',
    height: '100%',
    margin: '0 auto'
  },
  ChartDataStatsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  ChartPercentageLabel: {
    display: 'flex',
    marginRight: 'auto',
    color: theme.palette.grey[700]
  },
  ChartDifferenceLabel: {
    display: 'flex',
    marginLeft: 'auto'
  },
  ChartContainer: {
    height: '45px',
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '.5rem',
    marginTop: '15px',
    left: 0,
    right: 0,
    textAlign: 'center',
    '& #lineChartId': {
      position: 'relative',
      width: '100% !important'
    }
  }
}));

const DashboardLineChart: React.FC<DashboardLineChartProps> = ({ averageHistoric, averageCurrent, chartData }) => {
  const theme = useTheme();
  const {
    ChartDataContainer,
    ChartContainer,
    ChartDataStatsContainer,
    ChartDifferenceLabel,
    ChartPercentageLabel
  } = useStyles();
  const scoreDifference =
    Number.parseInt(formatToDecimalPlaces(averageCurrent, 0)) -
    Number.parseInt(formatToDecimalPlaces(averageHistoric, 0));
  const dataColor =
    scoreDifference === 0 || Math.abs(scoreDifference) === 1
      ? theme.palette.grey[400]
      : scoreDifference > 1
      ? theme.palette.teal.light4
      : theme.palette.red[300];

  const lineChartData = {
    labels: chartData.map((elem, index) => index),
    datasets: [
      {
        label: 'line chart',
        data: chartData,
        fill: false,
        borderColor: dataColor,
        tension: 0.3
      }
    ]
  };

  return (
    <Box className={ChartDataContainer}>
      <Box className={ChartDataStatsContainer}>
        <Typography variant="h4" className={ChartPercentageLabel} fontWeight={'bold'} textAlign={'center'}>
          {formatToDecimalPlaces(averageCurrent, 0) + '%'}
        </Typography>
        <Typography
          fontSize={'15px'}
          className={ChartDifferenceLabel}
          fontWeight={'bold'}
          textAlign={'center'}
          color={dataColor}
        >
          {`${scoreDifference === 0 || scoreDifference < 1 ? '' : '+'}${scoreDifference}%`}
        </Typography>
      </Box>
      <Box className={ChartContainer}>
        <Line
          id="lineChartId"
          data={lineChartData}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            tooltips: {
              enabled: false
            },
            hover: {
              mode: undefined
            },
            legend: {
              display: false
            },
            scales: {
              xAxes: [
                {
                  display: false
                }
              ],
              yAxes: [
                {
                  display: false
                }
              ]
            },
            elements: {
              point: {
                radius: 0
              }
            },
            layout: {
              padding: {
                bottom: 3,
                top: 3
              }
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default DashboardLineChart;
