import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import HospitalAndGroupFilter from '@clinintell/containers/reports/components/hospitalAndGroupFilter/HospitalAndGroupFilter';
import DateRangePicker from '@clinintell/components/datepicker/DateRangePicker';
import SelectClinicalLeader from '@clinintell/containers/common/SelectClinicalLeader';
import ReportEmailTextBox from '@clinintell/containers/reports/components/reportEmailTextBox/ReportEmailTextBox';
import {
  ReportType,
  ScheduleReportEmailObjType,
  ScheduleReportRolesType
} from '@clinintell/containers/reports/types/actionTypes';
import ReportNameInput from '@clinintell/containers/reports/components/reportNameInput/ReportNameInput';
import SelectFrequency from '@clinintell/containers/common/SelectFrequency';
import ReportSidePanel from '@clinintell/containers/reports/components/reportSidePanel/ReportSidePanel';
import useReportSchedulerEntitySelector from '@clinintell/containers/reports/sections/context/hooks/useReportSchedulerEntitySelector';
import useReportSchedulerEmails from '@clinintell/containers/reports/sections/context/hooks/useReportSchedulerEmails';
import { useDocScoreGoalGetLastImplementationDateDocScoreGoalByOrgId } from '@clinintell/api/apiComponents';
import useErrorHandling from '@clinintell/errors/useErrorHandling';
import { ScheduleFrequencyDto, TargetDocScoreGoalResponseDto } from '@clinintell/api/apiSchemas';
import { addMonthsToDate } from '@clinintell/utils/dates';
import useReportSchedulerValidation from '@clinintell/containers/reports/sections/context/hooks/useReportSchedulerValidation';
import useReportSchedulerSave, {
  QuarterlyReportSubmissionType,
  GroupReportSubmissionType
} from '@clinintell/containers/reports/sections/context/hooks/useReportSchedulerSave';

const defaultAdmins = ['GroupReportAdmins@ClinIntell.com'];

const MonthylGroupReport: React.FC = () => {
  const { showErrorMessage } = useErrorHandling();
  const absoluteMin = new Date();
  absoluteMin.setMonth(absoluteMin.getMonth() + 1);
  const { hospitalData, groupData, hospitalFilterHandler, groupFilterHandler } = useReportSchedulerEntitySelector();
  const { adminEmails, providerEmails, resetEmailLists, setReportEmailList } = useReportSchedulerEmails();
  const [reportName, setReportName] = useState<string>('');
  const [clinicalLeader, setClinicalLeader] = useState<number>();
  const [existingStrategy, setExistingStrategy] = useState<boolean>(true);
  const [reportStartDate, setReportStartDate] = useState<string | Date>('');
  const [selectedFrequency, setSelectedFrequency] = useState<ScheduleFrequencyDto>({ frequencyName: '', id: 0 });
  const { scheduleReportClickHandler } = useReportSchedulerSave();
  const {
    submitEnabled,
    setReportScheduleType,
    checkGroupIdRequirement,
    checkReportNameRequirement,
    checkReportClinicalLeadRequirement,
    checkReportEmailRequirement
  } = useReportSchedulerValidation();

  const {
    data: lastReportScheduled,
    isLoading: isLastImplementationLoading
  } = useDocScoreGoalGetLastImplementationDateDocScoreGoalByOrgId(
    {
      pathParams: {
        orgId: groupData.id
      },
      onError: showErrorMessage
    },
    {
      enabled: groupData.id > 0
    }
  );

  useEffect(() => {
    if (hospitalData.id === -1) setReportScheduleType(ReportType.Group);
    checkGroupIdRequirement(groupData.id);
    if (groupData.name === '' || groupData.id === -1) {
      setReportName('');
      if (hospitalData.id !== -1 && adminEmails.emails.length === 0)
        setReportEmailList({
          ...adminEmails,
          emails: [...defaultAdmins],
          validList: true
        });
      return;
    }
    setReportName(`Group Report - ${hospitalData.name} ${groupData.name}`);
    checkReportNameRequirement(reportName);
    checkReportEmailRequirement(ReportType.Group, adminEmails, providerEmails);
  }, [
    hospitalData,
    groupData,
    reportName,
    adminEmails,
    providerEmails,
    setReportScheduleType,
    checkGroupIdRequirement,
    checkReportNameRequirement,
    checkReportEmailRequirement,
    setReportEmailList
  ]);

  useEffect(() => {
    if (isLastImplementationLoading) return;
    else {
      const implStrategyData = lastReportScheduled as TargetDocScoreGoalResponseDto;
      const clinicalLeaderId = implStrategyData.clinicalLeadUserId;
      setClinicalLeader(clinicalLeaderId as number);
      checkReportClinicalLeadRequirement(clinicalLeaderId as number);
      // 9999 is the response we get if no implementation date is set
      const startDate = implStrategyData.implementationDate?.includes('9999')
        ? ''
        : addMonthsToDate(new Date(implStrategyData.implementationDate as string), 1);
      setExistingStrategy(startDate !== '');
      setReportStartDate(startDate === '' ? addMonthsToDate(new Date(), 1) : startDate);
    }
  }, [isLastImplementationLoading, lastReportScheduled, setReportStartDate, checkReportClinicalLeadRequirement]);

  const emailTextBoxInput = (
    validEmails: string[],
    invalidEmails: string[],
    listRole: ScheduleReportRolesType,
    setEmailList: (emailObj: ScheduleReportEmailObjType) => void
  ) => {
    return (
      <>
        <ReportEmailTextBox
          validEmailList={validEmails}
          invalidEmailList={invalidEmails}
          emailRoleType={listRole}
          textBoxHeader={listRole === ScheduleReportRolesType.admin ? 'Admin Recipients' : 'Provider Recipients'}
          entryRequired={listRole === ScheduleReportRolesType.admin && (!clinicalLeader || clinicalLeader <= 0)}
          setValidEmailsFunc={setEmailList}
        />
      </>
    );
  };

  return (
    <>
      <ReportSidePanel
        title="Schedule Group Report"
        submitEnabled={submitEnabled}
        onCloseHandler={() => {
          setReportScheduleType(null);
          hospitalFilterHandler(-1);
          resetEmailLists();
          setReportStartDate('');
          setExistingStrategy(true);
        }}
        onSubmitHandler={(requestData: QuarterlyReportSubmissionType | GroupReportSubmissionType) => {
          return scheduleReportClickHandler(requestData as GroupReportSubmissionType);
        }}
        reportSubmissionData={{
          schedulingReportType: ReportType.Group,
          groupData,
          reportName,
          clinicalLeader: clinicalLeader as number,
          reportStartDate,
          selectedFrequency,
          adminEmails,
          providerEmails
        }}
      >
        <>
          <HospitalAndGroupFilter
            hospitalId={hospitalData.id}
            groupId={groupData.id}
            onHospitalChange={hospitalFilterHandler}
            onPhysicianGroupChange={groupFilterHandler}
          ></HospitalAndGroupFilter>

          <Box padding="8px">
            <ReportNameInput
              reportName={reportName}
              showError={groupData.id > 0 && reportName.length === 0}
              showRequired={reportName.length === 0 && hospitalData.id >= 0 && groupData.id >= 0}
              inputChangeHandler={() => {
                // currently disabled
              }}
            />
          </Box>

          <Box padding="8px">
            <SelectFrequency
              width={'100%'}
              defaultValue={4}
              disableDropdown={true}
              onFrequencyChangeFn={(value: number, display: string) => {
                setSelectedFrequency({ frequencyName: display, id: value });
              }}
            />
          </Box>
          <Box padding="8px">
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography style={{ marginBottom: 8 }} variant="p2">
                Start Date
              </Typography>
              <DateRangePicker
                id="implementationDateActionPicker"
                title="Implementation Date"
                isLoading={isLastImplementationLoading && hospitalData.id >= 0 && groupData.id >= 0}
                useSingleCalendar={true}
                isPreselectVisible={false}
                isDisabled={existingStrategy || isLastImplementationLoading}
                isInterventionAvailable={false}
                absoluteMinDate={existingStrategy ? reportStartDate.toString() : absoluteMin.toString()}
                absoluteMaxDate={''}
                selectionMinDate={reportStartDate.toString()}
                selectionMaxDate={''}
                widthStyling={'175px'}
                handleDateRangeApply={(minDate: Date) => {
                  setReportStartDate(minDate);
                }}
                onDisabledShowFormat={true}
                disableTooltip
                closeOnSelect={true}
              />
            </Box>
          </Box>
          <Box padding="8px">
            <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
              <Typography style={{ marginBottom: 8 }} variant="p2">
                Clinical Leader
              </Typography>
              <SelectClinicalLeader
                defaultValue={clinicalLeader}
                onClinicalLeaderChangeFn={value => {
                  // Nothing needs to be currently handled
                }}
                width={'100%'}
                disableDropdown={true}
              />
            </Box>
          </Box>
          {emailTextBoxInput(
            adminEmails['emails'],
            adminEmails['invalidEmails'],
            ScheduleReportRolesType.admin,
            setReportEmailList
          )}
          {emailTextBoxInput(
            providerEmails['emails'],
            providerEmails['invalidEmails'],
            ScheduleReportRolesType.provider,
            setReportEmailList
          )}
        </>
      </ReportSidePanel>
    </>
  );
};

export default MonthylGroupReport;
