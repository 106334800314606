/* eslint-disable @typescript-eslint/no-explicit-any */

import { ApiRequestError} from '../errors/customErrors';
import { IUseErrorHandlingOutput, useErrorHandling } from '../errors/useErrorHandling';
import { QueryOperation } from './apiComponents';

import type { QueryKey, UseQueryOptions } from '@tanstack/react-query';

export type ApiContext = {
  fetcherOptions: {
    /**
     * Headers to inject in the fetcher
     */
    headers?: {
      Authorization: `Bearer ${string}`;
      [headerName: string]: string;
    };
    /**
     * Query params to inject in the fetcher
     */
    queryParams?: {};

    /**
     * How an API call error should be handled
     *
     * NOTE: a failed API call will invoke onError and the 'data' property will be null
     *
     * - prefer to use @see [useErrorHandling] hook to expose showErrorMessage, captureErrorSilently and throwToErrorBoundary
     * - optionally provide a custom error handler
     */
    onError:
    | IUseErrorHandlingOutput['showErrorMessage']
    | IUseErrorHandlingOutput['throwToErrorBoundary']
    | IUseErrorHandlingOutput['captureErrorSilently']
    | ((error: ApiRequestError) => void | Promise<void>);
  };
  queryOptions: {
    /**
     * Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
     * Defaults to `true`.
     */
    enabled?: boolean;
  };
  /**
   * Query key manager.
   */
  queryKeyFn: (operation: QueryOperation) => QueryKey;
};

/**
 * Context injected into every react-query hook wrappers
 *
 * @param queryOptions options from the useQuery wrapper
 */
export function useApiContext<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  _queryOptions?: Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  'queryKey' | 'queryFn'
  >,
): ApiContext {
  // NOTE: when using the hooks it is still required to define onError, this is just for the usage in this hook
  const { captureErrorSilently } = useErrorHandling();

  return {
    fetcherOptions: {
      onError: captureErrorSilently,
      queryParams: {},
    },
    queryOptions: {
      ..._queryOptions,
    },
    queryKeyFn,
  };
}

export const queryKeyFn = (operation: QueryOperation) => {
  const queryKey: unknown[] = hasPathParams(operation) ?
    operation.path
      .split('/')
      .filter(Boolean)
      .map((i) => resolvePathParam(i, operation.variables.pathParams)) :
    operation.path.split('/').filter(Boolean);

  if (hasQueryParams(operation)) {
    queryKey.push(operation.variables.queryParams);
  }

  if (hasBody(operation)) {
    queryKey.push(operation.variables.body);
  }

  return queryKey;
};
// Helpers
const resolvePathParam = (key: string, pathParams: Record<string, string>) => {
  if (key.startsWith('{') && key.endsWith('}')) {
    return pathParams[key.slice(1, -1)];
  }

  return key;
};

const hasPathParams = (
  operation: QueryOperation,
): operation is QueryOperation & {
  variables: { pathParams: Record<string, string> };
} => Boolean((operation.variables as any).pathParams);

const hasBody = (
  operation: QueryOperation,
): operation is QueryOperation & {
  variables: { body: Record<string, unknown> };
} => Boolean((operation.variables as any).body);

const hasQueryParams = (
  operation: QueryOperation,
): operation is QueryOperation & {
  variables: { queryParams: Record<string, unknown> };
} => Boolean((operation.variables as any).queryParams);
