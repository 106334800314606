import { createBlob } from '@clinintell/containers/metrics/sections/Download';
import { TrainingProgressEntity } from '@clinintell/containers/reports/types/routeProps';
import { ApplicationAPI } from '@clinintell/utils/api';
import { useState } from 'react';
import { ReportTypes } from '../TrainingStatusReport';

type UseReportDownloadProps = {
  sortColumn?: string;
  sortIsDescending?: boolean;
  reportType: ReportTypes;
  hospital?: TrainingProgressEntity;
  group?: TrainingProgressEntity;
  physician?: TrainingProgressEntity;
};

type UseReportDownloadOutput = {
  handleDownload: ({ ...args }: UseReportDownloadProps) => Promise<void>;
  isDownloading: boolean;
};

const useReportDownload = (): UseReportDownloadOutput => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async ({
    sortColumn,
    sortIsDescending,
    reportType,
    hospital,
    group,
    physician
  }: UseReportDownloadProps): Promise<void> => {
    setIsDownloading(true);

    const sortParam = `sortColumn=${sortColumn}&sortDirection=${sortIsDescending ? 'desc' : 'asc'}`;
    const hospitalId = hospital?.id === -1 ? 1 : hospital?.id;

    let endpoint = ``;
    switch (reportType) {
      case 'System':
        endpoint = `reports/training/system/${hospitalId}/download?${sortParam}`;
        break;
      case 'Hospital':
        endpoint = `reports/training/hospital/${hospitalId}/download?${sortParam}`;
        break;
      case 'Group':
        endpoint = `reports/training/physicianGroup/${group?.id}/download?${sortParam}`;
        break;
      case 'Provider':
        endpoint = `reports/training/physician/${physician?.id}/download?${sortParam}`;
        break;
      default:
        break;
    }

    const result = await ApplicationAPI.download(endpoint, 'xlsx');
    createBlob(result.data, 'Training Progress Report.xlsx');

    setIsDownloading(false);
  };

  return {
    handleDownload,
    isDownloading
  };
};

export default useReportDownload;
