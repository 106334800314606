import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
const LiveHelpIcon = (props: SvgIconProps): JSX.Element => {
  const colorOverride = props.color ?? 'inherit';
  return (
    <SvgIcon sx={{ ...props.sx }} viewBox="0 0 18 22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0002 0.259766H2.00024C0.890244 0.259766 0.000244141 1.15977 0.000244141 2.25977V16.2598C0.000244141 17.3598 0.890244 18.2598 2.00024 18.2598H6.00024L9.00024 21.2598L12.0002 18.2598H16.0002C17.1002 18.2598 18.0002 17.3598 18.0002 16.2598V2.25977C18.0002 1.15977 17.1002 0.259766 16.0002 0.259766ZM16.0002 16.2598H11.1702L10.5802 16.8498L9.00024 18.4298L7.41024 16.8398L6.83024 16.2598H2.00024V2.25977H16.0002V16.2598ZM8.00024 13.2598H10.0002V15.2598H8.00024V13.2598ZM9.00024 5.25977C10.1002 5.25977 11.0002 6.15977 11.0002 7.25977C11.0002 9.25977 8.00024 9.00977 8.00024 12.2598H10.0002C10.0002 10.0098 13.0002 9.75977 13.0002 7.25977C13.0002 5.04977 11.2102 3.25977 9.00024 3.25977C6.79024 3.25977 5.00024 5.04977 5.00024 7.25977H7.00024C7.00024 6.15977 7.90024 5.25977 9.00024 5.25977Z"
        fill={colorOverride}
        strokeWidth={0}
      />
    </SvgIcon>
  );
};
LiveHelpIcon.muiName = SvgIcon.muiName;
export default LiveHelpIcon;
