import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

type GetHyperlinkTypeProps = {
  isExternalHyperlink: boolean;
  onClick: () => void;
  url: string;
};

const GetHyperlinkType: React.FC<GetHyperlinkTypeProps> = ({ isExternalHyperlink, children, onClick, url }) => {
  return isExternalHyperlink ? (
    <Box component="a" onClick={onClick} href={url} target="_blank" sx={{ textDecoration: 'none' }}>
      {children}
    </Box>
  ) : (
    <Link onClick={onClick} to={url} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  );
};

export default GetHyperlinkType;
