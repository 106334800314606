import { NodeTypeIds, TreeNode } from '@clinintell/modules/orgTree';
import { useClientOrgTreeState, useCMIComparisonState, useSAFOrgTreeState } from '@clinintell/modules/store';
import { dfsFindNodeInTree } from '@clinintell/utils/treeTraversal';
import { useCallback, useState } from 'react';

export type EntityDetailsState = {
  id: number | undefined;
  name: string;
  type?: number;
};

type UseEntityDetailsOutput = {
  entityId: number | undefined;
  entityName: string;
  updateEntity: (entityDetails: EntityDetailsState) => void;
};

export const getEntityDescriptiveName = (entity: EntityDetailsState, node: TreeNode | null): string => {
  let descriptiveName = entity.name;
  if (entity.type === NodeTypeIds['SpecialtyGroup'] && node && entity.id !== undefined) {
    const givenNode = dfsFindNodeInTree(node, entity.id);
    if (givenNode && givenNode.parentId) {
      const parentNode = dfsFindNodeInTree(node, givenNode.parentId);
      if (parentNode) {
        descriptiveName = `${parentNode.name} - ${descriptiveName}`;
      }
    }
  }

  return descriptiveName;
};

const useEntityDetails = (defaultEntity?: EntityDetailsState): UseEntityDetailsOutput => {
  const [entity, setEntity] = useState<EntityDetailsState>(
    defaultEntity
      ? {
          ...defaultEntity
        }
      : {
          id: undefined,
          name: '',
          type: undefined
        }
  );

  const { orgTreeType } = useCMIComparisonState();
  const { node: clientNode } = useClientOrgTreeState();
  const { node: safNode } = useSAFOrgTreeState();

  const node = orgTreeType === 'client' ? clientNode : safNode;

  const updateEntity = useCallback((entityDetails: EntityDetailsState): void => {
    setEntity({
      ...entityDetails
    });
  }, []);

  return {
    entityId: entity.id,
    entityName: getEntityDescriptiveName(entity, node),
    updateEntity
  };
};

export default useEntityDetails;
