import { formatNumberWithCommas, roundDecimal } from '@clinintell/utils/formatting';
import React from 'react';

type IntegerCellProps = {
  value: number;
  prefix?: string;
  postfix?: string;
};

export const IntegerCell: React.FC<IntegerCellProps> = ({ value, prefix, postfix }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '10px', transform: 'translateX(10px)' }}>
      <div>{`${prefix || ''}${formatNumberWithCommas(roundDecimal(value, 0))}${postfix || ''}`}</div>
    </div>
  );
};
