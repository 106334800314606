import React, { useRef } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Button from '@clinintell/components/button/Button';
import ActionPanel from '@clinintell/components/ActionPanel';
import CIModal from '@clinintell/components/modal/CIModal';
import useReportSchedulerSidePanel from '@clinintell/containers/reports/sections/context/hooks/useReportSchedulerSidePanel';
import {
  QuarterlyReportSubmissionType,
  GroupReportSubmissionType
} from '@clinintell/containers/reports/sections/context/hooks/useReportSchedulerSave';

type ReportSidePanelProps = {
  title: string;
  submitEnabled: boolean;
  onCloseHandler: () => void;
  onSubmitHandler: (requestData: QuarterlyReportSubmissionType | GroupReportSubmissionType) => Promise<number>;
  reportSubmissionData: QuarterlyReportSubmissionType | GroupReportSubmissionType;
};

const ReportSidePanel: React.FC<ReportSidePanelProps> = ({
  children,
  title,
  submitEnabled,
  onSubmitHandler,
  onCloseHandler,
  reportSubmissionData
}) => {
  const theme = useTheme();
  const { isOpen, isBusy, setOpened, setBusy } = useReportSchedulerSidePanel();
  const errorModalRef = useRef<HTMLButtonElement>(null);

  const setActionPanelState = (setPanel: boolean) => {
    setOpened(setPanel);
    setBusy(setPanel);
    if (!setPanel) onCloseHandler();
  };

  const scheduledReportCloseClickHandler = async () => {
    const schedulerResults = onSubmitHandler({ ...reportSubmissionData });
    if ((await schedulerResults) !== 200 && (await schedulerResults) !== 407) errorModalRef?.current?.click();
    else {
      setActionPanelState(false);
    }
  };

  return (
    <>
      <Box borderColor="grey.300" pl={2} ml={2} borderLeft={`1px solid ${theme.palette.grey[300]}`}>
        <Button
          label="Schedule Report"
          type="primary"
          variant="text"
          onClick={() => {
            setOpened(true);
          }}
        />
      </Box>
      <ActionPanel
        title={
          <Box width="100%" padding={0} margin={0} display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Typography variant="h4" sx={{ color: `${theme.palette.grey[700]} !important` }}>
              {title}
            </Typography>
            <Button
              label={'Schedule'}
              type="primary"
              sx={{ marginLeft: 'auto', padding: '0px 34px 0px 34px' }}
              disabled={!submitEnabled}
              onClick={() => {
                setBusy(true);
                scheduledReportCloseClickHandler();
              }}
              isBusy={isBusy}
            />
          </Box>
        }
        open={isOpen}
        closeFn={() => {
          setActionPanelState(false);
        }}
      >
        <Box display="flex" flexDirection="column" padding="40px 24px 0 26px">
          {children}
        </Box>
      </ActionPanel>
      <CIModal modalBtn={<Box id="errorMdlBtn" ref={errorModalRef} sx={{ width: '0', height: '0' }} />}>
        <CIModal.Header>
          <Typography
            variant="body1"
            style={{ color: theme.palette.common.black, fontWeight: 'bold', fontSize: '1rem' }}
          >
            This schedule already exists
          </Typography>
        </CIModal.Header>
        <CIModal.Content>
          <Typography
            variant="body1"
            style={{
              color: '#64748B',
              fontFamily: 'Poppins',
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(2)
            }}
          >
            Your schedule couldn't be saved. A schedule with the same parameters already exists.
          </Typography>
        </CIModal.Content>
        <CIModal.Footer>
          <Button
            type="primary"
            variant="contained"
            label="Ok"
            onClick={() => {
              if (isBusy) setBusy(false);
              errorModalRef?.current?.click();
            }}
            sx={{ marginRight: 2, minWidth: 130 }}
          />
        </CIModal.Footer>
      </CIModal>
    </>
  );
};

export default ReportSidePanel;
