/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isMobileOnly } from 'react-device-detect';
import clsx from 'clsx';
import { useTableDispatch } from '@clinintell/modules/store';
import { setTableColumnSize } from '@clinintell/modules/table';

const useStyles = makeStyles(theme => ({
  divider: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 3,
    cursor: 'col-resize',
    userSelect: 'none',
    minHeight: 38,
    height: '100%',
    borderRight: `1px solid #2e7cb1`,
    borderLeft: `1px solid #2e7cb1`
  }
}));

let pageX: number | undefined;
let curCol: HTMLElement | null | undefined;
let curColWidth: number | undefined;

const ColumnResizer: React.FC = () => {
  const navDispatch = useTableDispatch();
  const { divider } = useStyles();

  const paddingDiff = (col: Element): number => {
    if (getStyleVal(col, 'box-sizing') === 'border-box') {
      return 0;
    }

    const padLeft = getStyleVal(col, 'padding-left');
    const padRight = getStyleVal(col, 'padding-right');
    return parseInt(padLeft) + parseInt(padRight);
  };

  const getStyleVal = (elem: Element, css: string): string => {
    return window.getComputedStyle(elem, null).getPropertyValue(css);
  };

  const handleMouseOver = (e: any): void => {
    e.target.style.borderRight = `1px solid #A0AABE`;
    e.target.style.borderLeft = `1px solid #A0AABE`;
  };

  const handleMouseOut = (e: any): void => {
    e.target.style.borderRight = `1px solid #2e7cb1`;
    e.target.style.borderLeft = `1px solid #2e7cb1`;
  };

  const handleMouseDown = (e: any): void => {
    curCol = e.target.parentElement as HTMLElement;
    pageX = e.pageX;
    const padding = paddingDiff(curCol);
    curColWidth = curCol.offsetWidth - padding;
  };

  const handleMouseMove = (e: any): void => {
    if (curCol) {
      const diffX = pageX ? e.pageX - pageX : e.pageX;
      curCol.style.width = curColWidth ? `${curColWidth + diffX}px` : `${diffX}px`;
    }
  };

  const handleMouseUp = (e: any): void => {
    if (curCol && curCol.offsetWidth > 0) {
      const padding = paddingDiff(curCol);
      navDispatch(setTableColumnSize({ id: curCol.id, width: curCol.offsetWidth - padding }));
    }
    pageX = undefined;
    curCol = undefined;
    curColWidth = undefined;
  };

  useEffect(() => {
    const elems = document.querySelectorAll('.dragResizer');

    elems.forEach(elem => {
      elem.addEventListener('mouseover', handleMouseOver);
      elem.addEventListener('mousedown', handleMouseDown);
      elem.addEventListener('mouseout', handleMouseOut);
    });

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return (): void => {
      elems.forEach(elem => {
        elem.removeEventListener('mouseover', handleMouseOver);
        elem.removeEventListener('mousedown', handleMouseDown);
        elem.removeEventListener('mouseout', handleMouseOut);
      });
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMobileOnly) {
    return null;
  }

  return <Box className={clsx([divider, 'dragResizer'])} />;
};

export default ColumnResizer;
