import { Hospital } from './hospitals';
import {
  DomainModelProviderOrgDetails as ProviderDetails,
  OrgChangeRequestOrgChangeRequestQueue as QueuePayloadType,
  OrgChangeRequestRenameProvider as RenameProviderType,
  OrgChangeRequestMoveProvider as MoveProviderType,
  OrgChangeRequestRenameGroup as RenameGroupType
} from '@clinintell/api/apiSchemas';

enum SystemManagementActions {
  SET_PM_DISPLAY = 'SET_PM_DISPLAY',
  SET_HOSPITAL = 'SET_HOSPITAL',
  SET_PROVIDER = 'SET_PROVIDER',
  RESET_SELECTED_PROVIDERS = 'RESET_SELECTED_PROVIDERS',
  SELECT_PROVIDER = 'SELECT_PROVIDER',
  RESET = 'RESET',
  SET_DISPLAY_TREE = 'SET_DISPLAY_TREE',
  ADD_RENAME_PROVIDER = 'ADD_RENAME_PROVIDER',
  REMOVE_RENAME_PROVIDER = 'REMOVE_RENAME_PROVIDER',
  ADD_MOVE_PROVIDER = 'ADD_MOVE_PROVIDER',
  REMOVE_MOVE_PROVIDER = 'REMOVE_MOVE_PROVIDER',
  ADD_RENAME_GROUP = 'ADD_RENAME_GROUP',
  REMOVE_RENAME_GROUP = 'REMOVE_RENAME_GROUP',
  INIT_QUEUE = 'INIT_QUEUE',
  SET_ORIGINAL_QUEUE = 'SET_ORIGINAL_QUEUE'
}

export interface SystemManagementAction<T> {
  type?: keyof typeof SystemManagementActions;
  payload?: T;
}

type SelectionType = null | 'provider' | 'group';

type Dataset = {
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
};

export interface SystemManagementData extends Dataset {
  display: SelectionType;
  provider: ProviderDetails | null;
  isNewProvider?: boolean;
  hospital: Hospital | null;
  isNewGroup?: boolean;
  selectedProviders: Map<number, Omit<ProviderDetails, 'providerIsHidden'>>;
  displayTree: boolean;
  queue: {
    queueInitialized: boolean;
    renameProviders: Map<number, RenameProviderType>;
    moveProviders: Map<number, MoveProviderType>;
    renameGroups: Map<number, RenameGroupType>;
  };
  originalQueue?: {
    renameProviders: Map<number, RenameProviderType>;
    moveProviders: Map<number, MoveProviderType>;
    renameGroups: Map<number, RenameGroupType>;
  };
}

export type ProviderPayload = {
  id: number;
  provider: ProviderDetails;
};

export const setProvider = (payload: ProviderPayload): SystemManagementAction<ProviderPayload> => ({
  type: SystemManagementActions.SET_PROVIDER,
  payload
});

export const selectProvider = (payload: ProviderDetails): SystemManagementAction<ProviderDetails> => ({
  type: SystemManagementActions.SELECT_PROVIDER,
  payload
});

export const resetProviderManagement = (): SystemManagementAction<void> => ({
  type: SystemManagementActions.RESET
});

export const setDisplayTree = (payload: boolean): SystemManagementAction<boolean> => ({
  type: SystemManagementActions.SET_DISPLAY_TREE,
  payload
});

export const initialSystemManagementDataset: SystemManagementData = {
  isLoading: false,
  hasError: false,
  display: null,
  provider: null,
  hospital: null,
  selectedProviders: new Map(),
  displayTree: false,
  queue: {
    queueInitialized: false,
    renameProviders: new Map(),
    moveProviders: new Map(),
    renameGroups: new Map()
  }
};

export const addRenameProvider = (payload: RenameProviderType): SystemManagementAction<RenameProviderType> => ({
  type: SystemManagementActions.ADD_RENAME_PROVIDER,
  payload
});

export const removeRenameProvider = (payload: number): SystemManagementAction<number> => ({
  type: SystemManagementActions.REMOVE_RENAME_PROVIDER,
  payload
});

export const addMoveProvider = (payload: MoveProviderType): SystemManagementAction<MoveProviderType> => ({
  type: SystemManagementActions.ADD_MOVE_PROVIDER,
  payload
});

export const removeMoveProvider = (payload: number): SystemManagementAction<number> => ({
  type: SystemManagementActions.REMOVE_MOVE_PROVIDER,
  payload
});

export const addRenameGroup = (payload: RenameGroupType): SystemManagementAction<RenameGroupType> => ({
  type: SystemManagementActions.ADD_RENAME_GROUP,
  payload
});

export const removeRenameGroup = (payload: number): SystemManagementAction<number> => ({
  type: SystemManagementActions.REMOVE_RENAME_GROUP,
  payload
});

export const resetSelectedProviders = (): SystemManagementAction<void> => ({
  type: SystemManagementActions.RESET_SELECTED_PROVIDERS
});

export const initializeQueue = (payload: QueuePayloadType): SystemManagementAction<QueuePayloadType> => ({
  type: SystemManagementActions.INIT_QUEUE,
  payload
});

export const setOriginalQueue = (): SystemManagementAction<void> => ({
  type: SystemManagementActions.SET_ORIGINAL_QUEUE
});

const systemManagementReducer = (
  state: SystemManagementData = initialSystemManagementDataset,
  action: SystemManagementAction<
    | SelectionType
    | Hospital
    | ProviderPayload
    | ProviderDetails
    | RenameProviderType
    | MoveProviderType
    | RenameGroupType
    | QueuePayloadType
    | string
    | boolean
    | number
  >
): SystemManagementData => {
  switch (action.type) {
    case 'SET_PM_DISPLAY': {
      return {
        ...state,
        display: action.payload as SelectionType
      };
    }
    case 'SET_HOSPITAL': {
      return {
        ...state,
        hospital: action.payload as Hospital,
        displayTree: true
      };
    }
    case 'SET_PROVIDER': {
      const selectedProviders = new Map([...state.selectedProviders]);
      const { id, provider } = action.payload as ProviderPayload;
      if (selectedProviders.has(id)) {
        selectedProviders.delete(id);
      } else {
        selectedProviders.set(id, provider);
      }
      return {
        ...state,
        selectedProviders,
        displayTree: true
      };
    }
    case 'SELECT_PROVIDER': {
      return {
        ...state,
        provider: action.payload as ProviderDetails
      };
    }
    case 'ADD_RENAME_PROVIDER': {
      const renameProviders = new Map([...state.queue.renameProviders]);
      const { providerOrgId } = action.payload as RenameProviderType;
      renameProviders.set(Number(providerOrgId), action.payload as RenameProviderType);
      return {
        ...state,
        queue: {
          ...state.queue,
          renameProviders
        }
      };
    }
    case 'REMOVE_RENAME_PROVIDER': {
      const renameProviders = new Map([...state.queue.renameProviders]);
      renameProviders.delete(Number(action.payload));
      return {
        ...state,
        queue: {
          ...state.queue,
          renameProviders
        }
      };
    }
    case 'ADD_MOVE_PROVIDER': {
      const moveProviders = new Map([...state.queue.moveProviders]);
      const { providerOrgId } = action.payload as MoveProviderType;
      moveProviders.set(Number(providerOrgId), action.payload as MoveProviderType);
      return {
        ...state,
        queue: {
          ...state.queue,
          moveProviders
        }
      };
    }
    case 'REMOVE_MOVE_PROVIDER': {
      const moveProviders = new Map([...state.queue.moveProviders]);
      moveProviders.delete(Number(action.payload));
      return {
        ...state,
        queue: {
          ...state.queue,
          moveProviders
        }
      };
    }
    case 'ADD_RENAME_GROUP': {
      const renameGroups = new Map([...state.queue.renameGroups]);
      const { currentGroupOrgId } = action.payload as RenameGroupType;
      renameGroups.set(Number(currentGroupOrgId), action.payload as RenameGroupType);
      return {
        ...state,
        queue: {
          ...state.queue,
          renameGroups
        }
      };
    }
    case 'REMOVE_RENAME_GROUP': {
      const renameGroups = new Map([...state.queue.renameGroups]);
      renameGroups.delete(Number(action.payload));
      return {
        ...state,
        queue: {
          ...state.queue,
          renameGroups
        }
      };
    }
    case 'RESET_SELECTED_PROVIDERS': {
      return {
        ...state,
        selectedProviders: new Map()
      };
    }
    case 'RESET': {
      return {
        ...state,
        selectedProviders: new Map(),
        hospital: null,
        display: null,
        queue: {
          queueInitialized: false,
          renameProviders: new Map(),
          moveProviders: new Map(),
          renameGroups: new Map()
        }
      };
    }
    case 'INIT_QUEUE': {
      const {
        renameProviders: queueRenameProviders,
        moveProviders: queueMoveProviders,
        renameGroups: queueRenameGroups
      } = action.payload as QueuePayloadType;

      const renameProviders = new Map();
      const moveProviders = new Map();
      const renameGroups = new Map();

      queueRenameProviders?.forEach(provider => {
        renameProviders.set(provider.providerOrgId, provider);
      });
      queueMoveProviders?.forEach(provider => {
        moveProviders.set(provider.providerOrgId, provider);
      });
      queueRenameGroups?.forEach(group => {
        renameGroups.set(group.currentGroupOrgId, group);
      });

      return {
        ...state,
        queue: {
          queueInitialized: true,
          renameProviders,
          moveProviders,
          renameGroups
        },
        originalQueue: {
          renameProviders,
          moveProviders,
          renameGroups
        }
      };
    }
    case 'SET_DISPLAY_TREE': {
      return {
        ...state,
        displayTree: false
      };
    }
    case 'SET_ORIGINAL_QUEUE': {
      return {
        ...state,
        originalQueue: {
          ...state.queue
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default systemManagementReducer;
