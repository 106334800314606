import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Select, SelectOptionType } from '../../components/index';
import { ScheduleFrequencyDto, ScheduleFrequencies } from '@clinintell/api/apiSchemas';
import { useScheduleGetReportsFrequencies } from '@clinintell/api/apiComponents';
import useErrorHandling from '@clinintell/errors/useErrorHandling';

type Props = {
  labelPosition?: 'top' | 'side';
  title?: string;
  defaultValue?: number | string;
  onFrequencyChangeFn: (value: number, display: string) => void;
  width?: number | string;
  labelWidth?: number | string;
  disableDropdown?: boolean;
};

const SelectFrequency: React.FC<Props> = ({
  labelPosition = 'top',
  title = 'Frequency',
  defaultValue,
  onFrequencyChangeFn,
  width = 400,
  labelWidth,
  disableDropdown = false
}) => {
  const [dropdDownfrequencies, setDropdDownfrequencies] = useState<SelectOptionType[]>([]);
  const { showErrorMessage } = useErrorHandling();

  const {
    data: reportScheduleFrequencies,
    isLoading: isFetchingScheduleFrequencies
  } = useScheduleGetReportsFrequencies({
    onError: showErrorMessage
  });

  useEffect(() => {
    if (isFetchingScheduleFrequencies) return;
    const { scheduleFrequencyList } = reportScheduleFrequencies as ScheduleFrequencies;
    const frequenciesSelectorOptions: SelectOptionType[] | [] = (scheduleFrequencyList as ScheduleFrequencyDto[]).map(
      selectOption => {
        if (defaultValue && (defaultValue as number) === selectOption.id) {
          onFrequencyChangeFn(selectOption.id, selectOption.frequencyName as string);
        }
        return {
          value: selectOption.id,
          label: selectOption.frequencyName as string,
          useLabelForTestId: true
        };
      }
    );
    setDropdDownfrequencies(frequenciesSelectorOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, reportScheduleFrequencies, isFetchingScheduleFrequencies]);

  return (
    <Box
      display="flex"
      flexDirection={labelPosition === 'top' ? 'column' : 'row'}
      alignItems={labelPosition === 'top' ? 'flex-start' : 'center'}
      width={width}
    >
      <Typography variant="p2" width={labelWidth !== undefined || labelWidth !== null ? labelWidth : 'auto'}>
        {title}
      </Typography>
      <Box width="100%" style={labelPosition === 'top' ? { marginTop: 8 } : { marginLeft: 8 }}>
        <Select
          isDisabled={dropdDownfrequencies === undefined || isFetchingScheduleFrequencies || disableDropdown}
          isSearchable={true}
          options={dropdDownfrequencies}
          value={Number(defaultValue)}
          onChange={(value, label): void => {
            onFrequencyChangeFn(Number(value), label);
          }}
          testId="frequencySelectorId"
        />
      </Box>
    </Box>
  );
};

export default React.memo(SelectFrequency);
