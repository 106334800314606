import React from 'react';
import { Box, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import useCmiAnalysis from '../../hooks/useCmiAnalysis';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';

export type CmiAnalysisTableType = {
  historicalCmi: number;
  currentCmi: number;
  historicalDrg: number;
  currentDrg: number;
  historicalSeverity: number;
  currentSeverity: number;
};

const CmiAnalysisWidgetTable: React.FC = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const cmiAnalysisWidgetData = useCmiAnalysis();

  if (cmiAnalysisWidgetData === null) {
    return (
      <Box>
        <Box>No Data</Box>
      </Box>
    );
  }

  const {
    historicalDates,
    currentDates,
    historicalCmi,
    currentCmi,
    cmiDelta,
    historicalDrg,
    currentDrg,
    drgDelta,
    drgPercentage,
    historicalSeverity,
    currentSeverity,
    severityDelta,
    severityPercentage
  } = cmiAnalysisWidgetData;

  return (
    <Grid container spacing={1} sx={{ mb: isMobileView ? 2 : 4 }}>
      <Grid item xs={4} />
      <Grid item xs={2}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2">{historicalDates}</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ textAlign: 'center', ml: 2 }}>
          <Typography variant="body2">{currentDates}</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end', height: '100%', ml: 3 }}>
          <Typography variant="body2">Change</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ borderColor: 'grey.300' }} />
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="body2">CMI</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2">{formatToDecimalPlaces(historicalCmi, 2)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ textAlign: 'center', ml: String(currentCmi)[0] === '-' ? 'calc(24% - 6px)' : '24%' }}>
          <Typography variant="body2">{formatToDecimalPlaces(currentCmi, 2)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box ml={2}>
          <Box sx={{ textAlign: 'left', ml: String(cmiDelta)[0] === '-' ? 'calc(24% - 6px)' : '24%' }}>
            <Typography variant="body2">{formatToDecimalPlaces(cmiDelta, 2)}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ borderColor: 'grey.300' }} />
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="body2">DRG Mix Value</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2">{formatToDecimalPlaces(historicalDrg, 2)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ textAlign: 'center', ml: String(currentDrg)[0] === '-' ? 'calc(24% - 6px)' : '24%' }}>
          <Typography variant="body2">{formatToDecimalPlaces(currentDrg, 2)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box ml={2}>
          <Box
            sx={{
              textAlign: 'left',
              display: 'flex',
              gap: 1,
              ml: String(drgDelta)[0] === '-' ? 'calc(24% - 6px)' : '24%'
            }}
          >
            <Typography variant="body2">{formatToDecimalPlaces(drgDelta, 2)}</Typography>
            {Number(drgPercentage) > 0 && (
              <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'red.500' }}>
                ({formatToDecimalPlaces(drgPercentage, 0)}%)
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="body2">Severity Value</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2">{formatToDecimalPlaces(historicalSeverity, 2)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ textAlign: 'center', ml: String(currentSeverity)[0] === '-' ? 'calc(24% - 6px)' : '24%' }}>
          <Typography variant="body2">{formatToDecimalPlaces(currentSeverity, 2)}</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box ml={2}>
          <Box
            sx={{
              textAlign: 'left',
              display: 'flex',
              gap: 1,
              ml: String(severityDelta)[0] === '-' ? 'calc(24% - 6px)' : '24%'
            }}
          >
            <Typography variant="body2">{formatToDecimalPlaces(severityDelta, 2)}</Typography>
            {Number(severityPercentage) > 0 && (
              <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'teal.light5' }}>
                ({formatToDecimalPlaces(severityPercentage, 0)}%)
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CmiAnalysisWidgetTable;
