import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { ProviderTrainingConditionType } from '@clinintell/containers/providerTrainingAssignment/types/providerTrainingConditionType';
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { OrgTableProps } from '../TrainingStatusReport';
import { useReportDownloadStatus } from '../context/ReportDownloadContext';
import CheckBox from '@mui/material/Checkbox';
import {
  providerHasAssignedTraining,
  providerHasCompletedTraining
} from '@clinintell/containers/trainingConfiguration/logic/providerTrainingStatuses';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import { Table, DateCell, useTableApiActions } from '@clinintell/components/table_v2';

type ProviderFooterTrainingConditionType = {
  isConditionTrainingEnabled: boolean;
  assignedDate: string;
  totalQuestions: number;
  questionAnswered: number;
  correctAnswers: number;
};

const calculateConditionScore = ({
  assignedDate,
  totalQuestions,
  questionAnswered,
  correctAnswers
}: ProviderTrainingConditionType | ProviderFooterTrainingConditionType): string => {
  if (!assignedDate) {
    return '-';
  }

  // Don't return a score if the provider hasn't completed training yet
  if (questionAnswered !== totalQuestions) {
    return '-';
  }

  return `${correctAnswers} of ${questionAnswered} (${formatToDecimalPlaces(
    (correctAnswers / questionAnswered) * 100,
    0
  )}%)`;
};

const ProviderGeneratedReport: React.FC<OrgTableProps> = ({ orgId }): JSX.Element => {
  const theme = useTheme();

  const { isLoading: providerIsLoading, output: providers } = useGetAPICAll<ProviderTrainingConditionType[]>({
    endpoint: `reports/training/physician/${orgId}`,
    isWaiting: false
  });

  const { enableDownload, disableDownload } = useReportDownloadStatus();
  const { initializeTableApis } = useTableApiActions();

  if (providerIsLoading || !providers) {
    return <ClinIntellSkeleton variant="rectangular" height="25rem" width="100%" />;
  }

  if (!providerIsLoading && providers && providers.data && providers.data.length === 0) {
    disableDownload();
    return (
      <Box component="span" color={theme.palette.red[500]}>
        This provider is not enrolled in ClinIntell's online training.
      </Box>
    );
  } else {
    enableDownload();
  }

  return (
    <Table<ProviderTrainingConditionType>
      onGridReady={initializeTableApis}
      rowData={providers.data || []}
      renderColumns={({ renderColumn, renderCustomColumn }) => {
        return [
          renderColumn('conditionName', {
            suppressMenu: false,
            cellRenderer: props => <Box>{props.value}</Box>,
            sortable: true,
            headerName: 'Clinical Condition',
            width: 350,
            suppressSizeToFit: true,
            sort: 'asc'
          }),
          renderColumn('isConditionTrainingEnabled', {
            cellRenderer: props => <CheckBox checked={props.value === true} color="primary" disabled />,
            width: 100,
            cellStyle: {
              justifyContent: 'center'
            },
            headerName: 'Status',
            sortable: true,
            headerTooltip: 'Checked status indicates the condition is included in the current training'
          }),
          renderColumn('assignedDate', {
            cellRenderer: props => {
              return props.value && props.data && providerHasAssignedTraining(props.data) ? (
                <DateCell date={props.value} />
              ) : (
                '-'
              );
            },
            cellStyle: {
              justifyContent: 'center'
            },
            sortable: true
          }),
          renderColumn('completedDate', {
            cellRenderer: props =>
              props.value && props.data && providerHasCompletedTraining(props.data) ? (
                <DateCell date={props.value} />
              ) : (
                '-'
              ),
            sortable: true,
            cellStyle: {
              justifyContent: 'center'
            },
            headerName: 'Completion Date'
          }),
          renderCustomColumn({
            cellRenderer: props => {
              if (!props.data) {
                return '-';
              }

              const conditionScore = calculateConditionScore(props.data);

              return <Box>{conditionScore}</Box>;
            },
            sortable: true,
            valueFormatter: props => {
              if (!props.data) {
                return 0;
              }

              if (props.data.questionAnswered === 0) {
                return 0;
              }

              return (props.data.correctAnswers / props.data.questionAnswered) * 100;
            },
            cellStyle: {
              justifyContent: 'center'
            },
            headerName: 'Test Score'
          })
        ];
      }}
    />
  );
};

export default ProviderGeneratedReport;
