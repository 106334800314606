import { useCallback, useEffect, useState } from 'react';
import { useHospitals } from '@clinintell/modules/store';
import { Hospital } from '@clinintell/modules/hospitals';
import { SelectOptionType } from '@clinintell/types/common';

type UseHospitalFiltersOutput = {
  options: SelectOptionType[];
  isLoading: boolean;
};

const useHospitalFilters = (): UseHospitalFiltersOutput => {
  const [options, setOptions] = useState<SelectOptionType[]>([]);
  const { data, isLoading } = useHospitals();

  const setHospitalAsync = useCallback(async (): Promise<void> => {
    const hospitals: Hospital[] = [];
    // If just one hospital, we can set the state using that one only. Otherwise we have to make an org call to figure out the system ID
    // using the returned lineage output.
    const hospitalList = Object.keys(data);
    if (hospitalList.length === 1) {
      hospitals.push(data[hospitalList[0]]);
      setOptions(
        hospitals.map<SelectOptionType>(hospital => ({ label: hospital.hospitalName, value: hospital.id }))
      );
    } else {
      hospitals.push(...hospitalList.map(hospital => data[hospital]));
      const sortedHospitals = hospitals
        .filter(hospital => hospital.trainingUsers)
        .map<SelectOptionType>(hospital => ({ label: hospital.hospitalName, value: hospital.id }))
        .sort((a, b) => {
          return a.label.localeCompare(b.label, 'en', { ignorePunctuation: true });
        });
      sortedHospitals.unshift({ label: 'All Hospitals', value: -1 });

      setOptions(sortedHospitals);
    }
  }, [data]);

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) {
      return;
    }
    setHospitalAsync();
  }, [data, setHospitalAsync]);

  return {
    options,
    isLoading
  };
};

export default useHospitalFilters;
