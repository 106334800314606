import { Box, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';

type ProgressBarSizeTypes = 'small' | 'large';

type ProgressBarProps = {
  value: number;
  width?: number | string;
  size: ProgressBarSizeTypes;
  top?: number;
};

const largeSizeConfiguration = {
  height: '25px',
  top: 3
};

const smallSizeConfiguration = {
  height: '20px',
  top: 3
};

type StyleProps = {
  size: ProgressBarSizeTypes;
  percentageValue: string;
  top?: number;
};

const useStyles = makeStyles(theme => ({
  bar: {
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 20,
    background: theme.palette.grey[50],
    height: ({ size }: StyleProps): string =>
      size === 'large' ? largeSizeConfiguration.height : smallSizeConfiguration.height
  },
  barFill: {
    background: theme.palette.green[300],
    height: '100%',
    width: ({ percentageValue }: { percentageValue: string }): string => percentageValue,
    position: 'absolute',
    display: 'block',
    top: 0,
    borderRadius: ({ percentageValue }: StyleProps): string => (percentageValue === '100%' ? '20px' : '20px 0 0 20px'),
    transition: 'width 200ms linear'
  },
  label: {
    position: 'absolute',
    top: ({ size, top }: StyleProps): number =>
      !!top ? top : size === 'large' ? largeSizeConfiguration.top : smallSizeConfiguration.top,
    left: '50%',
    transform: 'translateX(-50%)'
  }
}));

// A component that displays the progress in graphic view from 0 to 100%. Progress value is a decimal from 0 to 1.
const ProgressBar: React.FC<ProgressBarProps> = ({ value, width, size, top }) => {
  const theme = useTheme();

  const percentageValue = value === 0 ? '0%' : `${formatToDecimalPlaces(value * 100, 0)}%`;
  const { bar, barFill, label } = useStyles({ percentageValue, size, top });

  return (
    <Box position="relative">
      <Box className={barFill} />
      <Box {...(width !== undefined ? { width } : null)} className={bar} height="100%" />
      <Box
        fontSize={size === 'large' ? theme.typography.p1.fontSize : theme.typography.p2.fontSize}
        component="span"
        className={label}
      >
        {percentageValue}
      </Box>
    </Box>
  );
};

export default ProgressBar;
