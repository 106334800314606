import React from 'react';
import { Box, Typography } from '@mui/material';

const AutoCompleteOption = ({ label }: { label: string }): JSX.Element => {
  return (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      <Box display="flex" alignContent="center">
        <Typography variant="p2" color="textPrimary">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default AutoCompleteOption;
