import { Box, useTheme } from '@mui/material';
import React from 'react';

type AnalysisTypeButtonProps = {
  isSelected: boolean;
  onClick: () => void;
  label: string;
};

const AnalysisTypeButton: React.FC<AnalysisTypeButtonProps> = ({ isSelected, onClick, label }) => {
  const theme = useTheme();

  return (
    <Box
      onClick={onClick}
      role="button"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid',
        borderColor: 'blue.main',
        borderRadius: '16px',
        py: 0.75,
        width: '9rem',
        backgroundColor: isSelected ? 'blue.light5' : 'white',
        cursor: 'pointer',
        transition: 'background .2s linear',
        '&:hover': {
          backgroundColor: 'blue.light5'
        }
      }}
    >
      <Box fontWeight={500} fontSize={theme.typography.p2.fontSize} component="span" color={theme.palette.blue.main}>
        {label}
      </Box>
    </Box>
  );
};

export default AnalysisTypeButton;
