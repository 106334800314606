import { TreeNode } from '@clinintell/modules/orgTree';
import { Box, Checkbox, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 4,
    paddingBottom: 4
  }
}));

type NodeLabelCheckboxProps = {
  isChecked: boolean;
  item: TreeNode;
  onChecked: (checked: boolean) => void;
};

const NodeLabelCheckbox: React.FC<NodeLabelCheckboxProps> = ({ item, isChecked, onChecked }) => {
  const { root } = useStyles();

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Checkbox
        color="primary"
        checked={isChecked}
        onChange={(event): void => {
          onChecked(event.target.checked);
        }}
        classes={{
          root
        }}
      />
      <Typography variant="body2">{item.name}</Typography>
    </Box>
  );
};

export default NodeLabelCheckbox;
