import {
  ChildMenuItem,
  MenuItem,
  setActiveMenuItem,
  setMenuExpanded,
  setMenuItemChildrenExpanded,
  setMenuTemporarilyExpanded
} from '@clinintell/modules/menuNavigation';
import { useMenuNavigationDispatch, useMenuNavigationState } from '@clinintell/modules/store';
import useSignOut from '@clinintell/containers/authentication/useSignOut';

type UseMenuActionsOutput = {
  handleMenuExpansionClick: (isExpanded: boolean) => void;
  handleMenuLinkClick: (menuItem: MenuItem) => void;
  handleMenuParentClick: (menuItem: MenuItem) => void;
  handleChildMenuLinkClick: (childMenuItem: ChildMenuItem) => void;
};

const useMenuActions = (): UseMenuActionsOutput => {
  const menuNavigationDispatch = useMenuNavigationDispatch();
  const { signOut } = useSignOut();
  const { menuIsExpanded, menuIsTemporarilyExpanded } = useMenuNavigationState();

  const expandMenu = menuIsExpanded || menuIsTemporarilyExpanded;

  const handleMenuExpansionClick = (isExpanded: boolean) => {
    menuNavigationDispatch(setMenuExpanded({ isExpanded }));
  };

  const handleMenuLinkClick = (menuItem: MenuItem) => {
    if (menuItem.name === 'signout') {
      signOut();
      return;
    }
    menuNavigationDispatch(setActiveMenuItem({ name: menuItem.name }));
  };

  const handleMenuParentClick = (menuItem: MenuItem) => {
    menuNavigationDispatch(
      setMenuItemChildrenExpanded({
        name: menuItem.name,
        isExpanded: !expandMenu ? true : !menuItem.childrenAreExpanded
      })
    );

    if (!menuIsExpanded) {
      menuNavigationDispatch(setMenuTemporarilyExpanded({ isTemporarilyExpanded: true }));
    }
  };

  const handleChildMenuLinkClick = (childMenuItem: ChildMenuItem) => {
    menuNavigationDispatch(setActiveMenuItem({ name: childMenuItem.name }));
  };

  return {
    handleMenuExpansionClick,
    handleMenuLinkClick,
    handleMenuParentClick,
    handleChildMenuLinkClick
  };
};

export default useMenuActions;
