import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Can from '@clinintell/components/Can';
import LoadingScreen from '@clinintell/components/LoadingScreen';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';

const TrainingConfiguration = lazy(() =>
  import(
    /* webpackChunkName: 'trainingConfiguration' */ '@clinintell/containers/trainingConfiguration/TrainingConfiguration'
  )
);

const TrainingTest = lazy(() =>
  import(/* webpackChunkName: 'trainingTest' */ '@clinintell/containers/trainingTest/TrainingTest')
);

const ConditionTrainingContent = lazy(() =>
  import(
    /* webpackChunkName: 'trainingConditionContent' */ '@clinintell/containers/trainingContent/ConditionTrainingContent'
  )
);

const ClinicalContentIndex = lazy(() =>
  import(
    /* webpackChunkName: 'ClinicalContentIndex' */ '@clinintell/containers/trainingContentApproval/clinicalContentIndex'
  )
);

const TrainingContentPocketCard = lazy(() =>
  import(
    /* webpackChunkName: 'trainingConditionContent' */ '@clinintell/containers/trainingContentApproval/trainingContentPocketCard'
  )
);

const TrainingRoutes = (): JSX.Element => {
  return (
    <Suspense fallback={<LoadingScreen loadingIndicatorSize={250} />}>
      <Routes>
        <Route
          path="assign"
          element={
            <Can
              permissions={['trainingEdit', 'trainingContentView']}
              yes={(): JSX.Element => {
                return <TrainingConfiguration />;
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
            />
          }
        />
        <Route
          path="clinicalcontent"
          element={
            <Can
              permissions={['trainingContentApproval', 'trainingEdit', 'trainingPreview']}
              yes={(): JSX.Element => {
                return <ClinicalContentIndex />;
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
            />
          }
        />
        <Route
          path="test/:orgId"
          element={
            <Can
              permissions={['trainingTake', 'trainingPreview']}
              yes={(): JSX.Element => {
                return <TrainingTest />;
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
            />
          }
        />
        <Route
          path="conditionContent/:conditionId/:contentConditionId"
          element={
            <Can
              permissions={['trainingTake', 'trainingPreview']}
              yes={(): JSX.Element => {
                return <TrainingContentPocketCard />;
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
            />
          }
        />
        <Route
          path=":groupOrgId/condition/:conditionId"
          element={
            <Can
              permissions={['trainingTake', 'trainingPreview']}
              yes={(): JSX.Element => {
                return <ConditionTrainingContent />;
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
            />
          }
        />
        <Route path="*" element={<RouteErrorPage error={new Error('404')} />} />
      </Routes>
    </Suspense>
  );
};

export default TrainingRoutes;
