import { EntityDetailsState, getEntityDescriptiveName } from '@clinintell/components/index';
import { userCan } from '@clinintell/containers/authentication/rules';
import EntityNavigation from '@clinintell/containers/entityNavigation/EntityNavigation';
import { NodeDetails } from '@clinintell/containers/treeSelector/logic/TreeContext';
import { EntitySelection } from '@clinintell/modules/cmiAnalysisSelections';
import { useClientOrgTreeState, useHospitals, useSAFOrgTreeState, useUser } from '@clinintell/modules/store';
import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import EntitySelectionActions, { EntitySelectionActionsProps } from '../entitySelectionActions/EntitySelectionActions';

const SingleEntity: React.FC<EntitySelectionActionsProps> = props => {
  const [myEntity, setMyEntity] = useState<EntitySelection>();

  const user = useUser();
  const hospitals = useHospitals();
  const userCanSelectProvidersInTree = userCan(user, ['reportCmiAnalysisViewProviders']);

  const { node: clientNode } = useClientOrgTreeState();
  const { node: safNode } = useSAFOrgTreeState();
  const node = props.orgTreeType === 'client' ? clientNode : safNode;

  const handleEntitySelection = useCallback(
    (entity: number, nodeDetails: NodeDetails) => {
      const entityDetailsState: EntityDetailsState = { name: nodeDetails.name, id: entity, type: nodeDetails.type };
      setMyEntity({ name: getEntityDescriptiveName(entityDetailsState, node), id: entity });
    },
    [node]
  );

  return (
    <EntitySelectionActions
      {...props}
      type="single"
      analyzeIsDisabled={!myEntity}
      entity={myEntity}
      selectionDescription="Select your entity below. Your entity can range from your health system to hospital or physician group."
      actionButtonLabel="Analyze"
    >
      <Box sx={{ typography: 'p1', fontWeight: 500 }} component="span">
        My Entity:
      </Box>
      {myEntity ? ` ${myEntity.name}` : ''}
      <Box mt={1} mb={4}>
        <EntityNavigation
          defaultExpandedNodes={[user.rootId.toString()]}
          isTreeVisible={true}
          entity={myEntity ? myEntity.id : user.rootId}
          withSearch={hospitals.totalCount > 1}
          maxnodetype={userCanSelectProvidersInTree ? 'Provider' : 'SpecialtyGroup'}
          minCharactersToSearch={3}
          maxCharactersToSearch={50}
          hideButtonColor="primary"
          onEntitySelected={handleEntitySelection}
          onEntitySearchComplete={handleEntitySelection}
          id="myEntitySelector"
          cannotHideTree
          height={450}
          type={props.orgTreeType}
        />
      </Box>
    </EntitySelectionActions>
  );
};

export default SingleEntity;
