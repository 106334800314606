import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import Widget from '../../components/widget/Widget';
import DashboardDoughnutChart from '@clinintell/containers/dashboard/charts/DashboardDoughnutChart';
import { useDashboard2, useDashboard2Dispatch, useMenuNavigationDispatch, useUser } from '@clinintell/modules/store';
import { setActiveMenuItem } from '@clinintell/modules/menuNavigation';
import { DashboardWidget, fetchSmartGoalsModalData, WidgetSchema } from '@clinintell/modules/dashboard2';
import { useNavigate } from 'react-router-dom';
import SmartGoalsWidgetTable from './SmartGoalsWidgetTable';
import WidgetModal from '../../components/modal/WidgetModal';
import WidgetError from '../../components/widget/WidgetError';
import { userCan } from '@clinintell/containers/authentication/rules';

const SmartGoalsWidget: React.FC = () => {
  // Redux Data
  const user = useUser();
  const navigation = useNavigate();
  const dashboardDispatch = useDashboard2Dispatch();
  const menuNavigationDispatch = useMenuNavigationDispatch();
  const { smartGoalsWidget, smartGoalsWidget: widget, entity, groupName: group, groupId } = useDashboard2();
  const { isModalLoading, isLoading } = smartGoalsWidget;
  const { hasError, data } = widget as DashboardWidget;

  // Component States
  const [smartGoalsData, setSmartGoalsData] = useState<number[]>([0]);
  const [totalStrategies, setTotalStrategies] = useState<number>(0);
  const [meetingStrategies, setmeetingStrategies] = useState<number>(0);
  const [widgetStatus, setWidgetStatus] = useState<string>('');
  const [widgetMsg, setWidgetMsg] = useState<string | null>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const getOrgTitle = useMemo(() => {
    if (group && group !== 'All') return group;
    if (entity) return entity.name;
    return '';
  }, [entity, group]);

  const getOrgId = useMemo(() => {
    if (groupId && groupId !== entity?.id) return groupId;
    if (entity) return entity.id;
    return -1;
  }, [entity, groupId]);

  const widgetTooltip = (
    <Box width={300} my={-1}>
      <p>This widget monitors the health of the strategies across {getOrgTitle}.</p>
      <p>Click on the donut chart to see more details.</p>
    </Box>
  );

  const handleChartClick = (): void => {
    if (entity !== undefined && entity.id) dashboardDispatch(fetchSmartGoalsModalData(getOrgId));
    setIsModalOpen(true);
  };

  const userCanViewSmartGoals = userCan(user, ['docScoreGoalView']);
  const WidgetModalMemo = useMemo(
    () => (
      <WidgetModal
        modalVisible={isModalOpen}
        toggleVisible={() => setIsModalOpen(false)}
        tooltipMsg={`Navigate to SMART Goals - Overview`}
        canRedirect={userCanViewSmartGoals}
        redirectUserHandler={(): void => {
          navigation('/goals/overview');
          menuNavigationDispatch(setActiveMenuItem({ name: 'overviewLanding' }));
        }}
        headerTitle={`${getOrgTitle} SMART Goals`}
        isModalLoading={isModalLoading}
      >
        <SmartGoalsWidgetTable />
      </WidgetModal>
    ),
    [getOrgTitle, isModalLoading, isModalOpen, menuNavigationDispatch, navigation, userCanViewSmartGoals]
  );

  useEffect(() => {
    if (isLoading || !data) return;
    const {
      message,
      numBelow,
      numMeeting,
      numNearing,
      percentBelow,
      percentMeeting,
      percentNearing,
      status
    } = data as WidgetSchema;

    setWidgetStatus(status);

    if (widgetStatus === 'Active') {
      setTotalStrategies(numBelow + numMeeting + numNearing);
      setSmartGoalsData([percentMeeting, percentNearing, percentBelow]);
      setmeetingStrategies(numMeeting);
    } else {
      setWidgetMsg(message);
    }
  }, [data, isLoading, widgetStatus]);

  return (
    <>
      <Widget
        isLoading={isLoading}
        hasError={hasError}
        headerTitle="SMART Goals"
        toolTip={widgetTooltip}
        displayTooltip={widgetStatus === 'Active' ? true : false}
      >
        {widgetStatus !== 'Active' ? (
          <WidgetError graphMessage={widgetMsg ?? undefined} graphStatus={widgetStatus} />
        ) : (
          <>
            <DashboardDoughnutChart
              chartData={smartGoalsData}
              chartLabels={['Meeting', 'Nearing', 'Below']}
              labelMsg={`${meetingStrategies}/${totalStrategies}\n${
                groupId && groupId !== entity?.id ? 'Providers ' : 'Strategies'
              } Meeting\nGoal`}
              onClick={handleChartClick}
              testId="smartGoalsWidget"
            />
          </>
        )}
      </Widget>
      {WidgetModalMemo}
    </>
  );
};

export default SmartGoalsWidget;
