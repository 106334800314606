import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ClinIntellLogo = (props: SvgIconProps) => (
  <SvgIcon
    sx={{
      width: '48px',
      height: '48px',
      fill: 'none',
      ...props.sx
    }}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="#385EAB"
    />
    <path
      d="M10.661 23.7474C10.661 22.6526 10.8463 21.5832 11.2168 20.5389C11.6042 19.4779 12.16 18.5179 12.8842 17.6589C13.6084 16.7832 14.501 16.0926 15.5621 15.5874C16.6231 15.0653 17.8273 14.8042 19.1747 14.8042C20.7747 14.8042 22.139 15.1747 23.2674 15.9158C24.4126 16.64 25.2547 17.5747 25.7937 18.72L24.4042 19.5789C24.0168 18.7873 23.5284 18.1642 22.9389 17.7095C22.3494 17.2379 21.7179 16.901 21.0442 16.6989C20.3874 16.4968 19.7305 16.3958 19.0737 16.3958C17.9958 16.3958 17.0442 16.6147 16.2189 17.0526C15.3937 17.4737 14.6947 18.0463 14.1221 18.7705C13.5663 19.4779 13.1453 20.2779 12.8589 21.1705C12.5726 22.0463 12.4295 22.9389 12.4295 23.8484C12.4295 24.8421 12.5979 25.7937 12.9347 26.7031C13.2716 27.6126 13.7431 28.421 14.3495 29.1284C14.9558 29.819 15.6631 30.3747 16.4716 30.7958C17.2968 31.2 18.1895 31.4021 19.1495 31.4021C19.8231 31.4021 20.5137 31.2842 21.221 31.0484C21.9284 30.8126 22.5853 30.4505 23.1916 29.9621C23.8147 29.4737 24.3116 28.8421 24.6821 28.0674L26.1474 28.8253C25.7768 29.7179 25.2042 30.4758 24.4295 31.0989C23.6547 31.7221 22.7874 32.1937 21.8274 32.5137C20.8842 32.8337 19.9579 32.9937 19.0484 32.9937C17.819 32.9937 16.6905 32.7326 15.6631 32.2105C14.6358 31.6716 13.7431 30.9642 12.9853 30.0884C12.2442 29.1958 11.6716 28.2021 11.2674 27.1074C10.8631 25.9958 10.661 24.8758 10.661 23.7474Z"
      fill="white"
    />
    <path d="M36.1263 29.8105H29.3053V32.3368H36.1263V29.8105Z" fill="white" />
    <path d="M36.1263 15.1579H29.3053V17.6842H36.1263V15.1579Z" fill="white" />
    <path d="M34.3741 15.4085V32.3368H30.9787V15.4085H34.3741Z" fill="white" />
  </SvgIcon>
);

export default ClinIntellLogo;
