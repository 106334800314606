import { NodeDetails } from '@clinintell/containers/treeSelector/logic/TreeContext';
import {
  fetchPathToRootFromClientNode,
  fetchPathToRootFromSAFNode,
  OrgTreeTypes,
  TreeNode
} from '@clinintell/modules/orgTree';
import { useClientOrgTreeState, useOrgTreeDispatch, useSAFOrgTreeState, useUser } from '@clinintell/modules/store';
import { useCallback, useEffect } from 'react';

type UseOrgStructureByTypeProps = {
  type: OrgTreeTypes;
};

export type FetchNodeArgs = {
  nodeId: number;
  nodeDetails?: NodeDetails;
  parentId?: number;
};

type UseOrgStructureByTypeOutput = {
  treeNode: TreeNode | null;
  nodeIdBeingFetched: number | null;
  fetchNode: (args: FetchNodeArgs) => Promise<NodeDetails>;
};

const useOrgTreeByType = ({ type }: UseOrgStructureByTypeProps): UseOrgStructureByTypeOutput => {
  const clientOrgTree = useClientOrgTreeState();
  const safOrgTree = useSAFOrgTreeState();
  const dispatch = useOrgTreeDispatch();
  const user = useUser();

  const treeNode = type === 'client' ? clientOrgTree.node : safOrgTree.node;
  const nodeIdBeingFetched = type === 'client' ? clientOrgTree.isFetchingNodeId : safOrgTree.isFetchingNodeId;
  const fetchToRootMethod = type === 'client' ? fetchPathToRootFromClientNode : fetchPathToRootFromSAFNode;

  useEffect(() => {
    if (!treeNode && !nodeIdBeingFetched) {
      dispatch(fetchToRootMethod({ nodeId: type === 'client' ? user.rootId : 2 }));
    }
  }, [dispatch, fetchToRootMethod, nodeIdBeingFetched, treeNode, type, user.rootId]);

  const fetchNode = useCallback(
    async ({ nodeId, nodeDetails, parentId }: FetchNodeArgs): Promise<NodeDetails> => {
      return new Promise<NodeDetails>(resolve => {
        if (!nodeDetails || nodeDetails.isNew) {
          dispatch(fetchToRootMethod({ nodeId, onFetchComplete: resolve, parentId }));
        }
      });
    },
    [dispatch, fetchToRootMethod]
  );

  return {
    treeNode,
    nodeIdBeingFetched,
    fetchNode
  };
};

export default useOrgTreeByType;
