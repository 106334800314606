export const DRAG_CONTROL_WIDTH = 17.5;

export class SliderBox {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  isDraggingRight: boolean;
  isDraggingLeft: boolean;
  rightDragCenterX: number;
  leftDragCenterX: number;
  dragCenterY: number;
  color: string;

  constructor(x: number, y: number, width: number, height: number, id: string, color: string) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.isDraggingRight = false;
    this.isDraggingLeft = false;
    this.id = id;
    this.color = color;

    // Add the drag controls coordinates
    this.rightDragCenterX = x + width - DRAG_CONTROL_WIDTH;
    this.leftDragCenterX = x;
    this.dragCenterY = height / 2;
  }

  setIsDraggingRight(isDragging: boolean): void {
    this.isDraggingRight = isDragging;
  }

  setIsDraggingLeft(isDragging: boolean): void {
    this.isDraggingLeft = isDragging;
  }

  setX(x: number): void {
    this.width = this.width + (this.x - x);
    this.x = x;
    this.leftDragCenterX = x;
  }

  setWidth(x: number): void {
    this.width = x - this.x;
    this.rightDragCenterX = x - DRAG_CONTROL_WIDTH;
    this.leftDragCenterX = this.x;
  }

  hasConflictWithOtherBoxesToRight(x: number, otherBoxes: SliderBox[]): number | undefined {
    // Make sure there are no conflicts with other slider boxes to the right of this box
    let conflictingX;
    otherBoxes.forEach(otherBox => {
      // Exclude checking box we are dragging or boxes to the left of this box
      if (this.id === otherBox.id || this.x > otherBox.x) {
        return;
      }

      if (x > otherBox.x) {
        conflictingX = otherBox.x;
        return;
      }
    });

    return conflictingX;
  }

  hasConflictWithOtherBoxesToLeft(x: number, otherBoxes: SliderBox[]): number | undefined {
    // Make sure there are no conflicts with other slider boxes to the left of this box
    let conflictingX;
    otherBoxes.forEach(otherBox => {
      // Exclude checking box we are dragging or boxes to the right of this box
      if (this.id === otherBox.id || this.x < otherBox.x) {
        return;
      }

      if (x < otherBox.x + otherBox.width) {
        conflictingX = otherBox.x + otherBox.width;
        return;
      }
    });

    return conflictingX;
  }
}

export class SliderPluginClass {
  canvas: HTMLCanvasElement;
  boxes: SliderBox[];
  widthBetweenPoints: number;
  sliderAdjustment: number;
  xValues: string[];
  onBoxStartChange: (id: string, xStart: string) => void;
  onBoxEndChange: (id: string, xEnd: string) => void;
  mouseIsDown: boolean;

  constructor(canvasId: string, chartArea: Chart.ChartArea, offsetLeft: number) {
    const canvas = document.createElement('canvas');
    canvas.setAttribute('style', `position: absolute; top: ${chartArea.top}px; left: ${chartArea.left + offsetLeft}px`);
    canvas.setAttribute('id', `${canvasId}_overlay`);
    canvas.setAttribute('height', `${chartArea.bottom - chartArea.top}px`);
    canvas.setAttribute('width', `${chartArea.right - chartArea.left}px`);

    this.canvas = canvas;
    this.boxes = [];
    this.widthBetweenPoints = 0;
    this.sliderAdjustment = 0;
    this.xValues = [];
    this.mouseIsDown = false;
    this.onBoxStartChange = (): void => {
      /* empty object */
    };
    this.onBoxEndChange = (): void => {
      /* empty object */
    };

    const mouseDownEvent = (event: MouseEvent): void => {
      this.startDrag(event.offsetX);
    };

    const mouseUpEvent = (event: MouseEvent): void => {
      this.endDrag(event.offsetX);
    };

    const mouseMoveEvent = (event: MouseEvent): void => {
      this.drag(event.offsetX);
    };

    this.canvas.addEventListener('mousedown', mouseDownEvent);
    this.canvas.addEventListener('mouseup', mouseUpEvent);
    this.canvas.addEventListener('mousemove', mouseMoveEvent);
  }

  setWidthBetweenPoints(chart: Chart): void {
    const dataset = chart.getDatasetMeta(0).data;
    // Figure out the width between points for slider coverage purposes. This will be used for a lot of things.
    const firstPointX = dataset[0]._model.x;
    const secondPointX = dataset[1]._model.x;
    this.widthBetweenPoints = secondPointX - firstPointX;
    this.sliderAdjustment = this.widthBetweenPoints / 2;
  }

  setXValues(values: string[]): void {
    this.xValues = values;
  }

  startDrag(x: number): void {
    // Loop through each slider box and see if there is a collision between the mouse location and slider control
    this.boxes.forEach(box => {
      if (x > box.rightDragCenterX && x < box.rightDragCenterX + DRAG_CONTROL_WIDTH) {
        // Adding a slight delay so that single clicks do not activate the dragger
        this.mouseIsDown = true;
        setTimeout(() => {
          if (this.mouseIsDown) {
            box.setIsDraggingRight(true);
          }
        }, 100);
      } else if (x > box.leftDragCenterX && x < box.leftDragCenterX + DRAG_CONTROL_WIDTH) {
        this.mouseIsDown = true;
        setTimeout(() => {
          if (this.mouseIsDown) {
            box.setIsDraggingLeft(true);
          }
        }, 100);
      }
    });
  }

  endDrag(x: number): void {
    this.mouseIsDown = false;

    // Loop through each slider box and see if there are any dragging states. If so, set to false
    this.boxes.forEach(box => {
      if (box.isDraggingLeft) {
        box.setIsDraggingLeft(false);

        let startPoint = 0;
        // If there is a collision with another box, snap the point right next to the conflicting box
        const conflictingX = box.hasConflictWithOtherBoxesToLeft(x, this.boxes);
        if (conflictingX !== undefined) {
          startPoint = Math.floor(conflictingX / this.widthBetweenPoints);
          box.setX(conflictingX);
          // If the width could end up being less than the minimum width between points, snap it so that the width is
          // the minimum allowed width.
        } else if (box.width + box.x - x < this.widthBetweenPoints) {
          box.setX(box.x);
          startPoint = Math.floor(box.x / this.widthBetweenPoints);
        } else {
          // The end of the slider needs to be snapped into place in the middle of two data points. Use math to figure out where to place the end.
          // The end should be in the middle of whatever month is passed. Example - if the end is anywhere inbetween an Aug20 - Sep20 datapoints, place the end in the middle
          // of Aug20 and Sep20.
          startPoint = Math.floor(x / this.widthBetweenPoints);
          const sliderX = startPoint * this.widthBetweenPoints + this.sliderAdjustment;
          box.setX(sliderX);
        }

        const startPeriodLabel = this.xValues[startPoint + 1] as string;

        this.onBoxStartChange(box.id, startPeriodLabel);
      } else if (box.isDraggingRight) {
        box.setIsDraggingRight(false);

        let sliderSpan = 0;
        // If there is a collision with another box, snap the point right next to the conflicting box
        const conflictingX = box.hasConflictWithOtherBoxesToRight(x, this.boxes);
        if (conflictingX !== undefined) {
          sliderSpan = Math.floor(
            (conflictingX - this.sliderAdjustment - (box.x - this.sliderAdjustment)) / this.widthBetweenPoints - 0.5
          );
          box.setWidth(conflictingX);
          // If the width could end up being less than the minimum width between points, snap it so that the width is
          // the minimum allowed width.
        } else if (x < box.x + box.width) {
          box.setWidth(box.x + this.widthBetweenPoints);
          sliderSpan = 0;
        } else {
          // The end of the slider needs to be snapped into place in the middle of two data points. Use math to figure out where to place the end.
          // The end should be in the middle of whatever month is passed. Example - if the end is anywhere inbetween an Aug20 - Sep20 datapoints, place the end in the middle
          // of Aug20 and Sep20.
          sliderSpan = Math.floor(
            (x - this.sliderAdjustment - (box.x - this.sliderAdjustment)) / this.widthBetweenPoints - 0.5
          );

          const sliderEndPoint = box.x + this.widthBetweenPoints + sliderSpan * this.widthBetweenPoints;
          box.setWidth(sliderEndPoint);
        }

        // Figure out which periods the slider covers for every slider
        const startPeriod = Math.floor(box.x / this.widthBetweenPoints) + 1;
        const endPeriod = startPeriod + sliderSpan;

        const endPeriodLabel = this.xValues[endPeriod] as string;

        this.onBoxEndChange(box.id, endPeriodLabel);
      }
    });
  }

  drag(x: number): void {
    this.boxes.forEach(box => {
      // Keeps slider from going back too far, colliding with start of the slider
      if (box.isDraggingRight && x >= box.x + this.widthBetweenPoints) {
        if (!box.hasConflictWithOtherBoxesToRight(x, this.boxes)) {
          box.setWidth(x);
        }
      } else if (box.isDraggingLeft && x < box.x + box.width - this.widthBetweenPoints) {
        if (!box.hasConflictWithOtherBoxesToLeft(x, this.boxes)) {
          box.setX(x);
        }
      }
    });
  }
}
