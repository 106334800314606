import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { ChartData, ChartTooltipItem } from 'chart.js';

export type ChartLegendType = {
  label: string;
  color?: string;
  tooltip?: string;
};

type Props = {
  data: ChartData;
  legendItems?: ChartLegendType[];
  handleOnChartSliceClick: (_?: globalThis.MouseEvent, elements?: unknown[]) => void;
  testId: string;
};

const DashboardPieChart: React.FC<Props> = ({ data, legendItems, handleOnChartSliceClick, testId }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0 auto'
        }}
      >
        <Pie
          height={isMobileView ? 150 : undefined}
          width={isMobileView ? 300 : undefined}
          data={data as ChartData}
          options={{
            responsive: false,
            maintainAspectRatio: true,
            onClick: handleOnChartSliceClick,
            legend: {
              display: false
            },
            tooltips: {
              enabled: true,
              callbacks: {
                label(tooltipItem: ChartTooltipItem, data: Chart.ChartData): string {
                  if (
                    (!tooltipItem.index && !Array.isArray(data.datasets)) ||
                    !data.datasets?.length ||
                    !Array.isArray(data.datasets[0].data) ||
                    !data.datasets[0].data?.length
                  )
                    return '';
                  return data.labels !== undefined
                    ? `${data.labels[tooltipItem.index as number]}: ${
                        data.datasets[0].data[tooltipItem.index as number]
                      }%`
                    : `missingLabel: ${data.datasets[0].data[tooltipItem.index as number]}`;
                }
              }
            }
          }}
        />
      </Box>
      <Box id={`${testId}-graphDescription`} display="none">
        {data && data.datasets && data.datasets[0] && data.datasets[0].data ? (
          <>
            <Box>{JSON.stringify(data.datasets[0].data)}</Box>
            {data.datasets[0].data.map((d, ix) => {
              const optionTestId = data.labels && data.labels[ix] ? data.labels[ix].toString().replaceAll(' ', '') : d;
              return (
                <button
                  key={ix}
                  data-test-id={`${testId}-${optionTestId}-activator`}
                  data-test-value={d}
                  onClick={() => handleOnChartSliceClick(undefined, [{ _index: ix }])}
                />
              );
            })}
          </>
        ) : null}
      </Box>
    </>
  );
};

export default DashboardPieChart;
