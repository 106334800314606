import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Timeline } from '@mui/icons-material';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import Footnotes from '@clinintell/components/Footnotes';
import { useDashboard2 } from '@clinintell/modules/store';
import { DRGConditionsOpportunityData, DrgConditionOpportunityData } from '@clinintell/modules/dashboard2';
import { parseDateIntoPeriod } from '@clinintell/utils/formatting';
import { IntegerCell, IsStatSignificantCell, Table } from '@clinintell/components/table_v2';
import { MetricRecordJSON } from '@clinintell/containers/metrics/typings/metricTypes';

type Props = {
  startDate: string | Date;
  endDate: string | Date;
  onGraphClick: (conditionId: number, conditionDescription: string) => void;
};

export type DRGDataType = {
  drgOpportunityData: DRGConditionsOpportunityData;
  widgetTableData: DrgConditionOpportunityData;
};

const DrgConditionsWidgetTable: React.FC<Props> = ({ startDate, endDate, onGraphClick }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { drgConditionsByOpportunityWidget } = useDashboard2();

  const { drgOpportunityData } = drgConditionsByOpportunityWidget.data as DRGDataType;

  const footnotes: string[] = [
    `Period: ${parseDateIntoPeriod(new Date(startDate))}-${parseDateIntoPeriod(new Date(endDate))}`
  ];

  return (
    <Box>
      <Typography variant={'h5'} style={{ color: theme.palette.grey[700], fontWeight: 'bold' }}>
        Condition Performance Table
      </Typography>
      <Box mt={2}>
        <Table<MetricRecordJSON>
          rowData={drgOpportunityData.metrics}
          renderColumns={({ renderColumn, renderCustomColumn }) => {
            return [
              renderColumn('name', {
                headerName: 'Clinical Condition',
                headerAlignment: 'left',
                minWidth: 350,
                sortable: true,
                cellRenderer: props => props.value || ''
              }),
              renderCustomColumn({
                cellRenderer: props => (
                  <Box
                    onClick={(): void => {
                      if (!props.data) {
                        return;
                      }

                      return onGraphClick(props.data?.conditionId, props.data?.name);
                    }}
                    onTouchEnd={(): void => {
                      if (!props.data) {
                        return;
                      }

                      return onGraphClick(props.data?.conditionId, props.data?.name);
                    }}
                    sx={{ marginTop: 1, cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '100%' }}
                  >
                    <Tooltip content="View Graph" position="right">
                      <Timeline />
                    </Tooltip>
                  </Box>
                ),
                cellStyle: {
                  justifyContent: 'center'
                },
                width: 90
              }),
              renderColumn('current', {
                headerName: 'Coded Rate',
                cellRenderer: props => <IntegerCell value={props.value || 0} postfix="%" />,
                sortable: true,
                cellStyle: {
                  justifyContent: 'center'
                }
              }),
              !isMobileView &&
                renderColumn('clinicallyExpected', {
                  headerName: 'Clinically Expected',
                  cellRenderer: props => <IntegerCell value={props.value || 0} postfix="%" />,
                  sortable: true,
                  cellStyle: {
                    justifyContent: 'center'
                  }
                }),
              renderColumn('gapVsCe', {
                headerName: 'Coded Rate Gap',
                cellRenderer: props =>
                  props.value && props.data ? (
                    <IsStatSignificantCell
                      value={props.value}
                      errorMargin={props.data?.gapVsCeInErrorRange}
                      isPercentageBased
                      captureOverdocumentation
                    />
                  ) : (
                    '-'
                  ),
                sortable: true,
                cellStyle: {
                  justifyContent: 'center'
                },
                headerTooltip: (
                  <Box>
                    <p>
                      Gap is the percent of the patient population for which a clinical condition was underreported as a
                      secondary diagnosis.
                    </p>
                    <div>
                      The bubble color indicates:
                      <ul style={{ marginLeft: -16 }}>
                        <li>Red – Gap is statistically significant and lower than Clinically Expected</li>
                        <li>Amber – Gap is statistically significant and higher than Clinically Expected</li>
                        <li>No bubble – Gap is not statistically significant</li>
                      </ul>
                    </div>
                  </Box>
                )
              }),
              !isMobileView &&
                renderColumn('otoE', {
                  headerName: 'Secondary O/CE',
                  cellRenderer: props => <IntegerCell value={props.value || 0} postfix="%" />,
                  sortable: true,
                  cellStyle: {
                    justifyContent: 'center'
                  }
                }),
              !isMobileView &&
                renderColumn('opportunityRW', {
                  headerName: 'Coded Rate Opportunity',
                  cellRenderer: props => <IntegerCell value={props.value || 0} />,
                  sortable: true,
                  cellStyle: {
                    justifyContent: 'center'
                  },
                  headerTooltip: (
                    <Box>
                      <p>
                        Estimate of the RW value of the clinical condition gap based on all the claims for which it was
                        the only CC or MCC for the particular provider, physician group or hospital.
                      </p>
                    </Box>
                  )
                })
            ];
          }}
        />
        <Box mt={2} ml={-2}>
          <Footnotes footnotes={footnotes} />
        </Box>
      </Box>
    </Box>
  );
};

export default DrgConditionsWidgetTable;
