import { ClickAwayListener, Popover, SxProps, Theme } from '@mui/material';
import { SystemCssProperties } from '@mui/system';
import React from 'react';

type PopupProps = {
  isOpen: boolean;
  connectingElement: HTMLElement | null;
  sx?: SxProps<Theme>;
  paperSx?: SystemCssProperties<Theme>;
  onClose: () => void;
};

const Popup: React.FC<PopupProps> = ({ isOpen, connectingElement, children, sx, paperSx, onClose }) => {
  return (
    <Popover
      sx={{
        '& .MuiPaper-root': {
          boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.05)',
          border: '0.5px solid',
          borderColor: 'grey.300',
          borderRadius: '5px',
          ...paperSx
        },
        ...sx
      }}
      keepMounted={false}
      open={isOpen}
      anchorEl={connectingElement}
    >
      <ClickAwayListener disableReactTree onClickAway={onClose}>
        <div>{children}</div>
      </ClickAwayListener>
    </Popover>
  );
};

export default Popup;
