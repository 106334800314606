import React, { useState } from 'react';
import { Box, Popover, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Select from 'react-select';
import { SelectOptionType } from '@clinintell/types/common';
import TextInput from './textInput/TextInput';

const useStyles = makeStyles(theme => ({
  container: {
    border: `solid 2px ${theme.palette.grey[300]}`,
    borderRadius: 4,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    minHeight: theme.spacing(5),
    minWidth: theme.spacing(12)
  },
  dropdown: {
    minWidth: theme.spacing(15),
    width: '100%'
  }
}));

interface AnchorOrigin {
  vertical: 'top' | 'center' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
}

interface FilterProps {
  id: string;
  type: 'text' | 'date' | 'type';
  anchorEl: HTMLElement | null;
  anchorOrigin: AnchorOrigin;
  open: boolean;
  selectOptions?: SelectOptionType[];
  defaultOptions?: SelectOptionType[];
  onClose: (value: boolean) => void;
  onFilter: (value: string | string[] | number | number[]) => void;
}

const TableHeaderFilter: React.FC<FilterProps> = ({
  id,
  type,
  anchorEl,
  anchorOrigin,
  open,
  selectOptions,
  defaultOptions = [],
  onClose,
  onFilter
}) => {
  const { container, dropdown } = useStyles();
  const theme = useTheme();
  const [selectedOptionValues, setSelectedOptionValues] = useState<SelectOptionType[]>(defaultOptions);
  const [searchValue, setSearchValue] = useState('');

  let filterComponent;
  if (type === 'type') {
    const selectStyle = {
      menu: (): { [key: string]: string } => ({
        backgroundColor: theme.palette.common.white
      })
    };

    filterComponent = (
      <Select
        isMulti
        menuIsOpen
        id={`filter-${type}-${id}`}
        options={selectOptions}
        defaultValue={selectedOptionValues}
        styles={selectStyle}
        className={dropdown}
        closeMenuOnSelect={false}
        onChange={(values, actionMeta): void => {
          let selectedOptions: number[] = [];
          let selectedValues: SelectOptionType[] = [];
          if (actionMeta.action === 'select-option' || actionMeta.action === 'remove-value') {
            selectedOptions = values
              ? (values as SelectOptionType[]).map((o: SelectOptionType) => o.value as number)
              : [];
            selectedValues = values ? (values as SelectOptionType[]) : [];
          } else if (actionMeta.action === 'clear') {
            selectedOptions = [];
            selectedValues = [];
          }
          setSelectedOptionValues(selectedValues);
          onFilter(selectedOptions);
        }}
      />
    );
  } else {
    filterComponent = (
      <TextInput
        defaultValue={searchValue}
        placeholder="Type to search"
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          const input = event.currentTarget as HTMLInputElement;
          setTimeout(() => {
            setSearchValue(input.value);
            onFilter(input.value);
          }, 750);
        }}
      />
    );
  }

  return (
    <Popover id={`popover-${id}`} anchorEl={anchorEl} anchorOrigin={anchorOrigin} open={open} onClose={onClose}>
      <Box className={container}>{filterComponent}</Box>
    </Popover>
  );
};

export default TableHeaderFilter;
