import { TreeJSON } from '@clinintell/modules/orgTree';
import { SelectOptionType } from '@clinintell/types/common';
import { ApplicationAPI } from '@clinintell/utils/api';
import { useCallback, useState } from 'react';
import { EntityReturnType } from '@clinintell/containers/common/index';

const getOptionsFromOrgTree = (tree: EntityReturnType): SelectOptionType[] => {
  let options: SelectOptionType[] = [];
  if (tree.physicianGroups) {
    options = tree.physicianGroups
      .filter(group => group.trainingUsers === true)
      .map(group => {
        return {
          label: group.physicianGroupName,
          value: group.id
        };
      });
  }

  return options;
};

const getPhysiciansFromOrgTree = (tree: TreeJSON): SelectOptionType[] => {
  let options: SelectOptionType[] = [];

  if (tree.children) {
    options = tree.children.map(o => {
      return { label: o.name, value: o.id };
    });
  }
  return options;
};

type UseSubFiltersOutput = {
  options: SelectOptionType[];
  isLoading: boolean;
  fetchSubFilters: (id: number, callType?: 'group' | 'physician') => Promise<EntityReturnType | TreeJSON | undefined>;
};

const useSubFilters = (): UseSubFiltersOutput => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<SelectOptionType[]>([]);

  const fetchSubFilters = useCallback(async (id: number, callType = 'group') => {
    if (id === -1) return; // no need to fetch if -1

    setIsLoading(true);
    const endpoint = callType === 'group' ? `hospitals/${id}?includeAllGroups=0` : `org/${id}`;

    const result = await ApplicationAPI.get<EntityReturnType | TreeJSON>({ endpoint });

    if (result.data) {
      const resultOptions =
        callType === 'group'
          ? getOptionsFromOrgTree(result.data as EntityReturnType)
          : getPhysiciansFromOrgTree(result.data as TreeJSON);
      const sortedOptions = resultOptions.sort((a, b) => {
        return a.label.localeCompare(b.label, 'en', { ignorePunctuation: true });
      });

      if (sortedOptions.length > 1) {
        sortedOptions.unshift({ value: -1, label: 'All' });
      }

      setOptions(sortedOptions);
      setIsLoading(false);

      return result.data;
    }
  }, []);

  return {
    isLoading,
    fetchSubFilters,
    options
  };
};

export default useSubFilters;
