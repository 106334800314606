import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconPropType } from '@clinintell/types/common';

const PlaneIcon = (props: SvgIconPropType): JSX.Element => {
  return (
    <SvgIcon sx={{ fill: 'none', ...props.sx }} viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 9.1665L17.3333 1.6665L9.83333 17.4998L8.16667 10.8332L1.5 9.1665Z"
        stroke="#385EAB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default PlaneIcon;
