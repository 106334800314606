import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import stethoscope from '@clinintell/assets/images/stethoscope_v2.png';
import microscope from '@clinintell/assets/images/microscope.png';
import lab1 from '@clinintell/assets/images/lab1.png';

const useStyles = makeStyles(theme => ({
  widgetImage: {
    display: 'flex',
    flexDirection: 'column',
    '& img': {
      margin: '0 auto',
      height: '110px',
      padding: '24px 40px'
    },
    '& p': {
      fontSize: '10px',
      width: '100%',
      textAlign: 'center',
      fontWeight: 400,
      lineHeight: '14px',
      letter: '2%'
    }
  },
  widgetText: {
    display: 'flex',
    flexDirection: 'row',
    height: '110px',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: '80%'
    }
  },
  widgetErrMsgTitle: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    margin: '0 auto',
    marginBottom: '.7rem'
  },
  widgetErrMsg: {
    display: 'flex',
    margin: '0 auto',
    textAlign: 'center'
  }
}));

type Props = {
  graphStatus?: string;
  graphMessage?: string;
  textOnly?: boolean;
};

const WidgetError: React.FC<Props> = ({
  graphStatus = '',
  graphMessage = 'Something went wrong',
  textOnly = false
}) => {
  const { widgetImage, widgetText, widgetErrMsgTitle, widgetErrMsg } = useStyles();
  let imgSrc;
  let imgAlt;

  switch (graphStatus.toLocaleLowerCase()) {
    case 'not applicable':
      imgSrc = microscope;
      imgAlt = 'No Eligibility';
      break;
    case 'pending':
      imgSrc = lab1;
      imgAlt = 'Strategy Confirmed';
      break;
    case 'not currently available':
      imgSrc = microscope;
      imgAlt = 'No Active Strategy';
      break;
    case 'no data':
      imgSrc = microscope;
      imgAlt = 'No Data Available';
      break;
    default:
      imgSrc = stethoscope;
      imgAlt = 'Something went wrong loading this widget';
  }

  return (
    <Box className={textOnly ? widgetText : widgetImage}>
      {textOnly ? null : <img src={imgSrc} alt={imgAlt} />}
      {textOnly ? null : (
        <Typography variant="h4" className={widgetErrMsgTitle}>
          {graphStatus ?? 'Something went wrong loading this widget'}
        </Typography>
      )}
      <Typography variant={textOnly ? 'p1' : 'p2'} className={widgetErrMsg}>
        {graphMessage}
      </Typography>
    </Box>
  );
};

export default WidgetError;
