import { useAuth0Config } from '@clinintell/modules/store';
import { Box, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { animated, useTransition } from 'react-spring';
import {
  useConfidentialityAgreementDispatch,
  useConfidentialityAgreementState
} from './ConfidentialityAgreementContext';
import ConfidentialityCircle from '@clinintell/components/icons/ConfidentialityCircle';
import Auth0Button from './Auth0Button';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'white',
    width: '28rem',
    transform: 'translate(-50%, -50%)',
    top: '50% !important',
    left: '50% !important',
    borderRadius: '4px',
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: '100vh',
      borderRadius: 0,
      '& > div:first-child': {
        flexGrow: 1
      }
    }
  },
  buttonContainer: {
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    display: 'flex',
    alignSelf: 'stretch',
    marginTop: theme.spacing(4),
    background: theme.palette.blue.main
  }
}));

const ConfidentialityAgreement: React.FC = ({ children }) => {
  const { useConfidentialityStatement, confidentialityStatement } = useAuth0Config();
  const hasAgreed = useConfidentialityAgreementState();
  const confidentialityAgreementDispatch = useConfidentialityAgreementDispatch();

  const { root, buttonContainer } = useStyles();
  const theme = useTheme();

  const transition = useTransition(!hasAgreed, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: !hasAgreed
  });

  const childrenTransition = useTransition(hasAgreed, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    delay: 500
  });

  if (!useConfidentialityStatement) {
    return <>{children}</>;
  }

  return (
    <>
      {transition(
        (styles, item) =>
          item && (
            <animated.div style={styles}>
              <Box className={root}>
                <Box display="flex" alignItems="center" flexDirection="column">
                  <Box padding={2}>
                    <ConfidentialityCircle sx={{ fontSize: '3.8rem', color: theme.palette.blue.main }} />
                  </Box>
                  <Box paddingX={2} textAlign="center" fontSize={theme.typography.p1.fontSize}>
                    {confidentialityStatement}
                  </Box>
                </Box>
                <Box className={buttonContainer}>
                  <Auth0Button text="OK" onClick={(): void => confidentialityAgreementDispatch(true)} />
                </Box>
              </Box>
            </animated.div>
          )
      )}
      {childrenTransition((styles, item) => item && <animated.div style={styles}>{children}</animated.div>)}
    </>
  );
};

export default ConfidentialityAgreement;
