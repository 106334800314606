import React, { useState, createContext, FC, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useDownloadReport from '@clinintell/containers/systemReport/logic/useDownloadReport';
import useExecutiveSummaryReport from '@clinintell/containers/systemReport/logic/useExecutiveSummaryReport';
import { EntityReportRouteProps, ReportType, TrainingProgressRouteProps } from '../../types';
import useReportSchedulerEntitySelector from './hooks/useReportSchedulerEntitySelector';

interface IReportContext {
  systemId?: number;
  hospitalId: number;
  groupId: number;
  physicianId: number;
  trainingGroupData: TrainingProgressRouteProps;
  reportDate: Date | string;
  reportBtnDisabled: boolean;
  isCreating?: boolean;
  isCreatingExecutiveReport?: boolean;
  setReportSystemId: (id: number | null) => void;
  setReportHospitalId: (id: number | null) => void;
  setReportGrouplId: (id: number | null) => void;
  setReportPhysicianlId: (id: number | null) => void;
  setReportTrainingGroupHandler: (data: TrainingProgressRouteProps) => void;
  setReportDateHandler: (date: string) => void;
  reportResetFilters: () => void;
  reportSubmitHandler: (report: ReportType, hospitalId: number, groupId: number) => void;
  setReportBtnDisabled: (value: boolean) => void;
}

const ReportsContext = createContext<IReportContext | null>(null);

export const useReportsContext = () => {
  const currentReportsContext = useContext(ReportsContext);

  if (!currentReportsContext) {
    throw new Error('useReportsContext has to be used within ReportContext Provider');
  }

  return currentReportsContext;
};

export const ReportsContextProvider: FC = ({ children }) => {
  const {
    systemData,
    hospitalData,
    groupData,
    physicianData,
    systemFilterHandler,
    hospitalFilterHandler,
    groupFilterHandler,
    physicianGroupFilterHandler
  } = useReportSchedulerEntitySelector();
  const navigation = useNavigate();
  const location = useLocation();
  const [trainingGroupData, setTrainingGroupData] = useState({ hospital: { name: '' } });
  const [reportDate, setReportDate] = useState('0');
  const [reportBtnDisabled, setReportBtnDisabled] = useState(true);
  const { isCreating, handleCreateReport } = useDownloadReport();
  const { isCreatingExecutiveReport, handleCreateExecutiveReport } = useExecutiveSummaryReport();

  const routeToReport = useCallback(
    <T,>(route: string, state: T) => {
      navigation(`${location.pathname}/${route}`, { state: { ...state } });
    },
    [location.pathname, navigation]
  );

  // useMemo for the old reportAPI was causing state issues
  const setReportSystemId = (id: number | null) => systemFilterHandler(id as number);
  const setReportHospitalId = (id: number | null) => hospitalFilterHandler(id as number);
  const setReportGrouplId = (id: number | null) => groupFilterHandler(id as number);
  const setReportPhysicianlId = (id: number | null) => physicianGroupFilterHandler(id as number);
  const setReportDateHandler = (date: string) => setReportDate(date);
  const setReportTrainingGroupHandler = (data: TrainingProgressRouteProps) => setTrainingGroupData(data);
  const reportResetFilters = () => {
    systemFilterHandler(-1);
    setReportDate('0');
    setReportBtnDisabled(true);
  };
  const reportSubmitHandler = (report: ReportType, hospitalId: number, groupId: number) => {
    switch (report) {
      case ReportType.Provider:
      case ReportType.Group:
        routeToReport<EntityReportRouteProps>(report, { hospitalId, groupId });
        break;
      case ReportType.Quarterly:
        break;
      case ReportType.System:
        handleCreateReport(reportDate);
        break;
      case ReportType.Executive:
        handleCreateExecutiveReport(
          new Date(reportDate),
          systemData.id < 0 ? undefined : systemData.id,
          hospitalId < 0 ? undefined : hospitalId,
          groupId < 0 ? undefined : groupId
        );
        break;
      case ReportType.Training:
        routeToReport<TrainingProgressRouteProps>(report, trainingGroupData);
        break;
      default:
        console.log('something went wrong - report type: ' + report);
    }
  };

  return (
    <ReportsContext.Provider
      value={{
        systemId: systemData.id,
        hospitalId: hospitalData.id,
        groupId: groupData.id,
        physicianId: physicianData.id,
        trainingGroupData,
        reportDate,
        reportBtnDisabled,
        isCreating,
        isCreatingExecutiveReport,
        setReportSystemId,
        setReportHospitalId,
        setReportGrouplId,
        setReportPhysicianlId,
        setReportDateHandler,
        setReportTrainingGroupHandler,
        reportResetFilters,
        setReportBtnDisabled,
        reportSubmitHandler
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};
