import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WrenchIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      sx={{ width: 19, height: 19, fill: 'none', ...props.sx }}
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 5.25043C7.90269 5.40621 7.98821 5.61564 7.98821 5.83376C7.98821 6.05189 7.90269 6.26132 7.75 6.4171L6.41667 7.75043C6.26089 7.90312 6.05146 7.98865 5.83333 7.98865C5.61521 7.98865 5.40577 7.90312 5.25 7.75043L2.10833 4.60876C1.6893 5.53476 1.56243 6.56646 1.74462 7.56639C1.92681 8.56631 2.40941 9.48697 3.1281 10.2057C3.8468 10.9244 4.76745 11.407 5.76738 11.5891C6.7673 11.7713 7.79901 11.6445 8.725 11.2254L14.4833 16.9838C14.8149 17.3153 15.2645 17.5015 15.7333 17.5015C16.2022 17.5015 16.6518 17.3153 16.9833 16.9838C17.3149 16.6522 17.5011 16.2026 17.5011 15.7338C17.5011 15.2649 17.3149 14.8153 16.9833 14.4838L11.225 8.72543C11.644 7.79944 11.7709 6.76774 11.5887 5.76781C11.4065 4.76788 10.9239 3.84723 10.2052 3.12853C9.48654 2.40984 8.56588 1.92724 7.56595 1.74505C6.56603 1.56286 5.53432 1.68973 4.60833 2.10876L7.74166 5.2421L7.75 5.25043Z"
        stroke="#64748B"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
WrenchIcon.muiName = SvgIcon.muiName;

export default WrenchIcon;
