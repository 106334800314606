import { useState, useEffect, useCallback } from 'react';
import { ReportType, ScheduleReportEmailObjType } from '@clinintell/containers/reports/types/actionTypes';

type ReportSchedulerReturnPropsType = {
  submitEnabled: boolean;
  setReportScheduleType: (value: React.SetStateAction<ReportType | null>) => void;
  checkGroupIdRequirement: (groupId: number) => void;
  checkReportNameRequirement: (reportName: string) => void;
  checkReportClinicalLeadRequirement: (CL: number | undefined) => void;
  checkReportEmailRequirement: (
    reportScheduleType: ReportType,
    emailFirstList: ScheduleReportEmailObjType,
    emailSecondaryList?: ScheduleReportEmailObjType
  ) => void;
  resetSchedulerValidationData: () => void;
};

const useReportSchedulerValidation = (): ReportSchedulerReturnPropsType => {
  const [reportScheduleType, setReportScheduleType] = useState<ReportType | null>(null);
  const [validGroupSelected, setValidGroupSelected] = useState<boolean>(false);
  const [validReportName, setValidReportName] = useState<boolean>(true);
  const [validClinicalLead, setValidClinicalLead] = useState<boolean>(false);
  const [validEmailLists, setValidEmailLists] = useState<boolean>(false);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);

  const checkGroupIdRequirement = useCallback((groupId: number | undefined) => {
    if (groupId && groupId >= 0) setValidGroupSelected(true);
    else setValidGroupSelected(false);
  }, []);
  const checkReportNameRequirement = useCallback((reportName: string) => {
    if (reportName.length > 0) setValidReportName(true);
    else setValidReportName(false);
  }, []);
  const checkReportClinicalLeadRequirement = useCallback((CL: number | undefined) => {
    if (CL !== undefined && CL > 0) setValidClinicalLead(true);
    else setValidClinicalLead(false);
  }, []);
  const checkReportEmailRequirement = useCallback(
    (
      reportScheduleType: ReportType,
      primaryEmailList: ScheduleReportEmailObjType,
      secondaryEmailList?: ScheduleReportEmailObjType
    ) => {
      if (ReportType.Quarterly === reportScheduleType) {
        setValidEmailLists(primaryEmailList.validList);
      }
      if (ReportType.Group === reportScheduleType && secondaryEmailList) {
        setValidEmailLists(
          primaryEmailList.validList && primaryEmailList.emails.length > 0 && secondaryEmailList.validList
        );
      }
    },
    []
  );
  const checkQuarterlyReportRequirements = useCallback(
    (validGroupSelected: boolean, validReportName: boolean, validEmailLists: boolean) => {
      if (validGroupSelected && validReportName && validEmailLists) {
        return true;
      } else return false;
    },
    []
  );
  const checkMonthlyReportRequirements = useCallback(
    (validGroupSelected: boolean, validReportName: boolean, validClinicalLead: boolean, validEmailLists: boolean) => {
      if (validGroupSelected && validReportName) {
        // Case 1: We have the CL, we've met the requirement
        if (validClinicalLead) {
          return true;
        }
        // Case 2: No CL but an admin is present
        else if (!validClinicalLead && validEmailLists) {
          return true;
        } else return false;
      } else return false;
    },
    []
  );
  const resetSchedulerValidationData = useCallback(() => {
    setValidGroupSelected(false);
    setValidReportName(true);
    setValidClinicalLead(false);
    setSubmitEnabled(false);
  }, []);

  useEffect(() => {
    if (!validGroupSelected) {
      setSubmitEnabled(false);
      return;
    }
    switch (reportScheduleType) {
      case ReportType.Quarterly:
        setSubmitEnabled(checkQuarterlyReportRequirements(validGroupSelected, validReportName, validEmailLists));
        break;
      case ReportType.Group:
        setSubmitEnabled(
          checkMonthlyReportRequirements(validGroupSelected, validReportName, validClinicalLead, validEmailLists)
        );
        break;
      default:
        setSubmitEnabled(false);
        break;
    }
  }, [
    validGroupSelected,
    validReportName,
    validClinicalLead,
    validEmailLists,
    reportScheduleType,
    checkQuarterlyReportRequirements,
    checkMonthlyReportRequirements,
    submitEnabled
  ]);

  return {
    submitEnabled,
    setReportScheduleType,
    checkGroupIdRequirement,
    checkReportNameRequirement,
    checkReportClinicalLeadRequirement,
    checkReportEmailRequirement,
    resetSchedulerValidationData
  };
};

export default useReportSchedulerValidation;
