import React, { useState, useEffect } from 'react';
import DownloadIcon from '@clinintell/components/icons/Download';
import { ApplicationAPI } from '@clinintell/utils/api';
import { useBreadcrumbNavigationState } from '@clinintell/modules/store';
import { Metrics as MetricTypes, metricLabels } from '@clinintell/modules/metricsNavigation';
import { QueryStringParam, createUrlWithQueryString } from '@clinintell/utils/querystring';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import { useMetricsNavigation } from '@clinintell/modules/store';
import { getMetricTableColumns } from '@clinintell/containers/metrics/typings/tableSchemas';
import { capitalizeWord } from '@clinintell/utils/formatting';
import { ReorderList } from '@clinintell/utils/resources';
import { ColumnDataType } from '@clinintell/components/StyledTableCell';
import { useTargetedConditionsState } from '../logic/TargetedConditionsContext';
import Button from '@clinintell/components/button/Button';
import { ChartDataSetType } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import Tooltip from '@clinintell/components/tooltip/Tooltip';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createBlob = (buffer: any, filename: string): void => {
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
    return;
  }

  const link = document.createElement('a');
  link.download = filename;
  link.href = URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

interface Props {
  style?: React.CSSProperties;
  mdcIds?: number[];
}

const DownloadReport: React.FC<Props> = ({ style, mdcIds }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    hccSessionWt: hccShare,
    exmSessionWt: mortalityShare,
    exrSessionWt: readmissionShare,
    psiSessionWt: psiShare
  } = useTargetedConditionsState();

  const { breadcrumbs } = useBreadcrumbNavigationState();

  const {
    entity,
    view,
    metric,
    previousMetric,
    dataTrend,
    periodStart,
    periodEnd,
    comparisonPeriodStart,
    comparisonPeriodEnd,
    display,
    timeSeriesAveragePeriodType,
    timeSeriesPeriodStart,
    timeSeriesPeriodEnd,
    condition,
    conditionName,
    sortColumn,
    sortDesc,
    valueType,
    useTargetConditions,
    graphDataPoints
  } = useMetricsNavigation();

  useEffect(() => {
    if (!isDownloading) {
      return;
    }

    let srcMetricList = [];
    for (const key in MetricTypes) {
      srcMetricList.push(key);
    }
    srcMetricList = ReorderList(srcMetricList, srcMetricList.indexOf(metric), 0);
    const displayPart = display === 'table' || display === 'comparison' ? 'metrics' : 'graph';
    const metricPart =
      metric === 'condition' || metric === 'allConditions' || metric === 'targetedConditions' ? 'conditions' : metric;
    let endpoint = `download/${displayPart}/${metricPart}/${entity}`;
    if (displayPart === 'graph' && metricPart !== 'conditions') {
      endpoint = `download/${displayPart}/${entity}`;
    }
    const minDate = display === 'table' || display === 'comparison' ? periodStart : timeSeriesPeriodStart;
    const maxDate = display === 'table' || display === 'comparison' ? periodEnd : timeSeriesPeriodEnd;

    const queryStringValues: QueryStringParam[] = [
      {
        key: display === 'table' || display === 'comparison' ? 'currentStart' : 'startDate',
        value: formatDateForAPI(minDate)
      },
      {
        key: display === 'table' || display === 'comparison' ? 'currentEnd' : 'endDate',
        value: formatDateForAPI(maxDate)
      }
    ];

    if (display === 'table' || display === 'comparison') {
      const { columns, defaultSort } = getMetricTableColumns(metric, view, dataTrend === 'otoce');
      const newValueType = valueType === 'rwpv' ? 'RW' : 'Dollar';
      const oldValueType = valueType === 'rwpv' ? 'Dollar' : 'RW';
      const updatedColumns = columns.map(column => {
        if (!column.endsWith(oldValueType)) {
          return column;
        }

        return (`${column.split(oldValueType)[0]}${newValueType}` as unknown) as ColumnDataType;
      });
      let column = sortColumn ? sortColumn : defaultSort;
      if (column.toLowerCase() === 'selectall') {
        column = defaultSort;
      }
      if (column.endsWith('RW') || column.endsWith('Dollar')) {
        column = column.replace(oldValueType, newValueType);
      }
      const desc = sortDesc;
      const columnList = updatedColumns
        .filter(word => word !== 'action')
        .map(word => {
          return capitalizeWord(word);
        })
        .join(',');

      queryStringValues.push(
        {
          key: 'historicalStart',
          value: formatDateForAPI(comparisonPeriodStart)
        },
        {
          key: 'historicalEnd',
          value: formatDateForAPI(comparisonPeriodEnd)
        },
        {
          key: 'viewname',
          value: view
        },
        {
          key: 'csvcolumns',
          value: columnList
        },
        {
          key: 'sortColumn',
          value: capitalizeWord(column)
        },
        {
          key: 'sortdescending',
          value: String(desc)
        }
      );
    } else {
      queryStringValues.push({
        key: 'monthrange',
        value: timeSeriesAveragePeriodType
      });
      if (displayPart === 'graph' && metricPart !== 'conditions') {
        const metricViewList: Set<string> = new Set();
        Object.keys(graphDataPoints).forEach(key =>
          graphDataPoints[key].datapoints.forEach((dp: ChartDataSetType) =>
            dp === 'COVID' ? metricViewList.add(`${key}${dp}`) : metricViewList.add(dp)
          )
        );
        // Removing 'Actual' from download since it's only clickable in LOS
        if (!graphDataPoints['los'].datapoints.includes('Actual') && metricViewList.has('Actual')) {
          metricViewList.delete('Actual');
        }

        queryStringValues.push(
          {
            key: 'CSVViewList',
            value: srcMetricList.join(',')
          },
          {
            key: 'metricviewlist',
            value: [...metricViewList].join(',')
          }
        );
      }
    }

    if (metric !== 'condition' && metric !== 'allConditions' && metric !== 'targetedConditions') {
      endpoint = createUrlWithQueryString(endpoint, queryStringValues);
    } else {
      if (metric === 'condition') {
        queryStringValues.push({
          key: 'conditionId',
          value: condition ? condition.toString() : '0'
        });
        queryStringValues.push({
          key: 'shareDrg',
          value: '5'
        });
        const isTargetedConditions = previousMetric === 'targetedConditions';
        if (hccShare !== undefined) {
          queryStringValues.push({
            key: 'shareHcc',
            value: isTargetedConditions ? '5' : hccShare.toString()
          });
        }
        if (mortalityShare !== undefined) {
          queryStringValues.push({
            key: 'shareMortality',
            value: isTargetedConditions ? '5' : mortalityShare.toString()
          });
        }
        if (readmissionShare !== undefined) {
          queryStringValues.push({
            key: 'shareReadmission',
            value: isTargetedConditions ? '5' : readmissionShare.toString()
          });
        }
        if (psiShare !== undefined) {
          queryStringValues.push({
            key: 'sharePSI',
            value: isTargetedConditions ? '5' : psiShare.toString()
          });
        }
      } else if (metric === 'allConditions' || metric === 'targetedConditions') {
        if (condition) {
          queryStringValues.push({
            key: 'conditionId',
            value: condition ? condition.toString() : '0'
          });
        }
        queryStringValues.push({
          key: 'includeAllConditions',
          value: metric === 'targetedConditions' ? '0' : '1'
        });
        queryStringValues.push({
          key: 'useSavedTargetConds',
          value: useTargetConditions ? '1' : '0'
        });
        queryStringValues.push({
          key: 'shareDrg',
          value: '5'
        });
        const isTargetedConditions = previousMetric === 'targetedConditions';
        if (hccShare !== undefined) {
          queryStringValues.push({
            key: 'shareHcc',
            value: isTargetedConditions ? '5' : hccShare.toString()
          });
        }
        if (mortalityShare !== undefined) {
          queryStringValues.push({
            key: 'shareMortality',
            value: isTargetedConditions ? '5' : mortalityShare.toString()
          });
        }
        if (readmissionShare !== undefined) {
          queryStringValues.push({
            key: 'shareReadmission',
            value: isTargetedConditions ? '5' : readmissionShare.toString()
          });
        }
        if (psiShare !== undefined) {
          queryStringValues.push({
            key: 'sharePSI',
            value: isTargetedConditions ? '5' : psiShare.toString()
          });
        }
        if (mdcIds && mdcIds.length) {
          queryStringValues.push({
            key: 'mdcIds',
            value: mdcIds.join(',')
          });
        }
      } else if (metric === 'targetedConditions') {
        queryStringValues.push({
          key: 'includeAllConditions',
          value: '0'
        });
      }
      // Only add the metric view list when in coded rate
      if (dataTrend === 'codedrate') {
        const metricViewList: Set<string> = new Set();
        Object.keys(graphDataPoints).forEach(key =>
          graphDataPoints[key].datapoints.forEach((dp: ChartDataSetType) =>
            dp === 'COVID' ? metricViewList.add(`${key}${dp}`) : metricViewList.add(dp)
          )
        );

        queryStringValues.push({
          key: 'metricviewlist',
          value: [...metricViewList].join(',')
        });
      }

      if (dataTrend === 'otoce') {
        queryStringValues.push({
          key: 'IsOtoCE',
          value: 'true'
        });
      }

      endpoint = createUrlWithQueryString(endpoint, queryStringValues);
    }

    ApplicationAPI.download(endpoint, 'xlsx')
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          throw new Error(response.error);
        }
      })
      .then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const currentBreadcrumbEntity = breadcrumbs[breadcrumbs.length - 1].label;
        const metricLabel = () => {
          if (display === 'graph') return '';
          else
            return metric !== 'condition'
              ? metricLabels[metric]
              : capitalizeWord(conditionName ? conditionName : 'Condition');
        };
        let filename = `${currentBreadcrumbEntity}_${metricLabel()}${
          display === 'table' ? '_Table' : display === 'graph' ? 'Performance Graph' : ''
        }.xlsx`;
        if (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions') {
          const currentEntity =
            display === 'comparison' || display === 'graph'
              ? currentBreadcrumbEntity.replace(` - ${conditionName}`, '')
              : currentBreadcrumbEntity;
          filename = `${currentEntity}_${capitalizeWord(conditionName ? conditionName : metricLabels[metric])}_${
            dataTrend === 'otoce' ? 'OCE' : 'Coded Rate'
          }_${display === 'graph' ? 'Graph' : 'Table'}.xlsx`;
        }
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
          return;
        }
        const link = document.createElement('a');
        link.download = filename;
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        throw new Error(`Error with the download request: ${error}`);
        // Update global error boundary state to display error boundary
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, [
    comparisonPeriodEnd,
    comparisonPeriodStart,
    condition,
    conditionName,
    display,
    entity,
    isDownloading,
    metric,
    previousMetric,
    dataTrend,
    periodEnd,
    periodStart,
    sortColumn,
    sortDesc,
    timeSeriesAveragePeriodType,
    timeSeriesPeriodEnd,
    timeSeriesPeriodStart,
    view,
    valueType,
    hccShare,
    mortalityShare,
    readmissionShare,
    psiShare,
    useTargetConditions,
    mdcIds,
    graphDataPoints,
    breadcrumbs
  ]);

  return (
    <Tooltip content="Download Excel">
      <div>
        <Button
          variant="outlined"
          label="Download"
          onClick={(): void => setIsDownloading(true)}
          isBusy={isDownloading}
          leftAdornment={<DownloadIcon sx={theme => ({ width: 36, height: 36, color: theme.palette.primary.main })} />}
        />
      </div>
    </Tooltip>
  );
};

export default DownloadReport;
