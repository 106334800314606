import React from 'react';
import Tooltip from './Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, SxProps, Theme, TooltipProps as MuiTooltipProps, useTheme } from '@mui/material';

type Props = {
  content: string | JSX.Element;
  position?: MuiTooltipProps['placement'];
  sx?: SxProps<Theme>;
};

// If inside of a Typography element, update the Typography sx/style
// with display: 'flex' and alignItems: 'center'
const TooltipHelp: React.FC<Props> = ({ content, position, sx }) => {
  const theme = useTheme();
  return (
    <Tooltip content={content} position={position}>
      <Box component="span" ml={1} height={22} sx={{ cursor: 'pointer' }}>
        <HelpOutlineIcon component="svg" sx={{ color: theme.palette.grey[500], ...sx }} />
      </Box>
    </Tooltip>
  );
};

export default TooltipHelp;
