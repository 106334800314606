export interface AppConfig {
  environment: string;
  loginAPI: string;
  applicationAPI: string;
  isBDTenant: boolean;
}

// Actions
enum AppConfigActions {
  SET_CONFIG = 'SET_CONFIG'
}

export interface AppConfigAction {
  type?: keyof typeof AppConfigActions;
}

export interface AppConfigActionWithPayload extends AppConfigAction {
  payload?: AppConfig;
}

export const defaultAppState = {
  environment: '',
  loginAPI: '',
  applicationAPI: '',
  isBDTenant: false
};

// Reducer
const reducer = (state: AppConfig = defaultAppState, action: AppConfigActionWithPayload): AppConfig => {
  switch (action.type) {
    case 'SET_CONFIG': {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
