import { Box, Typography } from '@mui/material';
import React from 'react';
import { ChartDataSetAverageTypes } from '../typings/metricChartTypes';
import { GraphData, MetricData } from '@clinintell/api/apiSchemas';
import { MetricsTimeSeriesRecord } from '../logic/MetricsTimeSeriesDataContext';

const NoDataMessage: React.FC = () => {
  return (
    <Box fontStyle="italic" px={2} py={2}>
      <Typography variant="p2">Note: no data available based on the time period selected.</Typography>
    </Box>
  );
};

type CheckForDataProps = {
  timeSeriesDataset: MetricsTimeSeriesRecord;
  timeSeriesAverageType: keyof typeof ChartDataSetAverageTypes;
};

const CheckForData: React.FC<CheckForDataProps> = ({ children, timeSeriesDataset, timeSeriesAverageType }) => {
  if (!timeSeriesDataset.dataset) {
    return <NoDataMessage />;
  }

  // Deep search for records in the time series dataset, if all are null return the no data component
  // ANNOTATION: remove null/undefined as value types for GraphData and MetricData properties
  const chartData = timeSeriesDataset.dataset.data;
  const actualData = (chartData as GraphData[]).find(
    record => record.dataSetType === 'Actual' && record.dataSetAverageType === timeSeriesAverageType
  );

  if (!actualData) {
    return <NoDataMessage />;
  }

  const firstValue = (actualData.dataSet as MetricData[]).find(data => data.value !== null);
  if (firstValue) {
    return <>{children}</>;
  }

  return <NoDataMessage />;
};

export default CheckForData;
