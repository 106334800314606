import React from 'react';

import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export interface FootnotesProps {
  footnotes?: string[];
}

const useStyles = makeStyles(theme => ({
  fineprint: {
    '& ul': {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
      '& li': {
        lineHeight: '0.8rem',
        paddingLeft: theme.spacing(2)
      }
    },
    padding: theme.spacing(1),
    fontStyle: 'italic'
  }
}));

const Footnotes: React.FC<FootnotesProps> = ({ footnotes }) => {
  const classes = useStyles();

  if (!footnotes || footnotes.length === 0) {
    return <Box />;
  }

  return (
    <Box className={classes.fineprint}>
      <ul>
        {footnotes.map((v, i) => {
          return (
            <li key={i}>
              <Typography variant="caption">{v}</Typography>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

export default Footnotes;
