import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ResetButton = (props: SvgIconProps): JSX.Element => (
  <SvgIcon style={props.style} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 5V1L7 6L12 11V7C15.31 7 18 9.69 18 13C18 16.31 15.31 19 12 19C8.69 19 6 16.31 6 13H4C4 17.42 7.58 21 12 21C16.42 21 20 17.42 20 13C20 8.58 16.42 5 12 5Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default ResetButton;
