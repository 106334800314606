import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import { SevCMIGroupShare } from '@clinintell/containers/cmiAnalysis/types/SevCMIGroup';
import { Table } from '../table_v2';

type SeverityImpactTableProps = {
  tableRows: SevCMIGroupShare[];
  sortDirection: 'asc' | 'desc';
};

const SeverityImpactTable: React.FC<SeverityImpactTableProps> = ({ tableRows, sortDirection }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const totalCmiImpact = tableRows.reduce((value, obj) => {
    return (value += Number(formatToDecimalPlaces(obj.cmiImpact, 3)));
  }, 0);

  const footerRow: SevCMIGroupShare = {
    conditionDescription: '',
    // properties to format footer
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    deltaOtoCE: undefined,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    deltaCodedRate: undefined,
    cmiImpact: totalCmiImpact
  };

  return (
    <Table<SevCMIGroupShare>
      rowData={tableRows}
      footerData={footerRow}
      renderColumns={({ renderColumn }) => {
        return [
          renderColumn('conditionDescription', {
            headerName: 'Clinical Condition',
            minWidth: 450,
            cellRenderer: props => props.value || '',
            headerAlignment: 'left',
            sortable: true
          }),
          !isMobileView &&
            renderColumn('deltaOtoCE', {
              cellRenderer: props => {
                if (props.value === undefined) {
                  return '';
                }
                if (props.value === null) {
                  return '-';
                }
                return `${Number(formatToDecimalPlaces(props.value, 2)).toFixed(0)}%`;
              },
              headerName: 'O/CE Change',
              cellStyle: {
                justifyContent: 'center'
              },
              sortable: true
            }),
          !isMobileView &&
            renderColumn('deltaCodedRate', {
              cellRenderer: props =>
                props.value ? `${Number(formatToDecimalPlaces(props.value, 2)).toFixed(0)}%` : '',
              headerName: 'Coded Rate Change',
              cellStyle: {
                justifyContent: 'center'
              },
              sortable: true
            }),
          renderColumn('cmiImpact', {
            cellRenderer: props => `${formatToDecimalPlaces(props.value || 0, 3)}`,
            headerName: 'CMI Impact',
            sort: sortDirection,
            sortable: true,
            cellStyle: {
              justifyContent: 'center'
            }
          })
        ];
      }}
    />
  );
};

export default SeverityImpactTable;
