import React, { useCallback } from 'react';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { MetricRecordJSON, MetricConditionRecordJSON } from '@clinintell/containers/metrics/typings/metricTypes';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NoDataMessage } from '@clinintell/containers/dashboard/Dashboard';
import { Metrics as MetricTypes } from '@clinintell/modules/metricsNavigation';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { QueryStringParam, createUrlWithQueryString } from '@clinintell/utils/querystring';
import { QueryParams, TableInfo } from '@clinintell/containers/dashboard/widgetTypes';
import clsx from 'clsx';
import { IntegerCell, IsStatSignificantCell, Table } from '@clinintell/components/table_v2';
import { getColumnHeader } from '@clinintell/containers/metrics/logic/metricsTableUtils';
import useMetricTooltips from '@clinintell/containers/metrics/logic/useMetricTooltips';
import DRG from '@clinintell/assets/images/DRG.svg';
import HCC from '@clinintell/assets/images/HCC.svg';
import Ex from '@clinintell/assets/images/Ex.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '../tooltip/Tooltip';
import { getDefaultViewForMetric } from '@clinintell/containers/metrics/typings/tableSchemas';
import { parseDateIntoPeriod } from '@clinintell/utils/formatting';

const useStyles = makeStyles(theme => ({
  table: {
    [theme.breakpoints.down(1425)]: {
      '& .ag-theme-alpine .ag-root-wrapper .ag-root-wrapper-body .ag-layout-normal': {
        overflow: 'hidden'
      }
    }
  },
  training: {
    '& table td:not(:last-child)': {
      color: `${theme.palette.blue.main} !important`,
      cursor: 'pointer !important'
    },
    '& table td.notApproved': {
      color: `${theme.palette.common.black} !important`,
      cursor: 'default !important'
    }
  }
}));

type WidgetProps = {
  name: string;
  hashId: string;
  endpoint: string;
  metric?: MetricTypes;
  queryString?: QueryParams;
  tableInfo: TableInfo;
};

const TableWidget: React.FC<WidgetProps> = props => {
  if (
    (props.queryString && props.queryString.includeAllConditions && props.queryString.includeAllConditions.length) ||
    props.metric === 'allConditions' ||
    props.metric === 'targetedConditions' ||
    props.metric === 'condition'
  ) {
    return <TableConditionWidget {...props} />;
  }
  return <TableMetricWidget {...props} />;
};

const TableMetricWidget: React.FC<WidgetProps> = ({ name, hashId, endpoint, metric, queryString, tableInfo }) => {
  const { table } = useStyles();
  const queryStringParams: QueryStringParam[] = [];

  const { getTooltip } = useMetricTooltips();

  if (queryString) {
    for (const [key, value] of Object.entries(queryString)) {
      if (value !== null && value !== undefined) {
        queryStringParams.push({ key, value });
      }
    }
  }

  const metricsEndpoint = createUrlWithQueryString(endpoint, queryStringParams);

  const { output: metricOutput, isLoading: metricsAreLoading } = useGetAPICAll<MetricRecordJSON[]>({
    endpoint: metricsEndpoint,
    isWaiting: false,
    duplicateRequestHandler: 'takeEvery'
  });

  if (metricOutput && metricOutput.data && metricOutput.data.length === 0) {
    return <NoDataMessage />;
  }

  const isTableDataMissing =
    metricOutput &&
    metricOutput.data &&
    metricOutput.data.filter(data => data.name === null && data.orgId === 0).length > 0;

  return (
    <>
      {isTableDataMissing ? (
        <NoDataMessage />
      ) : (
        <Box
          key={`widgetTable-${hashId}`}
          className={table}
          maxHeight={380}
          minHeight={150}
          style={{ overflowY: 'auto' }}
        >
          {metricOutput && metricOutput.data && !metricsAreLoading ? (
            <Table<MetricRecordJSON>
              rowData={metricOutput.data.filter(row => !row.isFooter)}
              footerData={metricOutput.data.find(row => row.isFooter)}
              renderColumns={({ renderColumn }) => {
                return [
                  renderColumn('name', {
                    cellRenderer: props => props.value || '',
                    minWidth: 300,
                    sortable: true,
                    suppressMenu: false,
                    headerAlignment: 'left'
                  }),
                  renderColumn('current', {
                    headerName: getColumnHeader({
                      column: 'current',
                      nodeType: 'Specialty Group',
                      periodStart:
                        queryString && queryString['currentStart'] ? queryString['currentStart'].toString() : '',
                      periodEnd: queryString && queryString['currentEnd'] ? queryString['currentEnd'].toString() : '',
                      comparisonPeriodEnd:
                        queryString && queryString['historicalStart'] ? queryString['historicalStart'].toString() : '',
                      comparisonPeriodStart:
                        queryString && queryString['historicalEnd'] ? queryString['historicalEnd'].toString() : '',
                      metric: 'docScore'
                    }),
                    cellRenderer: props =>
                      props.value !== null && props.value !== undefined ? (
                        <IntegerCell value={props.value} postfix="%" />
                      ) : (
                        '-'
                      ),
                    sortable: true,
                    cellStyle: {
                      justifyContent: 'center'
                    }
                  }),
                  renderColumn('clinicallyExpected', {
                    cellRenderer: props =>
                      props.value !== null && props.value !== undefined ? (
                        <IntegerCell value={props.value} postfix="%" />
                      ) : (
                        '-'
                      ),
                    sortable: true,
                    cellStyle: {
                      justifyContent: 'center'
                    }
                  }),
                  renderColumn('gapVsCe', {
                    headerName: 'Gap',
                    headerTooltip: getTooltip('gapVsCe', 'docScore', 'gap'),
                    cellRenderer: props => {
                      if (props.value === null || props.value === undefined || !props.data) {
                        return '-';
                      }

                      return (
                        <IsStatSignificantCell
                          value={props.value}
                          errorMargin={props.data.gapVsCeInErrorRange}
                          isPercentageBased
                          captureOverdocumentation
                        />
                      );
                    },
                    sortable: true,
                    cellStyle: {
                      justifyContent: 'center'
                    }
                  }),
                  renderColumn('cases', {
                    cellRenderer: props =>
                      props.value !== null && props.value !== undefined ? <IntegerCell value={props.value} /> : '-',
                    sortable: true,
                    cellStyle: {
                      justifyContent: 'center'
                    }
                  })
                ];
              }}
            />
          ) : (
            <ClinIntellSkeleton variant="rectangular" width="100%" height="20rem" />
          )}
        </Box>
      )}
    </>
  );
};

const TableConditionWidget: React.FC<WidgetProps> = ({ hashId, name, endpoint, metric, queryString, tableInfo }) => {
  const { training, table } = useStyles();
  // const { defaultPeriodEnd, defaultPeriodStart } = useMetricsNavigation();
  const { tableType } = tableInfo;
  const queryStringParams: QueryStringParam[] = [];

  const { getTooltip } = useMetricTooltips();

  if (queryString) {
    for (const [key, value] of Object.entries(queryString)) {
      if (value !== null && value !== undefined) {
        queryStringParams.push({ key, value });
      }
    }
  }

  const metricsEndpoint = createUrlWithQueryString(endpoint, queryStringParams);

  const { output: metricOutput, isLoading: metricsAreLoading } = useGetAPICAll<MetricConditionRecordJSON>({
    endpoint: metricsEndpoint,
    isWaiting: false,
    duplicateRequestHandler: 'takeEvery'
  });

  const handleNameClick = useCallback((entity: number, condition?: number): void => {
    window.location.href = `training/${entity}/condition/${condition}?referrer=dashboard`;
  }, []);

  const getCurrentHeaderText = (): string => {
    const currentStart = parseDateIntoPeriod(new Date(String(queryString?.currentStart)));
    const currentEnd = parseDateIntoPeriod(new Date(String(queryString?.currentEnd)));

    return `${currentStart}-${currentEnd}`;
  };

  if (
    (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions') &&
    !tableInfo.columns.some(col => col === 'conditionTypeId') &&
    !queryString?.conditionId
  ) {
    tableInfo.columns = [...tableInfo.columns.slice(0, 1), 'conditionTypeId', ...tableInfo.columns.slice(1)];
  }

  if (metricOutput && metricOutput.data && metricOutput.data.metrics.length === 0) {
    return <NoDataMessage />;
  }

  const isTableDataMissing =
    metricOutput &&
    metricOutput.data &&
    metricOutput.data.metrics.filter(data => data.name === null && data.orgId === 0).length > 0;

  const footerData = metricOutput?.data?.metrics.find(d => d.isFooter)
    ? metricOutput.data.metrics.filter(d => d.isFooter)[0]
    : undefined;

  return (
    <>
      {isTableDataMissing ? (
        <NoDataMessage />
      ) : (
        <Box
          key={`widget-${name}`}
          className={tableType && tableType === 'training' ? clsx(training, table) : table}
          maxHeight={380}
          style={{ overflowY: 'auto' }}
        >
          {metricOutput && metricOutput.data && !metricsAreLoading ? (
            <Table<MetricRecordJSON>
              tableHeight={350}
              rowData={metricOutput.data.metrics.filter(d => !d.isFooter)}
              footerData={footerData}
              renderColumns={({ renderColumn }) => {
                const isAllConditionPerformance = name === 'allConditionPerformanceTable';
                const isTop10UnderDocumented = name === 'top10UnderDocumentedClinicalConditionTable';

                const currentHeaderText = getCurrentHeaderText();

                if (isAllConditionPerformance) {
                  return [
                    renderColumn('name', {
                      headerName: 'Physician Name',
                      cellRenderer: props => {
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            {props.value}
                          </Box>
                        );
                      },
                      suppressMenu: false,
                      sortable: true,
                      headerAlignment: 'left',
                      minWidth: 350
                    }),
                    renderColumn('current', {
                      headerName: currentHeaderText,
                      cellRenderer: props =>
                        props.value !== null && props.value !== undefined ? (
                          <IntegerCell value={props.value} postfix="%" />
                        ) : (
                          '-'
                        ),
                      sortable: true,
                      cellStyle: {
                        justifyContent: 'center'
                      }
                    }),
                    renderColumn('clinicallyExpected', {
                      headerName: 'Clinically Expected',
                      cellRenderer: props =>
                        props.value !== null && props.value !== undefined ? (
                          <IntegerCell value={props.value} postfix="%" />
                        ) : (
                          '-'
                        ),
                      sortable: true,
                      cellStyle: {
                        justifyContent: 'center'
                      }
                    }),
                    renderColumn('gapVsCe', {
                      headerName: 'Gap',
                      cellRenderer: props => {
                        if (props.value === null || props.value === undefined || !props.data) {
                          return '-';
                        }

                        return (
                          <IsStatSignificantCell
                            value={props.value}
                            errorMargin={props.data.gapVsCeInErrorRange}
                            isPercentageBased
                            captureOverdocumentation
                          />
                        );
                      },
                      sortable: true,
                      sort: 'desc',
                      headerTooltip: getTooltip('gapVsCe', 'allConditions', getDefaultViewForMetric('allConditions'))
                    })
                  ];
                }
                if (isTop10UnderDocumented) {
                  return [
                    renderColumn('name', {
                      headerName: 'Clinical Condition',
                      cellRenderer: props => {
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            {props.value}
                          </Box>
                        );
                      },
                      suppressMenu: false,
                      sortable: true,
                      headerAlignment: 'left',
                      minWidth: 350
                    }),
                    renderColumn('conditionTypeId', {
                      cellRenderer: props => {
                        return (
                          <Box style={{ width: '100%', marginTop: 2 }}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                              <img
                                src={props.value === 1 ? DRG : props.value === 2 ? HCC : Ex}
                                alt={props.value === 1 ? 'DRG' : props.value === 2 ? 'HCC' : 'Ex'}
                              />
                            </Box>
                          </Box>
                        );
                      },
                      headerName: 'Type',
                      sortable: true,
                      width: 100,
                      filterValuesRenderer: data => {
                        switch (data.conditionTypeId) {
                          case 1:
                            return 'DRG';
                          case 2:
                            return 'HCC';
                          case 3:
                            return 'EX';
                          default:
                            return '';
                        }
                      }
                    }),
                    renderColumn('current', {
                      headerName: currentHeaderText,
                      cellRenderer: props =>
                        props.value !== null && props.value !== undefined ? (
                          <IntegerCell value={props.value} postfix="%" />
                        ) : (
                          '-'
                        ),
                      sortable: true,
                      cellStyle: {
                        justifyContent: 'center'
                      }
                    }),
                    renderColumn('clinicallyExpected', {
                      headerName: 'Clinically Expected',
                      cellRenderer: props =>
                        props.value !== null && props.value !== undefined ? (
                          <IntegerCell value={props.value} postfix="%" />
                        ) : (
                          '-'
                        ),
                      sortable: true,
                      cellStyle: {
                        justifyContent: 'center'
                      }
                    }),
                    renderColumn('gapVsCe', {
                      headerName: 'Gap',
                      cellRenderer: props => {
                        if (props.value === null || props.value === undefined || !props.data) {
                          return '-';
                        }

                        return (
                          <IsStatSignificantCell
                            value={props.value}
                            errorMargin={props.data.gapVsCeInErrorRange}
                            isPercentageBased
                            captureOverdocumentation
                          />
                        );
                      },
                      sortable: true,
                      sort: 'desc',
                      headerTooltip: getTooltip('gapVsCe', 'allConditions', getDefaultViewForMetric('allConditions'))
                    })
                  ];
                }
                return [
                  renderColumn('name', {
                    headerName: 'Clinical Condition',
                    cellRenderer: props => {
                      if (!props.data || !props.value) {
                        return '';
                      }

                      if (props.data.hasApprovedContent) {
                        return (
                          <Box
                            onClick={() => handleNameClick(Number(props.data?.orgId), props.data?.conditionId)}
                            sx={{ color: 'primary.main', cursor: 'pointer' }}
                          >
                            {props.value}
                          </Box>
                        );
                      }

                      return (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          {props.value}
                          <Tooltip content="Clinical content currently unavailable">
                            <HelpOutlineIcon sx={{ ml: 1, fontSize: '1.25rem' }} />
                          </Tooltip>
                        </Box>
                      );
                    },
                    sortable: true,
                    headerAlignment: 'left',
                    minWidth: 350
                  }),
                  renderColumn('conditionTypeId', {
                    cellRenderer: props => {
                      return (
                        <Box style={{ width: '100%', marginTop: 9 }}>
                          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <img
                              src={props.value === 1 ? DRG : props.value === 2 ? HCC : Ex}
                              alt={props.value === 1 ? 'DRG' : props.value === 2 ? 'HCC' : 'Ex'}
                            />
                          </Box>
                        </Box>
                      );
                    },
                    headerName: 'Type',
                    suppressMenu: false,
                    width: 100,
                    sortable: true,
                    filterValuesRenderer: data => {
                      switch (data.conditionTypeId) {
                        case 1:
                          return 'DRG';
                        case 2:
                          return 'HCC';
                        case 3:
                          return 'EX';
                        default:
                          return '';
                      }
                    }
                  }),
                  renderColumn('gapVsCe', {
                    headerName: 'Gap',
                    cellRenderer: props => {
                      if (props.value === null || props.value === undefined || !props.data) {
                        return '-';
                      }

                      return (
                        <IsStatSignificantCell
                          value={props.value}
                          errorMargin={props.data.gapVsCeInErrorRange}
                          isPercentageBased
                          captureOverdocumentation
                        />
                      );
                    },
                    sortable: true,
                    sort: 'desc',
                    headerTooltip: getTooltip('gapVsCe', 'allConditions', getDefaultViewForMetric('allConditions'))
                  })
                ];
              }}
            />
          ) : (
            <ClinIntellSkeleton variant="rectangular" width="100%" height="20rem" />
          )}
        </Box>
      )}
    </>
  );
};

export default TableWidget;
