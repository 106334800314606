/* This is the new dashboard redux store */

import { all, call, put, spawn, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';
import { BestFitDates } from '@clinintell/containers/cmiAnalysis/types/BestFitDates';
import { CMIMetricRecordJSON, DefaultDatesJSON } from '@clinintell/containers/metrics/typings/metricTypes';
import { PatientMixAnalysisJSON } from '@clinintell/containers/cmiAnalysis/types/DRGGroup';
import { SeverityCMIAnalysisJSON } from '@clinintell/containers/cmiAnalysis/types/SevCMIGroup';
import { QueryStringParam, createUrlWithQueryString } from '@clinintell/utils/querystring';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import {
  ChartDataSetAverageTypes,
  ChartDataSetJSON,
  ChartDataSet
} from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { MetricRecordJSON } from '@clinintell/containers/metrics/typings/metricTypes';
import { HospitalsWithSystem } from '@clinintell/types/common';

export type WidgetSchema = {
  message: string | null;
  numBelow: number;
  numMeeting: number;
  numNearing: number;
  orgId: number;
  percentBelow: number;
  percentMeeting: number;
  percentNearing: number;
  status: string;
};

export type TargetedConditionsTableType = {
  historicalStart: string;
  historicalEnd: string;
  currentStart: string;
  currentEnd: string;
  isActiveStrategy: boolean;
  table: {
    conditionId: number;
    conditionDescription: string;
    historicalOtoCE: number;
    currentOtoCE: number;
    status: string;
  }[];
};

interface Dataset {
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
}

export interface DashboardDefaultDates extends Dataset {
  historicStart: string;
  historicEnd: string;
  currentStart: string;
  currentEnd: string;
}

export interface DashboardWidget extends Dataset {
  data: unknown;
  modalRecordId: number;
  modalRecordName: string;
  modalGraphData: unknown;
  modalTableData: unknown;
  isModalLoading: boolean;
  isModalGraphLoading: boolean;
}

export type DocScoreGoalItems = {
  hospitalName: string;
  physicianGroupName: string;
  providerName: string | null;
  docScore: number;
  docScoreGoal: number;
  implementationDate: string;
  statusId: number;
  status: string;
};

export interface SmartGoalsModalData {
  orgId: number;
  orgName: string;
  docScoreGoalItems: DocScoreGoalItems[];
}

export type CMIAnalysisWidgetData = {
  bestFitDates: BestFitDates;
  cmiAnalysis: CMIMetricRecordJSON[];
  drgMix: PatientMixAnalysisJSON;
  severityCMI: CMIMetricRecordJSON[];
  severityValue: SeverityCMIAnalysisJSON;
};

export type DrgConditionOpportunityData = {
  name: string;
  opportunityRW: number;
  conditionId: number;
};

export interface Dashboard2Dataset extends Dataset {
  isDashboard2Initialized: boolean;
  entity?: HospitalsWithSystem;
  groupId?: number;
  groupName: string;
  previousEntity?: HospitalsWithSystem;
  previousGroupId?: number;
  dashboardDefaultDates?: DashboardDefaultDates;
  hospitalsWithSystem: HospitalsWithSystem[];
  isHospitalsWithSystemInitialized: boolean;
  isHospitalsWithSystemLoading: boolean;
  smartGoalsWidget: DashboardWidget;
  targetedConditionsWidget: DashboardWidget;
  cmiDocScoreWidget: DashboardWidget;
  ahrqMortalityRAWidget: DashboardWidget;
  cmiAnalysisWidget: DashboardWidget;
  drgConditionsByOpportunityWidget: DashboardWidget;
  severityOpportunityWidget: DashboardWidget;
}

export enum Dashboard2Actions {
  INITIALIZE_DASHBOARD2 = 'INITIALIZE_DASHBOARD2',
  INITIALIZE_DASHBOARD2_BEGIN = 'INITIALIZE_DASHBOARD2_BEGIN',
  INITIALIZE_DASHBOARD2_FAILED = 'INITIALIZE_DASHBOARD2_FAILED',
  INITIALIZE_DASHBOARD2_SUCCESS = 'INITIALIZE_DASHBOARD2_SUCCESS',
  GET_DASHBOARD2_DATA = 'GET_DASHBOARD2_DATA',
  GET_DASHBOARD2_DATA_BEGIN = 'GET_DASHBOARD2_DATA_BEGIN',
  GET_DASHBOARD2_DATA_FAILED = 'GET_DASHBOARD2_DATA_FAILED',
  GET_DASHBOARD2_DATA_SUCCESS = 'GET_DASHBOARD2_DATA_SUCCESS',
  SET_DASHBOARD2_ORG = 'SET_DASHBOARD2_ORG',
  SET_DASHBOARD2_DEFAULT_DATES = 'SET_DASHBOARD2_DEFAULT_DATES',
  GET_DASHBOARD2_TARGET_CONDITIONS_DATA_BEGIN = 'GET_DASHBOARD2_TARGET_CONDITIONS_DATA_BEGIN',
  GET_DASHBOARD2_TARGET_CONDITIONS_DATA_FAILED = 'GET_DASHBOARD2_TARGET_CONDITIONS_DATA_FAILED',
  GET_DASHBOARD2_TARGET_CONDITIONS_DATA_SUCCESS = 'GET_DASHBOARD2_TARGET_CONDITIONS_DATA_SUCCESS',
  GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA = 'GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA',
  GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_BEGIN = 'GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_BEGIN',
  GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_FAILED = 'GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_FAILED',
  GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_SUCCESS = 'GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_SUCCESS',
  GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA = 'GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA',
  GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_BEGIN = 'GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_BEGIN',
  GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_FAILED = 'GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_FAILED',
  GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_SUCCESS = 'GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_SUCCESS',
  GET_DASHBOARD2_SMARTGOALS_DATA_BEGIN = 'GET_DASHBOARD2_SMARTGOALS_DATA_BEGIN',
  GET_DASHBOARD2_SMARTGOALS_DATA_FAILED = 'GET_DASHBOARD2_SMARTGOALS_DATA_FAILED',
  GET_DASHBOARD2_SMARTGOALS_DATA_SUCCESS = 'GET_DASHBOARD2_SMARTGOALS_DATA_SUCCESS',
  GET_DASHBOARD2_SMARTGOALS_MODAL_DATA = 'GET_DASHBOARD2_SMARTGOALS_MODAL_DATA',
  GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_BEGIN = 'GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_BEGIN',
  GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_FAILED = 'GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_FAILED',
  GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_SUCCESS = 'GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_SUCCESS',
  GET_DASHBOARD2_CMI_DOC_SCORE_DATA = 'GET_DASHBOARD2_CMI_DOC_SCORE_DATA',
  GET_DASHBOARD2_CMI_DOC_SCORE_DATA_BEGIN = 'GET_DASHBOARD2_CMI_DOC_SCORE_DATA_BEGIN',
  GET_DASHBOARD2_CMI_DOC_SCORE_DATA_FAILED = 'GET_DASHBOARD2_CMI_DOC_SCORE_DATA_FAILED',
  GET_DASHBOARD2_CMI_DOC_SCORE_DATA_SUCCESS = 'GET_DASHBOARD2_CMI_DOC_SCORE_DATA_SUCCESS',
  GET_DASHBOARD2_AHRQ_MORTALITY_DATA = 'GET_DASHBOARD2_AHRQ_MORTALITY_DATA',
  GET_DASHBOARD2_AHRQ_MORTALITY_DATA_BEGIN = 'GET_DASHBOARD2_AHRQ_MORTALITY_DATA_BEGIN',
  GET_DASHBOARD2_AHRQ_MORTALITY_DATA_FAILED = 'GET_DASHBOARD2_AHRQ_MORTALITY_DATA_FAILED',
  GET_DASHBOARD2_AHRQ_MORTALITY_DATA_SUCCESS = 'GET_DASHBOARD2_AHRQ_MORTALITY_DATA_SUCCESS',
  GET_DASHBOARD2_CMI_ANALYSIS_DATA = 'GET_DASHBOARD2_CMI_ANALYSIS_DATA',
  GET_DASHBOARD2_CMI_ANALYSIS_DATA_BEGIN = 'GET_DASHBOARD2_CMI_ANALYSIS_DATA_BEGIN',
  GET_DASHBOARD2_CMI_ANALYSIS_DATA_FAILED = 'GET_DASHBOARD2_CMI_ANALYSIS_DATA_FAILED',
  GET_DASHBOARD2_CMI_ANALYSIS_DATA_SUCCESS = 'GET_DASHBOARD2_CMI_ANALYSIS_DATA_SUCCESS',
  GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA = 'GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA',
  GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_BEGIN = 'GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_BEGIN',
  GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_FAILED = 'GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_FAILED',
  GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_SUCCESS = 'GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_SUCCESS',
  GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA = 'GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA',
  GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_BEGIN = 'GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_BEGIN',
  GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_FAILED = 'GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_FAILED',
  GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_SUCCESS = 'GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_SUCCESS',
  GET_DASHBOARD2_SEVERITY_OPPORTUNITY = 'GET_DASHBOARD2_SEVERITY_OPPORTUNITY',
  GET_DASHBOARD2_SEVERITY_OPPORTUNITY_BEGIN = 'GET_DASHBOARD2_SEVERITY_OPPORTUNITY_BEGIN',
  GET_DASHBOARD2_SEVERITY_OPPORTUNITY_FAILED = 'GET_DASHBOARD2_SEVERITY_OPPORTUNITY_FAILED',
  GET_DASHBOARD2_SEVERITY_OPPORTUNITY_SUCCESS = 'GET_DASHBOARD2_SEVERITY_OPPORTUNITY_SUCCESS',
  GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH = 'GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH',
  GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_BEGIN = 'GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_BEGIN',
  GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_FAILED = 'GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_FAILED',
  GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_SUCCESS = 'GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_SUCCESS'
}

export type Dashboard2Action<T> = {
  type: keyof typeof Dashboard2Actions;
  payload?: T;
};

export type Dashboard2Payload = {
  entityId: number;
  nodeTypeId: number;
  groupId?: number;
};

const initialDashDefaultDates: DashboardDefaultDates = {
  isLoading: false,
  hasError: false,
  historicStart: '',
  historicEnd: '',
  currentStart: '',
  currentEnd: ''
};

export const initialDashboardWidget: DashboardWidget = {
  isLoading: true,
  hasError: false,
  data: {},
  modalRecordId: -1,
  modalRecordName: '',
  modalGraphData: {},
  modalTableData: {},
  isModalLoading: false,
  isModalGraphLoading: false
};

export const initialDashboard2State: Dashboard2Dataset = {
  isDashboard2Initialized: false,
  groupId: -1,
  groupName: '',
  hasError: false,
  isLoading: false,
  hospitalsWithSystem: [],
  isHospitalsWithSystemInitialized: false,
  isHospitalsWithSystemLoading: false,
  dashboardDefaultDates: initialDashDefaultDates,
  smartGoalsWidget: initialDashboardWidget,
  targetedConditionsWidget: initialDashboardWidget,
  cmiDocScoreWidget: initialDashboardWidget,
  ahrqMortalityRAWidget: initialDashboardWidget,
  cmiAnalysisWidget: initialDashboardWidget,
  drgConditionsByOpportunityWidget: initialDashboardWidget,
  severityOpportunityWidget: initialDashboardWidget
};

type DashboardDTO = {
  dashboardDefaultDates?: DashboardDefaultDates;
  smartGoalsWidget?: DashboardWidget;
  targetedConditionsWidget?: DashboardWidget;
  cmiDocScoreWidget?: DashboardWidget;
  ahrqMortalityRAWidget?: DashboardWidget;
  cmiAnalysisWidget?: DashboardWidget;
  drgConditionsByOpportunityWidget?: DashboardWidget;
  severityOpportunityWidget?: DashboardWidget;
};

export type DRGConditionsOpportunityData = {
  drgShare: number;
  hccShare: number;
  metrics: MetricRecordJSON[];
  mortalityShare: number;
  psiShare: number;
  readmissionShare: number;
  severityOpportunityWidget?: DashboardWidget;
};

export type DRGDataType = {
  drgOpportunityData: CMIMetricRecordJSON[];
  widgetTableData: DrgConditionOpportunityData[];
};

type SeverityOpportunityPayload = {
  orgId: number;
  nodeTypeId: number;
  historicalStart: string;
  historicalEnd: string;
  currentStart: string;
  currentEnd: string;
};

type DrgOpportunityPayload = SeverityOpportunityPayload;

type DRGConditionOpportunityPayload = {
  orgId: number;
  startDate: string;
  endDate: string;
  conditionId: number;
};

export const initializeDashboard2 = (): Dashboard2Action<void> => ({
  type: Dashboard2Actions.INITIALIZE_DASHBOARD2
});

export const fetchDashboard2Data = (payload: Dashboard2Payload): Dashboard2Action<Dashboard2Payload> => ({
  type: Dashboard2Actions.GET_DASHBOARD2_DATA,
  payload
});

type OrgPayload = {
  entity?: HospitalsWithSystem;
  groupId?: number;
  groupName: string;
};
export const setDashboard2Org = (payload: OrgPayload): Dashboard2Action<OrgPayload> => ({
  type: Dashboard2Actions.SET_DASHBOARD2_ORG,
  payload
});

export const getTargetedConditionsModalData = (payload: number): Dashboard2Action<number> => ({
  type: Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA,
  payload
});

export const getTopDrgConditionModalData = (
  payload: DRGConditionOpportunityPayload
): Dashboard2Action<DRGConditionOpportunityPayload> => ({
  type: Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA,
  payload
});

type ConditionPayload = {
  entity: number;
  conditionId: number;
  conditionDescription: string;
  currentStart: string;
  currentEnd: string;
  historicalStart: string;
  historicalEnd: string;
};

export const getTargetedConditionsModalGraphData = (payload: ConditionPayload): Dashboard2Action<ConditionPayload> => ({
  type: Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA,
  payload
});

export const fetchSmartGoalsModalData = (payload: number): Dashboard2Action<number> => ({
  type: Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_MODAL_DATA,
  payload
});

export const getSeverityOpportunityGraphData = (
  payload: SeverityOpportunityPayload
): Dashboard2Action<SeverityOpportunityPayload> => ({
  type: Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH,
  payload
});

const dashboard2Reducer = (
  state: Dashboard2Dataset = initialDashboard2State,
  action: Dashboard2Action<
    Dashboard2Payload | HospitalsWithSystem[] | DashboardDTO | OrgPayload | SeverityOpportunityPayload | string | number
  >
): Dashboard2Dataset => {
  switch (action.type) {
    case Dashboard2Actions.INITIALIZE_DASHBOARD2_BEGIN: {
      return {
        isDashboard2Initialized: false,
        hasError: false,
        isLoading: true,
        groupId: -1,
        groupName: '',
        isHospitalsWithSystemInitialized: false,
        hospitalsWithSystem: [],
        isHospitalsWithSystemLoading: true,
        dashboardDefaultDates: initialDashDefaultDates,
        smartGoalsWidget: initialDashboardWidget,
        targetedConditionsWidget: initialDashboardWidget,
        cmiAnalysisWidget: initialDashboardWidget,
        ahrqMortalityRAWidget: initialDashboardWidget,
        drgConditionsByOpportunityWidget: initialDashboardWidget,
        severityOpportunityWidget: initialDashboardWidget,
        cmiDocScoreWidget: initialDashboardWidget
      };
    }
    case Dashboard2Actions.INITIALIZE_DASHBOARD2_FAILED: {
      return {
        isDashboard2Initialized: false,
        isHospitalsWithSystemInitialized: false,
        isHospitalsWithSystemLoading: false,
        hospitalsWithSystem: [],
        hasError: true,
        isLoading: false,
        groupId: -1,
        groupName: '',
        errorMessage: action.payload as string,
        dashboardDefaultDates: initialDashDefaultDates,
        smartGoalsWidget: initialDashboardWidget,
        targetedConditionsWidget: initialDashboardWidget,
        cmiAnalysisWidget: initialDashboardWidget,
        ahrqMortalityRAWidget: initialDashboardWidget,
        drgConditionsByOpportunityWidget: initialDashboardWidget,
        severityOpportunityWidget: initialDashboardWidget,
        cmiDocScoreWidget: initialDashboardWidget
      };
    }
    case Dashboard2Actions.INITIALIZE_DASHBOARD2_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isDashboard2Initialized: false,
        isHospitalsWithSystemInitialized: true,
        isHospitalsWithSystemLoading: false,
        hospitalsWithSystem: action.payload as HospitalsWithSystem[]
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_DATA_BEGIN: {
      return {
        ...state,
        isLoading: true,
        smartGoalsWidget: initialDashboardWidget,
        targetedConditionsWidget: initialDashboardWidget,
        dashboardDefaultDates: initialDashDefaultDates
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_DATA_FAILED:
    case Dashboard2Actions.GET_DASHBOARD2_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isDashboard2Initialized: true,
        previousEntity: state.entity,
        previousGroupId: state.groupId
      };
    }
    case Dashboard2Actions.SET_DASHBOARD2_ORG: {
      const { entity, groupId, groupName } = action.payload as OrgPayload;
      return {
        ...state,
        entity,
        groupId,
        groupName
      };
    }
    case Dashboard2Actions.SET_DASHBOARD2_DEFAULT_DATES: {
      const {
        historicalMinMonth,
        historicalMaxMonth,
        currentMinMonth,
        currentMaxMonth
      } = action.payload as DefaultDatesJSON;
      return {
        ...state,
        dashboardDefaultDates: {
          ...state.dashboardDefaultDates,
          historicStart: historicalMinMonth,
          historicEnd: historicalMaxMonth,
          currentStart: currentMinMonth,
          currentEnd: currentMaxMonth,
          hasError: false,
          isLoading: false
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_DATA_BEGIN: {
      return {
        ...state,
        targetedConditionsWidget: {
          ...initialDashboardWidget,
          isLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_DATA_FAILED: {
      return {
        ...state,
        targetedConditionsWidget: {
          ...initialDashboardWidget,
          isLoading: false,
          hasError: true,
          errorMessage: action.payload as string
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_DATA_SUCCESS: {
      return {
        ...state,
        targetedConditionsWidget: {
          ...initialDashboardWidget,
          isLoading: false,
          data: action.payload
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_BEGIN: {
      return {
        ...state,
        targetedConditionsWidget: {
          ...state.targetedConditionsWidget,
          isModalLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_FAILED: {
      const { targetedConditionsWidget } = action.payload as DashboardDTO;
      const { errorMessage } = targetedConditionsWidget as DashboardWidget;
      return {
        ...state,
        targetedConditionsWidget: {
          ...state.targetedConditionsWidget,
          isModalLoading: false,
          hasError: true,
          errorMessage: errorMessage as string
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_SUCCESS: {
      const { targetedConditionsWidget } = action.payload as DashboardDTO;

      const {
        modalGraphData,
        modalTableData,
        modalRecordId,
        modalRecordName
      } = targetedConditionsWidget as DashboardWidget;
      return {
        ...state,
        targetedConditionsWidget: {
          ...state.targetedConditionsWidget,
          isModalLoading: false,
          hasError: false,
          modalGraphData,
          modalTableData,
          modalRecordId,
          modalRecordName
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_BEGIN: {
      return {
        ...state,
        targetedConditionsWidget: {
          ...state.targetedConditionsWidget,
          isModalGraphLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_FAILED: {
      const { targetedConditionsWidget } = action.payload as DashboardDTO;
      const { errorMessage } = targetedConditionsWidget as DashboardWidget;
      return {
        ...state,
        targetedConditionsWidget: {
          ...state.targetedConditionsWidget,
          isModalGraphLoading: false,
          errorMessage
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_SUCCESS: {
      const { targetedConditionsWidget } = action.payload as DashboardDTO;
      const { modalGraphData, modalRecordId, modalRecordName } = targetedConditionsWidget as DashboardWidget;

      return {
        ...state,
        targetedConditionsWidget: {
          ...state.targetedConditionsWidget,
          isModalGraphLoading: false,
          modalGraphData,
          modalRecordId,
          modalRecordName
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_DATA_BEGIN: {
      return {
        ...state,
        smartGoalsWidget: {
          ...initialDashboardWidget,
          isLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_DATA_FAILED: {
      return {
        ...state,
        smartGoalsWidget: {
          ...initialDashboardWidget,
          isLoading: false,
          hasError: true,
          errorMessage: action.payload as string
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_DATA_SUCCESS: {
      return {
        ...state,
        smartGoalsWidget: {
          ...initialDashboardWidget,
          isLoading: false,
          data: action.payload
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_BEGIN: {
      return {
        ...state,
        smartGoalsWidget: {
          ...state.smartGoalsWidget,
          isModalLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_SUCCESS: {
      const { smartGoalsWidget } = action.payload as DashboardDTO;
      const { modalTableData, modalRecordId, modalRecordName } = smartGoalsWidget as DashboardWidget;
      return {
        ...state,
        hasError: false,
        smartGoalsWidget: {
          ...state.smartGoalsWidget,
          modalRecordId,
          modalRecordName,
          modalTableData,
          isModalLoading: false
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_FAILED: {
      const { smartGoalsWidget } = action.payload as DashboardDTO;
      const { errorMessage } = smartGoalsWidget as DashboardWidget;
      return {
        ...state,
        smartGoalsWidget: {
          ...state.smartGoalsWidget,
          hasError: true,
          errorMessage,
          isModalLoading: false
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_CMI_DOC_SCORE_DATA_BEGIN: {
      return {
        ...state,
        cmiDocScoreWidget: {
          ...state.cmiDocScoreWidget,
          isLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_CMI_DOC_SCORE_DATA_FAILED: {
      const { cmiDocScoreWidget } = action.payload as DashboardDTO;
      const { errorMessage } = cmiDocScoreWidget as DashboardWidget;
      return {
        ...state,
        cmiDocScoreWidget: {
          ...state.cmiDocScoreWidget,
          isLoading: false,
          hasError: true,
          errorMessage: errorMessage as string
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_CMI_DOC_SCORE_DATA_SUCCESS: {
      const { cmiDocScoreWidget } = action.payload as DashboardDTO;
      const { modalRecordId, modalGraphData, modalTableData } = cmiDocScoreWidget as DashboardWidget;

      return {
        ...state,
        cmiDocScoreWidget: {
          ...state.cmiDocScoreWidget,
          hasError: false,
          modalGraphData,
          modalTableData,
          modalRecordId: modalRecordId,
          isLoading: false
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_AHRQ_MORTALITY_DATA_BEGIN: {
      return {
        ...state,
        ahrqMortalityRAWidget: {
          ...state.ahrqMortalityRAWidget,
          isLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_AHRQ_MORTALITY_DATA_FAILED: {
      const { ahrqMortalityRAWidget } = action.payload as DashboardDTO;
      const { errorMessage } = ahrqMortalityRAWidget as DashboardWidget;
      return {
        ...state,
        ahrqMortalityRAWidget: {
          ...state.ahrqMortalityRAWidget,
          isLoading: false,
          hasError: true,
          errorMessage: errorMessage as string
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_AHRQ_MORTALITY_DATA_SUCCESS: {
      const { ahrqMortalityRAWidget } = action.payload as DashboardDTO;
      const { modalRecordId, modalGraphData, modalTableData } = ahrqMortalityRAWidget as DashboardWidget;

      return {
        ...state,
        hasError: false,
        ahrqMortalityRAWidget: {
          ...state.ahrqMortalityRAWidget,
          modalGraphData,
          modalTableData,
          modalRecordId: modalRecordId,
          isLoading: false
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_CMI_ANALYSIS_DATA_BEGIN: {
      return {
        ...state,
        cmiAnalysisWidget: {
          ...initialDashboardWidget,
          isLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_CMI_ANALYSIS_DATA_FAILED: {
      return {
        ...state,
        cmiAnalysisWidget: {
          ...state.cmiAnalysisWidget,
          isLoading: false,
          hasError: true,
          errorMessage: action.payload as string
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_CMI_ANALYSIS_DATA_SUCCESS: {
      const { cmiAnalysisWidget } = action.payload as DashboardDTO;
      const { data, modalRecordId } = cmiAnalysisWidget as DashboardWidget;
      return {
        ...state,
        cmiAnalysisWidget: {
          ...state.cmiAnalysisWidget,
          isLoading: false,
          modalRecordId,
          data
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_BEGIN: {
      return {
        ...state,
        drgConditionsByOpportunityWidget: {
          ...state.drgConditionsByOpportunityWidget,
          isLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_FAILED: {
      const { drgConditionsByOpportunityWidget } = action.payload as DashboardDTO;
      const { errorMessage } = drgConditionsByOpportunityWidget as DashboardWidget;
      return {
        ...state,
        drgConditionsByOpportunityWidget: {
          ...state.drgConditionsByOpportunityWidget,
          isLoading: false,
          hasError: true,
          errorMessage: errorMessage as string
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_SUCCESS: {
      const { drgConditionsByOpportunityWidget } = action.payload as DashboardDTO;
      const { modalRecordId, data, modalTableData } = drgConditionsByOpportunityWidget as DashboardWidget;

      return {
        ...state,
        hasError: false,
        drgConditionsByOpportunityWidget: {
          ...state.drgConditionsByOpportunityWidget,
          data: data,
          modalRecordId: modalRecordId,
          modalTableData: modalTableData,
          isLoading: false
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_BEGIN: {
      return {
        ...state,
        drgConditionsByOpportunityWidget: {
          ...initialDashboardWidget,
          ...state.drgConditionsByOpportunityWidget,
          isModalLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_FAILED: {
      return {
        ...state,
        drgConditionsByOpportunityWidget: {
          ...initialDashboardWidget,
          ...state.drgConditionsByOpportunityWidget,
          isModalLoading: false,
          hasError: true,
          errorMessage: action.payload as string
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_SUCCESS: {
      const { drgConditionsByOpportunityWidget } = action.payload as DashboardDTO;
      const { modalGraphData, modalRecordId } = drgConditionsByOpportunityWidget as DashboardWidget;
      return {
        ...state,
        hasError: false,
        drgConditionsByOpportunityWidget: {
          ...state.drgConditionsByOpportunityWidget,
          modalRecordId,
          modalGraphData,
          isModalLoading: false
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_BEGIN: {
      return {
        ...state,
        severityOpportunityWidget: {
          ...initialDashboardWidget,
          isLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_FAILED: {
      return {
        ...state,
        severityOpportunityWidget: {
          ...state.severityOpportunityWidget,
          isLoading: false,
          hasError: true,
          errorMessage: action.payload as string
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_SUCCESS: {
      const { severityOpportunityWidget } = action.payload as DashboardDTO;
      const { data, hasError } = severityOpportunityWidget as DashboardWidget;

      return {
        ...state,
        severityOpportunityWidget: {
          ...state.severityOpportunityWidget,
          isLoading: false,
          hasError,
          data,
          modalTableData: data
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_BEGIN: {
      return {
        ...state,
        severityOpportunityWidget: {
          ...initialDashboardWidget,
          ...state.severityOpportunityWidget,
          isModalGraphLoading: true
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_FAILED: {
      return {
        ...state,
        severityOpportunityWidget: {
          ...initialDashboardWidget,
          ...state.severityOpportunityWidget,
          isModalGraphLoading: false,
          hasError: true,
          errorMessage: action.payload as string
        }
      };
    }
    case Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_SUCCESS: {
      const { severityOpportunityWidget } = action.payload as DashboardDTO;
      const { modalGraphData, modalRecordId } = severityOpportunityWidget as DashboardWidget;
      return {
        ...state,
        severityOpportunityWidget: {
          ...initialDashboardWidget,
          ...state.severityOpportunityWidget,
          isModalGraphLoading: false,
          modalGraphData,
          modalRecordId
        }
      };
    }
    default: {
      return { ...state };
    }
  }
};

export function* initializeDashboard2Saga(): SagaIterator {
  yield put({ type: Dashboard2Actions.INITIALIZE_DASHBOARD2_BEGIN });

  const hospitalOutput: AsyncOutput<HospitalsWithSystem[]> = yield call(ApplicationAPI.get, {
    endpoint: 'hospitals/hospitalsWithSystem'
  });

  if (hospitalOutput.error) {
    yield put({ type: Dashboard2Actions.INITIALIZE_DASHBOARD2_FAILED, payload: hospitalOutput.error });
  } else {
    yield put({ type: Dashboard2Actions.INITIALIZE_DASHBOARD2_SUCCESS, payload: hospitalOutput.data });
  }
}

export function* fetchDashboard2DataSaga({ payload }: Dashboard2Action<Dashboard2Payload>): SagaIterator {
  const { entityId, nodeTypeId, groupId } = payload as Dashboard2Payload;
  if (!entityId) throw new Error('entityId is required');
  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_DATA_BEGIN });

  const orgId = groupId === undefined || groupId === -1 ? entityId : groupId;

  yield spawn(fetchSmartGoalsDataSaga, orgId);
  yield spawn(fetchTargetConditionsDataSaga, orgId);
  yield spawn(fetchCMIAnalysisSummaryRecords, orgId);

  const dashboardDefaultDatesOutput: AsyncOutput<DefaultDatesJSON> = yield call(ApplicationAPI.get, {
    endpoint: `DefaultDates/${orgId}`
  });

  if (dashboardDefaultDatesOutput.error) {
    const returningPayload: DashboardDTO = {};
    if (dashboardDefaultDatesOutput.error) {
      returningPayload.dashboardDefaultDates = {
        ...initialDashDefaultDates,
        hasError: true,
        errorMessage: dashboardDefaultDatesOutput.error
      };
    }
    yield put({ type: Dashboard2Actions.GET_DASHBOARD2_DATA_FAILED, payload: returningPayload });
  } else {
    // set default dashboard dates
    const {
      historicalMinMonth,
      historicalMaxMonth,
      currentMinMonth,
      currentMaxMonth
    } = dashboardDefaultDatesOutput.data as DefaultDatesJSON;

    yield put({
      type: Dashboard2Actions.SET_DASHBOARD2_DEFAULT_DATES,
      payload: { historicalMinMonth, historicalMaxMonth, currentMinMonth, currentMaxMonth }
    });

    yield spawn(fetchCMIDocScoreDataSaga, [
      { historicalMinMonth, historicalMaxMonth, currentMinMonth, currentMaxMonth } as DefaultDatesJSON,
      orgId
    ]);

    yield spawn(fetchAHRQMortalityDataSaga, [
      { historicalMinMonth, historicalMaxMonth, currentMinMonth, currentMaxMonth } as DefaultDatesJSON,
      orgId
    ]);

    yield spawn(fetchTopFiveDRGConditionsByOpportunityDataSaga, {
      orgId,
      nodeTypeId,
      historicalStart: historicalMinMonth,
      historicalEnd: historicalMaxMonth,
      currentStart: currentMinMonth,
      currentEnd: currentMaxMonth
    });

    yield spawn(fetchSeverityOpportunityDataSaga, {
      orgId,
      nodeTypeId,
      historicalStart: historicalMinMonth,
      historicalEnd: historicalMaxMonth,
      currentStart: currentMinMonth,
      currentEnd: currentMaxMonth
    });

    yield put({ type: Dashboard2Actions.GET_DASHBOARD2_DATA_SUCCESS });
  }
}

export function* fetchSmartGoalsDataSaga(payload: number): SagaIterator {
  if (!payload) throw new Error('payload is required');

  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_DATA_BEGIN });

  const output: AsyncOutput<unknown> = yield call(ApplicationAPI.get, {
    endpoint: `docscoregoal/smartgoalswidget/${payload}`
  });

  if (output.error) {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_DATA_FAILED,
      payload: output.error
    });
  } else {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_DATA_SUCCESS,
      payload: output.data
    });
  }
}

export function* fetchTopFiveDRGConditionsByOpportunityDataSaga(payload: DrgOpportunityPayload): SagaIterator {
  if (!payload) throw new Error('orgId is required');

  const {
    historicalStart,
    historicalEnd,
    currentStart,
    currentEnd,
    orgId,
    nodeTypeId
  } = payload as DrgOpportunityPayload;
  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_BEGIN });

  const queryStringValues: QueryStringParam[] = [
    {
      key: 'historicalStart',
      value: formatDateForAPI(new Date(historicalStart))
    },
    { key: 'historicalEnd', value: formatDateForAPI(new Date(historicalEnd)) },
    { key: 'currentStart', value: formatDateForAPI(new Date(currentStart)) },
    { key: 'currentEnd', value: formatDateForAPI(new Date(currentEnd)) },
    { key: 'count', value: '5' },
    { key: 'ConditionTypeIds', value: '1' },
    { key: 'includeAllConditions', value: '1' },
    { key: 'useSavedTargetConds', value: '1' },
    { key: 'shareDrg', value: '5' },
    { key: 'shareHcc', value: '0' },
    { key: 'shareMortality', value: '0' },
    { key: 'shareReadmission', value: '0' },
    { key: 'sharePSI', value: '0' }
  ];

  if (nodeTypeId === 1) {
    queryStringValues.push({ key: 'ForDashboard', value: 'true' });
  }

  const widgetEndpoint = createUrlWithQueryString(`metrics/conditions/${orgId}`, queryStringValues);

  const apiCallResult = yield call(ApplicationAPI.get, {
    endpoint: widgetEndpoint
  });

  if (apiCallResult.error) {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_FAILED,
      payload: {
        drgConditionsByOpportunityWidget: {
          ...initialDashboardWidget,
          hasError: true,
          errorMessage: apiCallResult.error as string
        }
      }
    });
  } else {
    const dataResult = (apiCallResult.data as DRGConditionsOpportunityData).metrics.map(elem => {
      return { name: elem.name, opportunityRW: elem.opportunityRW, conditionId: elem.conditionId };
    });

    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_DATA_SUCCESS,
      payload: {
        drgConditionsByOpportunityWidget: {
          ...initialDashboardWidget,
          modalRecordId: apiCallResult.data.condititionId,
          data: {
            drgOpportunityData: apiCallResult.data as unknown,
            widgetTableData: dataResult as DrgConditionOpportunityData[]
          }
        }
      }
    });
  }
}

export function* fetchTopFiveDRGConditionsByOpportunityModalDataSaga({
  payload
}: Dashboard2Action<DRGConditionOpportunityPayload>): SagaIterator {
  if (!payload) throw new Error('orgId is required');
  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_BEGIN });
  const { orgId, startDate, endDate, conditionId } = payload;
  const queryStringValues: QueryStringParam[] = [
    {
      key: 'StartDate',
      value: formatDateForAPI(new Date(startDate))
    },
    { key: 'EndDate', value: formatDateForAPI(new Date(endDate)) },
    { key: 'ConditionId', value: conditionId.toString() }
  ];
  const widgetEndpoint = createUrlWithQueryString(`Graph/conditions/${orgId}`, queryStringValues);

  const drgConditionModalData: AsyncOutput<ChartDataSet[]> = yield call(ApplicationAPI.get, {
    endpoint: widgetEndpoint
  });

  if (drgConditionModalData.error) {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_FAILED,
      payload: drgConditionModalData.error
    });
  } else {
    const result = drgConditionModalData.data;
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA_SUCCESS,
      payload: {
        drgConditionsByOpportunityWidget: {
          ...initialDashboardWidget,
          modalRecordId: conditionId,
          modalGraphData: result
        }
      }
    });
  }
}

export function* fetchAHRQMortalityDataSaga(payload: (DefaultDatesJSON | number)[]): SagaIterator {
  if (!payload[1]) throw new Error('orgId is required');
  if (!payload[0]) throw new Error('Payload, dates are required');

  const orgId = payload[1];
  const { historicalMinMonth, historicalMaxMonth, currentMinMonth, currentMaxMonth } = payload[0] as DefaultDatesJSON;
  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_AHRQ_MORTALITY_DATA_BEGIN });

  const queryStringValues: QueryStringParam[] = [
    {
      key: 'StartDate',
      value: formatDateForAPI(new Date(historicalMinMonth))
    },
    { key: 'EndDate', value: formatDateForAPI(new Date(currentMaxMonth)) }
  ];
  const widgetEndpoint = createUrlWithQueryString(`Graph/elixhausermortality/${orgId}`, queryStringValues);

  const metricQueryStringValues: QueryStringParam[] = [
    {
      key: 'currentStart',
      value: formatDateForAPI(new Date(currentMinMonth))
    },
    {
      key: 'currentEnd',
      value: formatDateForAPI(new Date(currentMaxMonth))
    },
    {
      key: 'historicalStart',
      value: formatDateForAPI(new Date(historicalMinMonth))
    },
    {
      key: 'historicalEnd',
      value: formatDateForAPI(new Date(historicalMaxMonth))
    }
  ];
  const metricEndpoint = createUrlWithQueryString(`metrics/elixhausermortality/${orgId}`, metricQueryStringValues);

  const [graphOutput, metricOutput] = yield all([
    call(ApplicationAPI.get, { endpoint: widgetEndpoint }),
    call(ApplicationAPI.get, { endpoint: metricEndpoint })
  ]);

  if (graphOutput.error || metricOutput.error) {
    const errorMessage: string[] = [];
    if (graphOutput.error) errorMessage.push(graphOutput.error);
    if (metricOutput.error) errorMessage.push(metricOutput.error);
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_AHRQ_MORTALITY_DATA_FAILED,
      payload: {
        ahrqMortalityRAWidget: {
          ...initialDashboardWidget,
          hasError: true,
          errorMessage: errorMessage.join('/')
        }
      }
    });
  } else {
    const dataResult = (graphOutput.data as ChartDataSetJSON).data.filter(
      element => element.dataSetType === 'Actual' && element.dataSetAverageType === ChartDataSetAverageTypes.Month
    );
    const ahrqMortality = (dataResult[0] as unknown) as ChartDataSet;
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_AHRQ_MORTALITY_DATA_SUCCESS,
      payload: {
        ahrqMortalityRAWidget: {
          ...initialDashboardWidget,
          modalRecordId: orgId,
          modalGraphData: ahrqMortality,
          modalTableData: metricOutput.data
        }
      }
    });
  }
}

export function* fetchCMIDocScoreDataSaga(payload: (DefaultDatesJSON | number)[]): SagaIterator {
  if (!payload[1]) throw new Error('orgId is required');
  if (!payload[0]) throw new Error('Payload, dates are required');

  const orgId = payload[1];
  const { historicalMinMonth, historicalMaxMonth, currentMinMonth, currentMaxMonth } = payload[0] as DefaultDatesJSON;
  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_CMI_DOC_SCORE_DATA_BEGIN });

  const queryStringValues: QueryStringParam[] = [
    {
      key: 'StartDate',
      value: formatDateForAPI(new Date(historicalMinMonth))
    },
    { key: 'EndDate', value: formatDateForAPI(new Date(currentMaxMonth)) }
  ];
  const widgetEndpoint = createUrlWithQueryString(`Graph/docScore/${orgId}`, queryStringValues);

  const metricQueryStringValues: QueryStringParam[] = [
    {
      key: 'currentStart',
      value: formatDateForAPI(new Date(currentMinMonth))
    },
    {
      key: 'currentEnd',
      value: formatDateForAPI(new Date(currentMaxMonth))
    },
    {
      key: 'historicalStart',
      value: formatDateForAPI(new Date(historicalMinMonth))
    },
    {
      key: 'historicalEnd',
      value: formatDateForAPI(new Date(historicalMaxMonth))
    }
  ];
  const metricEndpoint = createUrlWithQueryString(`metrics/docScore/${orgId}`, metricQueryStringValues);

  const [graphOutput, metricOutput] = yield all([
    call(ApplicationAPI.get, { endpoint: widgetEndpoint }),
    call(ApplicationAPI.get, { endpoint: metricEndpoint })
  ]);

  if (graphOutput.error || metricOutput.error) {
    const errorMessage: string[] = [];
    if (graphOutput.error) errorMessage.push(graphOutput.error);
    if (metricOutput.error) errorMessage.push(metricOutput.error);
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_CMI_DOC_SCORE_DATA_FAILED,
      payload: {
        cmiDocScoreWidget: {
          ...initialDashboardWidget,
          hasError: true,
          errorMessage: errorMessage.join('/')
        }
      }
    });
  } else {
    const dataResult = (graphOutput.data as ChartDataSetJSON).data.filter(
      element => element.dataSetType === 'Actual' && element.dataSetAverageType === ChartDataSetAverageTypes.Month
    );
    const cmiDocScore = (dataResult[0] as unknown) as ChartDataSet;
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_CMI_DOC_SCORE_DATA_SUCCESS,
      payload: {
        cmiDocScoreWidget: {
          ...initialDashboardWidget,
          modalRecordId: orgId,
          modalGraphData: cmiDocScore,
          modalTableData: metricOutput.data
        }
      }
    });
  }
}

export function* fetchCMIAnalysisSummaryRecords(orgId: number): SagaIterator {
  if (!orgId) throw new Error('Payload is required');

  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_CMI_ANALYSIS_DATA_BEGIN });

  const bestFitOutput: AsyncOutput<BestFitDates> = yield call(ApplicationAPI.get, {
    endpoint: `CmiAnalysis/widgetdates/${orgId}`
  });

  if (bestFitOutput.error) {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_CMI_ANALYSIS_DATA_FAILED,
      payload: bestFitOutput.error
    });
  } else {
    const {
      bestFitHistoricalStart,
      bestFitHistoricalEnd,
      bestFitCurrentStart,
      bestFitCurrentEnd
    } = bestFitOutput.data as BestFitDates;

    const historicalMin = new Date(bestFitHistoricalStart);
    const historicalMax = new Date(bestFitHistoricalEnd);
    const currentMin = new Date(bestFitCurrentStart);
    const currentMax = new Date(bestFitCurrentEnd);

    const querystring = `?historicalStart=${formatDateForAPI(historicalMin)}&historicalEnd=${formatDateForAPI(
      historicalMax
    )}&currentStart=${formatDateForAPI(currentMin)}&currentEnd=${formatDateForAPI(currentMax)}`;

    const [cmiAnalysis, severityCMIAnalysis, drgMix, severityValue]: [
      AsyncOutput<CMIMetricRecordJSON[]>,
      AsyncOutput<CMIMetricRecordJSON[]>,
      AsyncOutput<PatientMixAnalysisJSON>,
      AsyncOutput<SeverityCMIAnalysisJSON>
    ] = yield all([
      call(ApplicationAPI.get, { endpoint: `metrics/cmi/${orgId}${querystring}` }),
      call(ApplicationAPI.get, {
        endpoint: `metrics/severitycmi/${orgId}${querystring}`
      }),
      call(ApplicationAPI.get, {
        endpoint: `metrics/drgmix/${orgId}${querystring}`
      }),
      call(ApplicationAPI.get, {
        endpoint: `metrics/severitycmiconditions/${orgId}${querystring}`
      })
    ]);

    const errors: string[] = [];
    if (cmiAnalysis.error || severityCMIAnalysis.error || drgMix.error || severityValue.error) {
      if (cmiAnalysis.error) errors.push(cmiAnalysis.error);
      if (severityCMIAnalysis.error) errors.push(severityCMIAnalysis.error);
      if (drgMix.error) errors.push(drgMix.error);
      if (severityValue.error) errors.push(severityValue.error);
    }

    if (errors.length > 0) {
      yield put({ type: Dashboard2Actions.GET_DASHBOARD2_CMI_ANALYSIS_DATA_FAILED, payload: errors.join(' / ') });
    } else {
      yield put({
        type: Dashboard2Actions.GET_DASHBOARD2_CMI_ANALYSIS_DATA_SUCCESS,
        payload: {
          cmiAnalysisWidget: {
            modalRecordId: orgId,
            data: {
              bestFitDates: {
                bestFitHistoricalStart: historicalMin,
                bestFitHistoricalEnd: historicalMax,
                bestFitCurrentStart: currentMin,
                bestFitCurrentEnd: currentMax
              },
              cmiAnalysis: cmiAnalysis.data,
              severityCMI: severityCMIAnalysis.data,
              drgMix: drgMix.data,
              severityValue: severityValue.data
            }
          }
        }
      });
    }
  }
}

export function* fetchSmartGoalsModalDataSaga({ payload }: Dashboard2Action<number>): SagaIterator {
  if (!payload) throw new Error('orgId is required');
  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_BEGIN });

  const smartgoalsModalData: AsyncOutput<HospitalsWithSystem[]> = yield call(ApplicationAPI.get, {
    endpoint: `docscoregoal/smartgoalsmodal/${payload}`
  });

  if (smartgoalsModalData.error) {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_FAILED,
      payload: smartgoalsModalData.error
    });
  } else {
    const { orgId, orgName, docScoreGoalItems } = (smartgoalsModalData.data as unknown) as SmartGoalsModalData;
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_MODAL_DATA_SUCCESS,
      payload: {
        smartGoalsWidget: {
          ...initialDashboardWidget,
          modalRecordId: orgId,
          modalRecordName: orgName,
          modalTableData: docScoreGoalItems
        }
      }
    });
  }
}

export function* fetchTargetConditionsDataSaga(payload: number): SagaIterator {
  if (!payload) throw new Error('payload is required');

  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_DATA_BEGIN });

  const output: AsyncOutput<unknown> = yield call(ApplicationAPI.get, {
    endpoint: `docscoregoal/targetedconditionswidget/${payload}`
  });

  if (output.error) {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_DATA_FAILED,
      payload: output.error
    });
  } else {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_DATA_SUCCESS,
      payload: output.data
    });
  }
}

export function* fetchTargetConditionsModalDataSaga({ payload }: Dashboard2Action<number>): SagaIterator {
  if (!payload) throw new Error('orgId is required');
  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_BEGIN });

  const tableOutput: AsyncOutput<TargetedConditionsTableType> = yield call(ApplicationAPI.get, {
    endpoint: `docscoregoal/targetedconditionstable/${payload}`
  });

  let graphOutput;
  let firstRecord;
  if (tableOutput.data) {
    firstRecord = tableOutput.data.table[0];
    const queryStringValues: QueryStringParam[] = [
      {
        key: 'historicStartDate',
        value: formatDateForAPI(new Date(tableOutput.data.historicalStart))
      },
      {
        key: 'historicEndDate',
        value: formatDateForAPI(new Date(tableOutput.data.historicalEnd))
      },
      {
        key: 'currentStartDate',
        value: formatDateForAPI(new Date(tableOutput.data.currentStart))
      },
      { key: 'currentEndDate', value: formatDateForAPI(new Date(tableOutput.data.currentEnd)) },
      { key: 'conditionId', value: firstRecord.conditionId.toString() }
    ];

    const widgetEndpoint = createUrlWithQueryString(`Graph/conditionssmartmodal/${payload}`, queryStringValues);

    graphOutput = yield call(ApplicationAPI.get, {
      endpoint: widgetEndpoint
    });
  }

  if (graphOutput.error || tableOutput.error) {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_FAILED,
      payload: {
        targetedConditionsWidget: {
          ...initialDashboardWidget,
          hasError: true,
          errorMessage: (graphOutput.error ?? tableOutput.error) as string
        }
      }
    });
  } else {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA_SUCCESS,
      payload: {
        targetedConditionsWidget: {
          ...initialDashboardWidget,
          modalRecordId: firstRecord?.conditionId,
          modalRecordName: firstRecord?.conditionDescription,
          modalGraphData: graphOutput.data,
          modalTableData: tableOutput.data
        }
      }
    });
  }
}

export function* fetchTargetConditionsModalGraphDataSaga({
  payload
}: Dashboard2Action<ConditionPayload>): SagaIterator {
  if (!payload) throw new Error('conditionId and conditionDescription are required');
  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_BEGIN });
  const {
    entity,
    conditionId,
    conditionDescription,
    currentStart,
    currentEnd,
    historicalStart,
    historicalEnd
  } = payload;

  const queryStringValues: QueryStringParam[] = [
    {
      key: 'historicStartDate',
      value: formatDateForAPI(new Date(historicalStart))
    },
    {
      key: 'historicEndDate',
      value: formatDateForAPI(new Date(historicalEnd))
    },
    {
      key: 'currentStartDate',
      value: formatDateForAPI(new Date(currentStart))
    },
    { key: 'currentEndDate', value: formatDateForAPI(new Date(currentEnd)) },
    { key: 'conditionId', value: conditionId.toString() }
  ];

  const widgetEndpoint = createUrlWithQueryString(`Graph/conditionssmartmodal/${entity}`, queryStringValues);

  const graphOutput: AsyncOutput<DashboardWidget> = yield call(ApplicationAPI.get, {
    endpoint: widgetEndpoint
  });

  if (graphOutput.error) {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_FAILED,
      payload: {
        targetedConditionsWidget: {
          ...initialDashboardWidget,
          hasError: true,
          errorMessage: graphOutput.error
        }
      }
    });
  } else {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA_SUCCESS,
      payload: {
        targetedConditionsWidget: {
          ...initialDashboardWidget,
          modalGraphData: graphOutput.data,
          modalRecordId: conditionId,
          modalRecordName: conditionDescription
        }
      }
    });
  }
}

export function* fetchSeverityOpportunityDataSaga(payload: SeverityOpportunityPayload): SagaIterator {
  const { orgId, nodeTypeId, historicalStart, historicalEnd, currentStart, currentEnd } = payload;
  if (!orgId) throw new Error('orgId is required');
  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_BEGIN });

  const endpoint = `metrics/cmi/${orgId}`;
  const queryStringParams: QueryStringParam[] = [
    {
      key: 'historicalStart',
      value: formatDateForAPI(new Date(historicalStart))
    },
    {
      key: 'historicalEnd',
      value: formatDateForAPI(new Date(historicalEnd))
    },
    {
      key: 'currentStart',
      value: formatDateForAPI(new Date(currentStart))
    },
    {
      key: 'currentEnd',
      value: formatDateForAPI(new Date(currentEnd))
    }
  ];

  if (nodeTypeId === 1) {
    queryStringParams.push({ key: 'ForDashboard', value: 'true' });
  }

  const [severityOpportunityOutput, docScoreOutput]: [AsyncOutput<unknown>, AsyncOutput<unknown>] = yield all([
    call(ApplicationAPI.get, {
      endpoint: createUrlWithQueryString(endpoint, queryStringParams)
    }),
    call(ApplicationAPI.get, { endpoint: createUrlWithQueryString(`metrics/docscore/${orgId}`, queryStringParams) })
  ]);

  if (severityOpportunityOutput.error || docScoreOutput.error) {
    const errorMessage: string[] = [];
    if (severityOpportunityOutput.error) errorMessage.push(severityOpportunityOutput.error);
    if (docScoreOutput.error) errorMessage.push(docScoreOutput.error);
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_FAILED,
      payload: errorMessage.join(' / ')
    });
  } else {
    const data = (severityOpportunityOutput.data as CMIMetricRecordJSON[])
      .filter(rec => !rec.isFooter)
      .sort((a, b) => (a.opportunityRW < b.opportunityRW ? 1 : a.opportunityRW > b.opportunityRW ? -1 : 0))
      .slice(0, 5);

    const hasNullData = data.some(rec => rec.orgId === null);
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_SUCCESS,
      payload: {
        severityOpportunityWidget: {
          ...initialDashboardWidget,
          data: {
            severityOpportunityData: data as unknown,
            docScoreData: docScoreOutput.data
          },
          hasError: hasNullData
        }
      }
    });
  }
}

export function* fetchSeverityOpportunityGraphSaga({
  payload
}: Dashboard2Action<SeverityOpportunityPayload>): SagaIterator {
  if (!payload) throw new Error('orgId, historicalStart, historicalEnd, currentStart, and currentEnd are required');
  yield put({ type: Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_BEGIN });

  const { orgId, currentStart, currentEnd } = payload;

  const endpoint = `graph/docscore/${orgId}`;
  const queryStringParams: QueryStringParam[] = [
    {
      key: 'StartDate',
      value: formatDateForAPI(new Date(currentStart))
    },
    {
      key: 'EndDate',
      value: formatDateForAPI(new Date(currentEnd))
    }
  ];

  const severityOpportunityGraphOutput = yield call(ApplicationAPI.get, {
    endpoint: createUrlWithQueryString(endpoint, queryStringParams)
  });

  if (severityOpportunityGraphOutput.error) {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_FAILED,
      payload: severityOpportunityGraphOutput.error
    });
  } else {
    yield put({
      type: Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH_SUCCESS,
      payload: {
        severityOpportunityWidget: {
          ...initialDashboardWidget,
          modalGraphData: severityOpportunityGraphOutput.data,
          modalRecordId: orgId
        }
      }
    });
  }
}

export function* dashboard2DataSaga(): SagaIterator {
  yield takeLatest(Dashboard2Actions.INITIALIZE_DASHBOARD2, initializeDashboard2Saga);
  yield takeLatest(Dashboard2Actions.GET_DASHBOARD2_DATA, fetchDashboard2DataSaga);
  yield takeLatest(
    Dashboard2Actions.GET_DASHBOARD2_DRG_CONDITIONS_BY_OPPORTUNITY_MODAL_GRAPH_DATA,
    fetchTopFiveDRGConditionsByOpportunityModalDataSaga
  );
  yield takeLatest(Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_DATA, fetchTargetConditionsModalDataSaga);
  yield takeLatest(
    Dashboard2Actions.GET_DASHBOARD2_TARGET_CONDITIONS_MODAL_GRAPH_DATA,
    fetchTargetConditionsModalGraphDataSaga
  );
  yield takeLatest(Dashboard2Actions.GET_DASHBOARD2_SMARTGOALS_MODAL_DATA, fetchSmartGoalsModalDataSaga);
  yield takeLatest(
    Dashboard2Actions.GET_DASHBOARD2_SEVERITY_OPPORTUNITY_MODAL_GRAPH,
    fetchSeverityOpportunityGraphSaga
  );
}

export default dashboard2Reducer;
