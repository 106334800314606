import React, { createContext } from 'react';

export const ActionPanelContext = createContext({
  isActionPanelOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsActionPanelOpen: (value: boolean) => {}
});

interface ActionPanelProps {
  isActionPanelOpen: boolean;
  setIsActionPanelOpen: (value: boolean) => void;
}

const ActionPanelContextProvider: React.FC<ActionPanelProps> = ({
  isActionPanelOpen,
  setIsActionPanelOpen,
  children
}) => {
  return (
    <ActionPanelContext.Provider value={{ isActionPanelOpen, setIsActionPanelOpen }}>
      {children}
    </ActionPanelContext.Provider>
  );
};

export default ActionPanelContextProvider;
