import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import useChartDatasets from '../logic/useChartDatasets';
import ComparisonChart from './ComparisonChart';
import DRGImpactTables from '@clinintell/components/analysisSharedComponents/DRGImpactTables';
import { useCMIComparisonState } from '@clinintell/modules/store';
import { processComparisonChartData } from '../logic/processComparisonChartData';

const DRGMixResults: React.FC = () => {
  const { comparisonOrgChartDataset, orgChartDataset, isLoading } = useChartDatasets({
    metric: 'hcupcmi'
  });

  const { isLoadingSummaryRecords, drgAnalysis, orgId, comparisonOrgId, noSummaryData } = useCMIComparisonState();

  const theme = useTheme();

  let chartComponent = <ClinIntellSkeleton variant="rectangular" width="100%" height="23rem" />;
  if (!isLoading && comparisonOrgChartDataset && orgChartDataset) {
    const { combinedDatasets, filteredChartData, hasChartData } = processComparisonChartData(
      orgChartDataset,
      comparisonOrgChartDataset
    );

    if (!hasChartData) {
      return null;
    }

    chartComponent = (
      <ComparisonChart
        chartDataSets={filteredChartData}
        chartPeriodType="Month"
        dataset={combinedDatasets}
        chartTitle="DRG Mix"
        metric="hcupcmi"
      />
    );
  }

  let hasPositiveCMIDelta = true;
  if (drgAnalysis && drgAnalysis.entity2.hcupCmi > drgAnalysis.entity1.hcupCmi) {
    hasPositiveCMIDelta = false;
  }

  let drgImpactComponent;
  if (comparisonOrgId === orgId) {
    drgImpactComponent = (
      <Box mt={2}>
        <Box component="span" color={theme.palette.red[500]}>
          The selected entities are the same, thus there is no impact data to display.
        </Box>
      </Box>
    );
  } else if (drgAnalysis) {
    if (drgAnalysis.table.length === 0) {
      drgImpactComponent = (
        <Box mt={2}>
          <Box component="span" color={theme.palette.red[500]}>
            No impact data to analyze based on the entities or time period selected. Please verify that you are not
            comparing the same entity (they could have different names).
          </Box>
        </Box>
      );
    } else if (noSummaryData) {
      drgImpactComponent = null;
    } else {
      drgImpactComponent = (
        <DRGImpactTables
          isLoading={isLoadingSummaryRecords}
          hasPositiveDelta={hasPositiveCMIDelta}
          tableRows={drgAnalysis.table}
          referenceLabel="My Entity"
          comparerLabel="Comparer"
          changeLabel="Difference, Share"
        />
      );
    }
  }

  return (
    <Box>
      {chartComponent}
      {drgImpactComponent}
    </Box>
  );
};

export default DRGMixResults;
