import React from 'react';
import CIModal, { CIModalButtonWrapper } from '@clinintell/components/modal/CIModal';
import { Box, Typography } from '@mui/material';
import Button from '@clinintell/components/button/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ReportType } from '@clinintell/containers/reports/types/actionTypes';
import { useReportsContext } from '../../sections/context/ReportContext';

export type ReportDataProps = {
  groupId: number;
  hospitalId: number;
};

type CIReportsModalProps = {
  reportLabel: string;
  reportModalTitle: string;
  reportType: ReportType;
  modalBtnLabel?: string;
  closeCallback?: () => void;
  modalButtonOverride?: React.ReactElement;
  modalActionButtonOverride?: React.ReactElement;
};

// Check that the button is enabled based on the type of report we are seeing.
// If any of the following the button is enabled based on type.
const isReportTypeEnableRequirementMeet = (reportType: ReportType): boolean => {
  return reportType === ReportType.Executive || reportType === ReportType.Training || reportType === ReportType.System;
};

const CIReportsModal: React.FC<CIReportsModalProps> = ({
  reportLabel,
  reportModalTitle,
  reportType,
  modalBtnLabel = 'View Report',
  closeCallback,
  modalButtonOverride,
  modalActionButtonOverride,
  children
}) => {
  const {
    hospitalId,
    groupId,
    reportResetFilters,
    reportSubmitHandler,
    isCreating,
    isCreatingExecutiveReport,
    reportBtnDisabled
  } = useReportsContext();

  return (
    <CIModal
      modalBtn={modalButtonOverride || <Button label={reportLabel} type={'primary'} variant={'text'} />}
      onClose={() => {
        reportResetFilters();
        closeCallback && closeCallback();
      }}
    >
      <CIModal.Header>
        <CIModalButtonWrapper
          clickBtnHandler={() => {
            reportResetFilters();
            closeCallback && closeCallback();
          }}
        >
          <Button
            label="Back to Reports"
            leftAdornment={<ChevronLeftIcon sx={{ mt: 0.5 }} />}
            type={'primary'}
            variant="text"
          />
        </CIModalButtonWrapper>
      </CIModal.Header>
      <CIModal.Content>
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: '.25fr .75fr'
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              paddingBottom: '25px'
            }}
            variant="h6"
          >
            {reportModalTitle}
          </Typography>
          {children}
        </Box>
      </CIModal.Content>
      <CIModal.Footer>
        {modalActionButtonOverride || (
          <Button
            label={modalBtnLabel}
            type={`primary`}
            variant={`contained`}
            onClick={(): void => {
              reportSubmitHandler(reportType, hospitalId, groupId);
            }}
            isBusy={isCreating || isCreatingExecutiveReport}
            disabled={isReportTypeEnableRequirementMeet(reportType) ? false : reportBtnDisabled}
          />
        )}
      </CIModal.Footer>
    </CIModal>
  );
};

export default CIReportsModal;
