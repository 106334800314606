import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const ResultSummaryBox: React.FC = ({ children }) => {
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <Box width="100%" textAlign="center" marginBottom={1}>
        <Box
          fontWeight={500}
          marginBottom={1}
          component="span"
          color={theme.palette.blue.main}
          fontSize={theme.typography.subheading.fontSize}
        >
          Result Summary
        </Box>
      </Box>
      <Box minWidth="30rem" maxWidth={isMediumView ? '100%' : '45rem'} width="100" paddingX={1}>
        {children}
      </Box>
    </>
  );
};

export default ResultSummaryBox;
