import { useState } from 'react';
import { HistogramSavedFilter } from '@clinintell/api/apiSchemas';
import { RequiredTypeProperties } from '@clinintell/utils/typesAndInterfaces';

export type EntityDataType = RequiredTypeProperties<HistogramSavedFilter>;

type EntitySelectorReturnProps = {
  systemData: EntityDataType;
  hospitalData: EntityDataType;
  groupData: EntityDataType;
  physicianData: EntityDataType;
  systemFilterHandler: (systemId: number | null, systemName?: string) => void;
  hospitalFilterHandler: (hospiId: number | null, hospitalName?: string) => void;
  groupFilterHandler: (groupId: number | null, groupDisplayName?: string) => void;
  physicianGroupFilterHandler: (physId: number | null, physicianGroupName?: string) => void;
};

const useReportSchedulerEntitySelector = (): EntitySelectorReturnProps => {
  const [systemData, setSystemData] = useState<EntityDataType>({ id: -1, name: '' });
  const [hospitalData, setHospitalData] = useState<EntityDataType>({ id: -1, name: '' });
  const [groupData, setGroupData] = useState<EntityDataType>({ id: -1, name: '' });
  const [physicianData, setPhysicianData] = useState<EntityDataType>({ id: -1, name: '' });

  const systemFilterHandler = (systemId: number | null, systemName?: string) => {
    if (systemId && systemId === systemData.id) return;
    setSystemData({ id: Number(systemId), name: systemName ? systemName : '' });
    hospitalFilterHandler(-1);
    groupFilterHandler(-1);
  };

  const hospitalFilterHandler = (hospId: number | null, hospitalDisplayName?: string) => {
    if (hospId && hospId === hospitalData.id) return;
    setHospitalData({ id: hospId as number, name: hospitalDisplayName ? hospitalDisplayName : '' });
    if (groupData.id !== -1) {
      setGroupData({ id: -1, name: '' });
    }
  };

  const groupFilterHandler = (groupId: number | null, groupDisplayName?: string) => {
    if (groupId && groupId === groupData.id) return;
    setGroupData({ id: groupId as number, name: groupDisplayName ? groupDisplayName : '' });
    if (physicianData.id !== -1) {
      setPhysicianData({ id: -1, name: '' });
    }
  };

  const physicianGroupFilterHandler = (physId: number | null, physicianGroupName?: string) => {
    setPhysicianData({ id: physId as number, name: physicianGroupName ? physicianGroupName : '' });
  };

  return {
    systemData,
    hospitalData,
    groupData,
    physicianData,
    systemFilterHandler,
    hospitalFilterHandler,
    groupFilterHandler,
    physicianGroupFilterHandler
  };
};

export default useReportSchedulerEntitySelector;
