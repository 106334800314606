import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { FundamentalSpecialty, NodeTypeIds, TreeJSON } from './orgTree';
import { CombinedState } from './store';

export type FiltersJSON = {
  bedSizeType: number[];
  communityType: number[];
  dischargeType: number[];
  hospitalType: number[];
  regionType: number[];
};

export type AdvancedFilters = {
  timePeriodType: number | undefined;
  minimumDischargeCount: number | undefined;
};

export type SelectedFiltersJSON = FiltersJSON & AdvancedFilters;

export type Specialty = {
  id: number;
  name: string;
};

export type FilterState = {
  orgId: number | undefined;
  orgName: string;
  filters: SelectedFiltersJSON;
  callouts: Callout[];
  metrics: string[];
  conditions: string[];
  specialties: Specialty[];
};

type EntityDetails = {
  id: number;
  name: string;
  nodeTypeId: number;
};

export type Callout = EntityDetails & {
  parent: EntityDetails;
};

// TODO -- figure out how to handle this dynamically
export type MetricValueTypes = 'Current2' | 'OtoE';

export type SavedFilter = {
  id: number;
  name: string;
};

type MedicareHistogramsAsyncStatus =
  | 'fetchingFilters'
  | 'fetchingFilter'
  | 'savingFilters'
  | 'deletingFilter'
  | 'puttingFilter'
  | 'renamingFilter'
  | 'duplicatingFilter'
  | 'idle';

export const MAX_SAVED_FILTERS = 10;
export const MAX_FILTER_NAME_LENGTH = 100;

export type MedicareComparisonState = {
  orgId: number | undefined;
  orgName: string;
  filters: SelectedFiltersJSON;
  callouts: Callout[];
  metrics: string[];
  conditions: string[];
  specialties: Specialty[];
  metricValueType: MetricValueTypes;
  savedFilters: SavedFilter[];
  selectedSavedFilter?: number;
  asyncStatus: MedicareHistogramsAsyncStatus;
};

/* Update entity actions */
const UPDATE_ENTITY = 'UPDATE_ENTITY';

type UpdateEntityPayload = {
  orgId: number;
  orgName: string;
  specialties?: Specialty[];
  filters: SelectedFiltersJSON;
};

interface UpdateEntity {
  type: typeof UPDATE_ENTITY;
  payload: UpdateEntityPayload;
}

/* Update metric value type */
const UPDATE_METRIC_VALUE_TYPE = 'UPDATE_METRIC_VALUE_TYPE';

interface UpdateMetricValueType {
  type: typeof UPDATE_METRIC_VALUE_TYPE;
  payload: MetricValueTypes;
}

/* Update Filters */
const UPDATE_FILTERS = 'UPDATE_FILTERS';

type UpdateFiltersPayload = FilterState & {
  resetSelectedFilter?: boolean;
};

interface UpdateFilters {
  type: typeof UPDATE_FILTERS;
  payload: UpdateFiltersPayload;
}

/* Save Filters */
const SAVE_FILTERS = 'SAVE_FILTERS';
const SAVE_FILTERS_BEGIN = 'SAVE_FILTERS_BEGIN';
const SAVE_FILTERS_SUCCESSFUL = 'SAVE_FILTERS_SUCCESSFUL';
const SAVE_FILTERS_FAILED = 'SAVE_FILTERS_FAILED';

type SaveFiltersArgs = Omit<FilterState, 'orgName' | 'orgId'> & {
  filterName: string;
  userId: number;
  hospitalOrgId: number;
  onComplete: (errorMessage?: string) => void;
};

interface SaveFilters {
  type: typeof SAVE_FILTERS;
  payload: SaveFiltersArgs;
}

interface SaveFiltersBegin {
  type: typeof SAVE_FILTERS_BEGIN;
}

interface SaveFiltersSuccessful {
  type: typeof SAVE_FILTERS_SUCCESSFUL;
  payload: SavedFilter;
}

interface SaveFiltersFailed {
  type: typeof SAVE_FILTERS_FAILED;
}

/* Get Saved Filters */
const GET_SAVED_FILTERS = 'GET_SAVED_FILTERS';
const GET_SAVED_FILTERS_BEGIN = 'GET_SAVED_FILTERS_BEGIN';
const GET_SAVED_FILTERS_SUCCESSFUL = 'GET_SAVED_FILTERS_SUCCESSFUL';
const GET_SAVED_FILTERS_FAILED = 'GET_SAVED_FILTERS_FAILED';

interface GetSavedFiltersArgs {
  userId: number;
  onComplete: (errorMessage: string | PromiseLike<string>) => void;
}

interface GetSavedFilters {
  type: typeof GET_SAVED_FILTERS;
  payload: GetSavedFiltersArgs;
}

interface GetSavedFiltersBegin {
  type: typeof GET_SAVED_FILTERS_BEGIN;
}

interface GetSavedFiltersSuccessful {
  type: typeof GET_SAVED_FILTERS_SUCCESSFUL;
  payload: SavedFilter[];
}

interface GetSavedFiltersFailed {
  type: typeof GET_SAVED_FILTERS_FAILED;
  payload: string;
}

/* Get Saved Filter */
const GET_SAVED_FILTER = 'GET_SAVED_FILTER';
const GET_SAVED_FILTER_BEGIN = 'GET_SAVED_FILTER_BEGIN';
const GET_SAVED_FILTER_SUCCESSFUL = 'GET_SAVED_FILTER_SUCCESSFUL';
const GET_SAVED_FILTER_FAILED = 'GET_SAVED_FILTER_FAILED';

type GetSavedFilterPayload = {
  id: number;
  userId: number;
  onComplete?: (errorMessage: string) => void;
};

interface GetSavedFilter {
  type: typeof GET_SAVED_FILTER;
  payload: GetSavedFilterPayload;
}

interface GetSavedFilterBegin {
  type: typeof GET_SAVED_FILTER_BEGIN;
}

export type SavedFilterPayload = Omit<SavedFilterJSON, 'fundamentalSpecialties'> & {
  fundamentalSpecialties: FundamentalSpecialty[];
};

interface GetSavedFilterSuccessful {
  type: typeof GET_SAVED_FILTER_SUCCESSFUL;
  payload: SavedFilterPayload;
}

interface GetSavedFilterFailed {
  type: typeof GET_SAVED_FILTER_FAILED;
}

/* Rename Filter */
const RENAME_FILTER = 'RENAME_FILTER';
const RENAME_FILTER_BEGIN = 'RENAME_FILTER_BEGIN';
const RENAME_FILTER_SUCCESSFUL = 'RENAME_FILTER_SUCCESSFUL';
const RENAME_FILTER_FAILED = 'RENAME_FILTER_FAILED';

type RenameFilterPayload = {
  filterId: number;
  name: string;
  userId: number;
  onComplete?: (errorMessage: string) => void;
};

interface RenameFilter {
  type: typeof RENAME_FILTER;
  payload: RenameFilterPayload;
}

interface RenameFilterBegin {
  type: typeof RENAME_FILTER_BEGIN;
}

interface RenameFilterSuccessful {
  type: typeof RENAME_FILTER_SUCCESSFUL;
  payload: RenameFilterPayload;
}

interface RenameFilterFailed {
  type: typeof RENAME_FILTER_FAILED;
}

/* Delete Filter */
const DELETE_FILTER = 'DELETE_FILTER';
const DELETE_FILTER_START = 'DELETE_FILTER_START';
const DELETE_FILTER_SUCCESSFUL = 'DELETE_FILTER_SUCCESSFUL';
const DELETE_FILTER_FAILED = 'DELETE_FILTER_FAILED';

type DeleteFilterArgs = {
  id: number;
  userId: number;
  onComplete?: (errorMessage: string) => void;
};

type DeleteFilterPayload = {
  id: number;
};

interface DeleteFilter {
  type: typeof DELETE_FILTER;
  payload: DeleteFilterArgs;
}

interface DeleteFilterStart {
  type: typeof DELETE_FILTER_START;
}

interface DeleteFilterSuccessful {
  type: typeof DELETE_FILTER_SUCCESSFUL;
  payload: DeleteFilterPayload;
}

interface DeleteFilterFailed {
  type: typeof DELETE_FILTER_FAILED;
  payload: string;
}

/* PUT Filter */
const PUT_FILTER = 'PUT_FILTER';
const PUT_FILTER_BEGIN = 'PUT_FILTER_BEGIN';
const PUT_FILTER_SUCCESSFUL = 'PUT_FILTER_SUCCESSFUL';
const PUT_FILTER_FAILED = 'PUT_FILTER_FAILED';

type PutFilterArgs = SaveFiltersArgs & {
  filterId: number;
};

interface PutFilter {
  type: typeof PUT_FILTER;
  payload: PutFilterArgs;
}

interface PutFilterBegin {
  type: typeof PUT_FILTER_BEGIN;
}

interface PutFilterSuccessful {
  type: typeof PUT_FILTER_SUCCESSFUL;
  payload: number;
}

interface PutFilterFailed {
  type: typeof PUT_FILTER_FAILED;
  payload: string;
}

/* Duplicate Filter */
const DUPLICATE_FILTER = 'DUPLICATE_FILTER';
const DUPLICATE_FILTER_BEGIN = 'DUPLICATE_FILTER_BEGIN';
const DUPLICATE_FILTER_SUCCESSFUL = 'DUPLICATE_FILTER_SUCCESSFUL';
const DUPLICATE_FILTER_FAILED = 'DUPLICATE_FILTER_FAILED';

type DuplicateFilterArgs = {
  filterId: number;
  userId: number;
  onComplete?: (errorMessage: string) => void;
};

type DuplicateFilterPayload = {
  id: number;
  name: string;
};

interface DuplicateFilter {
  type: typeof DUPLICATE_FILTER;
  payload: DuplicateFilterArgs;
}

interface DuplicateFilterBegin {
  type: typeof DUPLICATE_FILTER_BEGIN;
}

interface DuplicateFilterSuccessful {
  type: typeof DUPLICATE_FILTER_SUCCESSFUL;
  payload: DuplicateFilterPayload;
}

interface DuplicateFilterFailed {
  type: typeof DUPLICATE_FILTER_FAILED;
}

export type MedicareComparisonActionTypes =
  | UpdateEntity
  | UpdateFilters
  | UpdateMetricValueType
  | SaveFilters
  | SaveFiltersBegin
  | SaveFiltersSuccessful
  | SaveFiltersFailed
  | GetSavedFilters
  | GetSavedFiltersBegin
  | GetSavedFiltersSuccessful
  | GetSavedFiltersFailed
  | GetSavedFilter
  | GetSavedFilterBegin
  | GetSavedFilterSuccessful
  | GetSavedFilterFailed
  | RenameFilter
  | RenameFilterBegin
  | RenameFilterSuccessful
  | RenameFilterFailed
  | DeleteFilter
  | DeleteFilterStart
  | DeleteFilterSuccessful
  | DeleteFilterFailed
  | PutFilter
  | PutFilterBegin
  | PutFilterSuccessful
  | PutFilterFailed
  | DuplicateFilter
  | DuplicateFilterSuccessful
  | DuplicateFilterFailed
  | DuplicateFilterBegin;

export const updateEntity = (args: UpdateEntityPayload): MedicareComparisonActionTypes => ({
  type: 'UPDATE_ENTITY',
  payload: args
});

export const updateFilters = (args: UpdateFiltersPayload): MedicareComparisonActionTypes => ({
  type: 'UPDATE_FILTERS',
  payload: args
});

export const updateMetricValueType = (args: MetricValueTypes): MedicareComparisonActionTypes => ({
  type: 'UPDATE_METRIC_VALUE_TYPE',
  payload: args
});

export const fetchSavedFilters = (args: GetSavedFiltersArgs): MedicareComparisonActionTypes => ({
  type: 'GET_SAVED_FILTERS',
  payload: args
});

export const fetchSavedFilter = (args: GetSavedFilterPayload): MedicareComparisonActionTypes => ({
  type: 'GET_SAVED_FILTER',
  payload: args
});

export const saveFilters = (args: SaveFiltersArgs): MedicareComparisonActionTypes => ({
  type: 'SAVE_FILTERS',
  payload: args
});

export const deleteFilter = (args: DeleteFilterArgs): MedicareComparisonActionTypes => ({
  type: 'DELETE_FILTER',
  payload: args
});

export const putFilter = (args: PutFilterArgs): MedicareComparisonActionTypes => ({
  type: 'PUT_FILTER',
  payload: args
});

export const renameFilter = (args: RenameFilterPayload): MedicareComparisonActionTypes => ({
  type: 'RENAME_FILTER',
  payload: args
});

export const duplicateFilter = (args: DuplicateFilterArgs): MedicareComparisonActionTypes => ({
  type: 'DUPLICATE_FILTER',
  payload: args
});

export const DEFAULT_MEDICARE_HISTOGRAM_METRICS = ['Cmi', 'DocScore'];

export const initialState: MedicareComparisonState = {
  orgId: undefined,
  orgName: '',
  filters: {
    timePeriodType: undefined,
    minimumDischargeCount: undefined,
    bedSizeType: [],
    communityType: [],
    dischargeType: [],
    hospitalType: [],
    regionType: []
  },
  callouts: [],
  metrics: DEFAULT_MEDICARE_HISTOGRAM_METRICS,
  conditions: [],
  specialties: [],
  metricValueType: 'Current2',
  savedFilters: [],
  selectedSavedFilter: undefined,
  asyncStatus: 'idle'
};

// Reducer
const reducer = (
  state: MedicareComparisonState = initialState,
  action: MedicareComparisonActionTypes
): MedicareComparisonState => {
  switch (action.type) {
    case 'UPDATE_ENTITY': {
      return {
        ...state,
        ...action.payload,
        specialties: action.payload.specialties ? [...action.payload.specialties] : [],
        filters: { ...action.payload.filters }
      };
    }
    case 'UPDATE_FILTERS': {
      const {
        orgId,
        orgName,
        specialties,
        callouts,
        metrics,
        conditions,
        filters,
        resetSelectedFilter
      } = action.payload;
      const {
        bedSizeType,
        dischargeType,
        communityType,
        regionType,
        hospitalType,
        minimumDischargeCount,
        timePeriodType
      } = filters;

      return {
        ...state,
        orgId,
        orgName,
        specialties: [...specialties],
        callouts: [...callouts],
        metrics: [...metrics],
        conditions: [...conditions],
        ...(resetSelectedFilter === true ? { selectedSavedFilter: undefined } : null),
        filters: {
          ...state.filters,
          bedSizeType: [...bedSizeType],
          dischargeType: [...dischargeType],
          communityType: [...communityType],
          regionType: [...regionType],
          hospitalType: [...hospitalType],
          minimumDischargeCount,
          timePeriodType
        }
      };
    }
    case 'UPDATE_METRIC_VALUE_TYPE': {
      return {
        ...state,
        metricValueType: action.payload
      };
    }
    case 'SAVE_FILTERS_BEGIN': {
      return {
        ...state,
        asyncStatus: 'savingFilters'
      };
    }
    case 'SAVE_FILTERS_SUCCESSFUL': {
      return {
        ...state,
        savedFilters: [...state.savedFilters, { ...action.payload }],
        selectedSavedFilter: action.payload.id,
        asyncStatus: 'idle'
      };
    }
    case 'SAVE_FILTERS_FAILED': {
      return {
        ...state,
        asyncStatus: 'idle'
      };
    }
    case 'GET_SAVED_FILTERS_BEGIN': {
      return {
        ...state,
        asyncStatus: 'fetchingFilters'
      };
    }
    case 'GET_SAVED_FILTERS_SUCCESSFUL': {
      return {
        ...state,
        savedFilters: [...action.payload],
        selectedSavedFilter: undefined,
        asyncStatus: 'idle'
      };
    }
    case 'GET_SAVED_FILTERS_FAILED': {
      return {
        ...state,
        asyncStatus: 'idle'
      };
    }
    case 'GET_SAVED_FILTER_BEGIN': {
      return {
        ...state,
        asyncStatus: 'fetchingFilter'
      };
    }
    case 'GET_SAVED_FILTER_SUCCESSFUL': {
      const {
        hospitalOrgId,
        hospitalName,
        fundamentalSpecialties,
        peerHospitals,
        metricTypes,
        conditionNames,
        dischargeTypes,
        regionTypes,
        bedSizeTypes,
        communityTypes,
        hospitalTypes,
        timePeriodTypeId,
        minimumDischargeCount,
        filterId
      } = action.payload;

      return {
        ...state,
        asyncStatus: 'idle',
        orgId: hospitalOrgId,
        orgName: hospitalName,
        specialties: [
          ...fundamentalSpecialties.map<Specialty>(specialty => ({
            id: specialty.id,
            name: specialty.fundamentalSpecialtyName
          }))
        ],
        callouts: peerHospitals.map(peerHospital => ({
          id: peerHospital.hospitalOrgId,
          name: peerHospital.name,
          nodeTypeId: NodeTypeIds['Hospital'],
          parent: {
            id: -1,
            name: '',
            nodeTypeId: NodeTypeIds['SystemView']
          }
        })),
        metrics: [...metricTypes],
        conditions: [...conditionNames],
        selectedSavedFilter: filterId,
        filters: {
          ...state.filters,
          dischargeType: [...dischargeTypes],
          regionType: [...regionTypes],
          bedSizeType: [...bedSizeTypes],
          communityType: [...communityTypes],
          hospitalType: [...hospitalTypes],
          timePeriodType: timePeriodTypeId,
          minimumDischargeCount
        }
      };
    }
    case 'GET_SAVED_FILTER_FAILED': {
      return {
        ...state,
        asyncStatus: 'idle'
      };
    }
    case 'RENAME_FILTER_BEGIN': {
      return {
        ...state,
        asyncStatus: 'renamingFilter'
      };
    }
    case 'RENAME_FILTER_SUCCESSFUL': {
      const renamedFilterIndex = state.savedFilters.findIndex(
        savedFilter => savedFilter.id === action.payload.filterId
      );
      if (renamedFilterIndex > -1) {
        state.savedFilters[renamedFilterIndex].name = action.payload.name;
      }

      return {
        ...state,
        asyncStatus: 'idle',
        savedFilters: [...state.savedFilters]
      };
    }
    case 'DELETE_FILTER_START': {
      return {
        ...state,
        asyncStatus: 'deletingFilter'
      };
    }
    case 'DELETE_FILTER_SUCCESSFUL': {
      return {
        ...state,
        asyncStatus: 'idle',
        selectedSavedFilter: state.selectedSavedFilter === action.payload.id ? undefined : state.selectedSavedFilter,
        savedFilters: [...state.savedFilters.filter(savedFilter => savedFilter.id !== action.payload.id)]
      };
    }
    case 'DELETE_FILTER_FAILED': {
      return {
        ...state,
        asyncStatus: 'idle'
      };
    }
    case 'PUT_FILTER_BEGIN': {
      return {
        ...state,
        asyncStatus: 'puttingFilter'
      };
    }
    case 'PUT_FILTER_SUCCESSFUL': {
      return {
        ...state,
        asyncStatus: 'idle',
        selectedSavedFilter: action.payload
      };
    }
    case 'PUT_FILTER_FAILED': {
      return {
        ...state,
        asyncStatus: 'idle'
      };
    }
    case 'DUPLICATE_FILTER_BEGIN': {
      return {
        ...state,
        asyncStatus: 'duplicatingFilter'
      };
    }
    case 'DUPLICATE_FILTER_SUCCESSFUL': {
      return {
        ...state,
        asyncStatus: 'idle',
        savedFilters: [...state.savedFilters, { id: action.payload.id, name: action.payload.name }]
      };
    }
    case 'DUPLICATE_FILTER_FAILED': {
      return {
        ...state,
        asyncStatus: 'idle'
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

type FetchedFiltersJSON = {
  filters: SavedFilter[];
};

export function* fetchMedicareHistogramSavedFilters({ payload }: GetSavedFilters): SagaIterator {
  let errorMessage = '';

  try {
    yield put({
      type: GET_SAVED_FILTERS_BEGIN
    });

    const result: AsyncOutput<FetchedFiltersJSON> = yield call(ApplicationAPI.get, {
      endpoint: `settings/${payload.userId}/histogram/filters`
    });

    if (result.data) {
      yield put({ type: 'GET_SAVED_FILTERS_SUCCESSFUL', payload: result.data.filters });
    } else if (result.status === 204) {
      yield put({ type: 'GET_SAVED_FILTERS_SUCCESSFUL', payload: [] });
    } else if (result.error) {
      errorMessage = `Error fetching saved filters - ${result.error}`;
      yield put({ type: 'GET_SAVED_FILTERS_FAILED', payload: result.error });
    }
  } catch (exception) {
    errorMessage = `Error fetching saved filters - ${exception}`;
    yield put({ type: 'GET_SAVED_FILTERS_FAILED', payload: exception });
  }

  if (payload.onComplete) {
    payload.onComplete(errorMessage);
  }
}

type PostFiltersJSON = {
  name: string;
  hospitalOrgId: number;
  minimumDischargeCount: number;
  timePeriodTypeId: number;
  fundamentalSpecialties: number[];
  peerHospitals: number[];
  metricTypes: string[];
  conditionNames: string[];
  bedSizeTypes: number[];
  communityTypes: number[];
  dischargeTypes: number[];
  hospitalTypes: number[];
  regionTypes: number[];
};

type PeerHospitalDetails = {
  hospitalOrgId: number;
  name: string;
};

type SavedFilterJSON = Omit<PostFiltersJSON, 'peerHospitals'> & {
  hospitalName: string;
  filterId: number;
  peerHospitals: PeerHospitalDetails[];
};

export function* fetchMedicareHistogramSavedFilter({ payload }: GetSavedFilter): SagaIterator {
  let errorMessage = '';

  try {
    yield put({
      type: GET_SAVED_FILTER_BEGIN
    });

    const filterResult: AsyncOutput<SavedFilterJSON> = yield call(ApplicationAPI.get, {
      endpoint: `settings/${payload.userId}/histogram/filters/${payload.id}`
    });

    if (filterResult.error) {
      errorMessage = `Error fetching saved filter - ${filterResult.error}`;
      yield put({
        type: GET_SAVED_FILTER_FAILED
      });
    } else if (filterResult.data) {
      // fetch org details for filter's org ID - can map specialty information to it
      const orgResult: AsyncOutput<TreeJSON> = yield call(ApplicationAPI.get, {
        endpoint: `org/${filterResult.data.hospitalOrgId}`,
        useSAF: true
      });

      if (orgResult.data) {
        const filteredSpecialties = orgResult.data.fundamentalSpecialties.filter(specialty =>
          filterResult.data?.fundamentalSpecialties.find(filterSpec => specialty.id === filterSpec)
        );

        yield put({
          type: GET_SAVED_FILTER_SUCCESSFUL,
          payload: { ...filterResult.data, fundamentalSpecialties: filteredSpecialties, filterId: payload.id }
        });
      } else if (orgResult.error) {
        errorMessage = `Error fetching saved filter specialties - ${filterResult.error}`;
        yield put({
          type: GET_SAVED_FILTER_FAILED
        });
      }
    }
  } catch (exception) {
    errorMessage = `Error fetching saved filter - ${exception}`;
    yield put({ type: GET_SAVED_FILTER_FAILED });
  }

  if (payload.onComplete) {
    payload.onComplete(errorMessage);
  }
}

const mapFilterToDTO = (filterState: SaveFiltersArgs): PostFiltersJSON => ({
  name: filterState.filterName,
  hospitalOrgId: filterState.hospitalOrgId,
  minimumDischargeCount: filterState.filters.minimumDischargeCount || 0,
  timePeriodTypeId: filterState.filters.timePeriodType || 0,
  fundamentalSpecialties: filterState.specialties.map(specialty => specialty.id),
  peerHospitals: filterState.callouts.map(callout => callout.id),
  metricTypes: filterState.metrics,
  conditionNames: filterState.conditions,
  bedSizeTypes: filterState.filters.bedSizeType,
  communityTypes: filterState.filters.communityType,
  dischargeTypes: filterState.filters.dischargeType,
  hospitalTypes: filterState.filters.hospitalType,
  regionTypes: filterState.filters.regionType
});

export function* postFilters({ payload }: SaveFilters): SagaIterator {
  let errorMessage = '';

  try {
    yield put({
      type: 'SAVE_FILTERS_BEGIN'
    });

    const postFiltersJSON = mapFilterToDTO(payload);

    const result: AsyncOutput<SavedFilter> = yield call(ApplicationAPI.post, {
      endpoint: `settings/${payload.userId}/histogram/filters`,
      data: postFiltersJSON
    });

    if (result.status === 201 && result.data) {
      yield put({
        type: 'SAVE_FILTERS_SUCCESSFUL',
        payload: result.data
      });
    } else if (result.error) {
      yield put({
        type: 'SAVE_FILTERS_FAILED'
      });

      errorMessage = `Error fetching saved filters - ${result.error}`;
    }
  } catch (exception) {
    yield put({
      type: 'SAVE_FILTERS_FAILED'
    });

    errorMessage = `Error fetching saved filters - ${exception}`;
  }

  if (payload.onComplete) {
    payload.onComplete(errorMessage);
  }
}

export function* deleteFilterAction({ payload }: DeleteFilter): SagaIterator {
  let errorMessage = '';

  try {
    yield put({ type: 'DELETE_FILTER_START' });

    const result = yield call(ApplicationAPI.delete, {
      endpoint: `settings/${payload.userId}/histogram/filters/${payload.id}`
    });

    if (result.status === 200) {
      yield put({ type: 'DELETE_FILTER_SUCCESSFUL', payload: { id: payload.id } });
    } else if (result.error) {
      errorMessage = `Error deleting filter - ${result.error}`;
      yield put({ type: 'DELETE_FILTER_FAILED' });
    }
  } catch (exception) {
    errorMessage = `Error deleting filter - ${exception}`;
    yield put({ type: 'DELETE_FILTER_FAILED' });
  }

  if (payload.onComplete) {
    payload.onComplete(errorMessage);
  }
}

export function* putFilterAction({ payload }: PutFilter): SagaIterator {
  let errorMessage = '';

  try {
    yield put({ type: 'PUT_FILTER_BEGIN' });

    const putFilterJSON = mapFilterToDTO(payload);

    const result = yield call(ApplicationAPI.put, {
      endpoint: `settings/${payload.userId}/histogram/filters/${payload.filterId}`,
      data: putFilterJSON
    });

    if (result.status === 200) {
      yield put({ type: 'PUT_FILTER_SUCCESSFUL', payload: payload.filterId });
    } else if (result.error) {
      yield put({ type: 'PUT_FILTER_FAILED' });

      errorMessage = `Error updating saved filter - ${result.error}`;
    }
  } catch (exception) {
    yield put({ type: 'PUT_FILTER_FAILED' });

    errorMessage = `Error updating saved filter - ${exception}`;
  }

  if (payload.onComplete) {
    payload.onComplete(errorMessage);
  }
}

export function* renameFilterAction({ payload }: RenameFilter): SagaIterator {
  let errorMessage = '';

  try {
    yield put({ type: 'RENAME_FILTER_BEGIN' });

    const result = yield call(ApplicationAPI.patch, {
      endpoint: `settings/${payload.userId}/histogram/filters/${payload.filterId}`,
      data: {
        name: payload.name
      }
    });

    if (result.status === 200) {
      yield put({ type: 'RENAME_FILTER_SUCCESSFUL', payload });
    } else if (result.error) {
      errorMessage = `Error renaming filter - ${result.error}`;
      yield put({ type: 'RENAME_FILTER_FAILED' });
    }
  } catch (exception) {
    errorMessage = `Error renaming filter - ${exception}`;
    yield put({ type: 'RENAME_FILTER_FAILED' });
  }

  if (payload.onComplete) {
    payload.onComplete(errorMessage);
  }
}

export const buildDuplicateFilterName = (sourceFilter: string, filterList: string[]): string => {
  let duplicateNameAttempt = `${sourceFilter} (1)`;
  while (filterList.includes(duplicateNameAttempt) && duplicateNameAttempt.length <= MAX_FILTER_NAME_LENGTH) {
    duplicateNameAttempt += '(1)';
  }

  return duplicateNameAttempt.substring(0, MAX_FILTER_NAME_LENGTH);
};

export const medicareComparisonSelector = (state: CombinedState): MedicareComparisonState => state.medicareComparison;
export function* duplicateFilterAction({ payload }: DuplicateFilter): SagaIterator {
  let errorMessage = '';

  try {
    yield put({ type: 'DUPLICATE_FILTER_BEGIN' });

    const { savedFilters }: MedicareComparisonState = yield select(medicareComparisonSelector);
    const selectedFilter = savedFilters.find(savedFilter => savedFilter.id === payload.filterId);

    if (!selectedFilter) {
      yield put({ type: 'DUPLICATE_FILTER_FAILED' });
      return;
    }

    const duplicateName = buildDuplicateFilterName(
      selectedFilter.name,
      savedFilters.map(savedFilter => savedFilter.name)
    );
    const result = yield call(ApplicationAPI.post, {
      endpoint: `settings/${payload.userId}/histogram/filters/duplicate?sourceId=${payload.filterId}&newName=${duplicateName}`
    });

    if (result.data) {
      yield put({ type: 'DUPLICATE_FILTER_SUCCESSFUL', payload: { id: result.data.id, name: duplicateName } });
    } else if (result.error) {
      errorMessage = `Error duplicating filter - ${result.error}`;
      yield put({ type: 'DUPLICATE_FILTER_FAILED' });
    }
  } catch (exception) {
    errorMessage = `Error duplicating filter - ${exception}`;
    yield put({ type: 'DELETE_FILTER_FAILED' });
  }

  if (payload.onComplete) {
    payload.onComplete(errorMessage);
  }
}

export function* fetchMedicareHistogramSavedFiltersSaga(): SagaIterator {
  yield takeLeading('GET_SAVED_FILTERS', fetchMedicareHistogramSavedFilters);
}

export function* fetchMedicareHistogramSavedFilterSaga(): SagaIterator {
  yield takeLatest('GET_SAVED_FILTER', fetchMedicareHistogramSavedFilter);
}

export function* saveMedicareHistogramFilterSaga(): SagaIterator {
  yield takeLeading('SAVE_FILTERS', postFilters);
}

export function* deleteMedicareHistogramSavedFilterSaga(): SagaIterator {
  yield takeLeading('DELETE_FILTER', deleteFilterAction);
}

export function* putMedicareHistogramSavedFilterSaga(): SagaIterator {
  yield takeLeading('PUT_FILTER', putFilterAction);
}

export function* renameMedicareHistogramSavedFilterSaga(): SagaIterator {
  yield takeLeading('RENAME_FILTER', renameFilterAction);
}

export function* duplicateMedicareHistogramSavedFilterSaga(): SagaIterator {
  yield takeLeading('DUPLICATE_FILTER', duplicateFilterAction);
}
