import ClinIntellLogo from '@clinintell/components/icons/ClinIntellLogo';
import ClinIntellLogoAlt from '@clinintell/components/icons/ClinIntellLogoAlt';
import SideMenuExpand from '@clinintell/components/icons/SideMenuExpand';
import SideMenuCollapse from '@clinintell/components/icons/SideMenuCollapse';
import { Box, Fade } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import MenuNavigation from '../menuNavigation/MenuNavigation';
import { useMenuNavigationState } from '@clinintell/modules/store';
import MenuNavigationLink from '../menuNavigation/sections/MenuNavigationLink';
import MenuNavigationParent from '../menuNavigation/sections/MenuNavigationParent';
import ChildMenuNavigationLink from '../menuNavigation/sections/ChildMenuNavigationLink';
import useMenuActions from '../menuNavigation/logic/useMenuActions';
import { WindowSizeContext } from '@clinintell/modules/windowSizeContext';

export const EXPANDED_SIDEBAR_WIDTH = 240;
export const COLLAPSED_SIDEBAR_WIDTH = 70;

const Sidebar = () => {
  const [isHovering, setIsHovering] = useState(false);

  const { menuIsExpanded, menuIsTemporarilyExpanded } = useMenuNavigationState();
  const { width } = useContext(WindowSizeContext);

  const handleHover = (isHovering: boolean) => {
    setIsHovering(isHovering);
  };

  const {
    handleMenuExpansionClick,
    handleMenuParentClick,
    handleChildMenuLinkClick,
    handleMenuLinkClick
  } = useMenuActions();

  const expandMenu = menuIsExpanded || menuIsTemporarilyExpanded;

  useEffect(() => {
    if (width < 1450 && expandMenu) {
      handleMenuExpansionClick(false);
    }
    if (width > 1450 && !expandMenu) {
      handleMenuExpansionClick(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <Box
      sx={{
        height: '100vh',
        borderRight: '1px solid',
        borderColor: 'grey.200',

        width: expandMenu ? `${EXPANDED_SIDEBAR_WIDTH}px` : `${COLLAPSED_SIDEBAR_WIDTH}px`,
        backgroundColor: 'shade.white',
        position: 'fixed',
        transition: 'width 200ms ease-in',
        zIndex: 'sidebar'
      }}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <Box sx={{ pt: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
        {expandMenu ? (
          <Box sx={{ position: 'absolute', top: 30, right: 20 }}>
            <Tooltip content="Close" position="right">
              {/* Need this to trigger the tooltip, setting height to same as SVG for positioning */}
              <Box component="span" sx={{ display: 'block', height: '34.5px' }}>
                <SideMenuCollapse
                  role="button"
                  onClick={() => handleMenuExpansionClick(false)}
                  sx={{
                    fontSize: '2.4rem',
                    stroke: theme => theme.palette.grey[900],
                    opacity: isHovering ? 1 : 0,
                    transition: 'stroke, opacity 200ms linear',
                    '&:hover': {
                      cursor: 'pointer',
                      stroke: theme => theme.palette.blue.main
                    }
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        ) : null}

        {!expandMenu && isHovering ? (
          <Box sx={{ alignSelf: 'center' }}>
            {/* For some reason the top property is overriden even though it's not set anywhere else, unfortunately have to use important */}
            <Tooltip content="Open" position="right" sx={{ top: '-7px !important' }}>
              {/* Need this to trigger the tooltip, setting height to same as SVG for positioning, paddingBottom is here to keep menu icons in same position when collapsed */}
              <Box component="span" sx={{ display: 'block', height: '34.5px', paddingBottom: '13.5px' }}>
                <SideMenuExpand
                  role="button"
                  onClick={() => handleMenuExpansionClick(true)}
                  sx={{
                    fontSize: '2.4rem',
                    stroke: theme => theme.palette.grey[900],
                    transition: 'stroke 200ms linear',
                    '&:hover': {
                      cursor: 'pointer',
                      stroke: theme => theme.palette.blue.main
                    }
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        ) : (
          <ClinIntellLogo sx={{ alignSelf: 'center' }} />
        )}
        <Fade appear={false} in={expandMenu}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ClinIntellLogoAlt sx={{ alignSelf: 'center', mt: 2 }} />
          </Box>
        </Fade>
        <Box data-testid="sideMenuToggle" sx={{ mt: 6, height: '100%' }}>
          <MenuNavigation
            renderMenuNavigationLink={props =>
              props.children.length > 0 ? (
                <MenuNavigationParent
                  key={props.name}
                  onClick={handleMenuParentClick}
                  menuItem={props}
                  renderChildMenuNavigationLink={childMenuItem => (
                    <ChildMenuNavigationLink
                      key={childMenuItem.name}
                      onClick={handleChildMenuLinkClick}
                      childMenuItem={childMenuItem}
                    />
                  )}
                />
              ) : (
                <MenuNavigationLink key={props.name} onClick={handleMenuLinkClick} menuItem={props} />
              )
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
