import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { Timeline } from '@mui/icons-material';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import Footnotes from '@clinintell/components/Footnotes';
import useInitializeMetrics from '@clinintell/containers/metrics/logic/useInitializeMetrics';
import { useDashboard2 } from '@clinintell/modules/store';
import { TargetedConditionsTableType } from '@clinintell/modules/dashboard2';
import { IntegerCell, Table } from '@clinintell/components/table_v2';

type TableData = {
  conditionId: number;
  conditionDescription: string;
  historicalOtoCE: number;
  currentOtoCE: number;
  status: string;
};

type Props = {
  onGraphClick: (conditionId: number, conditionDescription: string) => void;
};

const TargetedConditionsWidgetTable: React.FC<Props> = ({ onGraphClick }) => {
  const theme = useTheme();
  const { targetedConditionsWidget } = useDashboard2();
  const { modalTableData } = targetedConditionsWidget;

  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const getStatusColor = (text: string): string => {
    if (text === 'Improving') return theme.palette.teal.light4;
    if (text === 'Declining') return theme.palette.red[500];
    return theme.palette.grey[500];
  };

  const formatStatusText = (text: string): string => {
    const words = text.split(' ');
    words.forEach((word, ix) => {
      words[ix] = word[0].toUpperCase() + word.substring(1);
    });
    return words.join(' ');
  };

  const {
    historicalStart,
    historicalEnd,
    currentStart,
    currentEnd,
    table
  } = modalTableData as TargetedConditionsTableType;

  const footnotes: string[] = [
    `Historical O/CE: ${format(new Date(historicalStart), 'MMMyy')}-${format(new Date(historicalEnd), 'MMMyy')}`,
    `Current O/CE: ${format(new Date(currentStart), 'MMMyy')}-${format(new Date(currentEnd), 'MMMyy')}`
  ];

  useInitializeMetrics();

  return (
    <Box>
      <Typography variant={'h5'} style={{ color: theme.palette.grey[700], fontWeight: 'bold' }}>
        Condition Performance Table
      </Typography>
      <Box mt={2}>
        <Table<TableData>
          rowData={table}
          renderColumns={({ renderColumn, renderCustomColumn }) => {
            return [
              renderColumn('conditionDescription', {
                cellRenderer: props => props.value || '',
                headerName: 'Clinical Condition',
                minWidth: 450,
                sortable: true,
                headerAlignment: 'left'
              }),
              renderCustomColumn({
                cellRenderer: props => (
                  <Box
                    onClick={(): void => {
                      if (!props.data) {
                        return;
                      }

                      return onGraphClick(props.data?.conditionId, props.data?.conditionDescription);
                    }}
                    onTouchEnd={(): void => {
                      if (!props.data) {
                        return;
                      }

                      return onGraphClick(props.data?.conditionId, props.data?.conditionDescription);
                    }}
                    sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '100%' }}
                  >
                    <Tooltip content="View Graph" position="right">
                      <Timeline />
                    </Tooltip>
                  </Box>
                )
              }),
              !isMobileView &&
                renderColumn('historicalOtoCE', {
                  headerName: 'Historical O/CE',
                  cellRenderer: props => <IntegerCell value={props.value || 0} postfix="%" />,
                  sortable: true,
                  cellStyle: {
                    justifyContent: 'center'
                  }
                }),
              !isMobileView &&
                renderColumn('currentOtoCE', {
                  headerName: 'Current O/CE',
                  sortable: true,
                  cellRenderer: props => <IntegerCell value={props.value || 0} postfix="%" />,
                  cellStyle: {
                    justifyContent: 'center'
                  }
                }),
              renderColumn('status', {
                headerName: 'Status',
                sortable: true,
                cellRenderer: props => (
                  <Box sx={{ fontWeight: 'bold', color: getStatusColor(props.value || '') }}>
                    {formatStatusText(props.value || '')}
                  </Box>
                ),
                cellStyle: {
                  justifyContent: 'center'
                },
                headerTooltip: (
                  <Box>
                    <p>Met: The Doc Score – Implementation is at or above Doc Score – Goal</p>
                    <p>Near: The Doc Score – Implementation is within the margin of error of Doc Score – Goal</p>
                    <p>Below: The Doc Score – Implementation is significantly lower than Doc Score – Goal</p>
                  </Box>
                )
              })
            ];
          }}
        />
        <Box mt={1} ml={-2}>
          <Footnotes footnotes={footnotes} />
        </Box>
      </Box>
    </Box>
  );
};

export default TargetedConditionsWidgetTable;
