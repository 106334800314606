import { Legend, LegendItemProps } from '@clinintell/components/Chart/Legend';
import useGraphContainerProps, {
  UseGraphContainerProps
} from '@clinintell/containers/metricsTimeSeries/logic/useGraphContainerProps';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import Chart from '@clinintell/components/Chart/Chart';
import { Line } from 'react-chartjs-2';
import { SliderOptions, SliderRange } from '@clinintell/components/Chart/plugins/sliderPlugin';
import { parseDateIntoPeriod, parsePeriodIntoDate } from '@clinintell/utils/formatting';
import { PeriodType } from '../CMIAnalysis';
import { useCMIAnalysisDispatch, useCMIAnalysisState } from '@clinintell/modules/store';
import { setCMIAnalysisDates } from '@clinintell/modules/cmiAnalysis';
import { containerBorderRadius } from '@clinintell/theme/theme';

type AnalysisChartProps = {
  currentDateIsBetterTimes: boolean;
  chartTitle: string;
  yAxisLabelOverride?: string;
};

const HISTORICAL_PERIOD_COLOR = '#D12323';
const CURRENT_PERIOD_COLOR = '#369EA8';

const AnalysisChart: React.FC<UseGraphContainerProps & AnalysisChartProps> = ({
  metric,
  dataset,
  chartPeriodType,
  chartDataSets,
  currentDateIsBetterTimes,
  chartTitle,
  yAxisLabelOverride
}) => {
  const theme = useTheme();
  const dispatch = useCMIAnalysisDispatch();

  const {
    currentMinDate,
    currentMaxDate,
    historicalMinDate,
    historicalMaxDate,
    entityId,
    entityName
  } = useCMIAnalysisState();

  const handleStart = (periodType: PeriodType, value: string): void => {
    if (entityId === undefined || entityName === undefined) {
      return;
    }

    dispatch(
      setCMIAnalysisDates({
        [periodType === 'current' ? 'currentMinDate' : 'historicalMinDate']: parsePeriodIntoDate(value),
        orgId: entityId,
        orgName: entityName
      })
    );
  };

  const handleEnd = (periodType: PeriodType, value: string): void => {
    if (entityId === undefined || entityName === undefined) {
      return;
    }

    dispatch(
      setCMIAnalysisDates({
        [periodType === 'current' ? 'currentMaxDate' : 'historicalMaxDate']: parsePeriodIntoDate(value),
        orgId: entityId,
        orgName: entityName
      })
    );
  };

  const { yAxes, xAxis, legendItems, chartDatasets } = useGraphContainerProps({
    metric,
    dataset,
    chartPeriodType,
    chartDataSets,
    hideCovid: true
  });

  let yAxesSets = [...yAxes];
  if (yAxisLabelOverride) {
    yAxesSets = yAxes.map(yAxis => ({
      ...yAxis,
      title: yAxisLabelOverride
    }));
  }

  const sliders: SliderRange[] = [];
  if (historicalMinDate && historicalMaxDate) {
    const historicalStartPeriod = parseDateIntoPeriod(historicalMinDate);
    const historicalEndPeirod = parseDateIntoPeriod(historicalMaxDate);
    const historicalStartIndex = xAxis.labels.findIndex(label => label === historicalStartPeriod);
    const historicalEndIndex = xAxis.labels.findIndex(label => label === historicalEndPeirod);

    sliders.push({
      id: 'historical',
      startIndex: historicalStartIndex,
      span: historicalEndIndex - historicalStartIndex + 1,
      color: currentDateIsBetterTimes ? HISTORICAL_PERIOD_COLOR : CURRENT_PERIOD_COLOR
    });
  }

  if (currentMinDate && currentMaxDate) {
    const currentStartPeriod = parseDateIntoPeriod(currentMinDate);
    const currentEndPeirod = parseDateIntoPeriod(currentMaxDate);
    const currentStartIndex = xAxis.labels.findIndex(label => label === currentStartPeriod);
    const currentEndIndex = xAxis.labels.findIndex(label => label === currentEndPeirod);

    sliders.push({
      id: 'current',
      startIndex: currentStartIndex,
      span: currentEndIndex - currentStartIndex + 1,
      color: currentDateIsBetterTimes ? CURRENT_PERIOD_COLOR : HISTORICAL_PERIOD_COLOR
    });
  }

  const sliderPluginOptions: SliderOptions = {
    sliderEnabled: true,
    canvasId: metric,
    onSliderStartChange: (periodType, startLabel) => handleStart(periodType as PeriodType, startLabel),
    onSliderEndChange: (periodType, endLabel) => handleEnd(periodType as PeriodType, endLabel),
    sliders
  };

  const legendWithSliderPeriods: LegendItemProps[] = [
    ...legendItems.map((item, index) => ({
      ...item,
      label: index === 0 ? entityName ?? '' : item.label
    })),
    {
      color: currentDateIsBetterTimes ? HISTORICAL_PERIOD_COLOR : CURRENT_PERIOD_COLOR,
      label: 'Historical Period',
      type: 'Slider'
    },
    {
      color: currentDateIsBetterTimes ? CURRENT_PERIOD_COLOR : HISTORICAL_PERIOD_COLOR,
      label: 'Current Period',
      type: 'Slider'
    }
  ];

  return (
    <Box
      paddingTop={2}
      overflow="auto"
      bgcolor="white"
      border={1}
      borderColor={theme.palette.grey[300]}
      borderRadius={`${containerBorderRadius}px`}
    >
      <Box marginBottom={2}>
        <Box
          marginLeft={5}
          component="span"
          color={theme.palette.blue.main}
          fontSize={theme.typography.subheading.fontSize}
        >
          {chartTitle}
        </Box>
      </Box>
      <Legend styles={{ marginLeft: '3rem', marginBottom: '2.5rem' }} items={legendWithSliderPeriods} />
      <Chart
        customPlugins={{
          sliderPlugin: {
            ...sliderPluginOptions
          }
        }}
        title={metric}
        datasets={chartDatasets}
        yAxes={yAxesSets}
        xAxis={xAxis}
      >
        {Line}
      </Chart>
    </Box>
  );
};

export default AnalysisChart;
