import React, { useEffect, useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDashboard2, useMenuNavigationDispatch, useMetricsNavigationDispatch } from '@clinintell/modules/store';
import { setEntity, setMetric, Metrics, updateTreeAfterNavigation } from '@clinintell/modules/metricsNavigation';
import { setActiveMenuItem } from '@clinintell/modules/menuNavigation';
import { formatNumberWithCommas, parseDateIntoPeriod } from '@clinintell/utils/formatting';
import Widget from '../../components/widget/Widget';
import WidgetModal from '../../components/modal/WidgetModal';
import DashboardBarChart from '../../charts/DashboardBarChart';
import SeverityOpportunityModal from './SeverityOpportunityModal';
import { ChartDataWithFullLabels } from '@clinintell/types/common';
import { MetricRecordJSON } from '@clinintell/containers/metrics/typings/metricTypes';

export type CMIDocScoreType = {
  severityOpportunityData: MetricRecordJSON[];
  docScoreData: MetricRecordJSON[];
};

const SeverityOpportunity: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const menuNavigationDispatch = useMenuNavigationDispatch();
  const metricsDispatch = useMetricsNavigationDispatch();
  const { entity, groupName: group, groupId, dashboardDefaultDates, severityOpportunityWidget } = useDashboard2();
  const { isLoading, hasError } = severityOpportunityWidget;

  const [formattedCurrentStart, setFormattedCurrentStart] = useState('');
  const [formattedCurrentEnd, setFormattedCurrentEnd] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [chartData, setChartData] = useState<ChartDataWithFullLabels>({});
  const [chartTooltips, setChartTooltips] = useState<string[]>([]);
  const [selectedSliceEntity, setSelectedSliceEntity] = useState<MetricRecordJSON>();

  const getFullOrgTitle = useMemo(() => {
    if (group && group !== 'All') return `${entity?.name} - ${group}`;
    if (entity) return entity.name;
    return '';
  }, [entity, group]);

  const handleOnChartBarClick = (_?: globalThis.MouseEvent, elements?: unknown[]): void => {
    if (!elements || !Object.entries(elements).length) return;
    const { _index: index } = elements[0] as { _index: number; _datasetIndex: number };
    const slice = (severityOpportunityWidget.data as CMIDocScoreType).severityOpportunityData[index];
    setSelectedSliceEntity(slice);
    setOpenModal(true);
  };

  const WidgetModalMemo = useMemo(
    () => (
      <WidgetModal
        modalVisible={openModal}
        toggleVisible={() => setOpenModal(!openModal)}
        redirectUserHandler={(): void => {
          const orgId = groupId && groupId !== entity?.id ? groupId : entity ? entity.id : -1;
          navigate('/metrics');
          metricsDispatch(setEntity(orgId));
          metricsDispatch(setMetric(Metrics.docScore));
          metricsDispatch(updateTreeAfterNavigation());
          menuNavigationDispatch(setActiveMenuItem({ name: 'metrics' }));
        }}
        tooltipMsg="Navigate to Metrics"
        headerTitle={`${getFullOrgTitle} - Top Severity Documentation Opportunities`}
      >
        <SeverityOpportunityModal data={selectedSliceEntity as MetricRecordJSON} />
      </WidgetModal>
    ),
    [
      entity,
      getFullOrgTitle,
      groupId,
      menuNavigationDispatch,
      metricsDispatch,
      navigate,
      openModal,
      selectedSliceEntity
    ]
  );

  useEffect(() => {
    // Have to check for dashboardDefaultDates here and extract what we need
    // b/c dashboardDefaultDates can be undefined during loading
    if (isLoading || !dashboardDefaultDates || hasError) return;
    const { currentStart, currentEnd, isLoading: datesLoading } = dashboardDefaultDates;
    if (datesLoading || currentStart === '' || currentEnd === '') return;
    setFormattedCurrentStart(parseDateIntoPeriod(new Date(currentStart)));
    setFormattedCurrentEnd(parseDateIntoPeriod(new Date(currentEnd)));

    if (severityOpportunityWidget.data) {
      const labels: string[] = [];
      const fullTextLabels: string[] = [];
      const tooltips: string[] = [];
      const dataset: number[] = [];

      const { severityOpportunityData } = severityOpportunityWidget.data as CMIDocScoreType;
      if (!severityOpportunityData) return;

      severityOpportunityData.forEach(record => {
        const nameList = record.name.split(' ');
        const abbreviatedName =
          entity?.nodeTypeId === 1 ? nameList.map(name => name.charAt(0)).join('') : record.name.slice(0, 13) + '...';
        labels.push(`${abbreviatedName} (n=${formatNumberWithCommas(record.cases)})`);
        if (entity?.nodeTypeId === 1) fullTextLabels.push(abbreviatedName);
        tooltips.push(
          `${
            entity?.nodeTypeId === 1 ? nameList.map(name => name.charAt(0)).join('') : record.name
          } (n=${formatNumberWithCommas(record.cases)})`
        );
        dataset.push(record.opportunityRW);
      });
      setChartData({
        labels,
        fullTextLabels,
        datasets: [{ data: dataset, backgroundColor: theme.palette.blue.light3 }]
      });
      setChartTooltips(tooltips);
    }
    // Have to include the palette as a dependency to adhere to React hook rules
  }, [entity, isLoading, hasError, dashboardDefaultDates, severityOpportunityWidget, theme.palette.blue.light3]);

  return (
    <>
      <Widget
        isLoading={isLoading}
        hasError={hasError}
        headerTitle="Top Severity Documentation Opportunity"
        toolTip={
          `This widget displays the top 5 severity documentation opportunities ` +
          `at ${getFullOrgTitle} for ${formattedCurrentStart}-${formattedCurrentEnd}.`
        }
        displayTooltip={true}
        noPadding
      >
        <Box height={15} />
        <DashboardBarChart
          data={chartData}
          isHorizontal
          axisLabel="Opportunity (RW)"
          tooltips={chartTooltips}
          customHeight={230}
          handleOnChartBarClick={handleOnChartBarClick}
          testId="topSeverityOpportunityWidget"
        />
        <Box height={15} />
      </Widget>
      {WidgetModalMemo}
    </>
  );
};

export default SeverityOpportunity;
