import React, { HTMLAttributes } from 'react';
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  OutlinedInputProps,
  SxProps,
  Theme,
  useTheme
} from '@mui/material';
import { Box, TextField, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DropDownIcon from '@clinintell/components/icons/DropDownIcon';
import AutoCompleteOption from '@clinintell/components/AutoCompleteOption';
import { containerBorderRadius } from '@clinintell/theme/theme';
import SearchIcon from '@mui/icons-material/Search';

export const useStylesWithNotchedOutlineWidth = (notchedOutlineWidth: string | number): Record<string, string> =>
  makeStyles(theme => ({
    inputRoot: {
      '& > fieldset > legend': {
        width: notchedOutlineWidth
      }
    },
    results: {
      zIndex: 99999,
      // this targets the subtext next to the option
      '& > div > ul > div > div:last-child > span': {
        marginTop: 3.5
      }
    }
  }))();

export type AutoCompleteChoiceType = {
  childId: number;
  childName: string;
  childNodeTypeId: number;
  parentId: number;
  parentName: string;
  parentNodeTypeId: number;
};

export type ReasonType = 'clear' | 'reset' | 'input';

type SizeOptions = 'small' | 'medium' | 'undefined';

export type AutoCompleteOptions = {
  options: [] | AutoCompleteChoiceType[];
  getOptionLabel: (opt: AutoCompleteChoiceType) => string;
  renderOption: (
    props: HTMLAttributes<HTMLLIElement>,
    opt: AutoCompleteChoiceType,
    state: AutocompleteRenderOptionState
  ) => JSX.Element;
};

export type AutoCompleteProps = {
  options: AutoCompleteOptions;
  onChangeHandler: (e: React.SyntheticEvent, value: null | AutoCompleteChoiceType | AutoCompleteChoiceType[]) => void;
  inputValue: string;
  onTextChangeHandler: (e: React.SyntheticEvent | null, value: string, reason: ReasonType) => void;
  onCloseHandler: (e: React.SyntheticEvent) => void;
  openDropDown: boolean;
  onOpenHandler: () => void;
  inputLabel: string;
  inputStyles?: React.CSSProperties;
  inputShouldUseFullWidth?: boolean;
  inputWidth?: string | number;
  notchedOutlineWidth?: string | number;
  isMultiSelect?: boolean;
  loading?: boolean;
  testID?: string;
  sx?: SxProps<Theme>;
  multiSelectValue?: AutoCompleteChoiceType[];
  showStartIcon?: boolean;
};

export type AutoCompleteInputProps = {
  InputProps: OutlinedInputProps;
};

const AutoComplete: React.FC<AutoCompleteProps> = ({
  options,
  onChangeHandler,
  inputValue,
  onTextChangeHandler,
  onCloseHandler,
  openDropDown,
  onOpenHandler,
  inputLabel,
  inputStyles,
  inputShouldUseFullWidth,
  inputWidth,
  isMultiSelect,
  loading,
  notchedOutlineWidth,
  testID = '',
  sx,
  multiSelectValue,
  showStartIcon = false
}) => {
  const theme = useTheme();
  const { results } = useStylesWithNotchedOutlineWidth(notchedOutlineWidth || 'auto');

  function renderInput(params: AutoCompleteInputProps): JSX.Element {
    const isSearchField = inputLabel && inputLabel.toLowerCase() === 'search';
    const inputProps = {
      ...params.InputProps,
      endAdornment: loading ? (
        <Box marginLeft="-20px">
          <CircularProgress color="primary" size={20} />
        </Box>
      ) : null
    };
    if (showStartIcon) {
      inputProps.startAdornment = <SearchIcon style={{ color: theme.palette.grey[300] }} />;
    }
    return (
      <Box display="flex">
        <TextField
          sx={{
            backgroundColor: 'shade.white',
            borderRadius: `${containerBorderRadius}px`,
            ...(inputWidth !== undefined ? { width: inputWidth } : null),
            '& fieldset': {
              borderColor: 'grey.300',
              borderRadius: `${containerBorderRadius}px`
            },
            '&&:hover fieldset': {
              borderColor: 'blue.light2'
            },
            '&&.Mui-focused fieldset': {
              borderColor: 'blue.light2'
            },
            '& fieldset > legend': {
              width: '0px' // hiding the legend
            },
            ...sx
          }}
          {...params}
          fullWidth={inputShouldUseFullWidth}
          variant="outlined"
          label={isSearchField ? '' : inputLabel}
          InputProps={inputProps}
          placeholder={multiSelectValue && multiSelectValue.length ? '' : inputLabel ?? 'Select'}
        />
      </Box>
    );
  }

  // No idea why this Material UI autocmplete requires a type
  const size: SizeOptions = 'small';
  const autoCompleteProps = {
    ...options,
    size,
    classes: {
      popper: results
    },
    renderInput,
    onChange: onChangeHandler,
    inputValue,
    onInputChange: onTextChangeHandler,
    onClose: onCloseHandler,
    open: openDropDown,
    onOpen: onOpenHandler,
    style: inputStyles,
    loading,
    filterOptions: (options: AutoCompleteChoiceType[]) => options
  };

  return isMultiSelect ? (
    <Autocomplete
      noOptionsText={<AutoCompleteOption label="No records returned" />}
      {...autoCompleteProps}
      popupIcon={<DropDownIcon />}
      blurOnSelect
      autoHighlight
      multiple
      data-cy={testID}
      value={multiSelectValue}
    />
  ) : (
    <Autocomplete
      noOptionsText={<AutoCompleteOption label="No records returned" />}
      {...autoCompleteProps}
      popupIcon={<DropDownIcon />}
      blurOnSelect
      autoHighlight
      multiple={isMultiSelect}
      data-cy={testID}
    />
  );
};

export default AutoComplete;
