import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ClinIntellSkeleton } from '@clinintell/components/index';
import { DRGMixTableRow } from '@clinintell/containers/cmiComparison/typings/cmiComparisonDtos';
import DRGImpactTables from '@clinintell/components/analysisSharedComponents/DRGImpactTables';
import { parseDateIntoPeriod } from '@clinintell/utils/formatting';
import { useDashboard2 } from '@clinintell/modules/store';
import { CMIAnalysisWidgetData } from '@clinintell/modules/dashboard2';
import microscope from '@clinintell/assets/images/microscope.png';

const useStyles = makeStyles(theme => ({
  widgetImage: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    '& img': {
      margin: '0 auto',
      height: '110px',
      padding: '24px 40px'
    },
    '& p': {
      fontSize: '10px',
      width: '100%',
      textAlign: 'center',
      fontWeight: 400,
      lineHeight: '14px',
      letter: '2%'
    }
  },
  widgetErrMsgTitle: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    margin: '0 auto',
    marginBottom: '.7rem'
  },
  widgetErrMsg: {
    display: 'flex',
    margin: '0 auto',
    textAlign: 'center'
  }
}));

const DRGAnalysisModal: React.FC = () => {
  const { widgetImage, widgetErrMsgTitle, widgetErrMsg } = useStyles();
  const { isLoading, cmiAnalysisWidget } = useDashboard2();
  const drgAnalysis = (cmiAnalysisWidget.data as CMIAnalysisWidgetData).drgMix;

  const convertedTableData = useMemo(() => {
    if (!drgAnalysis) {
      return [];
    }

    return drgAnalysis.table.map(row => {
      return {
        ...row,
        entity1: {
          id: 1,
          share: row.historicalShare
        },
        entity2: {
          id: 2,
          share: row.currentShare
        }
      };
    });
  }, [drgAnalysis]);

  if (isLoading && !cmiAnalysisWidget) {
    return <ClinIntellSkeleton variant="rectangular" height="20rem" width="100%" />;
  }

  if (!drgAnalysis) {
    return (
      <Box className={widgetImage}>
        <img src={microscope} alt={''} />
        <Typography variant="h4" className={widgetErrMsgTitle}>
          No Data
        </Typography>
        <Typography variant="p2" className={widgetErrMsg}>
          No DRGs to display.
        </Typography>
      </Box>
    );
  }

  let historicalColumnLabel = '';
  if (drgAnalysis.historicalStart && drgAnalysis.historicalEnd) {
    const min = parseDateIntoPeriod(new Date(drgAnalysis.historicalStart));
    const max = parseDateIntoPeriod(new Date(drgAnalysis.historicalEnd));

    historicalColumnLabel = min;
    if (min !== max) {
      historicalColumnLabel += `-${max}`;
    }
  }

  let currentColumnLabel = '';
  if (drgAnalysis.currentStart && drgAnalysis.currentEnd) {
    const min = parseDateIntoPeriod(new Date(drgAnalysis.currentStart));
    const max = parseDateIntoPeriod(new Date(drgAnalysis.currentEnd));

    currentColumnLabel = min;
    if (min !== max) {
      currentColumnLabel += `-${max}`;
    }
  }

  const hasPositiveCMIDelta =
    drgAnalysis.currentHcupCmi && drgAnalysis.historicalHcupCmi
      ? drgAnalysis.currentHcupCmi - drgAnalysis.historicalHcupCmi >= 0
      : true;

  return (
    <Box>
      <DRGImpactTables
        isLoading={false}
        hasPositiveDelta={hasPositiveCMIDelta}
        tableRows={convertedTableData as DRGMixTableRow[]}
        referenceLabel={historicalColumnLabel}
        comparerLabel={currentColumnLabel}
        changeLabel="Change, Share"
      />
    </Box>
  );
};

export default DRGAnalysisModal;
