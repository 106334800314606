import {
  EntityDetailsState,
  getEntityDescriptiveName
} from '@clinintell/components/analysisSharedComponents/useEntityDetails';
import { userCan } from '@clinintell/containers/authentication/rules';
import EntityNavigation from '@clinintell/containers/entityNavigation/EntityNavigation';
import { NodeDetails } from '@clinintell/containers/treeSelector/logic/TreeContext';
import { EntitySelection } from '@clinintell/modules/cmiAnalysisSelections';
import { NodeTypeIds } from '@clinintell/modules/orgTree';
import { useClientOrgTreeState, useHospitals, useSAFOrgTreeState, useUser } from '@clinintell/modules/store';
import useIsMobileView from '@clinintell/utils/useIsMobileView';
import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import EntitySelectionActions, { EntitySelectionActionsProps } from '../entitySelectionActions/EntitySelectionActions';

const LABEL_STYLES = {
  display: 'inline-block',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

const EntityComparison: React.FC<EntitySelectionActionsProps> = props => {
  const [myEntity, setMyEntity] = useState<EntitySelection>();
  const [peerEntity, setPeerEntity] = useState<EntitySelection>();

  const hospitals = useHospitals();
  const user = useUser();
  const userCanSelectProvidersInTree = userCan(user, ['reportCmiComparisonOfProvidersView']);
  const isMobileView = useIsMobileView();

  const { node: clientNode } = useClientOrgTreeState();
  const { node: safNode } = useSAFOrgTreeState();
  const node = props.orgTreeType === 'client' ? clientNode : safNode;

  const handleMyEntitySelection = useCallback(
    (entity: number, nodeDetails: NodeDetails) => {
      const entityDetailsState: EntityDetailsState = { name: nodeDetails.name, id: entity, type: nodeDetails.type };
      setMyEntity({ name: getEntityDescriptiveName(entityDetailsState, node), id: entity });
    },
    [node]
  );

  const handlePeerEntitySelection = useCallback(
    (entity: number, nodeDetails: NodeDetails) => {
      const entityDetailsState: EntityDetailsState = { name: nodeDetails.name, id: entity, type: nodeDetails.type };
      setPeerEntity({ name: getEntityDescriptiveName(entityDetailsState, node), id: entity });
    },
    [node]
  );

  let maxnodetype: keyof typeof NodeTypeIds = userCanSelectProvidersInTree ? 'Provider' : 'SpecialtyGroup';
  if (props.orgTreeType === 'saf') {
    maxnodetype = 'Hospital';
  }

  const description =
    props.orgTreeType === 'client'
      ? 'Select your entity and a comparer below. Your entity can range from your health system to hospital or physician group.'
      : 'Select your entity and a comparer below. Your entity can range from your health system to hospital.';

  return (
    <EntitySelectionActions
      {...props}
      type={props.orgTreeType === 'saf' ? 'nationalcomparison' : 'comparison'}
      analyzeIsDisabled={!myEntity || !peerEntity}
      entity={myEntity}
      peerEntity={peerEntity}
      selectionDescription={description}
      actionButtonLabel="Compare"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          ...(isMobileView ? { flexDirection: 'column' } : null),
          '& > div': {
            width: '50%',
            px: 1,
            ...(isMobileView ? { width: '100%' } : null)
          }
        }}
      >
        <Box
          sx={{
            borderRight: isMobileView ? 'none' : '1px solid',
            borderColor: 'grey.300',
            ...(isMobileView ? { borderRight: 'none' } : null)
          }}
        >
          <Box component="span" sx={{ ...LABEL_STYLES }}>
            <Box component="span" fontWeight={500}>
              My Entity:
            </Box>
            {myEntity ? ` ${myEntity.name}` : ''}
          </Box>
          <Box mt={1} mb={2}>
            <EntityNavigation
              defaultExpandedNodes={[user.rootId.toString()]}
              isTreeVisible={true}
              entity={myEntity ? myEntity.id : user.rootId}
              withSearch={hospitals.totalCount > 1 || props.orgTreeType === 'saf'}
              maxnodetype={maxnodetype}
              minCharactersToSearch={3}
              maxCharactersToSearch={50}
              hideButtonColor="primary"
              onEntitySelected={handleMyEntitySelection}
              onEntitySearchComplete={handleMyEntitySelection}
              id="myEntitySelector"
              cannotHideTree
              height={450}
              type={props.orgTreeType}
            />
          </Box>
        </Box>
        <Box>
          <Box component="span" sx={{ ...LABEL_STYLES }}>
            <Box component="span" fontWeight={500}>
              Compared to:
            </Box>
            {peerEntity ? ` ${peerEntity.name}` : ''}
          </Box>
          <Box mt={1} mb={4}>
            <EntityNavigation
              defaultExpandedNodes={[user.rootId.toString()]}
              entity={peerEntity ? peerEntity.id : user.rootId}
              isTreeVisible={true}
              withSearch={hospitals.totalCount > 1 || props.orgTreeType === 'saf'}
              maxnodetype={maxnodetype}
              minCharactersToSearch={3}
              maxCharactersToSearch={50}
              hideButtonColor="primary"
              onEntitySelected={handlePeerEntitySelection}
              onEntitySearchComplete={handlePeerEntitySelection}
              id="peerEntitySelector"
              cannotHideTree
              height={450}
              type={props.orgTreeType}
            />
          </Box>
        </Box>
      </Box>
    </EntitySelectionActions>
  );
};

export default EntityComparison;
