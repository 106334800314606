export const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegEx.test(email.toLowerCase());
};

export const isSystemGroup = (name: string): boolean => {
  return (
    name
      .toLocaleLowerCase()
      .replace(/-|\s/g, '')
      .indexOf('singlesystems') === 0
  );
};

export const isValidMaxStringLength = (value: string, maxLength: number): boolean => {
  return value.length <= maxLength;
};

export const isRequired = (value: string): boolean => {
  return Boolean(value);
};
