import React, { useCallback, useMemo } from 'react';
import { TableProps, Table } from './Table';
import { RowDataUpdatedEvent, RowSelectedEvent } from 'ag-grid-community';
import { ColumnDef, ColumnDefNoValue } from '../types/table';
import {
  CellClassParams,
  CheckboxSelectionCallbackParams,
  ColDefField
} from 'ag-grid-community/dist/lib/entities/colDef';
import { colDefMapper } from '../utils/colDefMapper';

type SelectableTableProps<T, CheckboxId extends keyof T> = Omit<TableProps<T>, 'colDefs'> & {
  defaultSelectedRows: Array<T[CheckboxId]>;
  onSelectedRowsChanged: (rows: Array<T[CheckboxId]>, event: RowSelectedEvent, sourcedRow?: T[CheckboxId]) => void;
  getCheckboxValue?: (value: CheckboxId) => T[CheckboxId];
  filterSelectable?: (params: CheckboxSelectionCallbackParams<T, unknown>) => boolean;
  renderDisabledCheckbox?: (data: T) => boolean;
  canToggleAll?: boolean;
  checkboxId: CheckboxId;
  checkboxColumnWidth?: number;
};

export const SelectableTable = <T, CheckboxId extends keyof T>(props: SelectableTableProps<T, CheckboxId>) => {
  const handleRowDataUpdated = (event: RowDataUpdatedEvent) => {
    const defaultSelectedRowMap = new Set(props.defaultSelectedRows);
    setTimeout(() => {
      event.api.forEachNode(node => {
        const row = node.data as T;
        if (defaultSelectedRowMap.has(row[props.checkboxId])) {
          node.setSelected(true);
        }
      });
    }, 0);

    // If left pinned columns exist, also pin the checkbox column to the left as well so that it
    // always remains as the left-most column
    const columnState = event.columnApi.getColumnState();
    const hasLeftPinnedColumns = Boolean(columnState.find(col => col.pinned === 'left'));

    if (hasLeftPinnedColumns) {
      event.columnApi.setColumnPinned(props.checkboxId.toString(), 'left');
    }
  };

  const handleSelectedRowsChanged = (event: RowSelectedEvent) => {
    // If sourced from the API, that means the initial default rows were selected on table render.
    // We only want to return user-interacted row selection events
    if (event.source === 'api') {
      return;
    }

    const selectedRows = event.api.getSelectedRows();

    // Supply the node that was selected if sourced from a checkbox click. Otherwise it was a toggle all and thus undefined.
    const selectionSource = event.source === 'checkboxSelected' ? event.node.data : undefined;

    props.onSelectedRowsChanged(
      selectedRows.map(row => row[props.checkboxId]),
      event,
      selectionSource ? selectionSource[props.checkboxId] : undefined
    );
  };

  const renderColumn = useCallback(
    <Field extends keyof T>(field: Field, args: Omit<ColumnDef<T, Field>, 'field'>): ColumnDef<T, Field> => {
      return {
        ...args,
        field
      };
    },
    []
  );

  const renderCustomColumn = useCallback((args: Omit<ColumnDefNoValue<T>, 'field'>): ColumnDefNoValue<T> => {
    return {
      ...args,
      field: 'actions'
    };
  }, []);

  const colDefs = useMemo(() => {
    const columns = props.renderColumns({ renderColumn, renderCustomColumn });
    const castedColumns = columns as ColumnDef<T, keyof T>[];

    const newColDefs = colDefMapper({
      columns: castedColumns,
      persistedColumnState: props.persistedColumnState
    });

    newColDefs.unshift({
      field: (props.checkboxId as unknown) as ColDefField<T, unknown>,
      width: props.checkboxColumnWidth || 75,
      resizable: false,
      // TODO
      // ColDefFields allow for custom properties so this is OK. Maybe find a way to create custom col defs in AG Grid?
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      canToggleAll: props.canToggleAll === undefined ? true : props.canToggleAll,
      checkboxSelection: params => (props.filterSelectable ? props.filterSelectable(params) : true),
      headerCheckboxSelection: props.canToggleAll === undefined ? true : props.canToggleAll,
      headerName: '',
      cellRenderer: () => {
        return '';
      },
      cellStyle: (params: CellClassParams<T>) => {
        const data = params.data;
        const checkboxIsDisabled = props.renderDisabledCheckbox && data ? props.renderDisabledCheckbox(data) : false;

        return {
          justifyContent: 'center',
          // Lines up with column header checkbox
          paddingLeft: 28,
          ...(checkboxIsDisabled && { pointerEvents: 'none' })
        };
      }
    });

    return newColDefs;
  }, [props, renderColumn, renderCustomColumn]);

  return (
    <Table
      {...props}
      onSelectedRows={handleSelectedRowsChanged}
      onRowDataUpdated={handleRowDataUpdated}
      rowSelection="multiple"
      colDefs={colDefs}
    />
  );
};
