import React from 'react';
import Popup from '@clinintell/components/popup/Popup';
import { SxProps, Theme } from '@mui/system';

type PopupMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  sx?: SxProps<Theme>;
};

const PopupMenu: React.FC<PopupMenuProps> = ({ children, isOpen, onClose, anchorEl, sx }) => {
  return (
    <Popup connectingElement={anchorEl} isOpen={isOpen} onClose={onClose} sx={{ ...sx }}>
      {children}
    </Popup>
  );
};

export default PopupMenu;
