import React, { ReactElement } from 'react';
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';
import { SxProps, Theme } from '@mui/material';
import TooltipContainer from './TooltipContainer';

export type TooltipProps = {
  sx?: SxProps<Theme>;
  position?: MuiTooltipProps['placement'];
  title?: string;
  content?: string | JSX.Element;
  showIcon?: boolean;
  children: ReactElement;
};

export const renderTooltipComponentProps = (sxProps: SxProps<Theme> | undefined) => {
  return {
    popper: {
      sx: {
        zIndex: 'tooltip',
        ...sxProps
      }
    },
    tooltip: {
      sx: {
        backgroundColor: 'dark.700'
      }
    },
    arrow: {
      sx: {
        color: 'dark.700'
      }
    }
  };
};

const Tooltip: React.FC<TooltipProps> = ({ sx, position = 'bottom', content, children, title, showIcon }) => {
  if (!content) return <>{children}</>;
  return (
    <MuiTooltip
      title={<TooltipContainer title={title} content={content} showIcon={showIcon} />}
      disableTouchListener
      placement={position}
      arrow
      componentsProps={{
        ...renderTooltipComponentProps(sx)
      }}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
