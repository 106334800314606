import { userCan } from '@clinintell/containers/authentication/rules';
import { useUser } from '@clinintell/modules/store';

type UseCMIAnalysisPermissionsOutput = {
  canViewSingleEntity: boolean;
  canViewEntityComparison: boolean;
  canViewNationalMedicareComparison: boolean;
};

const useCMIAnalysisPermissions = (): UseCMIAnalysisPermissionsOutput => {
  const user = useUser();

  const canViewSingleEntity = userCan(user, ['reportCmiAnalysisView', 'reportCmiAnalysisViewProviders']);
  const canViewEntityComparison = userCan(user, ['reportCmiComparisonView', 'reportCmiComparisonOfProvidersView']);
  const canViewNationalMedicareComparison = userCan(user, [
    'reportMedicareComparisonView',
    'reportMedicareComparisonOfProvidersView'
  ]);

  return {
    canViewSingleEntity,
    canViewEntityComparison,
    canViewNationalMedicareComparison
  };
};

export default useCMIAnalysisPermissions;
