import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  useScheduleGetReportsMonthlyGroupReportByPhysicianGroupOrgId,
  useScheduleGetReportsMonthsListByPhysicianGroupOrgId
} from '@clinintell/api/apiComponents';
import { Button, Select, SelectOptionType } from '@clinintell/components/index';
import CIReportsModal from '@clinintell/containers/reports/components/reportModal/CIReportsModal';
import HospitalAndGroupFilter from '@clinintell/containers/reports/components/hospitalAndGroupFilter/HospitalAndGroupFilter';
import { ReportType } from '../../types';
import { isMobile } from 'react-device-detect';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import useErrorHandling from '@clinintell/errors/useErrorHandling';
import { downloadFile } from '@clinintell/utils/downloads';

type SaveReponseType = { originalError: { payload: string; status: number } };

type GroupReportSuperUserDownloadProps = {
  groupId: number;
  hospitalId: number;
  onHospitalChange: (hospitalId: number | null) => void;
  onGroupChange: (groupId: number | null) => void;
};

const GroupReportSuperUserDownload: React.FC<GroupReportSuperUserDownloadProps> = ({
  groupId,
  hospitalId,
  onHospitalChange,
  onGroupChange
}) => {
  const [reportDate, setReportDate] = useState<string | number>(-1);
  const { showErrorMessage, throwToErrorBoundary } = useErrorHandling();

  const { data: monthsList, isFetching: isFetchingMonthsList } = useScheduleGetReportsMonthsListByPhysicianGroupOrgId(
    {
      onError: throwToErrorBoundary,
      pathParams: {
        physicianGroupOrgId: groupId
      }
    },
    {
      enabled: groupId > -1
    }
  );

  const {
    data: groupReportData,
    isFetching: isFetchingMonthlyReports,
    refetch: fetchMonthlyGroupReport
  } = useScheduleGetReportsMonthlyGroupReportByPhysicianGroupOrgId(
    {
      onError: (err: unknown | SaveReponseType) => {
        showErrorMessage(err, 'This Group does not have data on the selected times.');
      },
      pathParams: {
        physicianGroupOrgId: groupId
      },
      queryParams: {
        reportDate: formatDateForAPI(new Date(reportDate))
      }
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (!groupReportData) {
      return;
    }

    // I believe the underlying API spec is wrong. This isn't a blob, it is an array buffer
    downloadFile((groupReportData as unknown) as ArrayBuffer, 'application/zip', 'Reports');
  }, [groupReportData]);

  // Hook which resets report date dropdown when the group changes
  useEffect(() => {
    if (groupId === -1) {
      return;
    }

    // Select value must be -1 to "reset" it. Using a blank string will keep the previous value in there
    setReportDate(-1);
  }, [groupId]);

  const handleReportDateChange = (date: string) => {
    setReportDate(date);
  };

  const handleDownloadClick = async () => {
    fetchMonthlyGroupReport();
  };

  const monthSelectorOptions: SelectOptionType[] =
    monthsList && !isFetchingMonthsList
      ? monthsList.map(
          (month): SelectOptionType => ({
            label: month.timePeriodText || '',
            value: month.timePeriodDate
          })
        )
      : [];

  return (
    <CIReportsModal
      modalButtonOverride={<Button label="Download Report" type={'primary'} variant={'text'} />}
      modalBtnLabel="Download Report"
      reportLabel="Download Report"
      reportModalTitle="Group Report"
      reportType={ReportType.Group}
      modalActionButtonOverride={
        <Button
          isBusy={isFetchingMonthlyReports}
          label="Download Report"
          disabled={[groupId, hospitalId, reportDate].includes(-1)}
          onClick={handleDownloadClick}
        />
      }
    >
      <HospitalAndGroupFilter
        hospitalId={hospitalId}
        groupId={groupId}
        onHospitalChange={onHospitalChange}
        onPhysicianGroupChange={onGroupChange}
        width={isMobile ? 325 : '100%'}
      />
      <Box sx={{ p: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: isMobile ? 325 : '100%'
          }}
        >
          <Typography variant="p2">Report Date</Typography>
          <Box id="SelectBoxId" width="100%" style={{ marginTop: 8 }}>
            <Select
              isDisabled={isFetchingMonthsList || [groupId, hospitalId].includes(-1)}
              options={monthSelectorOptions}
              onChange={value => handleReportDateChange(value.toString())}
              value={reportDate}
              isSearchable
            />
          </Box>
        </Box>
      </Box>
    </CIReportsModal>
  );
};

export default GroupReportSuperUserDownload;
