import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ApplicationAPI, HttpResponseException } from '@clinintell/utils/api';

export type ConditionDTO = {
  id: number;
  conditionName: string;
  conditionDescription: string;
  conditionType: string;
};

export type ConditionsDTO = ConditionDTO[];

export enum ConditionsActions {
  CONDITIONS_FETCH_REQUESTED = 'CONDITIONS_FETCH_REQUESTED',
  CONDITIONS_FETCH_SUCCESSFUL = 'CONDITIONS_FETCH_SUCCESSFUL',
  CONDITIONS_FETCH_FAILED = 'CONDITIONS_FETCH_FAILED',
  ADD_CONDITIONS = 'ADD_CONDITIONS',
  ENTITY_CHANGED = 'ENTITY_CHANGED',
  METRIC_CHANGED = 'METRIC_CHANGED'
}

export type ConditionsAction<T> = {
  type: keyof typeof ConditionsActions;
  payload?: T;
};

export type Condition = ConditionDTO;
export type IndexedCondition = { [key: string]: Condition };

export type ConditionsById = IndexedCondition | unknown;

export enum ConditionTypes {
  DRG = 'DRG',
  ELIX = 'Elixhauser',
  HCC = 'HCC'
}

export type ConditionsState = {
  byId: ConditionsById;
  error: string;
  isLoading: boolean;
  isInitialized: boolean;
};

export type ConditionByOrgPayload = {
  orgId?: number;
};

export const initialConditions: ConditionsState = {
  byId: {},
  isLoading: false,
  error: '',
  isInitialized: false
};

export const convertDTOtoById = (dto: ConditionsDTO): ConditionsById => {
  return dto.reduce((acc, val) => {
    return { ...acc, [val.id.toString()]: { ...val } };
  }, {});
};

export const fetchConditions = (payload: ConditionByOrgPayload): ConditionsAction<ConditionByOrgPayload> => ({
  type: 'CONDITIONS_FETCH_REQUESTED',
  payload
});

export const metricChanged = (): ConditionsAction<ConditionByOrgPayload> => ({
  type: 'METRIC_CHANGED'
});

export const entityChanged = (): ConditionsAction<ConditionByOrgPayload> => ({
  type: 'ENTITY_CHANGED'
});

function reducer(
  state: ConditionsState = initialConditions,
  action: ConditionsAction<ConditionsState | ConditionByOrgPayload>
): ConditionsState {
  switch (action.type) {
    case 'ADD_CONDITIONS': {
      const payload = action.payload as ConditionsState;
      return { ...state, isLoading: false, byId: { ...(payload.byId as IndexedCondition) }, isInitialized: true };
    }
    case 'CONDITIONS_FETCH_FAILED': {
      const payload = action.payload as ConditionsState;
      return { ...state, isLoading: false, error: payload.error, isInitialized: false };
    }
    case 'CONDITIONS_FETCH_REQUESTED': {
      return { ...state, isLoading: true, error: '' };
    }
    case 'CONDITIONS_FETCH_SUCCESSFUL': {
      console.log('shouldnt be!');
      return { ...state, isLoading: false };
    }
    case 'ENTITY_CHANGED':
    case 'METRIC_CHANGED': {
      return { ...state, isInitialized: false };
    }
    default: {
      return { ...state };
    }
  }
}

export default reducer;

export type ConditionsSaga<T> = {
  type: keyof typeof ConditionsActions;
  payload: T;
};

export const fetchAllConditions = (): ConditionsSaga<unknown> => ({
  type: 'CONDITIONS_FETCH_REQUESTED',
  payload: {}
});

type ConditionOrgType = {
  orgId?: number;
};

export function* fetchConditionsSaga(action: ConditionsSaga<ConditionOrgType>): SagaIterator {
  try {
    let endpoint = 'conditions';
    if (action.payload.orgId) {
      endpoint += `?orgid=${action.payload.orgId}`;
    }
    const response = yield call(ApplicationAPI.get, { endpoint });
    const conditions = convertDTOtoById(response.data);
    yield put({ type: 'ADD_CONDITIONS', payload: { byId: conditions } });
  } catch (e) {
    yield put({ type: 'CONDITIONS_FETCH_FAILED', payload: { error: (e as HttpResponseException).message } });
  }
}

export function* conditionsSaga(): SagaIterator {
  yield takeLatest('CONDITIONS_FETCH_REQUESTED', fetchConditionsSaga);
}
