import { BreadcrumbItem } from '@clinintell/modules/breadcrumbNavigation';
import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import Popup from '../popup/Popup';
import Breadcrumb from './Breadcrumb';
import CollapsedBreadcrumbsMenu from './CollapsedBreadcrumbsMenu';

export type BreadcrumbsProps = {
  breadcrumbs: BreadcrumbItem[];
  onBreadcrumbClick: (value: string | number) => void;
  maxBreadcrumbsBeforeCollapse: number;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs, onBreadcrumbClick, maxBreadcrumbsBeforeCollapse }) => {
  const [showCollapsedBreadcrumbs, setShowCollapsedBreadcrumbs] = useState(false);

  const showCollapsedBreadcrumbsRef = useRef<HTMLDivElement>(null);

  const handleBreadcrumbClick = (value: string | number) => {
    onBreadcrumbClick(value);
  };

  const handleShowCollapsedBreadcrumbsClick = () => {
    setShowCollapsedBreadcrumbs(true);
  };

  const handleCloseCollapsedBreadcrumbsClick = () => {
    setShowCollapsedBreadcrumbs(false);
  };

  const handleCollapsedBreadcrumbClick = (value: number | string) => {
    setShowCollapsedBreadcrumbs(false);
    handleBreadcrumbClick(value);
  };

  let breadcrumbPath;
  if (breadcrumbs.length > maxBreadcrumbsBeforeCollapse) {
    breadcrumbPath = (
      <>
        <Breadcrumb {...breadcrumbs[0]} onClick={handleBreadcrumbClick} isCurrent={false} />
        <Box component="span" sx={{ color: 'grey.600' }}>
          /
        </Box>
        <Box
          ref={showCollapsedBreadcrumbsRef}
          onClick={handleShowCollapsedBreadcrumbsClick}
          component="span"
          role="button"
          sx={{ color: 'blue.main', '&:hover': { cursor: 'pointer' } }}
        >
          ...
        </Box>
        <Box component="span" sx={{ color: 'grey.600' }}>
          /
        </Box>
        <Breadcrumb onClick={handleBreadcrumbClick} {...breadcrumbs[breadcrumbs.length - 1]} isCurrent />
        <Popup
          sx={{ marginTop: 3.5 }}
          connectingElement={showCollapsedBreadcrumbsRef.current}
          isOpen={showCollapsedBreadcrumbs}
          onClose={handleCloseCollapsedBreadcrumbsClick}
        >
          <CollapsedBreadcrumbsMenu onClick={handleCollapsedBreadcrumbClick} breadcrumbs={breadcrumbs} />
        </Popup>
      </>
    );
  } else {
    breadcrumbPath = breadcrumbs.map((breadcrumb, index) => {
      return (
        <React.Fragment key={breadcrumb.label}>
          <Breadcrumb {...breadcrumb} onClick={handleBreadcrumbClick} isCurrent={index === breadcrumbs.length - 1} />
          {index !== breadcrumbs.length - 1 ? (
            <Box component="span" sx={{ color: 'grey.600' }}>
              /
            </Box>
          ) : null}
        </React.Fragment>
      );
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        '& > *:not(last-child)': {
          marginRight: 1
        }
      }}
    >
      {breadcrumbPath}
    </Box>
  );
};

export default Breadcrumbs;
