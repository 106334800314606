import { Metrics, Views, viewLabels, getAnalagousView } from '@clinintell/modules/metricsNavigation';
import { NodeType, NodeTypeLabel } from '@clinintell/containers/metrics/typings/metricTypes';
import { Resources } from '@clinintell/utils/resources';

export type ColumnName =
  | 'name'
  | 'action'
  | 'current'
  | 'historical'
  | 'clinicallyExpected'
  | 'gapVsCe'
  | 'errorGap'
  | 'cases'
  | 'opportunityRW'
  | 'minOpportunityRW'
  | 'maxOpportunityRW'
  | 'opportunityDollar'
  | 'minOpportunityDollar'
  | 'maxOpportunityDollar'
  | 'improvementRW'
  | 'minImprovementRW'
  | 'maxImprovementRW'
  | 'improvementDollar'
  | 'minImprovementDollar'
  | 'maxImprovementDollar'
  | 'change'
  | 'errorChange'
  | 'hcupWgtAvg'
  | 'ceHcupWgtAvg'
  | 'opportunity'
  | 'improvement'
  | 'category'
  | 'group'
  | 'raf'
  | 'mdcId'
  | 'mdcName'
  | 'conditionTypeId'
  | 'conditionId'
  | 'isTargetCondition'
  | 'hasApprovedContent'
  | 'docScore'
  | 'otoE'
  | 'otoEChange'
  | 'otoEErrorChangePositive'
  | 'otoEErrorChangeNegative'
  | 'otoEErrorChange'
  | 'otoEErrorGapPositive'
  | 'otoEErrorGapNegative'
  | 'otoEErrorGap'
  | 'otoEGapVsCe'
  | 'otoEce'
  | 'historicalOtoE'
  | 'historicalOtoEce'
  | 'historicalOtoEErrorGap';

export type ColumnView = {
  columns: ColumnName[];
  defaultSort: ColumnName;
};

type DataView = {
  views: {
    [key in Views]?: ColumnView;
  };
  defaultView: keyof typeof Views;
  hasValueTypeToggle: boolean;
};

type CommonViews = {
  [key in Views]: ColumnView;
};

type MetricTableSchema = {
  [key in Metrics]: DataView;
};

const commonConfigurations: CommonViews = {
  opportunity: {
    columns: ['name', 'action', 'current', 'clinicallyExpected', 'gapVsCe', 'errorGap', 'cases', 'opportunityRW'],
    defaultSort: 'opportunityRW'
  },
  opportunityRange: {
    columns: [
      'name',
      'action',
      'current',
      'clinicallyExpected',
      'gapVsCe',
      'errorGap',
      'cases',
      'minOpportunityRW',
      'maxOpportunityRW'
    ],
    defaultSort: 'minOpportunityRW'
  },
  improvement: {
    columns: ['name', 'action', 'historical', 'current', 'change', 'cases', 'improvementRW'],
    defaultSort: 'improvementRW'
  },
  improvementRange: {
    columns: ['name', 'action', 'historical', 'current', 'change', 'cases', 'minImprovementRW', 'maxImprovementRW'],
    defaultSort: 'minImprovementRW'
  },
  improvementAndOpportunity: {
    columns: [
      'name',
      'action',
      'historical',
      'current',
      'change',
      'clinicallyExpected',
      'gapVsCe',
      'errorGap',
      'cases',
      'improvementRW',
      'opportunityRW'
    ],
    defaultSort: 'opportunityRW'
  },
  change: {
    columns: ['name', 'action', 'historical', 'current', 'change', 'errorChange', 'cases'],
    defaultSort: 'change'
  },
  gap: {
    columns: ['name', 'action', 'current', 'clinicallyExpected', 'gapVsCe', 'errorGap', 'cases'],
    defaultSort: 'gapVsCe'
  },
  changeAndGap: {
    columns: [
      'name',
      'action',
      'historical',
      'current',
      'change',
      'errorChange',
      'clinicallyExpected',
      'gapVsCe',
      'errorGap',
      'cases'
    ],
    defaultSort: 'gapVsCe'
  },
  opportunityRW: {
    columns: ['name', 'action', 'current', 'clinicallyExpected', 'gapVsCe', 'errorGap', 'opportunityRW'],
    defaultSort: 'opportunityRW'
  }
};

const cmiSchema: DataView = {
  views: {
    improvement: {
      ...commonConfigurations.improvement,
      columns: [
        ...commonConfigurations.improvement.columns.slice(0, 5),
        'errorChange',
        ...commonConfigurations.improvement.columns.slice(5, commonConfigurations.improvement.columns.length)
      ]
    },
    improvementRange: {
      ...commonConfigurations.improvementRange,
      columns: [
        ...commonConfigurations.improvementRange.columns.slice(0, 5),
        'errorChange',
        ...commonConfigurations.improvementRange.columns.slice(5, commonConfigurations.improvementRange.columns.length)
      ]
    },
    opportunity: commonConfigurations.opportunity,
    opportunityRange: commonConfigurations.opportunityRange,
    improvementAndOpportunity: {
      ...commonConfigurations.improvementAndOpportunity,
      columns: [
        ...commonConfigurations.improvementAndOpportunity.columns.slice(0, 5),
        'errorChange',
        ...commonConfigurations.improvementAndOpportunity.columns.slice(
          5,
          commonConfigurations.improvementAndOpportunity.columns.length
        )
      ]
    }
  },
  defaultView: 'opportunity',
  hasValueTypeToggle: true
};

// Determines which columns to show for each metric/view team, and their default sorting order
const metricTableSchema: MetricTableSchema = {
  cmi: cmiSchema,
  hcupcmi: cmiSchema,
  docScore: {
    views: {
      change: commonConfigurations.change,
      gap: commonConfigurations.gap,
      changeAndGap: commonConfigurations.changeAndGap
    },
    defaultView: 'gap',
    hasValueTypeToggle: false
  },
  severityCmi: {
    views: {
      improvement: {
        ...commonConfigurations.improvement,
        columns: [
          ...commonConfigurations.improvement.columns.slice(0, 5),
          'errorChange',
          ...commonConfigurations.improvement.columns.slice(5, commonConfigurations.improvement.columns.length)
        ]
      },
      improvementRange: {
        ...commonConfigurations.improvementRange,
        columns: [
          ...commonConfigurations.improvementRange.columns.slice(0, 5),
          'errorChange',
          ...commonConfigurations.improvementRange.columns.slice(
            5,
            commonConfigurations.improvementRange.columns.length
          )
        ]
      }
    },
    defaultView: 'improvement',
    hasValueTypeToggle: true
  },
  los: {
    views: {
      improvement: {
        columns: [
          ...commonConfigurations.improvement.columns.slice(0, commonConfigurations.improvement.columns.length - 1),
          'improvement'
        ],
        defaultSort: 'improvement'
      },
      opportunity: {
        columns: [
          'name',
          'action',
          'current',
          'hcupWgtAvg',
          'ceHcupWgtAvg',
          'gapVsCe',
          'errorGap',
          'cases',
          'opportunity'
        ],
        defaultSort: 'opportunity'
      },
      improvementAndOpportunity: {
        columns: [
          'name',
          'action',
          'historical',
          'current',
          'change',
          'hcupWgtAvg',
          'ceHcupWgtAvg',
          'gapVsCe',
          'errorGap',
          'cases',
          'improvement',
          'opportunity'
        ],
        defaultSort: 'opportunity'
      }
    },
    defaultView: 'opportunity',
    hasValueTypeToggle: false
  },
  elixhauserMortality: {
    views: {
      change: commonConfigurations.change,
      gap: commonConfigurations.gap,
      changeAndGap: commonConfigurations.changeAndGap
    },
    defaultView: 'change',
    hasValueTypeToggle: false
  },
  elixhauserReadmission: {
    views: {
      change: commonConfigurations.change,
      gap: commonConfigurations.gap,
      changeAndGap: commonConfigurations.changeAndGap
    },
    defaultView: 'change',
    hasValueTypeToggle: false
  },
  psi: {
    views: {
      change: commonConfigurations.change,
      gap: commonConfigurations.gap,
      changeAndGap: commonConfigurations.changeAndGap
    },
    defaultView: 'change',
    hasValueTypeToggle: false
  },
  raf: {
    views: {
      change: commonConfigurations.change,
      gap: commonConfigurations.gap,
      changeAndGap: commonConfigurations.changeAndGap
    },
    defaultView: 'change',
    hasValueTypeToggle: false
  },
  targetedConditions: {
    views: {
      change: {
        columns: ['name', 'conditionTypeId', 'action', 'historical', 'current', 'change', 'errorChange'],
        defaultSort: 'change'
      },
      gap: {
        columns: ['name', 'conditionTypeId', 'action', 'current', 'clinicallyExpected', 'gapVsCe', 'errorGap'],
        defaultSort: 'gapVsCe'
      },
      changeAndGap: {
        columns: [
          'name',
          'conditionTypeId',
          'action',
          'historical',
          'current',
          'change',
          'errorChange',
          'clinicallyExpected',
          'gapVsCe',
          'errorGap'
        ],
        defaultSort: 'gapVsCe'
      },
      opportunityRW: {
        columns: [
          'name',
          'conditionTypeId',
          'action',
          'current',
          'clinicallyExpected',
          'gapVsCe',
          'errorGap',
          'opportunityRW'
        ],
        defaultSort: 'opportunityRW'
      }
    },
    defaultView: 'opportunityRW',
    hasValueTypeToggle: false
  },
  allConditions: {
    views: {
      change: {
        columns: [
          'isTargetCondition',
          'name',
          'conditionTypeId',
          ...commonConfigurations.change.columns.slice(1, commonConfigurations.change.columns.length - 1)
        ],
        defaultSort: 'change'
      },
      gap: {
        columns: [
          'isTargetCondition',
          'name',
          'conditionTypeId',
          ...commonConfigurations.gap.columns.slice(1, commonConfigurations.gap.columns.length - 1)
        ],
        defaultSort: 'gapVsCe'
      },
      changeAndGap: {
        columns: [
          'isTargetCondition',
          'name',
          'conditionTypeId',
          ...commonConfigurations.changeAndGap.columns.slice(1, commonConfigurations.changeAndGap.columns.length - 1)
        ],
        defaultSort: 'gapVsCe'
      },
      opportunityRW: {
        columns: [
          'isTargetCondition',
          'name',
          'conditionTypeId',
          ...commonConfigurations.opportunityRW.columns.slice(1, commonConfigurations.opportunityRW.columns.length)
        ],
        defaultSort: 'opportunityRW'
      }
    },
    defaultView: 'opportunityRW',
    hasValueTypeToggle: false
  },
  condition: {
    views: {
      change: {
        columns: [...commonConfigurations.change.columns.slice(0, commonConfigurations.change.columns.length - 1)],
        defaultSort: 'change'
      },
      gap: {
        columns: [...commonConfigurations.gap.columns.slice(0, commonConfigurations.gap.columns.length - 1)],
        defaultSort: 'gapVsCe'
      },
      changeAndGap: {
        columns: [
          ...commonConfigurations.changeAndGap.columns.slice(0, commonConfigurations.changeAndGap.columns.length - 1)
        ],
        defaultSort: 'gapVsCe'
      },
      opportunityRW: commonConfigurations.opportunityRW
    },
    defaultView: 'gap',
    hasValueTypeToggle: false
  }
};

Object.freeze(metricTableSchema);

type ConditionTableSchema = Pick<
  MetricTableSchema,
  Metrics.allConditions | Metrics.condition | Metrics.targetedConditions
>;

const conditionTableSchema: ConditionTableSchema = {
  targetedConditions: {
    views: {
      change: {
        columns: ['name', 'historicalOtoE', 'otoE', 'otoEChange', 'action'],
        defaultSort: 'otoEChange'
      },
      gap: {
        columns: ['name', 'otoE', 'otoEce', 'otoEGapVsCe', 'action'],
        defaultSort: 'otoEGapVsCe'
      },
      changeAndGap: {
        columns: ['name', 'historicalOtoE', 'otoE', 'otoEChange', 'otoEce', 'otoEGapVsCe', 'action'],
        defaultSort: 'otoEGapVsCe'
      }
    },
    defaultView: 'gap',
    hasValueTypeToggle: false
  },
  allConditions: {
    views: {
      change: {
        columns: ['isTargetCondition', 'name', 'conditionTypeId', 'historicalOtoE', 'otoE', 'otoEChange', 'action'],
        defaultSort: 'otoEChange'
      },
      gap: {
        columns: ['isTargetCondition', 'name', 'conditionTypeId', 'otoE', 'otoEce', 'otoEGapVsCe', 'action'],
        defaultSort: 'otoEGapVsCe'
      },
      changeAndGap: {
        columns: [
          'isTargetCondition',
          'name',
          'conditionTypeId',
          'historicalOtoE',
          'otoE',
          'otoEChange',
          'otoEce',
          'otoEGapVsCe',
          'action'
        ],
        defaultSort: 'otoEGapVsCe'
      }
    },
    defaultView: 'gap',
    hasValueTypeToggle: false
  },
  condition: {
    views: {
      change: {
        columns: ['name', 'action', 'historicalOtoE', 'otoE', 'otoEChange'],
        defaultSort: 'otoEChange'
      },
      gap: {
        columns: ['name', 'action', 'otoE', 'otoEce', 'otoEGapVsCe'],
        defaultSort: 'otoEGapVsCe'
      },
      changeAndGap: {
        columns: ['name', 'action', 'historicalOtoE', 'otoE', 'otoEChange', 'otoEce', 'otoEGapVsCe'],
        defaultSort: 'otoEGapVsCe'
      }
    },
    defaultView: 'gap',
    hasValueTypeToggle: false
  }
};

Object.freeze(conditionTableSchema);

export const getMetricTableColumns = (
  metric: keyof typeof Metrics,
  view: keyof typeof Views,
  forOCE = false
): ColumnView => {
  try {
    if (forOCE && (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions')) {
      return { ...(conditionTableSchema[metric].views[view === 'opportunityRW' ? 'gap' : view] as ColumnView) };
    }

    return { ...(metricTableSchema[metric].views[view] as ColumnView) };
  } catch {
    return {
      columns: [],
      defaultSort: 'name'
    };
  }
};

export const getDefaultMetricTableColumns = (metric: keyof typeof Metrics, forOCE = false): ColumnView => {
  try {
    if (forOCE && (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions')) {
      return { ...(conditionTableSchema[metric].views[conditionTableSchema[metric].defaultView] as ColumnView) };
    }

    const config = metricTableSchema[metric];
    return { ...(metricTableSchema[metric].views[config.defaultView] as ColumnView) };
  } catch {
    return {
      columns: [],
      defaultSort: 'name'
    };
  }
};

type ViewsByMetric = {
  id: keyof typeof Views;
  label: string;
};

export const getMetricViews = (metric: keyof typeof Metrics, forOCE = false): ViewsByMetric[] => {
  const views =
    forOCE && (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions')
      ? conditionTableSchema[metric].views
      : metricTableSchema[metric].views;

  return Object.keys(views).map(key => ({
    id: key as keyof typeof Views,
    label: viewLabels[key as keyof typeof Views]
  }));
};

export const getDefaultViewForMetric = (metric: keyof typeof Metrics, forOCE = false): keyof typeof Views => {
  if (forOCE && (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions')) {
    return conditionTableSchema[metric].defaultView;
  }
  return metricTableSchema[metric].defaultView;
};

// Returns the following:
// IF the given view is associated with the metric, return that view
// ELSE IF the given view is analagous with another view associated with the metric, return that view
// ELSE return the first view associated with the given metric
export const getUpdatedViewFromMetric = (
  metric: keyof typeof Metrics,
  currentView: keyof typeof Views,
  forOCE = false
): keyof typeof Views => {
  const views = getMetricViews(metric, forOCE);
  if (views.find(view => view.id === currentView)) {
    return currentView;
  }

  const analagousView = getAnalagousView(currentView);
  if (
    analagousView &&
    views.find(view => view.id === analagousView) &&
    metric !== 'allConditions' &&
    metric !== 'targetedConditions'
  ) {
    return analagousView;
  }

  return getDefaultViewForMetric(metric, forOCE);
};

export const metricHasValueTypeToggle = (metric: keyof typeof Metrics, forOCE = false): boolean => {
  if (forOCE && (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions')) {
    return conditionTableSchema[metric].hasValueTypeToggle;
  }
  return metricTableSchema[metric].hasValueTypeToggle;
};

export const getNodeTypeLabelForNode = (node: NodeType): NodeTypeLabel => {
  switch (node) {
    case 'Entity':
    case 'System View':
      return 'System';
    case 'Hospital':
      return 'Hospital';
    case 'Specialty Group':
      return 'Physician Group';
    case 'Provider':
      return 'Physician Name';
    case 'Conditions':
      return 'Clinical Condition';
    default:
      return 'N/A';
  }
};

export interface TableSelectableColumn {
  column: ColumnName;
  title: string;
  isVisible: boolean;
}

export interface TableSelectableColumnConfig {
  columns: TableSelectableColumn[];
  defaultSort: ColumnName;
  length: number;
  toStringArray: () => string[];
  last: () => TableSelectableColumn;
}

export const getTableSelectableColumns = (
  metric: keyof typeof Metrics,
  view: keyof typeof Views | null,
  activeColumns?: ColumnName[]
): TableSelectableColumnConfig => {
  const tableConfig =
    view !== null && view !== undefined ? getMetricTableColumns(metric, view) : getDefaultMetricTableColumns(metric);

  const selectableTableColumns = tableConfig.columns.map(column => {
    return {
      column,
      title: Resources.TableColumns[column],
      isVisible: activeColumns === undefined || activeColumns.length === 0 ? true : activeColumns.includes(column)
    };
  });

  return {
    columns: selectableTableColumns,
    defaultSort: tableConfig.defaultSort,
    length: selectableTableColumns.filter(column => column.isVisible).length,
    toStringArray: (): string[] =>
      selectableTableColumns.filter(column => column.isVisible).map(column => column.column),
    last: (): TableSelectableColumn => {
      const activeList = selectableTableColumns.filter(column => column.isVisible);
      return activeList[activeList.length - 1];
    }
  };
};
