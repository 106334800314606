export type CMIAnalysisTypes = 'landing' | 'single' | 'comparison' | 'nationalcomparison';
export type EntitySelection = {
  name: string;
  id: number;
};
export type CMIAnalysisViews = 'cmi' | 'severity' | 'drgmix';

/* Actions */
const SET_ANALYSIS_TYPE = 'SET_ANALYSIS_TYPE';

type SetAnalysisTypePayload = {
  analysisType: CMIAnalysisTypes;
  entity?: EntitySelection;
  peerEntity?: EntitySelection;
};

interface SetAnalysisType {
  type: typeof SET_ANALYSIS_TYPE;
  payload: SetAnalysisTypePayload;
}

const CLEAR_ANALYSIS_TYPE = 'CLEAR_ANALYSIS_TYPE';

interface ClearAnalysisType {
  type: typeof CLEAR_ANALYSIS_TYPE;
  payload: null;
}

export type CMIAnalysisSelectionActionTypes = SetAnalysisType | ClearAnalysisType;

export const setAnalysisType = (args: SetAnalysisTypePayload): CMIAnalysisSelectionActionTypes => ({
  type: 'SET_ANALYSIS_TYPE',
  payload: args
});

export const clearAnalysisType = (): CMIAnalysisSelectionActionTypes => ({
  type: 'CLEAR_ANALYSIS_TYPE',
  payload: null
});

export type CMIAnalysisSelectionsState = {
  analysisType: CMIAnalysisTypes;
  entityId?: number;
  peerEntityId?: number;
  entityName: string;
  peerEntityName: string;
};

export const initialState: CMIAnalysisSelectionsState = {
  analysisType: 'landing',
  entityId: undefined,
  peerEntityId: undefined,
  entityName: '',
  peerEntityName: ''
};

const reducer = (state = initialState, action: CMIAnalysisSelectionActionTypes): CMIAnalysisSelectionsState => {
  switch (action.type) {
    case 'SET_ANALYSIS_TYPE':
      if (!action.payload.entity) {
        throw new Error(
          'Cannot open Single Entity, Entity Comparison or National Medicare Comparison without an entity selected.'
        );
      }

      if (action.payload.analysisType === 'single') {
        return {
          ...state,
          analysisType: action.payload.analysisType,
          entityId: action.payload.entity.id,
          entityName: action.payload.entity.name
        };
      }

      if (!action.payload.peerEntity) {
        throw new Error(
          'Cannot open Entity Comparison or National Medicare Comparison without a peer entity selected.'
        );
      }

      return {
        ...state,
        analysisType: action.payload.analysisType,
        entityId: action.payload.entity.id,
        peerEntityId: action.payload.peerEntity.id,
        entityName: action.payload.entity.name,
        peerEntityName: action.payload.peerEntity.name
      };
    case 'CLEAR_ANALYSIS_TYPE': {
      return {
        ...state,
        analysisType: 'landing',
        entityId: undefined,
        entityName: '',
        peerEntityId: undefined,
        peerEntityName: ''
      };
    }
    default:
      return state;
  }
};

export default reducer;
