import { useSelector, useDispatch } from 'react-redux';
import { AuthenticationStatus, AuthStatusAction } from '@clinintell/modules/authenticationStatus';
import { User, UserAction, userSaga } from '@clinintell/modules/user';
import { UserDataset, UserManagementAction, userManagementSaga } from '@clinintell/modules/userManagement';
import { AppConfig, AppConfigActionWithPayload } from '@clinintell/modules/appConfig';
import { Dispatch } from 'react';
import { MetricsNavigation, MetricsNavigationAction } from '@clinintell/modules/metricsNavigation';
import { HospitalsState, HospitalAction, hospitalSaga, allHospitalSaga } from '@clinintell/modules/hospitals';
import { all } from 'redux-saga/effects';
import { Saga } from 'redux-saga';
import {
  PhysicianGroupsState,
  PhysicianGroupAction,
  physicianGroupForHospitalSaga
} from '@clinintell/modules/physicianGroups';
import {
  ProviderReports,
  ProviderReportsAction,
  providerReportLogForSpecialtyGroupSaga
} from '@clinintell/modules/providerReports';
import { Auth0Config, Auth0ConfigActionWithPayload } from './auth0Config';
import { DefaultDatesState, DefaultDatesAction, defaultDatesSaga, defaultSAFDatesSaga } from './defaultDates';

import { ConditionsState, conditionsSaga, ConditionsAction } from '@clinintell/modules/conditions';
import {
  nextTrainingSequenceSaga,
  testQuestionSaga,
  TrainingAction,
  TrainingDataset,
  trainingStatusSaga,
  videoWatchedSaga
} from './training';

import { DashboardDataset, DashboardAction, dashboardDataSaga } from '@clinintell/modules/dashboard';
import { Dashboard2Dataset, Dashboard2Action, dashboard2DataSaga } from '@clinintell/modules/dashboard2';
import {
  groupTrainingAssignmentSaga,
  groupTrainingAssignmentSaveSaga,
  TrainingAssignmentAction,
  trainingAssignmentSaga,
  TrainingAssignmentState
} from './trainingAssignment';

import { docScoreGoalDataSaga, DocScoreGoalAction, HospitalDocScoreGoalDataset } from './autoGenStrategy';

import { TableAction, TableState } from '@clinintell/modules/table';
import {
  CMIAnalysis,
  CMIAnalysisActionTypes,
  cmiAnalysisBestFitSaga,
  cmiAnalysisSelectedDatesSaga,
  cmiAnalysisSummaryRecordsSaga
} from './cmiAnalysis';
import { fetchPathToRootFromNodeSaga, OrgTree, OrgTreeActionTypes, OrgTreeState } from './orgTree';
import {
  CMIComparison,
  CMIComparisonActionTypes,
  cmiComparisonSettingsSaga,
  cmiComparisonSummaryRecordsSaga
} from './cmiComparison';
import {
  deleteMedicareHistogramSavedFilterSaga,
  duplicateMedicareHistogramSavedFilterSaga,
  fetchMedicareHistogramSavedFilterSaga,
  fetchMedicareHistogramSavedFiltersSaga,
  MedicareComparisonActionTypes,
  MedicareComparisonState,
  putMedicareHistogramSavedFilterSaga,
  renameMedicareHistogramSavedFilterSaga,
  saveMedicareHistogramFilterSaga
} from './medicareComparison';
import { BreadcrumbNavigation, BreadcrumbNavigationActionTypes } from './breadcrumbNavigation';
import { MenuNavigationActionTypes, MenuNavigationState } from './menuNavigation';
import { CMIAnalysisSelectionActionTypes, CMIAnalysisSelectionsState } from './cmiAnalysisSelections';
import { SystemManagementData, SystemManagementAction } from './systemManagement';

export interface CombinedState {
  authenticationStatus: AuthenticationStatus;
  user: User;
  appConfig: AppConfig;
  metricsNavigation: MetricsNavigation;
  hospitals: HospitalsState;
  physicianGroups: PhysicianGroupsState;
  providerReports: ProviderReports;
  conditions: ConditionsState;
  auth0Config: Auth0Config;
  defaultDates: DefaultDatesState;
  training: TrainingDataset;
  dashboardData: DashboardDataset;
  dashboard2Data: Dashboard2Dataset;
  trainingAssignment: TrainingAssignmentState;
  tableState: TableState;
  hospitalDocScoreGoalDataset: HospitalDocScoreGoalDataset;
  cmiAnalysis: CMIAnalysis;
  orgTree: OrgTreeState;
  userManagement: UserDataset;
  cmiComparison: CMIComparison;
  medicareComparison: MedicareComparisonState;
  breadcrumbNavigation: BreadcrumbNavigation;
  menuNavigation: MenuNavigationState;
  cmiAnalysisSelections: CMIAnalysisSelectionsState;
  systemManagementData: SystemManagementData;
}

// thanks https://github.com/DefinitelyTyped/DefinitelyTyped/blob/2ab7a8073719008b169a3069977cc66f2e87a1e2/types/react-redux/index.d.ts#L544
export interface TypedUseSelectorHook<TState> {
  <TSelected>(
    selector: (state: TState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
  ): TSelected;
}

export const useTypedSelector: TypedUseSelectorHook<CombinedState> = useSelector;

export const useAuthenticationStatus = (): AuthenticationStatus =>
  useSelector<CombinedState, AuthenticationStatus>(store => store.authenticationStatus);
export const useAuthenticationStatusDispatch = (): Dispatch<AuthStatusAction> =>
  useDispatch<Dispatch<AuthStatusAction>>();

export const useUser = (): User => useSelector<CombinedState, User>(store => store.user);
export const useUserDispatch = (): Dispatch<UserAction> => useDispatch<Dispatch<UserAction>>();

export const useAppConfig = (): AppConfig => useSelector<CombinedState, AppConfig>(store => store.appConfig);
export const useAppConfigDispatch = (): Dispatch<AppConfigActionWithPayload> =>
  useDispatch<Dispatch<AppConfigActionWithPayload>>();

export const useMetricsNavigation = (): MetricsNavigation =>
  useSelector<CombinedState, MetricsNavigation>(store => store.metricsNavigation);
export const useMetricsNavigationDispatch = (): Dispatch<MetricsNavigationAction> =>
  useDispatch<Dispatch<MetricsNavigationAction>>();

export const useHospitals = (): HospitalsState => useSelector<CombinedState, HospitalsState>(store => store.hospitals);
export const useHospitalsDispatch = <T>(): Dispatch<HospitalAction<T>> => useDispatch<Dispatch<HospitalAction<T>>>();

export const usePhysicianGroups = (): PhysicianGroupsState =>
  useSelector<CombinedState, PhysicianGroupsState>(store => store.physicianGroups);
export const usePhysicianGroupsDispatch = <T>(): Dispatch<PhysicianGroupAction<T>> =>
  useDispatch<Dispatch<PhysicianGroupAction<T>>>();

export const useProviderReports = (): ProviderReports =>
  useSelector<CombinedState, ProviderReports>(store => store.providerReports);
export const useProviderReportsDispatch = <T>(): Dispatch<ProviderReportsAction<T>> =>
  useDispatch<Dispatch<ProviderReportsAction<T>>>();

export const useConditions = (): ConditionsState =>
  useSelector<CombinedState, ConditionsState>(store => store.conditions);
export const useConditionsDispatch = <T>(): Dispatch<ConditionsAction<T>> =>
  useDispatch<Dispatch<ConditionsAction<T>>>();
export const useAuth0Config = (): Auth0Config => useSelector<CombinedState, Auth0Config>(store => store.auth0Config);
export const useAuth0ConfigDispatch = (): Dispatch<Auth0ConfigActionWithPayload> =>
  useDispatch<Dispatch<Auth0ConfigActionWithPayload>>();

export const useDefaultDates = (): DefaultDatesState =>
  useSelector<CombinedState, DefaultDatesState>(store => store.defaultDates);
export const useDefaultDatesDispatch = <T>(): Dispatch<DefaultDatesAction<T>> =>
  useDispatch<Dispatch<DefaultDatesAction<T>>>();

export const useTraining = (): TrainingDataset => useSelector<CombinedState, TrainingDataset>(store => store.training);
export const useTrainingDispatch = <T>(): Dispatch<TrainingAction<T>> => useDispatch<Dispatch<TrainingAction<T>>>();

export const useDashboard = (): DashboardDataset =>
  useSelector<CombinedState, DashboardDataset>(store => store.dashboardData);
export const useDashboardDispatch = <T>(): Dispatch<DashboardAction<T>> => useDispatch<Dispatch<DashboardAction<T>>>();

export const useDashboard2 = (): Dashboard2Dataset =>
  useSelector<CombinedState, Dashboard2Dataset>(store => store.dashboard2Data);
export const useDashboard2Dispatch = <T>(): Dispatch<Dashboard2Action<T>> =>
  useDispatch<Dispatch<Dashboard2Action<T>>>();

export const useTrainingAssignment = (): TrainingAssignmentState =>
  useSelector<CombinedState, TrainingAssignmentState>(store => store.trainingAssignment);
export const useTrainingAssignmentDispatch = <T>(): Dispatch<TrainingAssignmentAction<T>> =>
  useDispatch<Dispatch<TrainingAssignmentAction<T>>>();

export const useTableState = (): TableState => useSelector<CombinedState, TableState>(store => store.tableState);
export const useTableDispatch = (): Dispatch<TableAction> => useDispatch<Dispatch<TableAction>>();

export const useHospitalDocScoreGoal = (): HospitalDocScoreGoalDataset =>
  useSelector<CombinedState, HospitalDocScoreGoalDataset>(store => store.hospitalDocScoreGoalDataset);
export const useHospitalDocScoreDispatch = <T>(): Dispatch<DocScoreGoalAction<T>> =>
  useDispatch<Dispatch<DocScoreGoalAction<T>>>();
export const useCMIAnalysisState = (): CMIAnalysis =>
  useSelector<CombinedState, CMIAnalysis>(store => store.cmiAnalysis);
export const useCMIAnalysisDispatch = (): Dispatch<CMIAnalysisActionTypes> =>
  useDispatch<Dispatch<CMIAnalysisActionTypes>>();

export const useClientOrgTreeState = (): OrgTree => useSelector<CombinedState, OrgTree>(store => store.orgTree.client);
export const useSAFOrgTreeState = (): OrgTree => useSelector<CombinedState, OrgTree>(store => store.orgTree.saf);
export const useOrgTreeDispatch = (): Dispatch<OrgTreeActionTypes> => useDispatch<Dispatch<OrgTreeActionTypes>>();

export const useUserManagement = (): UserDataset =>
  useSelector<CombinedState, UserDataset>(store => store.userManagement);
export const useUserManagementDispatch = <T>(): Dispatch<UserManagementAction<T>> =>
  useDispatch<Dispatch<UserManagementAction<T>>>();

export const useCMIComparisonState = (): CMIComparison =>
  useSelector<CombinedState, CMIComparison>(store => store.cmiComparison);
export const useCMIComparisonDispatch = (): Dispatch<CMIComparisonActionTypes> =>
  useDispatch<Dispatch<CMIComparisonActionTypes>>();

export const useMedicareComparisonState = (): MedicareComparisonState =>
  useSelector<CombinedState, MedicareComparisonState>(store => store.medicareComparison);
export const useMedicareComparisonDispatch = (): Dispatch<MedicareComparisonActionTypes> =>
  useDispatch<Dispatch<MedicareComparisonActionTypes>>();

export const useBreadcrumbNavigationState = (): BreadcrumbNavigation =>
  useSelector<CombinedState, BreadcrumbNavigation>(store => store.breadcrumbNavigation);
export const useBreadcrumbNavigationDispatch = (): Dispatch<BreadcrumbNavigationActionTypes> =>
  useDispatch<Dispatch<BreadcrumbNavigationActionTypes>>();

export const useMenuNavigationState = (): MenuNavigationState =>
  useSelector<CombinedState, MenuNavigationState>(store => store.menuNavigation);
export const useMenuNavigationDispatch = (): Dispatch<MenuNavigationActionTypes> =>
  useDispatch<Dispatch<MenuNavigationActionTypes>>();

export const useCMIAnalysisSelectionsState = (): CMIAnalysisSelectionsState =>
  useSelector<CombinedState, CMIAnalysisSelectionsState>(store => store.cmiAnalysisSelections);
export const useCMIAnalysisSelectionsDispatch = (): Dispatch<CMIAnalysisSelectionActionTypes> =>
  useDispatch<Dispatch<CMIAnalysisSelectionActionTypes>>();

export const useSystemManagement = (): SystemManagementData =>
  useSelector<CombinedState, SystemManagementData>(store => store.systemManagementData);
export const useSystemManagementDispatch = <T>(): Dispatch<SystemManagementAction<T>> =>
  useDispatch<Dispatch<SystemManagementAction<T>>>();

// saga middleware expects to see Saga<any[]> return so any is valid here
// eslint-disable-next-line
// @ts-ignore
export function* rootSaga(): Saga<any[]> /* eslint-disable-line */ {
  yield all([
    userSaga(),
    userManagementSaga(),
    hospitalSaga(),
    allHospitalSaga(),
    physicianGroupForHospitalSaga(),
    providerReportLogForSpecialtyGroupSaga(),
    defaultDatesSaga(),
    defaultSAFDatesSaga(),
    conditionsSaga(),
    dashboardDataSaga(),
    dashboard2DataSaga(),
    trainingStatusSaga(),
    nextTrainingSequenceSaga(),
    testQuestionSaga(),
    videoWatchedSaga(),
    groupTrainingAssignmentSaga(),
    groupTrainingAssignmentSaveSaga(),
    trainingAssignmentSaga(),
    docScoreGoalDataSaga(),
    cmiAnalysisBestFitSaga(),
    cmiAnalysisSelectedDatesSaga(),
    cmiAnalysisSummaryRecordsSaga(),
    fetchPathToRootFromNodeSaga(),
    cmiComparisonSettingsSaga(),
    cmiComparisonSummaryRecordsSaga(),
    fetchMedicareHistogramSavedFiltersSaga(),
    fetchMedicareHistogramSavedFilterSaga(),
    saveMedicareHistogramFilterSaga(),
    deleteMedicareHistogramSavedFilterSaga(),
    putMedicareHistogramSavedFilterSaga(),
    renameMedicareHistogramSavedFilterSaga(),
    duplicateMedicareHistogramSavedFilterSaga()
  ]);
}
