import { useCMIAnalysisDispatch, useCMIAnalysisState } from '@clinintell/modules/store';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AnalysisSelectors from './sections/AnalysisSelectors';
import AnalysisSummary from './sections/AnalysisSummary';
import CMIAnalysisDetails from './sections/CMIAnalysisDetails';
import DRGMixAnalysisDetails from './sections/DRGMixAnalysisDetails';
import SeverityCMIAnalysisDetails from './sections/SeverityCMIAnalysisDetails';
import EntitySelectionPanel from './sections/EntitySelectionPanel';
import useAlert from '@clinintell/components/alert/logic/useAlert';
import { CMIAnalysisViews } from '@clinintell/modules/cmiAnalysisSelections';
import { setCMIAnalysisView } from '@clinintell/modules/cmiAnalysis';
import PageTitle from '@clinintell/components/typography/pageTitle/PageTitle';

export type PatientMixDateRanges = {
  historicalMin: Date;
  historicalMax: Date;
  currentMin: Date;
  currentMax: Date;
};

export type PeriodType = 'historical' | 'current';

const CMIAnalysis: React.FC = () => {
  const [selectorIsOpen, setSelectorIsOpen] = useState(false);

  const { entityId: selectedEntityId, entityName: selectedEntityName, analysisView } = useCMIAnalysisState();
  const cmiAnalysisDispatch = useCMIAnalysisDispatch();

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { pushAlert } = useAlert();

  const { error, noBestFit } = useCMIAnalysisState();

  useEffect(() => {
    if (error) {
      pushAlert({ message: error, variant: 'error' });
    }
  }, [error, pushAlert]);

  const handleAnalysisViewChange = (view: CMIAnalysisViews) => {
    cmiAnalysisDispatch(setCMIAnalysisView({ analysisView: view }));
  };

  let analysisDetailsComponent;
  if (noBestFit || selectedEntityId === undefined) {
    analysisDetailsComponent = null;
  } else {
    switch (analysisView) {
      case 'cmi':
        analysisDetailsComponent = <CMIAnalysisDetails />;
        break;
      case 'severity':
        analysisDetailsComponent = <SeverityCMIAnalysisDetails />;
        break;
      case 'drgmix':
        analysisDetailsComponent = <DRGMixAnalysisDetails />;
        break;
      default:
        throw new Error('Invalid analysis type');
    }
  }

  return (
    <>
      <Box marginY={3} marginX={isMobileView ? 0 : 3}>
        <PageTitle title={selectedEntityName === undefined ? '' : selectedEntityName} />
        <Box marginTop={3}>
          <Box marginBottom={3}>
            <AnalysisSelectors
              entityName={selectedEntityName || ''}
              onSelect={(): void => setSelectorIsOpen(true)}
              analysisType={analysisView}
            />
          </Box>
          <AnalysisSummary onAnalysisChange={handleAnalysisViewChange} analysisType={analysisView} />
          {analysisDetailsComponent}
        </Box>
      </Box>
      {selectedEntityId !== undefined && selectedEntityName !== undefined ? (
        <EntitySelectionPanel
          isOpen={selectorIsOpen}
          toggleIsOpen={(): void => setSelectorIsOpen(false)}
          defaultEntityId={selectedEntityId}
          defaultEntityName={selectedEntityName}
          analysisType={analysisView}
        />
      ) : null}
    </>
  );
};

export default CMIAnalysis;
