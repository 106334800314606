import {
  ChartDataSetAverageTypes,
  ChartDataSetType
} from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { ChartStyles } from '@clinintell/containers/metricsTimeSeries/logic/useChartStyles';
import { XAxisSet, YAxisSet, ChartDataset, ChartTypes, ErrorBars } from '@clinintell/components/Chart/types';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import { DatasetConfiguration, YAxesConfiguration } from './getChartAndTableConfiguration';
import { GraphData, MetricData } from '@clinintell/api/apiSchemas';

interface Output {
  xAxis: XAxisSet;
  yAxes: YAxisSet[];
  chartDatasets: ChartDataset[];
  hasErrorBand: boolean;
}

type YAxesMap = {
  [key in ChartTypes]?: YAxisSet;
};

type GenerateChartProps = {
  chartDatasets: GraphData[];
  chartStyles: ChartStyles[];
  chartPeriodType: keyof typeof ChartDataSetAverageTypes;
  datasetConfigurations: DatasetConfiguration[];
  defaultHiddenChartDatasets?: Set<ChartDataSetType>;
  yAxes: YAxesConfiguration[];
  forOCE?: boolean;
};

// Used specifically for metric and condition chart - takes in Chart JSON values and Chart styles and outputs
// Y axes sets, X axis, and chart datasets usable by Chart component
const generateChartProps = ({
  chartDatasets,
  chartStyles,
  chartPeriodType,
  datasetConfigurations,
  defaultHiddenChartDatasets,
  yAxes,
  forOCE = false
}: GenerateChartProps): Output => {
  // Process datasets. Build Y axes array based on what's in the datasets. There can be two Y axes if both bar and line chart
  // types are present in the datasets.
  const yAxesMap: YAxesMap = {};
  const xAxis: XAxisSet = {
    title: '',
    labels: []
  };

  const dataPointsHoverContent: string[] = [];
  let hasErrorBand = false;

  const chartDataSetProps = chartDatasets
    .filter(dataset => dataset.dataSetAverageType === chartPeriodType)
    .map((dataset, datasetIndex) => {
      // TODO -- apply intervention date
      const appliedStyles = chartStyles.find(style => style.id === dataset.dataSetType);
      if (!appliedStyles) {
        throw new Error(`Can't find styles for chart dataset type ${dataset.dataSetType}. Check the configs.`);
      }

      const appliedConfig = datasetConfigurations.find(config => config.id === dataset.dataSetType);
      if (!appliedConfig) {
        throw new Error(
          `Can't find configuration for chart dataset type ${dataset.dataSetType}. Check the table and chart configuration module.`
        );
      }

      let tagValues: (string | null)[] = [];
      // Track the min and max values of this particular dataset
      let min = Number.MAX_SAFE_INTEGER;
      let max = 0;

      let errorBars: ErrorBars | undefined;

      // ANNOTATION: remove null/undefined as value types for MetricData and MetricData -> name properties.
      let values: (number | null)[] = (dataset.dataSet as MetricData[]).map((dataPoint, dataPointIndex) => {
        if (dataPoint.value !== null && dataPoint.value !== undefined && dataPoint.value < min) {
          min = dataPoint.value;
        }

        if (dataPoint.value !== null && dataPoint.value !== undefined && dataPoint.value > max) {
          max = dataPoint.value;
        }

        // Each dataset has the information we need to build the x axis. On the first dataset, go ahead
        // and build the x axis
        // Points are padded to the left and right of the chart, match that with the x axis labels by adding
        // blank values to the first and last of the array
        if (datasetIndex === 0) {
          if (dataPointIndex === 0) {
            xAxis.labels.push('');
          }

          xAxis.labels.push(dataPoint.name as string);

          if (dataPointIndex === (dataset.dataSet as MetricData[]).length - 1) {
            xAxis.labels.push('');
          }
        }

        // If there is an error band, adjust the min and max as necessary and build the error bar object
        if (dataPoint.errorBand && dataPoint.value !== null) {
          hasErrorBand = true;
          // const maxVal = dataPoint.value + dataPoint.errorBand;
          // if (maxVal > max) {
          //  max = maxVal;
          // }

          const minVal = Number(dataPoint.value) - dataPoint.errorBand;
          if (minVal < min) {
            min = minVal;
          }

          if (!errorBars) {
            errorBars = {};
          }

          errorBars[dataPoint.name as string] = {
            plus: dataPoint.errorBand,
            minus: forOCE && dataPoint.errorBandNegative ? -dataPoint.errorBandNegative : -dataPoint.errorBand
          };
        }

        // Add value to hover content array, rounding to precision that is configured in the styles
        dataPointsHoverContent.push(`${formatToDecimalPlaces(dataPoint.value || '', appliedConfig.precision)}`);

        // Add tag if available
        if (dataPoint.tag) tagValues.push(dataPoint.tag);

        return dataPoint.value === undefined || dataPoint.value === null ? null : dataPoint.value;
      });

      // Points are padded to the left and right of the chart, this simulates the desired effect
      values = [null, ...values, null];
      tagValues = [null, ...tagValues, null];

      let restProps;
      if (appliedStyles.lineType === 'Dashed') {
        restProps = { borderDash: [5, 5] };
      }
      if (appliedStyles.pointStyle) {
        restProps = { ...restProps, pointStyle: appliedStyles.pointStyle };
      }

      let yAxisId = 0;
      if (yAxes.length > 1) {
        yAxisId = appliedStyles.chartType === 'Line' ? 0 : 1;
      }

      const datasetProp: ChartDataset = {
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        barThickness: 30,
        verticalAnnotation: '',
        dataPointsHoverContent,
        data: values,
        tagDataSet: tagValues,
        label: appliedStyles.label,
        borderColor: appliedStyles.color,
        backgroundColor: appliedStyles.color,
        pointHoverBackgroundColor: appliedStyles.color,
        pointHoverBorderColor: appliedStyles.color,
        fill: false,
        errorBars,
        borderWidth: appliedStyles.chartType === 'Line' ? 2.2 : 0,
        type: appliedStyles.chartType.toLowerCase(),
        minValue: min,
        maxValue: max,
        yAxisID: yAxisId,
        precision: appliedConfig.precision,
        postfix: appliedConfig.usePercentage ? '%' : '',
        hiddenByDefault:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          defaultHiddenChartDatasets && defaultHiddenChartDatasets.has(dataset.dataSetType) ? true : false,
        ...restProps
      };

      // Y axes need global min and max among all datasets to correctly calculate the step size and intervals
      const yAxisData = yAxesMap[appliedStyles.chartType];

      if (yAxisData) {
        yAxisData.min = Math.min(yAxisData.min, min);
        yAxisData.max = Math.max(yAxisData.max, max);
      } else {
        const { label, precision, usePercentage } = yAxes[yAxisId];
        yAxesMap[appliedStyles.chartType] = {
          min,
          max,
          title: label,
          dataType: usePercentage ? 'percentage' : 'decimal',
          precision
        };
      }

      return datasetProp;
    });

  // Make sure in cases of there being 2 yaxes, the line chart set comes first everytime
  const yAxesArray = Object.keys(yAxesMap)
    .map(key => key)
    .sort(a => {
      return a === 'Line' ? -1 : 1;
    });

  return {
    xAxis,
    yAxes: yAxesArray.map(key => yAxesMap[key as keyof typeof ChartTypes] as YAxisSet),
    chartDatasets: chartDataSetProps,
    hasErrorBand
  };
};

export default generateChartProps;
