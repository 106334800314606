import { FetchNodeArgs } from '@clinintell/containers/entityNavigation/logic/useOrgTreeByType';
import { NodeTypeIds, TreeNode } from '@clinintell/modules/orgTree';
import {
  dfsFindLineageInTree,
  dfsFindLineageInTreeWithSpecificParent,
  dfsFindNodeInTree,
  dfsFindNodeInTreeWithSpecificParentId
} from '@clinintell/utils/treeTraversal';
import React, { ChangeEvent, createContext, useCallback, useContext, useEffect } from 'react';
import { findMaterialUITreeViewParentId } from './findMaterialUITreeViewParentId';
import useTreeExpandedNodes from './useTreeExpandedNodes';

type TreeState = {
  rootNode: TreeNode | null;
  selectednode: number | null;
  expandedNodes: string[];
  isLoadingNodeId: number | null;
};

const TreeStateContext = createContext<TreeState>({
  expandedNodes: [],
  selectednode: null,
  rootNode: null,
  isLoadingNodeId: null
});

type TreeDispatch = {
  selectNode: (id: number, parentId?: number) => void;
  expandNode: (id: number) => void;
  toggleNodes: (event: ChangeEvent<unknown>, ids: string[]) => void;
};

const TreeDispatchContext = createContext<TreeDispatch>({
  selectNode: () => {
    throw new Error('selectNode method is not set');
  },
  expandNode: () => {
    throw new Error('expandNode method is not set');
  },
  toggleNodes: () => {
    throw new Error('toggleNodes method is not set');
  }
});

export type NodeDetails = {
  name: string;
  type: number;
  isNew: boolean;
};

export const extractDetailsFromNode = (node: TreeNode): NodeDetails => ({
  name: node.name,
  type: node.nodeTypeId,
  isNew: !node.isLeaf && node.children.length === 0
});

export type TreeProviderProps = {
  defaultExpandedNodes: string[];
  selectednode: number;
  onNodeSelected: (entity: number, parentId?: number) => void;
  onNodeToggled: (args: FetchNodeArgs) => void;
  rootNode: TreeNode;
  maxnodetype: keyof typeof NodeTypeIds;
};

const getMaterialUiTreeViewParentId = (id: number): number | undefined => {
  const treeElement = document.getElementById(id.toString());

  const closestParentTreeItemRoot = treeElement?.closest('.MuiTreeItem-root');
  if (closestParentTreeItemRoot) {
    const parentElement = closestParentTreeItemRoot.parentElement;
    if (parentElement) {
      const id = parentElement.getAttribute('id');
      if (id !== null) {
        return Number(id);
      }
    }
  }

  return undefined;
};

export const TreeProvider: React.FC<TreeProviderProps> = ({
  defaultExpandedNodes,
  onNodeSelected,
  onNodeToggled,
  rootNode,
  selectednode,
  children,
  maxnodetype
}) => {
  const { setExpandedNodes, expandedNodes } = useTreeExpandedNodes(defaultExpandedNodes);

  const fetchNodeDetails = useCallback(
    (id: number, parentId?: number): [TreeNode, NodeDetails] | null => {
      const fetchedNode =
        parentId !== undefined
          ? dfsFindNodeInTreeWithSpecificParentId(rootNode, id, parentId)
          : dfsFindNodeInTree(rootNode, id);

      if (!fetchedNode) {
        return null;
      }

      return [
        fetchedNode,
        {
          name: fetchedNode.name,
          type: fetchedNode.nodeTypeId,
          // If node has no children but it's not a leaf or is under the given max node type, that means it hasn't been processed or even added to the tree yet.
          isNew: !fetchedNode.isLeaf && fetchedNode.children.length === 0
        }
      ];
    },
    [rootNode]
  );

  const fetchLineageDetails = useCallback(
    (id: number, parentId?: number): number[] | null => {
      return parentId !== undefined
        ? dfsFindLineageInTreeWithSpecificParent(rootNode, id, parentId)
        : dfsFindLineageInTree(rootNode, id);
    },
    [rootNode]
  );

  useEffect(() => {
    const parentElementId = findMaterialUITreeViewParentId(selectednode);
    const fetchedNode = fetchNodeDetails(selectednode, parentElementId);

    if (!fetchedNode) {
      return;
    }

    const [node] = fetchedNode;

    // If node has children, then it has already been processed and the full path will be in the tree.
    // Can simply DFS and find lineage, and set expanded nodes from there.
    if (node.children.length > 0 || node.isLeaf || (!node.isLeaf && NodeTypeIds[node.nodeTypeId] >= maxnodetype)) {
      const lineage = fetchLineageDetails(selectednode, parentElementId);
      if (lineage) {
        setExpandedNodes(nodes => {
          return [...lineage.map(node => node.toString())];
        });
      }
    } else {
      setExpandedNodes(nodes => {
        return [...nodes, selectednode.toString()];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectednode, setExpandedNodes]);

  // Happens when you click on an entity name, regardless if it has been loaded into the tree yet or not
  const handleNodeSelection = (id: number, parentId?: number): void => {
    if (!rootNode) {
      return;
    }

    onNodeSelected(id, parentId);

    if (parentId === undefined) {
      return;
    }

    const expandedLineage = dfsFindLineageInTreeWithSpecificParent(rootNode, Number(id), parentId);
    if (!expandedLineage) {
      return;
    }

    setExpandedNodes(expandedLineage.map(record => record.toString()));
  };

  // Happens when you click the arrow to the left of an entity BEFORE it has been loaded into the tree
  const handleNodeExpanded = (id: number, parentId?: number): void => {
    const fetchedNodeDetails = fetchNodeDetails(id, parentId);

    if (!fetchedNodeDetails) {
      return;
    }

    if (parentId === undefined) {
      return;
    }

    const expandedLineage = fetchLineageDetails(Number(id), parentId);
    if (!expandedLineage) {
      return;
    }

    setExpandedNodes(expandedLineage.map(record => record.toString()));

    onNodeToggled({ nodeId: id, nodeDetails: fetchedNodeDetails[1], parentId });
  };

  // Happens when you click the arrow to the left of an entity AFTER it has been loaded into the tree
  const handleToggledNodes = (event: ChangeEvent<unknown>, nodeIds: string[]): void => {
    if (!rootNode) {
      return;
    }

    const toggledElement = (event.currentTarget as HTMLElement).closest('.MuiTreeItem-root');
    const toggledContainer = toggledElement?.parentElement;

    if (!toggledContainer || !toggledElement) {
      return;
    }

    const id = toggledContainer.getAttribute('id');
    if (id === null) {
      return;
    }

    const parentId = getMaterialUiTreeViewParentId(Number(id));
    // If no parent, were at the root so let default logic handle this
    if (parentId === undefined) {
      setExpandedNodes(nodeIds);
      return;
    }

    const expandedLineage = dfsFindLineageInTreeWithSpecificParent(rootNode, Number(id), parentId);
    if (!expandedLineage) {
      return;
    }

    const isExpanded = toggledElement.getAttribute('aria-expanded');
    const expandedNodes = expandedLineage.map(record => record.toString());

    if (isExpanded === 'true') {
      expandedNodes.pop();
    }

    setExpandedNodes(expandedNodes);
  };

  return (
    <TreeStateContext.Provider
      value={{
        rootNode,
        expandedNodes,
        isLoadingNodeId: null,
        selectednode
      }}
    >
      <TreeDispatchContext.Provider
        value={{
          selectNode: handleNodeSelection,
          expandNode: handleNodeExpanded,
          toggleNodes: handleToggledNodes
        }}
      >
        {children}
      </TreeDispatchContext.Provider>
    </TreeStateContext.Provider>
  );
};

export const useTreeState = (): TreeState => {
  const context = useContext(TreeStateContext);
  if (context === undefined) {
    throw new Error('useTreeState hook must be used within a TreeProvider');
  }

  return context;
};

export const useTreeDispatch = (): TreeDispatch => {
  const context = useContext(TreeDispatchContext);
  if (context === undefined) {
    throw new Error('useTreeDispatch hook must be used within a TreeProvider');
  }

  return context;
};
