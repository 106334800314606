export const ChartDataSetTypes = [
  'Discharges',
  'ClinicallyExpected',
  'Actual',
  'Gap',
  'HCUPAvg',
  'CEHCUPAvg',
  'Total',
  'Secondary',
  'CESecondary',
  'SecondarySingle',
  'AvgImpactSingle',
  'SecondaryDual',
  'AvgImpactDual',
  'ComparisonEntity',
  'TargetDocScore',
  'CurrentAverageOtoCE',
  'HistoricalAverageOtoCE',
  'OtoERatio',
  'OtoECE',
  'COVID'
] as const;
export type ChartDataSetType = typeof ChartDataSetTypes[number];

export enum ChartDataSetAverageTypes {
  Month = 'Month',
  TwoMonth = 'TwoMonth',
  ThreeMonth = 'ThreeMonth'
}

export type ChartDataSetProperties = {
  name: string;
  value: number | null;
  errorBand?: number;
  errorBandNegative?: number;
  tag: string | null;
};

export type ChartDataSet = {
  dataSetType: ChartDataSetType;
  dataSetAverageType: keyof typeof ChartDataSetAverageTypes;
  dataSet: ChartDataSetProperties[];
};

export type ChartDataSetJSON = {
  footerNotes: string[];
  data: ChartDataSet[];
};
