import React from 'react';
import { NodeTypeIds, TreeNode } from '@clinintell/modules/orgTree';
import useScrollToNode from '../logic/useScrollToNode';
import { useTreeDispatch, useTreeState } from '../logic/TreeContext';
import Node, { NodeProps } from './Node';

export interface TreeItemType {
  id: number;
  name: string;
  children: TreeItemType[] | null;
  childrenCount: number;
  parentId?: number;
  nodeTypeId: number;
}

export type TreeProps = {
  selectednode: number | null | undefined;
  maxnodetype: keyof typeof NodeTypeIds;
  domid: string;
  renderTreeNode: (item: TreeNode) => React.ReactNode;
};

const Tree: React.FC<TreeProps> = ({ selectednode, maxnodetype, domid, renderTreeNode }) => {
  const { selectNode } = useTreeDispatch();
  const { rootNode } = useTreeState();

  useScrollToNode({ selectednode, id: domid });

  if (!rootNode) {
    return null;
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>, item: TreeNode): void => {
    if (event.key === 'Enter' && rootNode) {
      selectNode(item.id);
      event.stopPropagation();
    }
  };

  return (
    <RenderTree
      key={rootNode.id}
      nodeId={rootNode.id.toString()}
      onKeyDown={handleKeyDown}
      renderNode={renderTreeNode}
      maxnodetype={maxnodetype}
      node={rootNode}
    />
  );
};

const RenderTree: React.FC<NodeProps & Pick<TreeProps, 'maxnodetype'>> = props => {
  const { node, onKeyDown, renderNode, maxnodetype } = props;

  if (node.children.length === 0 || node.nodeTypeId >= NodeTypeIds[maxnodetype]) {
    return <Node {...props} />;
  }

  return (
    <Node {...props}>
      {node.children.map(child => {
        if (child.nodeTypeId > NodeTypeIds[props.maxnodetype]) {
          return null;
        }

        return (
          <RenderTree
            onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>): void => {
              onKeyDown(event, child);
            }}
            key={child.id}
            node={child}
            renderNode={renderNode}
            nodeId={child.id.toString()}
            maxnodetype={props.maxnodetype}
          />
        );
      })}
    </Node>
  );
};

export default Tree;
