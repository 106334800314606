import React, { useEffect, useState } from 'react';
import { ApplicationAPI } from '@clinintell/utils/api';
import {
  fetchScheduleDeleteReportsById,
  fetchSchedulePutReportsById,
  useScheduleGetReports
} from '@clinintell/api/apiComponents';
import { ScheduleReportDto } from '@clinintell/api/apiSchemas';
import { ScheduleReportEmailObjType } from '@clinintell/containers/reports/types/actionTypes';
import useErrorHandling from '@clinintell/errors/useErrorHandling';
import { useUser } from '@clinintell/modules/store';
import { userCan } from '@clinintell/containers/authentication/rules';

type SearchReport = {
  exists: boolean;
  report?: ScheduleReportDto;
};

type ScheduleReportOutput = {
  reportList: ScheduleReportDto[];
  isFetchingReportList: boolean;
  isRefetching: boolean;
  setReportList: React.Dispatch<React.SetStateAction<ScheduleReportDto[]>>;
  reportExist: (id: number) => SearchReport;
  downloadReport: (id: number) => Promise<void>;
  handleSaveUpdatedReport: (
    currentReport: ScheduleReportDto,
    adminEmails: ScheduleReportEmailObjType,
    providerEmails: ScheduleReportEmailObjType
  ) => Promise<boolean>;
  deleteReport: (id: number) => void;
};

const useScheduleReport = (): ScheduleReportOutput => {
  const user = useUser();
  const userHasAccess = userCan(user, ['schedulerReportEdit']);
  const [reportList, setReportList] = useState<ScheduleReportDto[]>([]);
  const { showErrorMessage } = useErrorHandling();

  const {
    data: scheduledReportsList,
    isLoading: isFetchingReportList,
    isRefetching,
    refetch: fetchSchedules
  } = useScheduleGetReports(
    {
      onError: showErrorMessage
    },
    {
      enabled: userHasAccess && reportList.length === 0
    }
  );

  const reportExist = (id: number): SearchReport => {
    if (reportList.length === 0) return { exists: false };
    const report = reportList.find(r => r.id === id);
    if (!report) {
      showErrorMessage({ message: `Report not found, reloading table`, variant: `error` });
      setReportList([]);
      return { exists: false };
    }
    return { exists: true, report };
  };

  const reloadReports = () => {
    setReportList([]);
    fetchSchedules();
  };

  const downloadReport = async (id: number): Promise<void> => {
    const endpoint = `schedule/reports/download/${id}`;

    const { exists, report } = reportExist(id);
    if (exists && report) {
      ApplicationAPI.download(endpoint, 'pdf')
        .then(response => {
          if (response.data) {
            return response.data;
          } else {
            showErrorMessage({ message: `${response.error}`, variant: `error` });
            throw new Error(response.error);
          }
        })
        .then(buffer => {
          const blob = new Blob([buffer], {
            type: 'application/pdf'
          });
          const filename = report?.reportName as string;
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
            return;
          }
          const link = document.createElement('a');
          link.download = filename;
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          showErrorMessage(`Error with the download request: ${error}`);
        });
    }
  };

  const handleSaveUpdatedReport = async (
    currentReport: ScheduleReportDto | null,
    adminEmails: ScheduleReportEmailObjType,
    providerEmails: ScheduleReportEmailObjType
  ): Promise<boolean> => {
    let successfulPost = true;
    await fetchSchedulePutReportsById({
      body: {
        ...currentReport,
        recipients: adminEmails.emails.join(),
        providerRecipients: providerEmails.emails.join()
      },
      pathParams: {
        id: currentReport && currentReport.id ? currentReport.id : 0
      },
      onError: (error: unknown) => {
        successfulPost = false;
        return showErrorMessage(error, `Failed to save Modifications to the Report Schedule`);
      }
    });

    if (successfulPost) reloadReports();
    return successfulPost;
  };

  const deleteReport = async (id: number): Promise<void> => {
    let deleteRequestSucessful = true;
    await fetchScheduleDeleteReportsById({
      pathParams: {
        id: id
      },
      onError: () => {
        deleteRequestSucessful = false;
        showErrorMessage('Error occurred while deleting Schedule');
      }
    }).then(res => {
      if (deleteRequestSucessful) reloadReports();
    });
  };

  useEffect(() => {
    if (isFetchingReportList || isRefetching || scheduledReportsList === null) return;
    if (scheduledReportsList?.scheduleReportList && scheduledReportsList?.scheduleReportList.length > 0)
      setReportList(scheduledReportsList?.scheduleReportList as ScheduleReportDto[]);
  }, [isFetchingReportList, isRefetching, scheduledReportsList, setReportList]);

  return {
    reportList,
    isFetchingReportList,
    isRefetching,
    setReportList,
    reportExist,
    downloadReport,
    handleSaveUpdatedReport,
    deleteReport
  };
};

export default useScheduleReport;
