import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SelectEntity from '@clinintell/containers/common/SelectEntity';
import SelectGroup from '@clinintell/containers/common/SelectGroup';
import { useDashboard2, useDashboard2Dispatch, useUser } from '@clinintell/modules/store';
import { fetchDashboard2Data, setDashboard2Org } from '@clinintell/modules/dashboard2';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import TargetedConditionsWidget from './widget/targetconditions/TargetedConditionsWidget';
import SmartGoalsWidget from './widget/smartgoals/SmartGoalsWidget';
import CmiAnalysisWidget from './widget/cmianalysis/CmiAnalysisWidget';
import CmiDocScoreWidget from './widget/cmiDocScore/cmiDocScoreWidget';
import AHRQMortalityRAWidget from './widget/ahrqMortalityRA/AHRQMortalityRA';
import DrgConditionsOpportunityWidget from './widget/drgConditionsOpportunity/DrgConditionsOpportunityWidget';
import SeverityOpportunity from './widget/severityopportunity/SeverityOpportunity';
import PageTitle from '@clinintell/components/typography/pageTitle/PageTitle';
import { HospitalsWithSystem } from '@clinintell/types/common';

interface NoDataProps {
  message?: string;
}

export const NoDataMessage: React.FC<NoDataProps> = ({ message = 'No data to display' }) => {
  return (
    <Box padding={5}>
      <Typography variant="subheading">{message}</Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    height: '100vh',
    padding: '0px',
    [theme.breakpoints.up(650)]: {
      padding: '25px'
    }
  },
  dashboardHeader: {
    width: '90%',
    margin: '0 auto',
    marginBottom: '1.5rem',
    textAlign: 'center',
    '& h3': {
      fontWeight: 600,
      color: theme.palette.grey[500],
      lineHeight: '28px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '60%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '90%',
      margin: '0 auto'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      padding: '15px 0',
      margin: 0
    }
  },
  dashboardEntityControls: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
    width: '90%',
    paddingBottom: '1rem',
    margin: '0 auto',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '60%'
    },
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '360px 360px 1fr',
      columnGap: '2%',
      width: '100%',
      padding: '2rem 0'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '90%'
    }
  },
  dashboardBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    margin: '0 auto',
    paddingBottom: '1rem',
    minWidth: '355px',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(3),
    [theme.breakpoints.between('sm', 'md')]: {
      width: '60%',
      margin: '0 auto',
      marginBottom: '10%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      gridTemplateColumns: '744px',
      gridTemplateRows: '516px 1.65fr 1fr 1fr'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      display: 'grid',
      gridTemplateColumns: '744px 480px',
      gridTemplateRows: '516px 300px',
      margin: '0'
    },
    [theme.breakpoints.up('xl')]: {
      display: 'grid',
      gridTemplateColumns: '42vw 40vw',
      gridTemplateRows: '516px auto',
      margin: '0'
    }
  },
  dashboardTopLeftContainer: {
    display: 'grid',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      width: '100%',
      margin: '0 auto'
    },
    [theme.breakpoints.between('md', 'xl')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '330px 160px'
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '65% auto'
    }
  }
}));

const DashboardUpdate: React.FC = () => {
  const { name } = useUser();
  const dashboardDispatch = useDashboard2Dispatch();
  const {
    hospitalsWithSystem,
    entity,
    groupId,
    previousEntity,
    previousGroupId,
    isLoading,
    isDashboard2Initialized,
    isHospitalsWithSystemInitialized,
    isHospitalsWithSystemLoading
  } = useDashboard2();

  const [selectedEntity, setSelectedEntity] = useState<HospitalsWithSystem>();
  const [selectedGroup, setSelectedGroup] = useState<number>(-1);

  const selectedValueDisablesGroups = (): boolean => {
    if (!entity) return true;
    return entity.nodeTypeId === 1 || entity.id === 1 ? true : false;
  };

  const {
    container,
    dashboardHeader,
    dashboardEntityControls,
    dashboardBodyContainer,
    dashboardTopLeftContainer
  } = useStyles();

  const handleEntityChangeCallback = (value: number, label?: string) => {
    if (entity && value === entity.id) return;
    const entitySelected = hospitalsWithSystem.find(entity => {
      return entity.id === value;
    });
    if (entitySelected !== undefined) {
      dashboardDispatch(setDashboard2Org({ entity: entitySelected, groupId: entitySelected.id, groupName: 'All' }));
      setSelectedEntity(entitySelected);
      setSelectedGroup(entitySelected.id);
    }
  };

  const handleGroupChangeCallback = (value: number | null, label?: string) => {
    dashboardDispatch(
      setDashboard2Org({
        entity: entity as HospitalsWithSystem,
        groupId: value === null ? undefined : value,
        groupName: label as string
      })
    );
    setSelectedGroup(Number(value));
  };

  const getDashboardDataCallback = useCallback((): void => {
    if (!selectedEntity) return;
    if (selectedEntity.id === previousEntity?.id && selectedGroup === previousGroupId) return;
    dashboardDispatch(
      fetchDashboard2Data({
        entityId: selectedEntity.id,
        nodeTypeId: selectedEntity.nodeTypeId,
        groupId: selectedGroup
      })
    );
  }, [dashboardDispatch, previousEntity, previousGroupId, selectedEntity, selectedGroup]);

  useEffect(() => {
    if (isLoading && isDashboard2Initialized) return;
    if (!entity && hospitalsWithSystem.length) {
      dashboardDispatch(
        setDashboard2Org({
          entity: hospitalsWithSystem[0],
          groupId: hospitalsWithSystem[0].id,
          groupName: 'All'
        })
      );
      setSelectedEntity(hospitalsWithSystem[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalsWithSystem, isHospitalsWithSystemInitialized, isHospitalsWithSystemLoading]);

  useEffect(() => {
    if (selectedEntity && selectedGroup) {
      getDashboardDataCallback();
    }
  }, [getDashboardDataCallback, selectedEntity, selectedGroup]);

  useEffect(() => {
    if (selectedEntity === undefined && entity) {
      setSelectedEntity(entity);
      setSelectedGroup(groupId ?? -1);
    }
  }, [entity, groupId, selectedEntity]);
  return (
    <>
      <Grid className={container} justifyContent="flex-start" alignItems="flex-start">
        <PageTitle sx={dashboardHeader} title={`Welcome, ${name}!`} mainHeaderCustomMargin="0px" />
        <Box className={dashboardEntityControls}>
          <SelectEntity
            width={'auto'}
            labelPosition={'side'}
            entitySelectData={hospitalsWithSystem}
            defaultValue={entity?.id}
            onEntityChangeFn={handleEntityChangeCallback}
          />
          <Tooltip content={selectedValueDisablesGroups() ? 'To enable, select a Hospital' : ''}>
            <Box>
              <SelectGroup
                hospitalId={selectedValueDisablesGroups() ? undefined : selectedEntity?.id}
                defaultValue={selectedGroup}
                includeAllGroups={true}
                width={'inherit'}
                labelWidth={'140px'}
                labelPosition={'side'}
                onGroupChangeFn={handleGroupChangeCallback}
                addAllGroupsToSelection={true}
                excludeOtherGroups
              />
            </Box>
          </Tooltip>
        </Box>
        <Box className={dashboardBodyContainer}>
          <Box className={dashboardTopLeftContainer}>
            <SmartGoalsWidget />
            <TargetedConditionsWidget />
            <CmiDocScoreWidget />
            <AHRQMortalityRAWidget />
          </Box>
          <CmiAnalysisWidget />
          <SeverityOpportunity />
          <DrgConditionsOpportunityWidget />
        </Box>
      </Grid>
    </>
  );
};

export default DashboardUpdate;
