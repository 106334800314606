import { userCan } from '@clinintell/containers/authentication/rules';
import EntityNavigation from '@clinintell/containers/entityNavigation/EntityNavigation';
import { NodeDetails } from '@clinintell/containers/treeSelector/logic/TreeContext';
import { NodeTypeIds } from '@clinintell/modules/orgTree';
import { useCMIComparisonState, useHospitals, useUser } from '@clinintell/modules/store';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    '& > div': {
      width: '50%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    }
  },
  leftSide: {
    borderRight: ({ withBorderSeparator }: { withBorderSeparator: boolean }): string =>
      withBorderSeparator ? `1px solid ${theme.palette.grey[300]}` : 'none',
    [theme.breakpoints.down('md')]: {
      borderRight: 'none'
    }
  },
  label: {
    display: 'inline-block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

type ComparisonSelectorsProps = {
  myEntity: number | undefined;
  myEntityName: string;
  onMyEntitySelected: (nodeId: number, nodeDetails: NodeDetails) => void;
  peerEntity: number | undefined;
  peerEntityName: string;
  onPeerEntitySelected: (nodeId: number, nodeDetails: NodeDetails) => void;
  withBorderSeparator: boolean;
  treeHeight?: number;
};

const ComparisonSelectors: React.FC<ComparisonSelectorsProps> = ({
  myEntity,
  peerEntity,
  myEntityName,
  peerEntityName,
  onMyEntitySelected,
  onPeerEntitySelected,
  withBorderSeparator,
  treeHeight
}) => {
  const [myEntityIsVisible, setMyEntityIsVisible] = useState(true);
  const [peerEntityIsVisible, setPeerEntityIsVisible] = useState(true);

  const { orgTreeType, comparisonViewOfProvidersRole } = useCMIComparisonState();
  const hospitals = useHospitals();
  const user = useUser();

  const userCanSelectProvidersInTree = userCan(user, [comparisonViewOfProvidersRole]);

  const { container, leftSide, label } = useStyles({ withBorderSeparator });

  let maxnodetype: keyof typeof NodeTypeIds = userCanSelectProvidersInTree ? 'Provider' : 'SpecialtyGroup';
  if (orgTreeType === 'saf') {
    maxnodetype = 'Hospital';
  }

  return (
    <Box className={container}>
      <Box className={leftSide}>
        <Box component="span" className={label}>
          <Box component="span" fontWeight={500}>
            My Entity:
          </Box>
          {myEntityName ? ` ${myEntityName}` : ''}
        </Box>
        <Box mt={1} mb={2}>
          <EntityNavigation
            defaultExpandedNodes={[user.rootId.toString()]}
            isTreeVisible={myEntityIsVisible}
            onToggleTreeVisibility={(): void => setMyEntityIsVisible(!myEntityIsVisible)}
            entity={myEntity || user.rootId}
            withSearch={hospitals.totalCount > 1 || orgTreeType === 'saf'}
            maxnodetype={maxnodetype}
            minCharactersToSearch={3}
            maxCharactersToSearch={50}
            hideButtonColor="primary"
            onEntitySelected={onMyEntitySelected}
            onEntitySearchComplete={onMyEntitySelected}
            id="myEntitySelector"
            cannotHideTree
            height={treeHeight}
            type={orgTreeType}
          />
        </Box>
      </Box>
      <Box>
        <Box component="span" className={label}>
          <Box component="span" fontWeight={500}>
            Compared to:
          </Box>
          {peerEntityName ? ` ${peerEntityName}` : ''}
        </Box>
        <Box mt={1} mb={4}>
          <EntityNavigation
            defaultExpandedNodes={[user.rootId.toString()]}
            isTreeVisible={peerEntityIsVisible}
            onToggleTreeVisibility={(): void => setPeerEntityIsVisible(!peerEntityIsVisible)}
            entity={peerEntity || user.rootId}
            withSearch={hospitals.totalCount > 1 || orgTreeType === 'saf'}
            maxnodetype={maxnodetype}
            minCharactersToSearch={3}
            maxCharactersToSearch={50}
            hideButtonColor="primary"
            onEntitySelected={onPeerEntitySelected}
            onEntitySearchComplete={onPeerEntitySelected}
            id="peerEntitySelector"
            cannotHideTree
            height={treeHeight}
            type={orgTreeType}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ComparisonSelectors;
