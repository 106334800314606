import React from 'react';
import { Box } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconPropType } from '@clinintell/types/common';

const CalendarIcon = (props: SvgIconPropType): JSX.Element => {
  const colorOverride = props.sx.color ?? 'inherit';
  return (
    /**
     * Not sure why the icon wasn't respecting the sizing in the sx, but using a div and setting the icon to 100% works.
     */
    <Box sx={{ ...props.sx }}>
      <SvgIcon
        sx={{ width: '100%', height: '100%', fill: 'none', ...props.sx }}
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
      >
        {props.withCircle && <circle cx="19" cy="19.0002" r="19" fill="#E1E8F5" />}
        <g clipPath="url(#clip0_5599_33494)">
          <path
            d="M24.25 13.0002H13.75C12.9216 13.0002 12.25 13.6718 12.25 14.5002V25.0002C12.25 25.8287 12.9216 26.5002 13.75 26.5002H24.25C25.0784 26.5002 25.75 25.8287 25.75 25.0002V14.5002C25.75 13.6718 25.0784 13.0002 24.25 13.0002Z"
            stroke={colorOverride}
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.6 15.4002H24.4"
            stroke={colorOverride}
            strokeWidth="4"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M16.3 19.9002H17.2"
            stroke={colorOverride}
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M20.8 19.9002H21.7"
            stroke={colorOverride}
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M22 11.5002V14.5002"
            stroke={colorOverride}
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 11.5002V14.5002"
            stroke={colorOverride}
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5599_33494">
            <rect width="18" height="18" fill="white" transform="translate(10 10.0002)" />
          </clipPath>
        </defs>
      </SvgIcon>
    </Box>
  );
};
CalendarIcon.muiName = SvgIcon.muiName;

export default CalendarIcon;
