import { TableApiProvider } from '@clinintell/components/table_v2';
import { useMetricsNavigation } from '@clinintell/modules/store';
import React from 'react';

const MetricsTableContainer: React.FC = ({ children }) => {
  const { metric, view, display, entity } = useMetricsNavigation();

  if (display === 'graph') {
    return <>{children}</>;
  }

  return (
    <TableApiProvider
      persistTableId={display === 'comparison' ? 'MetricsComparison' : 'Metrics'}
      persistTableKey={`${metric}_${view}`}
      persistFilterId={display === 'comparison' ? 'MetricsComparison' : 'Metrics'}
      persistFilterKey={`${entity}_${metric}_${view}`}
      persistRowSelectionId={display === 'comparison' ? 'MetricsComparison' : 'Metrics'}
      persistRowSelectionKey={entity.toString()}
    >
      {children}
    </TableApiProvider>
  );
};

export default MetricsTableContainer;
