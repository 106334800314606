import React, { lazy, Suspense } from 'react';
import { Routes, Route, useLocation, Navigate, useResolvedPath } from 'react-router-dom';
import Can from '@clinintell/components/Can';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import LoadingScreen from '@clinintell/components/LoadingScreen';
import Reports from '@clinintell/containers/reports/Reports';
import { EntityReportRouteProps, TrainingProgressRouteProps } from '@clinintell/containers/reports/types/routeProps';
import { reportGeneralPermissions } from '@clinintell/modules/menuNavigation';
import ReportContainer from '@clinintell/containers/reports/sections/reportContainer/ReportContainer';
import HospitalAndGroupFilter from '@clinintell/containers/reports/components/hospitalAndGroupFilter/HospitalAndGroupFilter';
import TrainingProgressFilterRoute from '@clinintell/containers/reports/sections/trainingProgressFilter/sections/TrainingProgressFilterRoute';
import ReportDownloadProvider from '@clinintell/containers/trainingStatusReport/context/ReportDownloadContext';
import { ReportsContextProvider } from '@clinintell/containers/reports/sections/context/ReportContext';
import AllScheduledReports from '@clinintell/containers/reports/sections/scheduled/AllScheduledReports';
import { TableApiProvider } from '@clinintell/components/table_v2';

const GroupReport = lazy(() =>
  import(/* webpackChunkName: 'reports/group' */ '@clinintell/containers/groupReport/GroupReport')
);

const ProviderReport = lazy(() =>
  import(/* webpackChunkName: 'reports/provider' */ '@clinintell/containers/providerReport/ProviderReport')
);

const TrainingStatusReport = lazy(() =>
  import(
    /* webpackChunkName: 'reports/trainingprogress' */ '@clinintell/containers/trainingStatusReport/TrainingStatusReport'
  )
);

const ReportRoutes = (): JSX.Element => {
  const path = useResolvedPath('/reports');
  const location = useLocation();

  return (
    <Suspense fallback={<LoadingScreen loadingIndicatorSize={250} />}>
      <Routes>
        <Route
          index
          element={
            <Can
              permissions={reportGeneralPermissions}
              yes={(): JSX.Element => {
                return (
                  <ReportsContextProvider>
                    <Reports />
                  </ReportsContextProvider>
                );
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
            />
          }
        />

        <Route
          path="provider"
          element={
            <Can
              permissions={['reportProviderReportView']}
              yes={(): JSX.Element => {
                if (!location.state) {
                  return <Navigate to={path} />;
                }

                const { groupId, hospitalId } = location.state as EntityReportRouteProps;

                return (
                  <ReportContainer<EntityReportRouteProps>
                    title="Provider Report"
                    defaultState={{ hospitalId, groupId }}
                  >
                    <ReportContainer.Filters<EntityReportRouteProps>>
                      {({ reportFilterState, setReportFilterState }) => {
                        return (
                          <HospitalAndGroupFilter
                            {...reportFilterState}
                            onHospitalChange={hospitalId =>
                              setReportFilterState(state => ({
                                ...state,
                                hospitalId: hospitalId as number,
                                groupId: state.hospitalId !== hospitalId ? -1 : state.groupId
                              }))
                            }
                            onPhysicianGroupChange={physicianGroupId =>
                              setReportFilterState(state => ({ ...state, groupId: physicianGroupId as number }))
                            }
                            width={300}
                          />
                        );
                      }}
                    </ReportContainer.Filters>
                    <ReportContainer.Content<EntityReportRouteProps>>
                      {({ reportFilterState }) => {
                        const { groupId } = reportFilterState as EntityReportRouteProps;
                        if (groupId === -1) return <></>;
                        return <ProviderReport {...reportFilterState} />;
                      }}
                    </ReportContainer.Content>
                  </ReportContainer>
                );
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('403')} />}
            />
          }
        />

        <Route
          path="group"
          element={
            <Can
              permissions={['reportGroupReportAdminView', 'reportGroupReportProviderView']}
              yes={(): JSX.Element => {
                if (!location.state) {
                  return <Navigate to={path} />;
                }

                const { groupId, hospitalId } = location.state as EntityReportRouteProps;

                return (
                  <ReportContainer<EntityReportRouteProps> title="Group Report" defaultState={{ hospitalId, groupId }}>
                    <ReportContainer.Filters<EntityReportRouteProps>>
                      {({ reportFilterState, setReportFilterState }) => {
                        return (
                          <HospitalAndGroupFilter
                            {...reportFilterState}
                            onHospitalChange={hospitalId =>
                              setReportFilterState(state => ({
                                ...state,
                                hospitalId: hospitalId as number,
                                groupId: state.hospitalId !== hospitalId ? -1 : state.groupId
                              }))
                            }
                            onPhysicianGroupChange={physicianGroupId =>
                              setReportFilterState(state => ({ ...state, groupId: physicianGroupId as number }))
                            }
                            width={300}
                          />
                        );
                      }}
                    </ReportContainer.Filters>
                    <ReportContainer.Content<EntityReportRouteProps>>
                      {({ reportFilterState }) => {
                        const { groupId } = reportFilterState as EntityReportRouteProps;
                        if (groupId === -1) return <></>;
                        return <GroupReport {...reportFilterState} />;
                      }}
                    </ReportContainer.Content>
                  </ReportContainer>
                );
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('403')} />}
            />
          }
        />

        <Route
          path="scheduled"
          element={
            <Can
              permissions={['reportGroupReportAdminEdit']}
              yes={(): JSX.Element => <AllScheduledReports />}
              no={(): JSX.Element => <RouteErrorPage error={new Error('403')} />}
            />
          }
        />

        <Route
          path="trainingprogress"
          element={
            <Can
              permissions={['reportTrainingReportProgressView']}
              yes={(): JSX.Element => {
                if (!location.state) {
                  return <Navigate to={path} />;
                }

                const { hospital, group, physician } = location.state as TrainingProgressRouteProps;

                return (
                  <TableApiProvider>
                    <ReportDownloadProvider>
                      <ReportContainer<TrainingProgressRouteProps>
                        title="Training Progress"
                        defaultState={{ hospital, group, physician }}
                      >
                        <ReportContainer.Filters<TrainingProgressRouteProps>>
                          {({ reportFilterState, setReportFilterState }) => {
                            return (
                              <TrainingProgressFilterRoute
                                reportFilterState={reportFilterState}
                                setReportFilterState={setReportFilterState}
                              />
                            );
                          }}
                        </ReportContainer.Filters>
                        <ReportContainer.Content<TrainingProgressRouteProps>>
                          {({ reportFilterState }) => {
                            return <TrainingStatusReport {...reportFilterState} />;
                          }}
                        </ReportContainer.Content>
                      </ReportContainer>
                    </ReportDownloadProvider>
                  </TableApiProvider>
                );
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('403')} />}
            />
          }
        />

        <Route path="*" element={<RouteErrorPage error={new Error('404')} />} />
      </Routes>
    </Suspense>
  );
};

export default ReportRoutes;
