import React from 'react';
import 'chartjs-plugin-annotation';
import YAxes from '@clinintell/components/Chart/YAxes';
import XAxes from '@clinintell/components/Chart/XAxes';
import { ChartDataset, YAxisSet, XAxisSet } from '@clinintell/components/Chart/types';
import { LinearComponentProps } from 'react-chartjs-2';
import ChartJS, { ChartTooltipItem } from 'chart.js';
import clinintellErrorBandPlugin from '@clinintell/components/Chart/plugins/clinintellErrorBandPlugin';
import barLabelPlugin from '@clinintell/components/Chart/plugins/barLabelPlugin';
import sliderPlugin, { SliderOptions } from '@clinintell/components/Chart/plugins/sliderPlugin';
import { truncateDecimals } from '@clinintell/utils/formatting';
import { Box, useTheme } from '@mui/material';
import { format } from 'date-fns';

export const calculateMinWidth = (datapoints: number, widthModifier: number): string => {
  const value = widthModifier * datapoints;
  return `${value}rem`;
};

export type ChartProps = {
  title?: string;
  datasets: ChartDataset[];
  yAxes: YAxisSet[];
  xAxis: XAxisSet;
  children: React.ComponentType<LinearComponentProps>;
  widthModifier?: number;
  topPadding?: number;
  rightPadding?: number;
  TestID?: string;
  customPlugins?: ChartCustomPlugins;
  height?: number;
  useErrorBands?: boolean;
};

export type ChartCustomPlugins = Record<string, SliderOptions>;

const Chart: React.FC<ChartProps> = ({
  datasets,
  xAxis,
  yAxes,
  children,
  widthModifier = 5,
  topPadding = 0,
  rightPadding = 0,
  title,
  TestID,
  customPlugins,
  height = 300,
  useErrorBands = true
}) => {
  const theme = useTheme();
  ChartJS.pluginService.register(clinintellErrorBandPlugin);
  ChartJS.pluginService.register(barLabelPlugin);
  ChartJS.pluginService.register(sliderPlugin);

  const ChartTypeComponent = children;

  let xAxisTickStart = 0;
  let xAxisTickEnd = xAxis.labels.length;

  if (!xAxis.labels[0]) {
    xAxisTickStart = 1;
  }
  if (!xAxis.labels[xAxis.labels.length - 1]) {
    xAxisTickEnd = xAxis.labels.length - 1;
  }

  return (
    <Box>
      <Box
        style={{
          position: 'relative',
          width: calculateMinWidth(datasets.length > 0 ? datasets[0].data.length : 1, widthModifier),
          paddingLeft: 10,
          height: height
        }}
        data-cy={TestID}
        role="img"
        aria-label={`${title} Graph Chart`}
        arial-describedby={`${title}-graphDescription`}
      >
        <ChartTypeComponent
          // id will contain the title for the graph
          id={title}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - apparently we need yAxisID fields to be integers and not strings for some reason
          data={{
            labels: xAxis.labels,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - apparently we need yAxisID fields to be integers and not strings for some reason
            datasets
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              position: 'bottom'
            },
            layout: {
              padding: {
                top: topPadding,
                right: rightPadding
              }
            },
            tooltips: {
              backgroundColor: theme.palette.blue.main,
              callbacks: {
                // this callback will be used if there is a tagDataSet
                afterFooter(tooltipItems: ChartTooltipItem[]): string {
                  try {
                    // There will only be one datapoint
                    const tooltipItem = tooltipItems[0];
                    if (tooltipItem.datasetIndex === undefined) {
                      return tooltipItem.value ? tooltipItem.value : '';
                    }

                    const hoveredDataSet = datasets[tooltipItem.datasetIndex];
                    if (
                      tooltipItem.index &&
                      hoveredDataSet.tagDataSet &&
                      hoveredDataSet.tagDataSet[tooltipItem.index] !== null
                    ) {
                      const formattedDate = format(
                        new Date(hoveredDataSet.tagDataSet[tooltipItem.index] as string),
                        'LLLyy'
                      );
                      return `
This CMI Doc Score Target is based on the current active strategy implemented on ${formattedDate}.
This will change if a new strategy is implemented.`;
                    }
                  } catch {
                    // fail gracefully
                  }
                  return '';
                },
                label(tooltipItem: ChartTooltipItem): string {
                  if (tooltipItem.datasetIndex === undefined) {
                    return tooltipItem.value ? tooltipItem.value : '';
                  }

                  const hoveredDataSet = datasets[tooltipItem.datasetIndex];
                  return tooltipItem.value
                    ? `${truncateDecimals(
                        Number(tooltipItem.value),
                        hoveredDataSet.precision
                      )} ${hoveredDataSet.postfix || ''}`
                    : '';
                }
              }
            },
            elements: {
              line: {
                tension: 0
              }
            },
            plugins: {
              clinintellErrorBandPlugin: useErrorBands
                ? {
                    color: theme.palette.chart.grey.light,
                    lineWidth: 2.5,
                    horizontalWidth: 16
                  }
                : false,
              barLabelPlugin: {
                fontSize: '12px',
                font: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
              },
              innerDoughnutText: false,
              ...customPlugins
            },
            scales: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore - ID's seem to only work with integers for this particular setup of having 2 sets of y axes
              yAxes: YAxes(yAxes, theme.palette.grey[100]),
              xAxes: XAxes(xAxisTickStart, xAxisTickEnd)
            },
            // This is required to be AFTER the scales objects
            // eslint-disable-next-line
            // @ts-ignore -- annotation definitely is part of this constructor
            annotation: {
              annotations: [
                {
                  type: 'line',
                  mode: 'vertical',
                  value: datasets.length > 0 ? datasets[0].verticalAnnotation : null,
                  scaleID: 'x-axis-0',
                  borderColor: theme.palette.blue.main,
                  borderWidth: 2.5
                }
              ]
            }
          }}
        />
      </Box>
      <Box id={`${title}-graphDescription`} display="none">
        {JSON.stringify(
          datasets.map(dataset => {
            const newDataset = { ...dataset };
            // _meta property is circular data which causes an error when stringified
            delete newDataset._meta;
            return newDataset;
          })
        )}
      </Box>
    </Box>
  );
};

export default Chart;
