import React, { createContext, useLayoutEffect, useState } from 'react';

export const WindowSizeContext = createContext({ height: 1024, width: 768 });

export interface WindowSize {
  height: number;
  width: number;
}

const WindowSizeContextProvider: React.FC = props => {
  const [size, setSize] = useState<WindowSize>({ height: 1024, width: 768 });
  useLayoutEffect(() => {
    function updateSize(): void {
      setSize({ height: window.innerHeight, width: window.innerWidth });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return (): void => window.removeEventListener('resize', updateSize);
  }, []);

  return <WindowSizeContext.Provider value={size}>{props.children}</WindowSizeContext.Provider>;
};

export default WindowSizeContextProvider;
