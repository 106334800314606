import { SummaryRecord } from '@clinintell/modules/cmiAnalysis';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import React from 'react';
import { calculateImpactOnCMI } from '../logic/calculateImpactOnCMI';
import ResultSummaryTable from '../../../components/analysisSharedComponents/ResultSummaryTable';

type CMIResultSummaryProps = {
  cmiSummary: SummaryRecord;
  drgMixSummary: SummaryRecord;
  severityCMISummary: SummaryRecord;
};

const CMIResultSummary: React.FC<CMIResultSummaryProps> = ({ cmiSummary, drgMixSummary, severityCMISummary }) => {
  const cmiSummaryDifference =
    cmiSummary.current && cmiSummary.historical
      ? Number(formatToDecimalPlaces(cmiSummary.current, 2)) - Number(formatToDecimalPlaces(cmiSummary.historical, 2))
      : '-';

  const drgMixSummaryDifference =
    drgMixSummary.current && drgMixSummary.historical
      ? Number(formatToDecimalPlaces(drgMixSummary.current, 2)) -
        Number(formatToDecimalPlaces(drgMixSummary.historical, 2))
      : '-';

  const sevCmiSummaryDifference =
    severityCMISummary.current && severityCMISummary.historical
      ? Number(formatToDecimalPlaces(severityCMISummary.current, 2)) -
        Number(formatToDecimalPlaces(severityCMISummary.historical))
      : '-';

  const drgMixImpactOnCMI =
    cmiSummaryDifference !== '-' && drgMixSummaryDifference !== '-' && sevCmiSummaryDifference !== '-'
      ? calculateImpactOnCMI(cmiSummaryDifference, drgMixSummaryDifference, sevCmiSummaryDifference)
      : 0;

  const severityCMIIMpactOnCMI =
    cmiSummaryDifference !== '-' && drgMixSummaryDifference !== '-' && sevCmiSummaryDifference !== '-'
      ? calculateImpactOnCMI(cmiSummaryDifference, sevCmiSummaryDifference, drgMixSummaryDifference)
      : 0;

  return (
    <ResultSummaryTable
      rows={[
        {
          columns: [
            {
              value: '',
              width: 4,
              align: 'center',
              bold: true,
              key: 'header1'
            },
            {
              value: 'Historical',
              width: 2,
              align: 'center',
              bold: true,
              key: 'headerHistorical'
            },
            {
              value: 'Current',
              width: 2,
              align: 'center',
              bold: true,
              key: 'headerCurrent'
            },
            {
              value: 'Change',
              width: 4,
              align: 'center',
              bold: true,
              key: 'headerChange'
            }
          ],
          key: 'headerRow'
        },
        {
          divider: 'solid',
          key: 'headerDivider'
        },
        {
          columns: [
            {
              value: 'CMI',
              width: 4,
              align: 'left',
              bold: true,
              key: 'cmiLabel'
            },
            {
              value: cmiSummary.historical ? formatToDecimalPlaces(cmiSummary.historical, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'cmiHist'
            },
            {
              value: cmiSummary.current ? formatToDecimalPlaces(cmiSummary.current, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'cmiCurr'
            },
            {
              value: cmiSummaryDifference !== '-' ? formatToDecimalPlaces(cmiSummaryDifference, 2) : '-',
              width: 4,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'cmiDiff'
            }
          ],
          key: 'cmiRow'
        },
        {
          divider: 'solid',
          key: 'cmiDivider'
        },
        {
          columns: [
            {
              value: 'DRG Mix Value',
              width: 4,
              align: 'left',
              bold: true,
              key: 'drgLabel'
            },
            {
              value: drgMixSummary.historical ? formatToDecimalPlaces(drgMixSummary.historical, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'drgHist'
            },
            {
              value: drgMixSummary.current ? formatToDecimalPlaces(drgMixSummary.current, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'drgCurr'
            },
            {
              value:
                drgMixSummaryDifference !== '-'
                  ? `${formatToDecimalPlaces(drgMixSummaryDifference, 2)} ${
                      drgMixImpactOnCMI !== 0 ? `   (${formatToDecimalPlaces(drgMixImpactOnCMI * 100, 0)}%)` : ''
                    }`
                  : '-',
              width: 4,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'drgDiff'
            }
          ],
          key: 'drgRow'
        },
        {
          columns: [
            {
              value: 'Severity Value',
              width: 4,
              align: 'left',
              bold: true,
              key: 'sevCmiLabel'
            },
            {
              value: severityCMISummary.historical ? formatToDecimalPlaces(severityCMISummary.historical, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'sevCmiHist'
            },
            {
              value: severityCMISummary.current ? formatToDecimalPlaces(severityCMISummary.current, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'sevCmiCurr'
            },
            {
              value:
                sevCmiSummaryDifference !== '-'
                  ? `${formatToDecimalPlaces(sevCmiSummaryDifference, 2)} ${
                      severityCMIIMpactOnCMI !== 0
                        ? `   (${formatToDecimalPlaces(severityCMIIMpactOnCMI * 100, 0)}%)`
                        : ''
                    }`
                  : '-',
              width: 4,
              align: 'left',
              type: 'number',
              offsetCenter: true,
              key: 'sevCmiDiff'
            }
          ],
          key: 'sevCmiRow'
        }
      ]}
    />
  );
};

export default CMIResultSummary;
