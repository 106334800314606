import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, useTheme } from '@mui/material';
import { validateEmail } from '@clinintell/utils/validators';
import { ScheduleReportEmailObjType, ScheduleReportRolesType } from '@clinintell/containers/reports/types/actionTypes';

type ReportTextBoxProps = {
  textBoxHeader?: string;
  validEmailList: string[];
  invalidEmailList: string[];
  emailRoleType: ScheduleReportRolesType;
  entryRequired?: boolean;
  setValidEmailsFunc: (emailObj: ScheduleReportEmailObjType) => void;
};

const ReportEmailTextBox: React.FC<ReportTextBoxProps> = ({
  textBoxHeader,
  validEmailList,
  invalidEmailList,
  emailRoleType,
  entryRequired = false,
  setValidEmailsFunc
}) => {
  const theme = useTheme();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [required, setRequired] = useState<boolean>(false);
  const placeholderEmails = ['name@hospital.com', 'name2@hospital.com'];

  useEffect(() => {
    if (invalidEmailList.length > 0 || (validEmailList.length === 0 && required)) setIsValid(false);
    else setIsValid(true);
  }, [required, invalidEmailList, validEmailList]);

  const emailEventListenerHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    entryRequired: boolean
  ): void => {
    const invalidTempList: string[] = [];
    const tempList: string[] = [];
    setRequired(entryRequired);

    if (event.target.value.length === 0) {
      setValidEmailsFunc({
        emails: [...tempList],
        invalidEmails: [...invalidTempList],
        validList: false,
        emailRole: emailRoleType
      });
      return;
    }

    event.target.value.split(',').forEach(element => {
      if (validateEmail(element.trim())) tempList.push(element.trim());
      else {
        if (element.trim().length > 0) invalidTempList.push(element.trim());
      }
    });

    if (invalidTempList.length > 0 || (!tempList.length && required)) {
      setValidEmailsFunc({
        emails: [...tempList],
        invalidEmails: [...invalidTempList],
        validList: false,
        emailRole: emailRoleType
      });
    } else
      setValidEmailsFunc({
        emails: [...tempList],
        invalidEmails: [...invalidTempList],
        validList: true,
        emailRole: emailRoleType
      });
  };

  const scheduleReportErrorElem = (children: JSX.Element) => {
    return (
      <Box padding="8px">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginBottom: '8px 8px 0 8px',
              color: theme.palette.red[500],
              fontWeight: 'bold'
            }}
            variant="p2"
          >
            {children}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box padding="8px">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography style={{ marginBottom: 8 }} variant="p2">
            {textBoxHeader ? textBoxHeader : 'Recipients'}
          </Typography>
          <Typography style={{ marginBottom: 8 }} variant="caption">
            Use a comma as a separator
          </Typography>
          <TextField
            multiline={true}
            error={!isValid}
            minRows={4}
            maxRows={5}
            inputProps={{ padding: '0' }}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              emailEventListenerHandler(event, entryRequired)
            }
            onBlur={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              emailEventListenerHandler(event, entryRequired)
            }
            placeholder={placeholderEmails.toString()}
            defaultValue={validEmailList}
            sx={{
              backgroundColor: 'shade.white',
              borderRadius: `8px`,
              width: '100%',
              '& fieldset': {
                borderColor: 'grey.300',
                borderRadius: `8px`
              },
              '&&:hover fieldset': {
                borderColor: 'blue.light2'
              },
              '&&.Mui-focused fieldset': {
                borderColor: 'blue.light2'
              },
              '& fieldset > legend': {
                width: '0px' // hiding the legend
              }
            }}
          ></TextField>
        </Box>
      </Box>
      {!isValid && invalidEmailList.length > 0 ? (
        scheduleReportErrorElem(
          <>
            Invalid Emails :
            {invalidEmailList.map((element, index) => {
              return (
                <Typography key={index} margin="0 0 0 4px">
                  {index === invalidEmailList.length - 1 ? `${element.trim()}` : `${element.trim()},`}
                </Typography>
              );
            })}
          </>
        )
      ) : (
        <></>
      )}
      {!isValid && validEmailList.length === 0 && invalidEmailList.length === 0 ? (
        scheduleReportErrorElem(<>Required Field</>)
      ) : (
        <></>
      )}
    </>
  );
};

export default ReportEmailTextBox;
