/*
    This component renders a type of wizard that steps back and forth between components in the sequence. It stores 
    given state and shares it among the step components. Undefined steps are valid, as they can be considered 
    conditional based on business logic. The wizard filters these out.
*/
import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import Button from '@clinintell/components/button/Button';
import useAlert from './alert/logic/useAlert';

type StepProps = {
  children: ReactElement;
  nextButtonText?: string;
  disableContinue?: boolean;
  saveMethod?: () => Promise<unknown> | void;
  onSaveCompleteMessage?: string;
  type?: string;
};

type WizardProps = {
  children: (ReactElement<StepProps> | undefined)[];
  onCancel: () => void;
  saveMethod?: () => void;
};

const Wizard: React.FC<WizardProps> = ({ children, onCancel }): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { pushAlert } = useAlert();

  const handleAdvanceStep = async (
    saveMethod: (() => Promise<unknown> | void) | undefined,
    isLastStep: boolean,
    onSaveCompleteMessage: string
  ): Promise<void> => {
    if (saveMethod) {
      if (saveMethod.constructor.name === 'AsyncFunction') {
        setIsSaving(true);
        await saveMethod();
        setIsSaving(false);
      } else {
        saveMethod();
      }

      pushAlert({ message: onSaveCompleteMessage, variant: 'success' });
    }

    if (isLastStep) {
      onCancel();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleGoToPreviousStep = (): void => {
    setCurrentStep(currentStep - 1);
  };

  const filteredSteps = children.filter(component => component !== undefined);

  return (
    <Box>
      {filteredSteps.map((component, index) => {
        const { nextButtonText, disableContinue, saveMethod, onSaveCompleteMessage } = (component as ReactElement)
          .props as StepProps;

        const isFirstStep = currentStep === 0;
        const isLastStep = currentStep === filteredSteps.length - 1;

        const previousButton = !isFirstStep ? (
          <Button
            variant="outlined"
            type="secondary"
            label="Previous"
            onClick={handleGoToPreviousStep}
            sx={{ minWidth: 130 }}
          />
        ) : null;

        const cancelButton = (
          <Button variant="outlined" type="secondary" label="Cancel" onClick={onCancel} sx={{ minWidth: 130 }} />
        );

        const nextButton = (
          <Button
            disabled={disableContinue || isSaving}
            label={nextButtonText || 'Next'}
            onClick={(): Promise<void> => handleAdvanceStep(saveMethod, isLastStep, onSaveCompleteMessage || '')}
            isBusy={isSaving}
            sx={{ minWidth: 130 }}
          />
        );

        return (
          // Keep the components rendered so that there is no unneccessary re-rendering
          <Box display={index === currentStep ? 'block' : 'none'} key={index}>
            <Box>{component}</Box>
            {!isMobileView ? (
              <Box marginTop={2} display="flex" justifyContent="space-between">
                <Box minWidth="15px">{previousButton}</Box>
                <Box
                  sx={{
                    '& > *:not(:last-child)': {
                      mr: 1
                    }
                  }}
                >
                  {cancelButton}
                  {nextButton}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  '& > *:not(:last-child)': {
                    mb: 0
                  }
                }}
              >
                {nextButton}
                {previousButton}
                {cancelButton}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export const Step: React.FC<StepProps> = ({ children }) => {
  return children;
};

export default Wizard;
