import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import { ApiRequestError } from './customErrors';

export interface IErrorMessageContentProps {
  header: string;
  details: string;
}

const ErrorFallback: React.FunctionComponent<FallbackProps> = props => {
  const { error, resetErrorBoundary } = props;

  // TODO: consider what to do if the error is unrecoverable
  // on mobile we just sign them out which effectively resets the app (back to the login screen)

  // shapes it to the downstream logic expected in RouteErrorPage
  const shapedError: Error = { name: error.name, message: '' };
  if (error instanceof ApiRequestError) {
    shapedError.message = String(error.statusCode) || '';
  }

  return <RouteErrorPage error={shapedError} resetError={resetErrorBoundary} />;
};

export default ErrorFallback;
