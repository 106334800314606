import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tooltip from './tooltip/Tooltip';

export type ButtonType = {
  title: string;
  tooltip?: string | JSX.Element;
};

type StyleProps = {
  isActive: boolean;
  width: number;
  disabled?: boolean;
};

type Props = {
  id?: string;
  isActive: boolean;
  buttons: ButtonType[];
  onChange: (value: boolean) => void;
  width?: number;
  disabled?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    position: 'relative',
    height: 28,
    borderRadius: 8,
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 3
  },
  text: {
    textAlign: 'center',
    width: '50%',
    zIndex: 10,
    borderRadius: 8,
    padding: 5,
    cursor: ({ disabled }): string => (disabled ? 'not-allowed' : 'pointer'),
    color: ({ disabled }) => (disabled ? theme.palette.grey[400] : 'inherit')
  },
  slider: {
    height: 28,
    borderRadius: 8,
    position: 'absolute',
    backgroundColor: ({ disabled }): string => (disabled ? theme.palette.grey[50] : theme.palette.shade.white),
    // Need this to initialize the state of the slider
    boxShadow: ({ isActive }): string => `${isActive ? '' : '-'}2px 2px 10px rgba(0, 0, 0, 0.2)`,
    transform: ({ isActive, width }): string => `translateX(${isActive ? 0 : width / 2}px)`,
    transitionProperty: 'transform, box-shadow',
    transitionDuration: '0.2s'
  }
}));

const AnimatedToggleButton: React.FC<Props> = ({
  id = 'anim-toggle-slider',
  isActive,
  buttons,
  onChange,
  disabled,
  width = 300
}) => {
  const { container, text, slider } = useStyles({ isActive: isActive, width, disabled });

  const toggleSlider = (): void => {
    if (disabled) {
      return;
    }

    onChange(!isActive);
  };

  return (
    <Box className={container} onClick={toggleSlider} width={width}>
      <Box id={id} className={slider} width={width / 2} />
      {buttons.map((button, index) => (
        <Box key={index} className={text}>
          {button.tooltip ? (
            <Tooltip key={index} content={button.tooltip}>
              <Typography variant="p2" style={{ fontWeight: 400 }}>
                {button.title}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="p2" style={{ fontWeight: 400 }}>
              {button.title}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default AnimatedToggleButton;
