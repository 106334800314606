import React, { useEffect, useState } from 'react';
import { HospitalsWithSystem } from '@clinintell/types/common';
import { Box, Typography } from '@mui/material';
import { Select, SelectOptionType } from '../../components/index';

type SelectOptionEntityType = SelectOptionType & { nodeTypeId?: boolean };
type EntityOptions = SelectOptionEntityType[];

type Props = {
  labelPosition?: 'top' | 'side' | 'none';
  entitySelectData: HospitalsWithSystem[];
  defaultValue?: number;
  onEntityChangeFn: (value: number, label?: string) => void;
  onSelectLoaded?: (value: boolean) => void;
  width?: number | string;
  trainingOnly?: boolean;
  labelWidth?: number | string;
  isLoading?: boolean;
};

const SelectEntity: React.FC<Props> = ({
  labelPosition = 'top',
  entitySelectData,
  defaultValue,
  onEntityChangeFn,
  onSelectLoaded,
  width = 400,
  labelWidth,
  isLoading = false
}) => {
  const [entityOptions, setEntityOptions] = useState<EntityOptions | []>([]);

  useEffect(() => {
    const selectOptionTypeArray = entitySelectData.map(entity => {
      return {
        value: entity.id,
        label: entity.name,
        nodeTypeId: entity.nodeTypeId,
        useLabelForTestId: true
      } as SelectOptionType;
    });
    setEntityOptions(selectOptionTypeArray);
  }, [entitySelectData]);

  return (
    <>
      <Box
        display="flex"
        flexDirection={labelPosition === 'top' ? 'column' : 'row'}
        alignItems={labelPosition === 'top' ? 'flex-start' : 'center'}
        width={width}
      >
        {labelPosition !== 'none' && (
          <Typography variant="p2" width={labelWidth !== undefined || labelWidth !== null ? labelWidth : 'auto'}>
            Entity
          </Typography>
        )}
        <Box
          id="SelectBoxId"
          width="100%"
          style={labelPosition === 'none' ? undefined : labelPosition === 'top' ? { marginTop: 8 } : { marginLeft: 8 }}
        >
          <Select
            width={width}
            isSearchable={true}
            options={entityOptions}
            value={Number(defaultValue)}
            onChange={(value, label): void => {
              onEntityChangeFn(Number(value), label);
            }}
            testId="entitySelector"
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </>
  );
};

export default React.memo(SelectEntity);
