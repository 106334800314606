import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  SxProps,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { PaperProps } from '@mui/material/Paper';
import { appBarHeight } from '../../theme/theme';
import ModalCloseIcon from '../icons/ModalCloseIcon';

export interface ModalProps {
  id?: string;
  openAnchor: boolean;
  onClose?: (value: boolean) => void;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  title?: string | React.ReactNode;
  icon?: JSX.Element;
  content: string | HTMLElement | React.ReactNode;
  actions?: HTMLButtonElement | HTMLButtonElement[] | React.ReactNode;
  fullScreenBackdrop?: boolean;
  disableBackdropClick?: boolean;
  disableEscapeKey?: boolean;
  fullScreenWindow?: boolean;
  zIndex?: number;
  sx?: SxProps<Theme>;
  titleStyling?: string;
  titleHeaderStyling?: string;
}

const PaperComponent = (props: PaperProps): JSX.Element => {
  return <Paper {...props} sx={{ borderRadius: 4 }} />;
};

const ModalWindow: React.FC<ModalProps> = ({
  id,
  openAnchor,
  onClose,
  width = 'md',
  title,
  icon,
  content,
  actions,
  disableBackdropClick = false,
  disableEscapeKey = false,
  fullScreenWindow = false,
  zIndex,
  sx,
  titleStyling,
  titleHeaderStyling
}) => {
  const theme = useTheme();

  return (
    <Dialog
      id={id}
      onClose={disableBackdropClick || disableEscapeKey || !onClose ? undefined : (): void => onClose(false)}
      open={openAnchor}
      fullWidth
      maxWidth={width}
      PaperComponent={PaperComponent}
      fullScreen={fullScreenWindow}
      disableEscapeKeyDown={disableEscapeKey}
      scroll="paper"
      sx={{
        zIndex: zIndex ?? theme.zIndex.modal,
        top: fullScreenWindow ? 0 : appBarHeight,
        maxWidth: '100%',
        ...sx
      }}
    >
      <DialogTitle className={titleHeaderStyling}>
        {React.isValidElement(title) ? (
          title
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2
            }}
          >
            {icon && <Box sx={{ pl: 1, pt: 2, width: 80 }}>{icon}</Box>}
            <Box className={titleStyling}>
              <Typography variant="h5" style={{ color: theme.palette.grey[900], fontWeight: 600 }}>
                {title}
              </Typography>
            </Box>
            {onClose ? (
              <Box
                onClick={(): void => onClose(false)}
                sx={{
                  pl: '5px',
                  alignSelf: 'start'
                }}
              >
                <ModalCloseIcon />
              </Box>
            ) : null}
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        {width === 'xs' ? content : <Box sx={{ px: icon ? 0 : 2, ml: icon ? '80px' : 0 }}>{content}</Box>}
      </DialogContent>
      {actions && (
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
            p: 2,
            backgroundColor: theme.palette.grey[50]
          }}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalWindow;
