import React, { useContext } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PlaneIcon, ModalWindow, ClinIntellSkeleton, Tooltip } from '@clinintell/components/index';
import ModalCloseIcon from '@clinintell/components/icons/ModalCloseIcon';
import { WindowSize, WindowSizeContext } from '@clinintell/modules/windowSizeContext';

type WidgetModalProps = {
  modalVisible: boolean;
  toggleVisible: () => void;
  redirectUserHandler: () => void;
  canRedirect?: boolean;
  headerTitle: string;
  tooltipMsg?: string;
  isModalLoading?: boolean;
  isModalContentLoading?: boolean;
  children?: JSX.Element;
};

const useStyles = makeStyles(theme => ({
  widgetHeaderStyling: {
    padding: '16px 36px 0px 36px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '& > div': {
      marginBottom: '.5rem'
    }
  },
  widgetHeaderContentStyling: {
    display: 'flex',
    color: theme.palette.grey[700],
    alignItems: 'center',
    gap: 1
  }
}));

const WidgetModal: React.FC<WidgetModalProps> = ({
  modalVisible,
  toggleVisible,
  redirectUserHandler,
  canRedirect = true,
  headerTitle,
  tooltipMsg,
  isModalLoading = false,
  isModalContentLoading = false,
  children
}) => {
  const theme = useTheme();
  const { widgetHeaderStyling, widgetHeaderContentStyling } = useStyles(theme);
  const { width } = useContext<WindowSize>(WindowSizeContext);
  if (!modalVisible) return null;

  return (
    <ModalWindow
      width="xl"
      fullScreenWindow={width < 1200}
      openAnchor={modalVisible}
      onClose={toggleVisible}
      titleHeaderStyling={widgetHeaderStyling}
      title={
        <Box className={widgetHeaderContentStyling}>
          <Typography variant={'h5'} sx={{ fontWeight: 'bold' }}>
            {headerTitle}
          </Typography>
          {(tooltipMsg as string) && canRedirect ? (
            <Tooltip content={tooltipMsg}>
              <IconButton disableFocusRipple disableRipple disableTouchRipple onClick={redirectUserHandler}>
                <PlaneIcon sx={{ height: 19, width: 19, color: 'blue.main' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
          <Box
            data-test-id="dashboard-modal-close"
            onClick={(): void => toggleVisible()}
            sx={{
              alignSelf: 'start',
              marginLeft: 'auto'
            }}
          >
            <ModalCloseIcon />
          </Box>
        </Box>
      }
      content={
        <Box pt={2}>
          {isModalLoading ? (
            <ClinIntellSkeleton variant="rectangular" width="100%" height="20rem" />
          ) : isModalContentLoading ? (
            <ClinIntellSkeleton width="100%" height="200px" variant={'rectangular'} />
          ) : React.isValidElement(children) ? (
            children
          ) : (
            <></>
          )}
        </Box>
      }
    />
  );
};

export default React.memo(WidgetModal);
