// TODO -- I would like to retire this, calling the API directly is much more flexible and removes a lot of
// weird logic that you have to code around
import { useEffect, useRef, useState } from 'react';
import { ApplicationAPI, AsyncOutput, DuplicateRequestHandler } from './api';

export interface UseApiOutput<T> {
  output: AsyncOutput<T> | null;
  isLoading: boolean;
}

interface GetHookProps {
  endpoint: string;
  headers?: Record<string, string>;
  isWaiting: boolean;
  duplicateRequestHandler?: DuplicateRequestHandler;
  hasPermission?: boolean;
}

export const useGetAPICAll = <T>({
  endpoint,
  headers,
  isWaiting,
  duplicateRequestHandler = 'takeEvery',
  hasPermission = true
}: GetHookProps): UseApiOutput<T> => {
  const [output, setOutput] = useState<AsyncOutput<T>>();
  const [isLoading, setIsLoading] = useState(false);

  const abortController = useRef(new AbortController());

  useEffect(() => {
    if (!hasPermission) {
      return;
    }

    // If we want to ignore duplicate requests whlie one is already in process, return here
    if (isWaiting || (isLoading && duplicateRequestHandler === 'takeFirst')) {
      return;
    }

    const fetcher = async (): Promise<void> => {
      // If there is an async call in progress from this hook and the takeLatest prop is true, cancel that and execute the newer one.
      // This ensures that we "take the latest" call.
      // NOTE - in local debug instances, you will see an error on your browser when a fetch request is aborted,
      // this won't happen in a production environment.
      if (isLoading && duplicateRequestHandler === 'takeLatest') {
        abortController.current.abort();
        abortController.current = new AbortController();
      } else {
        setIsLoading(true);
      }

      const result = await ApplicationAPI.get<T>({ endpoint, headers, signal: abortController.current.signal });

      setOutput(result);
      setIsLoading(false);
    };

    fetcher();
    // I don't like the react hooks exhaustive deps rule and apparently much of the React community doesn't either based
    // forum threads, I say we look into how to disable this as apparenetly it is not possible in the eslint config
    // eslint-disable-next-line
  }, [endpoint, headers, isWaiting, hasPermission]);

  return {
    output: output || null,
    isLoading
  };
};
