import Tooltip from '@clinintell/components/tooltip/Tooltip';
import { ChildMenuItem, MenuItem } from '@clinintell/modules/menuNavigation';
import { useMenuNavigationState, useUser } from '@clinintell/modules/store';
import { Box, Collapse, Fade, SxProps, Theme } from '@mui/material';
import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { userCan } from '@clinintell/containers/authentication/rules';
import useIsMobileView from '@clinintell/utils/useIsMobileView';

export type MenuNavigationParentProps = {
  menuItem: MenuItem;
  sx?: SxProps<Theme>;
  onClick: (menuItem: MenuItem) => void;
  renderChildMenuNavigationLink: (props: ChildMenuItem) => React.ReactElement;
};

const MenuNavigationParent: React.FC<MenuNavigationParentProps> = ({
  menuItem,
  sx,
  onClick,
  renderChildMenuNavigationLink
}) => {
  const { menuIsTemporarilyExpanded, menuIsExpanded } = useMenuNavigationState();
  const user = useUser();

  const isMobileView = useIsMobileView();
  const expandMenu = menuIsExpanded || menuIsTemporarilyExpanded || isMobileView;

  const iconComponent = !expandMenu ? (
    <Tooltip content={menuItem.label} position="right">
      <span>{<menuItem.IconComponent />}</span>
    </Tooltip>
  ) : (
    <menuItem.IconComponent sx={{ mr: 1 }} />
  );

  const handleMenuItemClick = () => {
    onClick(menuItem);
  };

  const isChildActive = menuItem.children.some(menu => menu.isActive);
  const isHighlighted =
    !isMobileView &&
    ((menuItem.isActive && !isChildActive) ||
      (!menuItem.childrenAreExpanded && isChildActive) ||
      (!expandMenu && isChildActive));

  return (
    <Box sx={{ ...sx }}>
      <Box
        onClick={handleMenuItemClick}
        role="button"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textDecoration: 'none',
          backgroundColor: isHighlighted ? 'teal.vyond7' : 'transparent',
          padding: 2,
          pl: !expandMenu ? '22px' : 2,
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'teal.vyond7'
          },
          '& > *': {
            color: isHighlighted ? 'teal.main' : 'grey.500',
            stroke: theme => (isHighlighted ? theme.palette.teal.main : theme.palette.grey[500]),
            fill: theme => (isHighlighted ? theme.palette.teal.main : theme.palette.grey[500]),
            '&:hover': {
              color: 'teal.main',
              stroke: theme => theme.palette.teal.main
            }
          }
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {iconComponent}
          <Fade timeout={{ enter: 800 }} appear={false} in={expandMenu}>
            <Box component="span" sx={{ fontWeight: 600, pointerEvents: 'none' }}>
              {menuItem.label}
            </Box>
          </Fade>
          <Fade timeout={{ enter: 1000 }} appear={false} in={expandMenu}>
            <Box sx={{ flex: 1, justifyContent: 'flex-end', display: 'flex', pointerEvents: 'none' }}>
              <ChevronRightIcon
                sx={{
                  transition: 'transform 100ms linear',
                  transform: menuItem.childrenAreExpanded ? 'rotate(90deg)' : 'rotate(0)',
                  strokeWidth: '0.25px'
                }}
              />
            </Box>
          </Fade>
        </Box>
      </Box>
      <Collapse in={menuItem.childrenAreExpanded && expandMenu}>
        <Box>
          {menuItem.children
            .filter(childMenuItem => userCan(user, childMenuItem.permissions))
            .map(childMenuItem => renderChildMenuNavigationLink(childMenuItem))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default MenuNavigationParent;
