import React from 'react';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { NoDataMessage } from '@clinintell/containers/dashboard/Dashboard';
import { Box } from '@mui/material';
import { QueryStringParam, createUrlWithQueryString } from '@clinintell/utils/querystring';
import { QueryParams } from '@clinintell/containers/dashboard/widgetTypes';
import D3Speedometer, { Bucket as BucketRange } from '@clinintell/components/Chart/D3Speedometer';

interface Props {
  endpoint: string;
  queryString?: QueryParams;
}

type Bucket = {
  order: number;
  minRange: number;
  maxRange: number;
  documentationScoreMinRange: number;
  documentationScoreMaxRange: number;
};

type DocScoreData = {
  orgId: number;
  currentDocumentationScore: number;
  buckets: Bucket[];
};

const SpeedometerWidget: React.FC<Props> = ({ endpoint, queryString }) => {
  const defaultColorSegments = ['#e74a4b', '#fecd1a', '#169f85', '#bfbfbf'];
  const colorSegments: string[] = [];
  const buckets: BucketRange[] = [];
  let docScore = 0;

  const queryStringValues: QueryStringParam[] = [];

  if (queryString) {
    for (const [key, value] of Object.entries(queryString)) {
      if (value !== null && value !== undefined) {
        queryStringValues.push({ key, value });
      }
    }
  }

  const widgetEndpoint = createUrlWithQueryString(endpoint, queryStringValues);

  const { output, isLoading } = useGetAPICAll<DocScoreData>({
    endpoint: widgetEndpoint,
    isWaiting: false
  });

  if (output && output.error) {
    return <NoDataMessage message="Error occurred attempting to retrieve your data" />;
  }

  if (!output || !output.data || isLoading) {
    return <ClinIntellSkeleton variant="rectangular" height="10rem" width="100%" />;
  }

  if (output && output.data && !isLoading) {
    docScore = Math.ceil(output.data.currentDocumentationScore);
    for (let i = 0; i < output.data.buckets.length; i++) {
      const bucket = output.data.buckets[i];
      buckets.push({
        min: Math.floor(Math.round(bucket.documentationScoreMinRange)),
        max: Math.floor(Math.round(bucket.documentationScoreMaxRange))
      });
      if (output.data.buckets.length > 1) {
        colorSegments.push(defaultColorSegments[i]);
      } else {
        colorSegments.push('#bfbfbf');
      }
    }
  }

  const isChartDataMissing = false;
  return (
    <>
      {isChartDataMissing ? (
        <NoDataMessage />
      ) : (
        <Box style={{ margin: `16px auto`, height: 175, width: '75%' }}>
          <D3Speedometer score={docScore} buckets={buckets} colors={colorSegments} />
        </Box>
      )}
    </>
  );
};

export default SpeedometerWidget;
