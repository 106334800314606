import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  textarea: {
    border: ({ hasValidationError }: { hasValidationError: boolean }): string =>
      `1px solid ${hasValidationError ? theme.palette.red[500] : theme.palette.grey[300]}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    fontFamily: theme.typography.fontFamily,
    '&:focus': {
      outline: `${theme.palette.blue.main} auto 1px`
    }
  }
}));

type TextAreaProps = {
  rows: number;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  width: string | number;
  hasValidationError: boolean;
};

const TextArea: React.FC<TextAreaProps> = ({ rows, placeholder, value, onChange, width, hasValidationError }) => {
  const { textarea } = useStyles({ hasValidationError });

  return (
    <textarea
      onChange={(event): void => onChange(event.target.value)}
      style={{ width }}
      rows={rows}
      placeholder={placeholder}
      className={textarea}
      value={value}
    />
  );
};

export default TextArea;
