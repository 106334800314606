import { containerBorderRadius } from '@clinintell/theme/theme';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';

type AnalysisSummaryRowProps = {
  leftComponent: ReactElement;
  rightComponent: ReactElement;
};

const AnalysisSummaryRow: React.FC<AnalysisSummaryRowProps> = ({ leftComponent, rightComponent }) => {
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('lg'));

  let summaryRowComponent;
  if (isMediumView) {
    summaryRowComponent = (
      <Box justifyContent="space-between" display="flex" flexDirection="column">
        <Box
          borderBottom={`1px solid ${theme.palette.grey[300]}`}
          paddingY={2}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          {leftComponent}
        </Box>
        <Box flexDirection="column" justifyContent="center" alignItems="center" paddingY={2} overflow="auto">
          {rightComponent}
        </Box>
      </Box>
    );
  } else {
    summaryRowComponent = (
      <Box justifyContent="space-around" display="flex">
        <Box
          borderRight={`1px solid ${theme.palette.grey[300]}`}
          flex="1 1 auto"
          paddingY={1}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          {leftComponent}
        </Box>
        <Box paddingY={1} flexDirection="column" alignItems="center" marginBottom={1} display="flex" flex="2 1 auto">
          {rightComponent}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={1}
      bgcolor="white"
      border={`1px solid ${theme.palette.grey[300]}`}
      borderRadius={`${containerBorderRadius}px`}
      marginBottom={2}
    >
      {summaryRowComponent}
    </Box>
  );
};

export default AnalysisSummaryRow;
