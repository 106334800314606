import { ExtendedPaletteOptions } from '@clinintell/types/createPalette';
import { ExtendedTypographyOptions } from '@clinintell/types/typography';
import { ExtendedZIndexes } from '@clinintell/types/zIndex';
import { createTheme } from '@mui/material/styles';

export const sideMenuWidth = '225px';
export const collapsedSideMenuWidth = 45;
export const appBarHeight = '64px';

export const containerBorderRadius = 8;

export const siteTheme = createTheme({
  palette: {
    // Don't use this if you can help it, just to set main color for certain Material UI components
    primary: {
      main: '#385EAB',
      secondary: '#369EA8'
    },
    blue: {
      light5: '#E1E8F5',
      light4: '#B3C4E6',
      light3: '#86A1D8',
      light2: '#688ACE',
      light1: '#4972C4',
      main: '#385EAB',
      dark1: '#305295',
      dark2: '#28447C',
      dark3: '#203663',
      dark4: '#18294A',
      vyond1: '#7F9BD5',
      vyond2: '#A9BCE3'
    },
    teal: {
      light1: '#E0F3F5',
      light2: '#B2E2E6',
      light3: '#93D6DC',
      light4: '#74CAD2',
      light5: '#46B9C3',
      main: '#369EA8',
      dark1: '#2F8991',
      dark2: '#277279',
      dark3: '#1F5B61',
      dark4: '#184549',
      vyond1: '#5EACB6',
      vyond2: '#9ECDD3',
      vyond7: '#F1F8F9'
    },
    grey: {
      50: '#F8FAFC',
      100: '#F1F5F9',
      200: '#E2E8F0',
      300: '#CBD5E1',
      400: '#94A3B8',
      500: '#64748B',
      600: '#475569',
      700: '#334155',
      800: '#1E293B',
      900: '#0F172A',
      A100: '#E5E5E5'
    },
    green: {
      50: '#ECFDF5',
      100: '#D1FAE5',
      200: '#A7F3D0',
      300: '#6EE7B7',
      400: '#34D399',
      500: '#10B981',
      600: '#059669',
      700: '#047857',
      800: '#065F46',
      900: '#064E3B'
    },
    dark: {
      50: '#5F626B',
      100: '#555962',
      200: '#4C4F59',
      300: '#424651',
      400: '#424651',
      500: '#393D48',
      600: '#2F343F',
      700: '#262A36',
      800: '#1C212E',
      900: '#131825'
    },
    yellow: {
      50: '#FFFBEB',
      100: '#FEF3C7',
      200: '#FDE68A',
      300: '#FCD34D',
      400: '#FBBF24',
      500: '#F59E0B',
      600: '#D97706',
      700: '#B45309',
      800: '#92400E',
      900: '#78350F'
    },
    red: {
      50: '#FEF2F2',
      100: '#FEE2E2',
      200: '#FECACA',
      300: '#FCA5A5',
      400: '#F87171',
      500: '#EF4444',
      600: '#DC2626',
      700: '#B91C1C',
      800: '#991B1B',
      900: '#7F1D1D'
    },
    shade: {
      white: '#FFFFFF',
      black: '#000000'
    },
    chart: {
      green: {
        main: 'green',
        light: '#00A746'
      },
      black: {
        main: 'black',
        light: 'black'
      },
      grey: {
        light: 'LightGray',
        main: '#808080'
      },
      blue: {
        main: 'rgb(79, 129, 189)',
        light: 'rgba(79,129,189,0.4)'
      },
      brown: {
        main: 'brown',
        light: 'rgba(165,42,42,0.4)'
      }
    },
    bubble: {
      red: {
        main: '#B91C1C',
        light: '#FEE2E2'
      },
      green: {
        main: '#146515',
        light: '#CAF3DE'
      },
      yellow: {
        main: '#92400E',
        light: '#FEF3C7'
      }
    },
    histogram: {
      lightGrey: 'lightgrey',
      dark: '#94A3B8'
    }
  } as ExtendedPaletteOptions,
  // Material UI doesn't support custom variant names with Typescript, thus this is an attempt to match up what Material UI does have
  // with variant names associated with the ClinIntell branding guide typography options
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Poppins'].join(','),
    fontFamilyMonospaced: ['-apple-system', 'Open Sans'].join(','),
    display1: {
      fontSize: '4.5rem'
    },
    display2: {
      fontSize: '3.75rem'
    },
    h1: {
      fontSize: '3rem'
    },
    h2: {
      fontSize: '2.5rem'
    },
    h3: {
      fontSize: '2rem'
    },
    h4: {
      fontSize: '1.625rem'
    },
    h5: {
      fontSize: '1.25rem'
    },
    h6: {
      fontSize: '1.125rem'
    },
    subheading: {
      fontSize: '1.25rem'
    },
    p1: {
      fontSize: '1rem'
    },
    p2: {
      fontSize: '0.9rem'
    },
    p3: {
      fontSize: '1.1rem'
    },
    caption: {
      fontSize: '0.875rem'
    },
    footer: {
      fontSize: '0.625rem'
    }
  } as ExtendedTypographyOptions,
  zIndex: {
    header: 1000,
    sidebar: 1099,
    actionPanel: 1100,
    modal: 3000,
    snackbar: 9000,
    tooltip: 9999
  } as ExtendedZIndexes
});
