import * as Sentry from '@sentry/react';
import { Primitive } from '@sentry/types';
import { AuthorizedUserDto } from '@clinintell/api/apiSchemas';

export interface ISentryUser extends Sentry.User {
  providerDetails: AuthorizedUserDto['providerDetails'];
  role: string;
  features: { id: number; displayName: string }[];
}

/**
 * Builds tags based on user details to enhance filtering / searching on Sentry
 * @param sentryUser
 * @returns
 */
export const buildSentryTagsFromUser = (sentryUser: ISentryUser): { [key: string]: Primitive } => {
  const { npi, providerName, providerOrgId, ...providerDetailTags } = sentryUser.providerDetails || {};

  return {
    tenant: sentryUser.tenant,
    role: sentryUser.role,
    // DISCUSS: should there be tags concatenating names with org IDs to make them unique?
    ...providerDetailTags
  };
};

/**
 * Converts the authorized user into a more consumable shape for Sentry
 * @param user
 * @returns
 */
export const shapeSentryUser = (user: AuthorizedUserDto): ISentryUser => ({
  id: user.id.toString(),
  email: user.email,
  tenant: user.tenant,
  username: user.name,
  providerDetails: user.providerDetails,
  role: user.role.displayName,
  features: user.features.map(feature => ({
    id: feature.id,
    displayName: feature.displayName
  }))
});
