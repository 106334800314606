import {
  ChartDataSet,
  ChartDataSetJSON,
  ChartDataSetProperties
} from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';

type ProcessComparisonChartDataOutput = {
  hasChartData: boolean;
  filteredChartData: ChartDataSet[];
  combinedDatasets: ChartDataSetJSON;
};

export const processComparisonChartData = (
  orgChartDataset: ChartDataSetJSON,
  comparisonOrgChartDataset: ChartDataSetJSON
): ProcessComparisonChartDataOutput => {
  const combinedDatasets: ChartDataSetJSON = {
    data: [
      ...orgChartDataset.data.map(record => ({
        ...record,
        dataSet: record.dataSet.map(
          (set): ChartDataSetProperties => ({
            ...set,
            errorBand: undefined
          })
        )
      })),
      ...comparisonOrgChartDataset.data
        .filter(record => record.dataSetType === 'Actual')
        .map(
          (record): ChartDataSet => {
            return {
              dataSet: record.dataSet.map(
                (set): ChartDataSetProperties => ({
                  ...set,
                  errorBand: undefined
                })
              ),
              dataSetAverageType: record.dataSetAverageType,
              dataSetType: 'ComparisonEntity'
            };
          }
        )
    ],
    footerNotes: []
  };

  const hasChartData =
    combinedDatasets.data.filter(set => set.dataSetType === 'Actual' && set.dataSet.length > 0).length > 0;

  const filteredChartData = combinedDatasets.data.filter(
    set => set.dataSetType === 'Actual' || set.dataSetType === 'ComparisonEntity'
  );

  return { hasChartData, filteredChartData, combinedDatasets };
};
