import React from 'react';
import { useLottie } from 'lottie-react';
import circleloaderlottie from '@clinintell/assets/CircleLoadingLottie.json';

type Props = {
  size?: number;
};

const DataLoaderLottie: React.FC<Props> = ({ size = 250 }) => {
  const style = {
    height: `${size}px`,
    width: `${size}px`
  };
  const options = {
    animationData: circleloaderlottie,
    autoPlay: true,
    loop: true
  };

  const { View } = useLottie(options, style);

  return View;
};

export default DataLoaderLottie;
