import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconPropType } from '@clinintell/types/common';

const ThreeDotLoadingAnimation: React.FC<SvgIconPropType> = (props): JSX.Element => {
  return (
    <SvgIcon fontSize="inherit" sx={{ width: 25, height: 25, ...props.sx }}>
      (
      <svg x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
        <circle fill="#4f81bd" stroke="none" cx="6" cy="50" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
        </circle>
        <circle fill="#4f81bd" stroke="none" cx="26" cy="50" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
        </circle>
        <circle fill="#4f81bd" stroke="none" cx="46" cy="50" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
        </circle>
      </svg>
      )
    </SvgIcon>
  );
};

export default ThreeDotLoadingAnimation;
