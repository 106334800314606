import waitUntilInDOM from '@clinintell/utils/waitUntilInDOM';
import { useEffect } from 'react';
import findVerticalPositionOfNodeInTree from './findVerticalPositionOfNodeInTree';

type UseScrollToNodeProps = {
  selectednode?: number | null | undefined;
  id: string;
};

const useScrollToNode = ({ selectednode, id }: UseScrollToNodeProps): void => {
  useEffect(() => {
    const handleScrollToNode = async (): Promise<void> => {
      if (!selectednode) {
        return;
      }

      const nodeInDom = await waitUntilInDOM(selectednode.toString());
      const treeElement = document.querySelector(`.MuiTreeView-root.id-${id}`);

      setTimeout(() => {
        if (!treeElement) {
          return;
        }

        const offsetTop = findVerticalPositionOfNodeInTree(nodeInDom);
        treeElement.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      }, 500);
    };

    handleScrollToNode();
  }, [id, selectednode]);
};

export default useScrollToNode;
