import { Slide, useScrollTrigger } from '@mui/material';
import React from 'react';

type HideOnScrollDownProps = {
  shouldAppear: boolean;
  children: React.ReactElement;
};

const HideOnScrollDown: React.FC<HideOnScrollDownProps> = ({ children, shouldAppear }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger && shouldAppear}>
      <div>{children}</div>
    </Slide>
  );
};

export default HideOnScrollDown;
