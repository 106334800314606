import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.blue.light5,
      cursor: 'pointer'
    },
    backgroundColor: theme.palette.blue.main,
    borderRadius: '27px'
  }
}));

function ThreeLinesIcon(props: SvgIconProps): JSX.Element {
  const classes = useStyles();

  return (
    <SvgIcon fontSize="inherit" style={{ width: 38, height: 38 }} {...props} classes={{ root: classes.root }}>
      {/* tslint:disable-next-line: max-line-length */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16Z"
        fill="#4F81BD"
        fillOpacity="0"
      />
      {/* tslint:disable-next-line: max-line-length */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64862 11.5687V9.51349H25.3319V11.5687H8.64862ZM8.64862 14.591V16.7671H22.1141V14.591H8.64862ZM8.64862 19.7893V21.8445H18.0745V19.7893H8.64862Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default ThreeLinesIcon;
