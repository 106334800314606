import { Feature } from '@clinintell/containers/authentication/rules';
import {
  DRGMixComparisonDTO,
  PooledSeverityCMIDTO
} from '@clinintell/containers/cmiComparison/typings/cmiComparisonDtos';
import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import { isAfter, isBefore } from 'date-fns';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { CMIAnalysisViews } from './cmiAnalysisSelections';
import { OrgTreeTypes } from './orgTree';
import { CombinedState } from './store';

export type AnalysisRecord = {
  base: number | null;
  compare: number | null;
};

export type CMIComparison = {
  isInitialized: boolean;
  chartMinDate: string;
  chartMaxDate: string;
  comparisonMinDate: string;
  comparisonMaxDate: string;
  orgId: number | undefined;
  orgName: string;
  comparisonOrgId: number | undefined;
  comparisonOrgName: string;
  cmiSummary: AnalysisRecord | undefined;
  pooledSeverityCMISummary: AnalysisRecord | undefined;
  docScoreSummary: AnalysisRecord | undefined;
  drgMixSummary: AnalysisRecord | undefined;
  drgAnalysis: DRGMixComparisonDTO | undefined;
  isLoadingSummaryRecords: boolean;
  error?: string;
  noSummaryData: boolean;
  analysisView: CMIAnalysisViews;
  orgTreeType: OrgTreeTypes;
  comparisonViewOfProvidersRole: Feature;
};

/* Set comparison period actions */
const SET_COMPARISON_SETTINGS = 'SET_COMPARISON_SETTINGS';
const SET_COMPARISON_SETTINGS_BEGIN = 'SET_COMPARISON_SETTINGS_BEGIN';
const SET_COMPARISON_SETTINGS_SUCCESSFUL = 'SET_COMPARISON_SETTINGS_SUCCESSFUL';
const SET_COMPARISON_SETTINGS_ERROR = 'SET_COMPARISON_SETTINGS_ERROR';

export type SetComparisonSettingsArgs = {
  comparisonMinDate?: string;
  comparisonMaxDate?: string;
  chartMinDate?: string;
  chartMaxDate?: string;
  orgId?: number;
  orgName?: string;
  comparisonOrgId?: number;
  comparisonOrgName?: string;
  orgTreeType?: OrgTreeTypes;
  comparisonViewOfProvidersRole?: Feature;
};

type SetComparisonSettingsPayload = {
  comparisonMinDate: string;
  comparisonMaxDate: string;
  chartMinDate: string;
  chartMaxDate: string;
  orgId: number;
  orgName: string;
  comparisonOrgId: number;
  comparisonOrgName: string;
  orgTreeType: OrgTreeTypes;
  comparisonViewOfProvidersRole: Feature;
};

interface SetComparisonSettings {
  type: typeof SET_COMPARISON_SETTINGS;
  payload: SetComparisonSettingsArgs;
}

interface SetComparisonSettingsBegin {
  type: typeof SET_COMPARISON_SETTINGS_BEGIN;
}

interface SetComparisonSettingsSuccessful {
  type: typeof SET_COMPARISON_SETTINGS_SUCCESSFUL;
  payload: SetComparisonSettingsPayload;
}

interface SetComparisonSettingsError {
  type: typeof SET_COMPARISON_SETTINGS_ERROR;
  payload: string;
}

/* Set Summary Records Actions */
const SET_COMPARISON_RECORDS = 'SET_COMPARISON_RECORDS';
const SET_COMPARISON_RECORDS_BEGIN = 'SET_COMPARISON_RECORDS_BEGIN';
const SET_COMPARISON_RECORDS_SUCCESSFUL = 'SET_COMPARISON_RECORDS_SUCCESSFUL';
const SET_COMPARISON_RECORDS_ERRORS = 'SET_COMPARISON_RECORDS_ERRORS';

export type SetComparisonRecordsArgs = {
  comparisonMin: string;
  comparisonMax: string;
  orgId: number;
  comparisonOrgId: number;
  orgTreeType: OrgTreeTypes;
};

export type SetComparisonRecordsPayload = {
  cmiSummary: AnalysisRecord | undefined;
  docScoreSummary: AnalysisRecord | undefined;
  pooledSeverityCMISummary: AnalysisRecord | undefined;
  drgMixSummary: AnalysisRecord | undefined;
  drgAnalysis: DRGMixComparisonDTO | undefined;
  noSummaryData: boolean;
};

interface SetComparisonRecords {
  type: typeof SET_COMPARISON_RECORDS;
  payload: SetComparisonRecordsArgs;
}

interface SetComparisonRecordsBegin {
  type: typeof SET_COMPARISON_RECORDS_BEGIN;
}

interface SetComparisonRecordsSuccessful {
  type: typeof SET_COMPARISON_RECORDS_SUCCESSFUL;
  payload: SetComparisonRecordsPayload;
}

interface SetComparisonRecordsErrors {
  type: typeof SET_COMPARISON_RECORDS_ERRORS;
  payload: string;
}

/* Set Analysis View */
const SET_ANALYSIS_VIEW = 'SET_ANALYSIS_VIEW';

type SetAnalysisViewPayload = {
  analysisView: CMIAnalysisViews;
};

interface SetAnalysisView {
  type: typeof SET_ANALYSIS_VIEW;
  payload: SetAnalysisViewPayload;
}

/* Reset Comparison */
const RESET_COMPARISON = 'RESET_COMPARISON';

interface ResetComparison {
  type: typeof RESET_COMPARISON;
}

export type CMIComparisonActionTypes =
  | SetComparisonSettings
  | SetComparisonSettingsBegin
  | SetComparisonSettingsSuccessful
  | SetComparisonSettingsError
  | SetComparisonRecords
  | SetComparisonRecordsBegin
  | SetComparisonRecordsSuccessful
  | SetComparisonRecordsErrors
  | SetAnalysisView
  | ResetComparison;

export const setCMIComparisonSettings = (args: SetComparisonSettingsArgs): CMIComparisonActionTypes => ({
  type: 'SET_COMPARISON_SETTINGS',
  payload: args
});

export const setComparisonRecords = (args: SetComparisonRecordsArgs): CMIComparisonActionTypes => ({
  type: 'SET_COMPARISON_RECORDS',
  payload: args
});

export const setAnalysisView = (args: SetAnalysisViewPayload): CMIComparisonActionTypes => ({
  type: 'SET_ANALYSIS_VIEW',
  payload: args
});

export const resetCMIComparison = (): CMIComparisonActionTypes => ({
  type: 'RESET_COMPARISON'
});

export const initialState: CMIComparison = {
  isInitialized: false,
  chartMinDate: '',
  chartMaxDate: '',
  comparisonMinDate: '',
  comparisonMaxDate: '',
  orgId: undefined,
  orgName: '',
  comparisonOrgId: undefined,
  comparisonOrgName: '',
  isLoadingSummaryRecords: false,
  cmiSummary: undefined,
  docScoreSummary: undefined,
  drgMixSummary: undefined,
  pooledSeverityCMISummary: undefined,
  drgAnalysis: undefined,
  noSummaryData: false,
  analysisView: 'cmi',
  orgTreeType: 'client',
  comparisonViewOfProvidersRole: 'reportCmiComparisonOfProvidersView'
};

// Reducer
const reducer = (state: CMIComparison = initialState, action: CMIComparisonActionTypes): CMIComparison => {
  switch (action.type) {
    case 'SET_COMPARISON_SETTINGS_BEGIN': {
      const { error, ...errorlessState } = state;

      return {
        ...errorlessState
      };
    }
    case 'SET_COMPARISON_SETTINGS_SUCCESSFUL': {
      const { error, ...errorlessState } = state;

      return {
        ...errorlessState,
        ...action.payload
      };
    }
    case 'SET_COMPARISON_SETTINGS_ERROR': {
      return {
        ...state,
        error: action.payload
      };
    }
    case 'SET_COMPARISON_RECORDS_BEGIN': {
      const { error, ...errorlessState } = state;

      return {
        ...errorlessState,
        isLoadingSummaryRecords: true,
        noSummaryData: false
      };
    }
    case 'SET_COMPARISON_RECORDS_SUCCESSFUL': {
      const { error, ...errorlessState } = state;

      return {
        ...errorlessState,
        ...action.payload,
        isLoadingSummaryRecords: false
      };
    }
    case 'SET_COMPARISON_RECORDS_ERRORS': {
      return {
        ...state,
        isLoadingSummaryRecords: false,
        error: action.payload
      };
    }
    case 'SET_ANALYSIS_VIEW': {
      return {
        ...state,
        analysisView: action.payload.analysisView
      };
    }
    case 'RESET_COMPARISON': {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

// Sagas
export function* fetchCMIComparisonSummaryRecords({ payload }: SetComparisonRecords): SagaIterator {
  yield put({
    type: SET_COMPARISON_RECORDS_BEGIN
  });

  const { comparisonOrgId, orgId, comparisonMax, comparisonMin, orgTreeType } = payload;
  const results: [AsyncOutput<PooledSeverityCMIDTO>, AsyncOutput<DRGMixComparisonDTO>] = yield all([
    call(ApplicationAPI.get, {
      endpoint: `${
        orgTreeType === 'saf' ? 'saf/' : ''
      }metrics/severitycmicomparison/${orgId}?compareTo=${comparisonOrgId}&startDate=${formatDateForAPI(
        new Date(comparisonMin)
      )}&endDate=${formatDateForAPI(new Date(comparisonMax))}`
    }),
    call(ApplicationAPI.get, {
      endpoint: `${
        orgTreeType === 'saf' ? 'saf/' : ''
      }metrics/drgmixcomparison/${orgId}?compareTo=${comparisonOrgId}&startDate=${formatDateForAPI(
        new Date(comparisonMin)
      )}&endDate=${formatDateForAPI(new Date(comparisonMax))}`
    })
  ]);

  const errors: string[] = [];
  const summaryState = results.reduce((obj, result, index) => {
    let metricLabel;
    switch (index) {
      case 0:
        metricLabel = 'Pooled Severity CMI';
        break;
      case 1:
        metricLabel = 'DRG Mix';
        break;
      default:
        metricLabel = 'N/A';
        break;
    }

    if (result.error) {
      errors.push(`Error fetching ${metricLabel} summary records - ${result.error}`);
      return {
        ...obj
      };
    }

    // Calculate comparison records for Pooled Severity CMI
    if (index === 0) {
      const comparisonResult = results[0];
      // Catch cases where there is no data returned
      if ((!result.data && result.status === 204) || (!comparisonResult.data && comparisonResult.status === 204)) {
        return {
          ...obj,
          cmiSummary: undefined,
          pooledSeverityCMISummary: undefined,
          docScoreSummary: undefined
        };
      }

      const output = result.data as PooledSeverityCMIDTO;
      return {
        ...obj,
        cmiSummary: {
          base: output.entity1.cmi,
          compare: output.entity2.cmi
        },
        docScoreSummary: {
          base: output.entity1.docScore,
          compare: output.entity2.docScore
        },
        pooledSeverityCMISummary: {
          base: output.entity1.pooledSeverityCmi,
          compare: output.entity2.pooledSeverityCmi
        }
      };
    }

    // Calculate comparison records for DRG mix
    if (index === 1) {
      const comparisonResult = results[1];
      // Catch cases where there is no data returned
      if ((!result.data && result.status === 204) || (!comparisonResult.data && comparisonResult.status === 204)) {
        return {
          ...obj,
          drgMixSummary: undefined,
          drgAnalysis: undefined
        };
      }

      return {
        ...obj,
        drgMixSummary: {
          base: (result.data as DRGMixComparisonDTO).entity1.hcupCmi,
          compare: (comparisonResult.data as DRGMixComparisonDTO).entity2.hcupCmi
        },
        drgAnalysis: result.data as DRGMixComparisonDTO
      };
    }

    return {
      ...obj
    };
  }, {} as SetComparisonRecordsPayload);

  if (errors.length > 0) {
    yield put({
      type: SET_COMPARISON_RECORDS_ERRORS,
      payload: errors.join(' / ')
    });
  }

  yield put({
    type: SET_COMPARISON_RECORDS_SUCCESSFUL,
    payload: {
      ...summaryState,
      noSummaryData: !summaryState.docScoreSummary || !summaryState.cmiSummary || !summaryState.pooledSeverityCMISummary
    }
  });
}

export const cmiComparisonSelector = (state: CombinedState): CMIComparison => state.cmiComparison;

export function* putCMIComparisonSettings({ payload }: SetComparisonSettings): SagaIterator {
  yield put({
    type: SET_COMPARISON_SETTINGS_BEGIN
  });

  const {
    comparisonMinDate,
    comparisonMaxDate,
    chartMinDate,
    chartMaxDate,
    orgId,
    orgName,
    comparisonOrgId,
    comparisonOrgName
  } = yield select(cmiComparisonSelector);

  const datePayload = {
    comparisonMinDate,
    comparisonMaxDate,
    chartMinDate,
    chartMaxDate,
    orgId,
    orgName,
    comparisonOrgId,
    comparisonOrgName,
    ...payload
  };

  let comparisonPeriodChanged = false;
  // If chart max date is less than the comparison min date, or chart min date is greater than the comparison max date,
  // slide the comparison period to equal the new chart period range
  if (
    isAfter(new Date(datePayload.comparisonMinDate), new Date(datePayload.chartMaxDate)) ||
    isBefore(new Date(datePayload.comparisonMaxDate), new Date(datePayload.chartMinDate))
  ) {
    datePayload.comparisonMaxDate = datePayload.chartMaxDate;
    datePayload.comparisonMinDate = datePayload.chartMinDate;
    comparisonPeriodChanged = true;
  }

  if (isAfter(new Date(datePayload.comparisonMaxDate), new Date(datePayload.chartMaxDate))) {
    // If chart max date is less than the comparison max date, set the comparison max date to the new chart max date
    datePayload.comparisonMaxDate = datePayload.chartMaxDate;
    comparisonPeriodChanged = true;
  }

  // Same with the min dates
  if (isBefore(new Date(datePayload.comparisonMinDate), new Date(datePayload.chartMinDate))) {
    datePayload.comparisonMinDate = datePayload.chartMinDate;
    comparisonPeriodChanged = true;
  }

  yield put({
    type: SET_COMPARISON_SETTINGS_SUCCESSFUL,
    payload: datePayload
  });

  // If comparison period changed as a result of the chart dates, fetch new summary records
  if (comparisonPeriodChanged) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(fetchCMIComparisonSummaryRecords, {
      payload: {
        comparisonMin: datePayload.comparisonMinDate,
        comparisonMax: datePayload.comparisonMaxDate,
        orgId: datePayload.orgId,
        comparisonOrgId: datePayload.comparisonOrgId,
        orgTreeType: datePayload.orgTreeType
      }
    });
  }
}

export function* cmiComparisonSettingsSaga(): SagaIterator {
  yield takeLeading('SET_COMPARISON_SETTINGS', putCMIComparisonSettings);
}

export function* cmiComparisonSummaryRecordsSaga(): SagaIterator {
  yield takeLatest('SET_COMPARISON_RECORDS', fetchCMIComparisonSummaryRecords);
}
