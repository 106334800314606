import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import DataLoaderLottie from '@clinintell/components/DataLoaderLottie';
import WidgetError from './WidgetError';

const useStyles = makeStyles(theme => ({
  WidgetCardContainer: {
    width: 'auto',
    height: 'auto',
    border: 'none',
    borderRadius: '12px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    padding: '25px 30px 5px 30px',
    color: theme.palette.grey[700]
  },
  WidgetCardHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  WidgetCardTitle: {
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: '18px',
    paddingRight: '10px'
  },
  WidgetCardHeaderToolTip: {
    display: 'inline-block',
    color: theme.palette.grey[500],
    width: 'fit-content',
    height: 'fit-content',
    alignSelf: 'self-end',
    '& svg': {
      width: '18px',
      height: '18px'
    }
  },
  WidgetCardBody: {
    position: 'relative'
  }
}));

type WidgetProps = {
  isLoading?: boolean;
  hasError?: boolean;
  headerTitle?: string;
  toolTip?: string | JSX.Element;
  displayTooltip?: boolean;
  smallWidget?: boolean;
  noPadding?: boolean;
  graphStatus?: string;
  graphMessage?: string;
  textOnly?: boolean;
};

const Widget: React.FC<WidgetProps> = ({
  isLoading,
  hasError,
  headerTitle,
  toolTip,
  displayTooltip,
  smallWidget = false,
  noPadding = false,
  graphStatus,
  graphMessage,
  textOnly,
  children
}) => {
  const {
    WidgetCardContainer,
    WidgetCardHeader,
    WidgetCardTitle,
    WidgetCardHeaderToolTip,
    WidgetCardBody
  } = useStyles();

  return isLoading === true ? (
    <Card className={WidgetCardContainer}>
      <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <DataLoaderLottie size={250} />
      </Box>
    </Card>
  ) : (
    <Card className={WidgetCardContainer}>
      <Box className={WidgetCardHeader}>
        <Typography variant="h4" className={WidgetCardTitle}>
          {headerTitle}
        </Typography>
        {displayTooltip ? (
          <Tooltip content={toolTip}>
            <span className={WidgetCardHeaderToolTip}>
              <HelpOutlineIcon component="svg" />
            </span>
          </Tooltip>
        ) : (
          ''
        )}
      </Box>
      {hasError ? (
        <WidgetError graphStatus={graphStatus} graphMessage={graphMessage} textOnly={textOnly} />
      ) : (
        <Box
          className={WidgetCardBody}
          style={{ padding: smallWidget || noPadding ? 0 : '30px 12px', height: smallWidget ? '85%' : 'auto' }}
        >
          {children}
        </Box>
      )}
    </Card>
  );
};

export default Widget;
