import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TrainingStart = (props: SvgIconProps): JSX.Element => (
  <SvgIcon style={props.style} viewBox="0 0 22 22" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1875 8.75062L12.5 4.06312L0 16.5631V21.2506H4.6875L17.1875 8.75062ZM20.8875 5.05062C21.375 4.56312 21.375 3.77562 20.8875 3.28812L17.9625 0.363123C17.475 -0.124377 16.6875 -0.124377 16.2 0.363123L13.75 2.81312L18.4375 7.50062L20.8875 5.05062Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default TrainingStart;
