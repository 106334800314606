import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconPropType } from '@clinintell/types/common';

const ConfidentialityCircle = (props: SvgIconPropType): JSX.Element => {
  const colorOverride = props.sx.color ?? 'inherit';
  return (
    <SvgIcon sx={{ fill: 'none', ...props.sx }} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#E1E8F5" />
      <path
        d="M29 20.6667C29 19.3406 28.4732 18.0688 27.5355 17.1311C26.5979 16.1934 25.3261 15.6667 24 15.6667C22.6739 15.6667 21.4021 16.1934 20.4645 17.1311C19.5268 18.0688 19 19.3406 19 20.6667C19 26.5 16.5 28.1667 16.5 28.1667H31.5C31.5 28.1667 29 26.5 29 20.6667Z"
        stroke={colorOverride}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#E1E8F5"
      />
      <path
        d="M25.4417 31.5C25.2952 31.7526 25.0849 31.9622 24.8319 32.1079C24.5789 32.2537 24.292 32.3304 24 32.3304C23.708 32.3304 23.4212 32.2537 23.1682 32.1079C22.9151 31.9622 22.7049 31.7526 22.5583 31.5"
        stroke={colorOverride}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ConfidentialityCircle;
