import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ExpandIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon
      viewBox="0 0 32 32"
      fontSize="inherit"
      sx={{
        width: 13,
        height: 13,
        '&:hover': {
          backgroundColor: 'secondary.light5'
        }
      }}
      {...props}
    >
      {/* tslint: disable-next-line: max-line-length */}
      <path d="M10 6L16 0L22 6H10Z" fill="inherit" />
      <path d="M10 26L16 32L22 26H10Z" fill="inherit" />
      <rect y="10" width="32" height="4" fill="inherit" />
      <rect y="18" width="32" height="4" fill="inherit" />
    </SvgIcon>
  );
}

export default ExpandIcon;
