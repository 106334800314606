import DateRangePicker from '@clinintell/components/datepicker/DateRangePicker';
import Button from '@clinintell/components/button/Button';
import { setCMIAnalysisBestFit } from '@clinintell/modules/cmiAnalysis';
import { useCMIAnalysisDispatch, useCMIAnalysisState } from '@clinintell/modules/store';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import useMyDefaultDates from '@clinintell/components/analysisSharedComponents/useMyDefaultDates';
import useExcelDownload from '@clinintell/components/analysisSharedComponents/useExcelDownload';
import DownloadIcon from '@clinintell/components/icons/Download';
import CreateIcon from '@mui/icons-material/Create';
import { isIE11 } from '@clinintell/utils/browsers';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import usePDFDownload from '@clinintell/components/analysisSharedComponents/usePDFDownload';
import { CMIAnalysisViews } from '@clinintell/modules/cmiAnalysisSelections';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    flexFlow: 'wrap',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    '& > *:not(last-child)': {
      marginRight: ({ isIE11 }: { isIE11: boolean }): string => (isIE11 ? theme.spacing(3) : '0')
    }
  }
}));

type AnalysisSelectorsProps = {
  analysisType: CMIAnalysisViews;
  onSelect: () => void;
  entityName: string;
};

const AnalysisSelectors: React.FC<AnalysisSelectorsProps> = ({ analysisType, onSelect, entityName }) => {
  const {
    chartMinDate,
    chartMaxDate,
    isLoadingBestFit,
    noBestFit,
    noSummaryData,
    historicalMinDate,
    historicalMaxDate,
    currentMaxDate,
    currentMinDate,
    entityId
  } = useCMIAnalysisState();

  const { container } = useStyles({ isIE11 });
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const cmiAnalysisDispatch = useCMIAnalysisDispatch();

  const defaultDates = useMyDefaultDates(entityId);
  const { downloadExcelOutput, isDownloading } = useExcelDownload();
  const { downloadPDF, isDownloading: isDownloadingPDF } = usePDFDownload();

  const handleDateRangeChanged = (startPeriod: string, endPeriod: string): void => {
    if (entityId === undefined) {
      return;
    }

    cmiAnalysisDispatch(
      setCMIAnalysisBestFit({
        orgId: entityId,
        metric: analysisType,
        startDate: new Date(startPeriod),
        endDate: new Date(endPeriod),
        recalculate: true,
        orgName: entityName
      })
    );
  };

  const handleBestFitClick = (): void => {
    if (!chartMinDate || !chartMaxDate || entityId === undefined) {
      return;
    }

    cmiAnalysisDispatch(
      setCMIAnalysisBestFit({
        orgId: entityId,
        startDate: chartMinDate,
        endDate: chartMaxDate,
        recalculate: true,
        metric: analysisType,
        orgName: entityName
      })
    );
  };

  const handleDownloadClick = (): void => {
    if (!historicalMinDate || !historicalMaxDate || !currentMinDate || !currentMaxDate || entityId === undefined) {
      return;
    }

    downloadExcelOutput({
      entityId,
      historicalMinDate,
      historicalMaxDate,
      currentMinDate,
      currentMaxDate,
      filename: `Single Entity Analysis_${analysisType === 'drgmix' ? 'DRG Mix' : 'Severity Value'}`,
      analysisType: analysisType === 'drgmix' ? 'analysis' : 'severity'
    });
  };

  const handleDownloadPDFClick = (): void => {
    if (
      !historicalMinDate ||
      !historicalMaxDate ||
      !currentMinDate ||
      !currentMaxDate ||
      !chartMinDate ||
      !chartMaxDate ||
      entityId === undefined
    ) {
      return;
    }

    downloadPDF({
      entityId,
      historicalMinDate,
      historicalMaxDate,
      currentMinDate,
      currentMaxDate,
      filename: 'CMI Analysis',
      analysisType: 'analysis',
      chartMinDate,
      chartMaxDate
    });
  };

  return (
    <Box>
      <Box className={container}>
        {defaultDates &&
        defaultDates.absoluteMinMonth &&
        defaultDates.currentMaxMonth &&
        chartMinDate &&
        chartMaxDate ? (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box typography="p1">Period:</Box>
            <Box ml={2}>
              <DateRangePicker
                id="patientMixDateRange"
                title="Period"
                isDisabled={false}
                absoluteMinDate={defaultDates.absoluteMinMonth}
                absoluteMaxDate={defaultDates.currentMaxMonth}
                selectionMinDate={chartMinDate}
                selectionMaxDate={chartMaxDate}
                handleDateRangeApply={handleDateRangeChanged}
                isPreselectVisible={false}
                isInterventionAvailable={false}
                size={isMobileView ? 'small' : 'large'}
                transformOrigin={
                  theme.breakpoints.down(1130)
                    ? { vertical: 'top', horizontal: 'left' }
                    : { vertical: 'top', horizontal: 'center' }
                }
              ></DateRangePicker>
            </Box>
          </Box>
        ) : null}
        <Button leftAdornment={<CreateIcon sx={{ mt: '4px' }} />} label="Change Selection" onClick={onSelect} />
        <Button
          isBusy={isLoadingBestFit}
          label="Best Fit"
          onClick={handleBestFitClick}
          disabled={noBestFit}
          leftAdornment={<CheckIcon sx={{ mt: '4px' }} />}
        />
        {analysisType !== 'cmi' ? (
          <Tooltip content={noSummaryData || noBestFit ? 'No data to download based on the time period selected' : ''}>
            <Button
              onClick={handleDownloadClick}
              label="Download Excel"
              isBusy={isDownloading}
              disabled={noSummaryData || noBestFit}
              variant="outlined"
              leftAdornment={
                <DownloadIcon
                  sx={{
                    stroke: theme => (noSummaryData || noBestFit ? theme.palette.grey[400] : theme.palette.blue.main)
                  }}
                />
              }
            />
          </Tooltip>
        ) : analysisType === 'cmi' ? (
          <Tooltip content={noSummaryData || noBestFit ? 'No data to download based on the time period selected' : ''}>
            <Button
              onClick={handleDownloadPDFClick}
              label="Download PDF"
              isBusy={isDownloadingPDF}
              disabled={noSummaryData || noBestFit}
              variant="outlined"
              leftAdornment={
                <DownloadIcon
                  sx={{
                    stroke: theme => (noSummaryData || noBestFit ? theme.palette.grey[400] : theme.palette.blue.main)
                  }}
                />
              }
            />
          </Tooltip>
        ) : null}
      </Box>
    </Box>
  );
};

export default AnalysisSelectors;
