import React from 'react';
import ErrorPage from './ErrorPage';
import unauthorized from '@clinintell/assets/images/unauthorized.svg';
import maintenance from '@clinintell/assets/images/maintenance.png';
import microscope from '@clinintell/assets/images/microscope.png';
import timeout from '@clinintell/assets/images/timeout.png';
import stethoscope from '@clinintell/assets/images/stethoscope_v2.png';

interface ErrorData {
  imgSrc: string;
  errorCode: string;
  errorTitle: string;
  errorTxtMsg: string;
  showBackBtn: boolean;
  showRefresh: boolean;
}

const isError = (error: Error, code: string) => {
  return error.name.includes(code) || error.message.includes(code) ? true : false;
};

const getCustomError = (error?: Error): ErrorData => {
  let errorInfo: ErrorData = {
    imgSrc: stethoscope,
    errorCode: '500',
    errorTitle: 'Something went wrong...',
    errorTxtMsg: 'It seems that we’re unable to diagnose your condition. Please try again or contact us.',
    showBackBtn: false,
    showRefresh: true
  };

  if (
    error === null ||
    error === undefined ||
    isError(error, '500') ||
    isError(error, '502') ||
    isError(error, '504')
  ) {
    errorInfo['errorCode'] =
      error === null || error === undefined || isError(error, '502') ? '502' : isError(error, '504') ? '504' : '500';
  } else if (error.name.includes('401') || error.message.includes('401')) {
    errorInfo = {
      imgSrc: unauthorized,
      errorCode: '401',
      errorTitle: 'Unauthorized!',
      errorTxtMsg: `We could not verify that you are authorized to access this application. Please try refreshing the page and fill in the correct sign in details.`,
      showBackBtn: false,
      showRefresh: false
    };
  } else if (isError(error, '403')) {
    errorInfo = {
      imgSrc: unauthorized,
      errorCode: '403',
      errorTitle: 'Forbidden!',
      errorTxtMsg: 'We’re sorry but you do not have access to this page.',
      showBackBtn: true,
      showRefresh: false
    };
  } else if (isError(error, '404') || isError(error, '410')) {
    errorInfo = {
      imgSrc: microscope,
      errorCode: isError(error, '404') ? '404' : '410',
      errorTitle: 'Sorry, this page doesn’t exist.',
      errorTxtMsg: 'Go back or choose a new destination on the side menu.',
      showBackBtn: false,
      showRefresh: false
    };
  } else if (isError(error, '408')) {
    errorInfo = {
      imgSrc: timeout,
      errorCode: '408',
      errorTitle: 'Request time-out!',
      errorTxtMsg: 'This request took too long to process. Please try again or contact us.',
      showBackBtn: false,
      showRefresh: true
    };
  } else if (isError(error, '503')) {
    errorInfo = {
      imgSrc: maintenance,
      errorCode: '503',
      errorTitle: 'Maintenance mode',
      errorTxtMsg:
        'Sorry, we’re down for maintenance but we’ll be back shortly! Thank you for your patience and feel free to contact us with any questions.',
      showBackBtn: false,
      showRefresh: false
    };
  }
  return errorInfo;
};

interface Props {
  error?: Error;
  resetError?: () => void;
}

const RouteErrorPage: React.FC<Props> = ({ error, resetError }) => {
  const errorMessage: ErrorData = error ? getCustomError(error) : getCustomError();

  return <ErrorPage {...errorMessage} resetError={resetError} />;
};

export default RouteErrorPage;
