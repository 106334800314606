import React from 'react';
import { CircularProgress, useTheme } from '@mui/material';

export type CircleLoadingIndicatorProps = {
  loadingIndicatorSize: number;
  colorOverride?: string;
};

const CircleLoadingIndicator: React.FC<CircleLoadingIndicatorProps> = ({ loadingIndicatorSize, colorOverride }) => {
  const theme = useTheme();

  return (
    <CircularProgress
      sx={{
        color: 'blue.main'
      }}
      style={{ color: colorOverride || theme.palette.blue.main }}
      size={loadingIndicatorSize}
      variant="indeterminate"
    />
  );
};

export default CircleLoadingIndicator;
