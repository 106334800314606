import ExpandIcon from '@clinintell/components/icons/ExpandIcon';
import { containerBorderRadius } from '@clinintell/theme/theme';
import { Box, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { browserVersion, isIE } from 'react-device-detect';
import { ResizableBox, ResizeHandle } from 'react-resizable';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: 0,
    '& ul': {
      paddingLeft: theme.spacing(2.5),
      marginBottom: theme.spacing(2)
    }
  },
  handle: {
    height: theme.spacing(3),
    marginLeft: '0px',
    // use backgroundColor and 100% width left: 0 ? for solid bar(change marginBottom to 0)
    left: 0,
    backgroundColor: theme.palette.blue.main,
    width: '100%',
    margin: 'auto',
    backgroundImage: 'none', // override default
    transform: 'rotate(0deg)',
    alignItems: 'stretch',
    paddingRight: '0px'
  },
  iconContainer: {
    height: theme.spacing(2.5),
    borderBottom: `1px solid ${theme.palette.blue.main}`,
    flexGrow: 1,
    backgroundColor: theme.palette.grey[200],
    fill: theme.palette.grey[500]
  },
  expandicon: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(2)
  },
  ib: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    height: '100%'
  }
}));

const isIE11 = isIE && browserVersion === '11';

type CollapsibleTreePanelProps = {
  isCollapsed: boolean;
  height?: number;
  withBorder?: boolean;
};

const CollapsibleTreePanel: React.FC<CollapsibleTreePanelProps> = ({ isCollapsed, children, height, withBorder }) => {
  const classes = useStyles();

  let defaultHeight = isIE11 ? 275 : 250;
  if (height !== undefined) {
    defaultHeight = height;
  }

  return (
    <Collapse in={isCollapsed}>
      <ResizableBox
        width={Infinity} // Make the Typescript prop type check happy
        height={defaultHeight}
        minConstraints={[100, 100]}
        // This works but has invalid propType. PR to fix issue on react-resizabe is in progress
        // https://github.com/STRML/react-resizable/pull/133
        handle={(h: ResizeHandle, ref: React.Ref<unknown>): JSX.Element => {
          return (
            <Box ref={ref} className={`react-resizable-handle react-resizable-handle-${h} ${classes.handle}`}>
              <Box display="flex" justifyContent="center" alignContent="center" className={classes.iconContainer}>
                <ExpandIcon className={classes.expandicon} />
              </Box>
            </Box>
          );
        }}
        resizeHandles={['s']}
      >
        <Box display="flex" flexWrap="nowrap" justifyContent="flex-start" alignItems="center" className={classes.ib}>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
              height: '100%',
              backgroundColor: 'white',
              boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.25)`,
              border: withBorder ? '1px solid' : 'none',
              borderColor: 'grey.300',
              borderRadius: `${containerBorderRadius}px`
            }}
          >
            {children}
          </Box>
        </Box>
      </ResizableBox>
    </Collapse>
  );
};

export default CollapsibleTreePanel;
