import React, { useState } from 'react';
import { Box, Button, Typography, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  containerClosed: {
    borderBottom: `1px solid ${theme.palette.blue.main}`
  },
  containerOpen: {
    border: `1px solid ${theme.palette.blue.main}`,
    borderRadius: '4px',
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(5)} ${theme.spacing(1)}`,
    background: theme.palette.common.white
  },
  button: {
    padding: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
    background: theme.palette.common.white,
    color: theme.palette.blue.main,
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '10rem',
    border: `1px solid ${theme.palette.blue.main}`,
    borderRadius: '24px',
    bottom: '-20px',
    '&:hover': {
      background: theme.palette.common.white
    }
  }
}));

type ExpandablePanelProps = {
  showButtonText: string;
  hideButtonText: string;
  children: JSX.Element;
  styles?: React.CSSProperties;
};

const ExpandablePanel: React.FC<ExpandablePanelProps> = ({ showButtonText, hideButtonText, children, styles }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { containerClosed, containerOpen, button } = useStyles();

  return (
    <Box
      style={styles}
      className={clsx({
        [containerOpen]: isExpanded,
        [containerClosed]: !isExpanded
      })}
      position="relative"
    >
      <Collapse in={isExpanded}>{children}</Collapse>
      <Button
        classes={{
          root: button
        }}
        onClick={(): void => setIsExpanded(!isExpanded)}
      >
        <Typography style={{ fontWeight: 700 }} variant="caption">
          {isExpanded ? hideButtonText : showButtonText}
        </Typography>
      </Button>
    </Box>
  );
};

export default ExpandablePanel;
