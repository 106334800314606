import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { useCMIAnalysisState } from '@clinintell/modules/store';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import React from 'react';
import useTimeSeriesData from '../logic/useTimeSeriesData';
import AnalysisChart from './AnalysisChart';
import SeverityImpactTables from '@clinintell/components/analysisSharedComponents/SeverityImpactTables';

const SeverityCMIAnalysisDetails: React.FC = () => {
  const {
    chartMinDate,
    chartMaxDate,
    severityCMISummary,
    severityCMIAnalysis,
    entityId,
    isLoadingSummaryRecords
  } = useCMIAnalysisState();

  const { data: severityCmiChartData, isLoading: severityCmiIsLoading } = useTimeSeriesData({
    minChartPeriod: chartMinDate,
    maxChartPeriod: chartMaxDate,
    orgId: entityId,
    metric: 'severityCmi'
  });

  let filteredSeverityCmiChartData;
  if (severityCmiChartData) {
    filteredSeverityCmiChartData = severityCmiChartData.data.filter(set => set.dataSetType === 'Actual');
  }

  const severityCmiDifference =
    severityCMISummary && severityCMISummary.current && severityCMISummary.historical
      ? Number(formatToDecimalPlaces(severityCMISummary.current, 2)) -
        Number(formatToDecimalPlaces(severityCMISummary.historical, 2))
      : 0;

  const hasPositiveCMIDelta =
    severityCMISummary && severityCMISummary.current && severityCMISummary.historical
      ? severityCMISummary.current - severityCMISummary.historical >= 0
      : true;

  const severityCmiChart =
    severityCmiChartData && filteredSeverityCmiChartData && !severityCmiIsLoading ? (
      <AnalysisChart
        metric="severityCmi"
        dataset={severityCmiChartData}
        chartDataSets={filteredSeverityCmiChartData}
        chartPeriodType="Month"
        currentDateIsBetterTimes={!severityCMISummary || severityCmiDifference > 0}
        chartTitle="Severity CMI"
      />
    ) : (
      <ClinIntellSkeleton variant="rectangular" height="26rem" width="100%" />
    );

  let tableElement = null;
  if (severityCMIAnalysis) {
    tableElement = (
      <SeverityImpactTables
        isLoading={isLoadingSummaryRecords}
        hasPositiveDelta={hasPositiveCMIDelta}
        tableRows={severityCMIAnalysis.table}
      />
    );
  }

  return (
    <>
      {severityCmiChart}
      {tableElement}
    </>
  );
};

export default SeverityCMIAnalysisDetails;
