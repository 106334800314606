import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  chip: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textAlign: 'left',
    textTransform: 'uppercase'
  },
  chipBorderFull: {
    borderRadius: '12px',
    border: `2px solid`
  },
  chipBorderLeft: {
    borderRadius: '12px 0 0 12px',
    borderTop: '2px solid',
    borderBottom: '2px solid',
    borderLeft: '2px solid'
  },
  chipBorderRight: {
    borderRadius: '0 12px 12px 0',
    borderTop: '2px solid',
    borderBottom: '2px solid',
    borderRight: '2px solid'
  },
  chipPillLayout: {
    display: 'grid',
    gridTemplateColumns: 'auto .02fr auto',
    width: '100%'
  },
  chipPillSeparator: {
    borderTop: '2px solid',
    borderBottom: '2px solid',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5)
  },
  chipPillSeparatorPipe: {
    borderLeft: '2px solid'
  },
  blueChip: {
    backgroundColor: '#E9F1F8',
    color: '#588FBA',
    '& h4, h5': {
      color: '#588FBA'
    }
  },
  greenChip: {
    backgroundColor: '#E0F3F5',
    color: '#369EA8',
    '& h4, h5': {
      color: '#369EA8'
    }
  },
  greyChip: {
    backgroundColor: '#F1F5F9',
    color: '#64748B',
    '& h4, h5': {
      color: '#64748B'
    }
  },
  disabledChip: {
    border: `2px solid #94A3B8`,
    color: '#94A3B8',
    backgroundColor: '#F1F5F9'
  }
}));

type Props = {
  color: 'blue' | 'green' | 'grey';
  title: string | JSX.Element;
  height?: number;
  disabled?: boolean;
  children: JSX.Element;
};

type SplitProps = {
  color: 'blue' | 'green' | 'grey';
  title: string[];
  height?: number;
  disabled?: boolean;
  children: JSX.Element[];
};

export const LabelChipSplit: React.FC<SplitProps> = ({ color, title, height = 56, disabled = false, children }) => {
  const {
    chip,
    blueChip,
    greenChip,
    greyChip,
    disabledChip,
    chipBorderLeft,
    chipBorderRight,
    chipPillLayout,
    chipPillSeparator,
    chipPillSeparatorPipe
  } = useStyles();
  return (
    <Box className={chipPillLayout}>
      <Box
        height={height}
        className={clsx(
          chip,
          chipBorderLeft,
          disabled ? disabledChip : color === 'blue' ? blueChip : color === 'green' ? greenChip : greyChip
        )}
      >
        <Typography variant="p2" style={{ fontWeight: '500', fontSize: '10px', paddingTop: height > 56 ? 8 : 0 }}>
          {title[0]}
        </Typography>
        {children[0]}
      </Box>
      <Box
        height={height}
        className={clsx(
          chipPillSeparator,
          disabled ? disabledChip : color === 'blue' ? blueChip : color === 'green' ? greenChip : greyChip
        )}
      >
        <Box height={height / 1.18} className={chipPillSeparatorPipe}></Box>
      </Box>
      <Box
        height={height}
        className={clsx(
          chip,
          chipBorderRight,
          disabled ? disabledChip : color === 'blue' ? blueChip : color === 'green' ? greenChip : greyChip
        )}
      >
        <Typography variant="p2" style={{ fontWeight: '500', fontSize: '10px', paddingTop: height > 56 ? 8 : 0 }}>
          {title[1]}
        </Typography>
        {children[1]}
      </Box>
    </Box>
  );
};

const StyledLabelChip: React.FC<Props> = ({ color, title, height = 56, disabled = false, children }) => {
  const { chip, blueChip, greenChip, greyChip, chipBorderFull } = useStyles();
  return (
    <Box
      height={height}
      className={clsx(chip, chipBorderFull, color === 'blue' ? blueChip : color === 'green' ? greenChip : greyChip)}
    >
      <Typography variant="p2" style={{ fontWeight: '500', fontSize: '10px', paddingTop: height > 56 ? 8 : 0 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default StyledLabelChip;
