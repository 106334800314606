import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { QueryStringParam, createUrlWithQueryString } from '@clinintell/utils/querystring';
import { ApplicationAPI, HttpResponseException } from '@clinintell/utils/api';

//GET /api/hospitals/{orgId} (list of physicianGrps)
export type HospitalsOrgIdDTO = {
  id: number;
  hospitalName: string;
  physicianGroups: PhysicianGroupInHospDTO[] | [];
};

export type PhysicianGroupInHospDTO = {
  id: number;
  physicianGroupName: string;
  trainingUsers: boolean;
};

export type PhysicianGroup = {
  id: string;
  physicianGroupName: string;
  providers: string[] | [];
  trainingUsers: boolean;
  hospital: string;
};

export type PhysicianGroupsStateData = { [key: string]: PhysicianGroup };
export type PhysicianGroupsState = {
  data: PhysicianGroupsStateData;
  isLoading: boolean;
  error: string;
  selectedPhysicianGroup: string | null;
};

export enum PhysicianGroupActions {
  SET_SELECTED_PHYSICIAN_GROUP = 'SET_SELECTED_PHYSICIAN_GROUP',
  ADD_PHYSICIAN_GROUPS_BY_HOSPITAL = 'ADD_PHYSICIAN_GROUPS_BY_HOSPITAL',
  PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_FAILED = 'PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_FAILED',
  PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_SUCCESSFUL = 'PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_FETCH_SUCCESSFUL',
  PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_REQUESTED = 'PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_REQUESTED'
}

export type PhysicianGroupAction<T> = {
  type: keyof typeof PhysicianGroupActions;
  payload: T;
};

// Normalize State
export const convertDTOtoById = (dto: HospitalsOrgIdDTO): PhysicianGroupsStateData => {
  if (dto.physicianGroups.length > 0) {
    const pgs: PhysicianGroupInHospDTO[] = dto.physicianGroups;
    return pgs.reduce((acc: PhysicianGroupsStateData, val: PhysicianGroupInHospDTO) => {
      const key = val.id.toString();
      return {
        ...acc,
        [key]: {
          physicianGroupName: val.physicianGroupName,
          id: key,
          providers: [],
          hospital: dto.id.toString(),
          trainingUsers: val.trainingUsers
        }
      };
    }, {});
  }
  return {};
};

// Initial State

export const initialPhysicianGroups: PhysicianGroupsState = {
  isLoading: false,
  error: '',
  selectedPhysicianGroup: null,
  data: {}
};

// Actions

// reducer

function reducer(
  state: PhysicianGroupsState = initialPhysicianGroups,
  action: PhysicianGroupAction<PhysicianGroupsState>
): PhysicianGroupsState {
  switch (action.type) {
    case 'SET_SELECTED_PHYSICIAN_GROUP': {
      const payload = action.payload as PhysicianGroupsState;
      return { ...state, selectedPhysicianGroup: payload.selectedPhysicianGroup };
    }
    case 'ADD_PHYSICIAN_GROUPS_BY_HOSPITAL': {
      const payload = action.payload as PhysicianGroupsState;
      return { ...state, data: { ...payload.data } };
    }
    case 'PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_FAILED': {
      const payload = action.payload as PhysicianGroupsState;
      return { ...state, error: payload.error, isLoading: false };
    }
    // if new hospital selected remove any previous hospital group selection
    case 'PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_REQUESTED': {
      return { data: state.data, isLoading: true, error: '', selectedPhysicianGroup: state.selectedPhysicianGroup };
    }
    case 'PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_SUCCESSFUL': {
      return { ...state, error: '', isLoading: false };
    }
    default: {
      return { ...state };
    }
  }
}

export default reducer;

// Sagas

type PhysicianGroupActionHospSagaPayload = {
  orgId: number;
  useAllGroups?: boolean;
  providerReportsOnly?: boolean;
};

// so many types!  todo find better patterns for saga with typescript
export type PhysicianGroupActionHospSag = {
  type: keyof typeof PhysicianGroupActions;
  payload: PhysicianGroupActionHospSagaPayload;
};

export function* fetchPhysicianGroupForHospitalSaga(action: PhysicianGroupActionHospSag): SagaIterator {
  let endpoint = `hospitals/${action.payload.orgId}`;
  const params: QueryStringParam[] = [{ key: 'includeAllGroups', value: action.payload.useAllGroups ? '1' : '0' }];

  if (action.payload.providerReportsOnly) {
    params.push({ key: 'excludeOtherGroups', value: '1' });
    params.push({ key: 'excludeOtherMDs', value: '1' });
    params.push({ key: 'excludeHiddenProviders', value: '1' });
    params.push({ key: 'excludeHiddenSpecialtyGroups', value: '1' });
  }

  endpoint = createUrlWithQueryString(endpoint, params);

  try {
    const response = yield call(ApplicationAPI.get, { endpoint });
    const physicianGroupsById = convertDTOtoById(response.data);
    yield put({ type: 'ADD_PHYSICIAN_GROUPS_BY_HOSPITAL', payload: { data: { ...physicianGroupsById } } });
    yield put({ type: 'PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_SUCCESSFUL' });
  } catch (e) {
    yield put({
      type: 'PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_FAILED',
      payload: { error: (e as HttpResponseException).message }
    });
  }
}

export function* physicianGroupForHospitalSaga(): SagaIterator {
  yield takeLatest(
    // idk why "No overload matches this call. The last overload gave the following error.
    // Argument of type  is not assignable to parameter of type 'TakeableChannel<unknown>'"
    // eslint-disable-next-line
    // @ts-ignore
    'PHYSICIAN_GROUPS_BY_HOSPITAL_FETCH_REQUESTED',
    fetchPhysicianGroupForHospitalSaga // called with payload used to dispatch above
  );
}

export type FetchDefaultDatesSaga = {
  type: keyof typeof PhysicianGroupActions;
  payload: {
    id: string;
  };
};
