import ActionPanel from '@clinintell/components/ActionPanel';
import useEntityDetails from '@clinintell/components/analysisSharedComponents/useEntityDetails';
import { NodeDetails } from '@clinintell/containers/treeSelector/logic/TreeContext';
import { setCMIAnalysisBestFit } from '@clinintell/modules/cmiAnalysis';
import { CMIAnalysisViews } from '@clinintell/modules/cmiAnalysisSelections';
import { useCMIAnalysisDispatch } from '@clinintell/modules/store';
import { Box } from '@mui/material';
import React from 'react';
import EntitySelector from './EntitySelector';
import { Button } from '@clinintell/components/index';

type EntitySelectionPanelProps = {
  isOpen: boolean;
  toggleIsOpen: () => void;
  defaultEntityId: number;
  defaultEntityName: string;
  analysisType: CMIAnalysisViews;
};

const EntitySelectionPanel: React.FC<EntitySelectionPanelProps> = ({
  isOpen,
  toggleIsOpen,
  defaultEntityId,
  defaultEntityName,
  analysisType
}) => {
  const { entityId, entityName, updateEntity } = useEntityDetails({
    id: defaultEntityId,
    name: defaultEntityName,
    type: undefined
  });

  const handleUpdateEntity = (entity: number, nodeDetails: NodeDetails) => {
    updateEntity({ id: entity, ...nodeDetails });
  };

  const cmiAnalysisDispatch = useCMIAnalysisDispatch();

  const handleEntitySelection = (): void => {
    if (entityId === undefined || entityName === undefined) {
      return;
    }

    cmiAnalysisDispatch(
      setCMIAnalysisBestFit({
        orgId: entityId,
        metric: analysisType,
        orgName: entityName
      })
    );

    toggleIsOpen();
  };

  return (
    <ActionPanel
      closeFn={toggleIsOpen}
      open={isOpen}
      title={
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>Change Selection</Box>
          <Button onClick={handleEntitySelection} label="Analyze" disabled={entityId === undefined} />
        </Box>
      }
    >
      <Box p={1}>
        <EntitySelector
          treeHeight={500}
          handleEntitySelection={handleUpdateEntity}
          myEntity={entityId}
          myEntityName={entityName}
        />
      </Box>
    </ActionPanel>
  );
};

export default EntitySelectionPanel;
