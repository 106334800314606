import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import DropDownIcon from '@clinintell/components/icons/DropDownIcon';
import { Box, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@clinintell/components/InputLabel';

export type Option = {
  value: string;
  display: string;
};

type Options = Option[];

type AsyncSelectProps = {
  isLoading: boolean;
  options: Options;
  id: string;
  handleChange: (e: React.SyntheticEvent, value: Option | null) => void;
  inputLabel?: string;
  textFieldLabel?: string;
  disabled?: boolean;
  current?: Option;
  initValue: string | null;
};

const useStyles = makeStyles(theme => ({
  inputLabelRoot: {
    top: '-2px'
  }
}));

export default function AsyncSelect(props: AsyncSelectProps): JSX.Element {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isNarrowScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const { isLoading, options, id, handleChange } = props;
  const [value, setValue] = useState<Option | null>(null);

  const { inputLabelRoot } = useStyles();

  // options may change entirely or
  // options may loose referential equality when additional properties are added
  // only want to reset when the list is to be replaced entirely

  useEffect(() => {
    if (!options || options.length === 0 || isLoading === true) {
      setValue(null);
    }
  }, [isLoading, options]);

  useEffect(() => {
    // if user has set a value, do nothing
    if (!value === null) {
      return;
    }
    // if there is no value, and an initial value, select it
    options.forEach(option => {
      if (option.value === props.initValue) {
        setValue(option);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, props.initValue]); //  - value would loop

  const classes = makeStyles(theme => ({
    root: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
      color: theme.palette.grey[900],
      borderRadius: 10,
      backgroundColor: 'white',
      width: isMobileView ? '250px' : '300px',
      marginTop: theme.spacing(1),
      '&:hover': {
        borderColor: theme.palette.grey[200]
      },
      '& .MuiFormControl-root': {
        borderRadius: 10,
        '& .MuiInputBase-formControl': {
          borderRadius: 10
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px ${theme.palette.grey[300]} solid !important`
      }
    },
    inputRoot: {
      width: isMobileView ? '250px' : '300px'
    },
    listbox: {
      borderRadius: 10
    },
    option: {
      color: theme.palette.grey[900],
      '&:hover': {
        backgroundColor: `${theme.palette.grey[50]} !important`
      },
      '&[aria-selected="true"]': {
        backgroundColor: `transparent !important`
      }
    },
    popper: {
      marginTop: '8px !important',
      borderRadius: 10,
      border: `1px ${theme.palette.grey[300]} solid !important`,
      overflow: 'hidden',
      boxShadow: `0px 10px 15px -3px rgba(0, 0, 0, 0.05)`
    },
    clearIndicator: {
      display: 'none'
    }
  }))(theme);

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: Option): JSX.Element => {
    return (
      <Box component="li" {...props} display="flex" flexWrap="wrap" alignItems="center">
        <Box display="flex" alignContent="center">
          <Typography
            variant="p2"
            color="textPrimary"
            sx={{ fontWeight: option === value ? 600 : 'normal', margin: 0, padding: 0 }}
          >
            {option.display}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleChangeSomeMore = (e: React.SyntheticEvent, v: Option | null): void => {
    setValue(v);
    handleChange(e, v);
  };

  return (
    <>
      <InputLabel
        position={isNarrowScreen ? 'top' : 'side'}
        label={props.inputLabel ? props.inputLabel : ''}
        labelStyles={{ top: 2, position: 'relative' }}
      >
        <Autocomplete
          id={id}
          style={isMobileView ? { width: 250 } : { width: 300 }}
          classes={{
            root: classes.root,
            inputRoot: classes.inputRoot,
            listbox: classes.listbox,
            option: classes.option,
            popper: classes.popper,
            clearIndicator: classes.clearIndicator
          }}
          value={value}
          disabled={props.disabled}
          onChange={handleChangeSomeMore}
          open={open}
          onOpen={(): void => {
            setOpen(true);
          }}
          onClose={(): void => {
            setOpen(false);
          }}
          options={options}
          isOptionEqualToValue={(option: Option): boolean => {
            return !!value && option.value === value.value;
          }}
          popupIcon={<DropDownIcon />}
          renderOption={renderOption}
          getOptionLabel={(option): string => {
            return options.length > 0 ? option.display : '';
          }}
          loading={isLoading}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              placeholder="Select"
              InputLabelProps={{
                classes: {
                  root: inputLabelRoot
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </InputLabel>
    </>
  );
}
