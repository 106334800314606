import React, { useEffect, useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import Widget from '../../components/widget/Widget';
import { useDashboard2 } from '@clinintell/modules/store';
import {
  DashboardWidget,
  DashboardDefaultDates,
  DrgConditionOpportunityData,
  DRGDataType
} from '@clinintell/modules/dashboard2';
import { parseDateIntoPeriod } from '@clinintell/utils/formatting';
import DashboardBarChart from '../../charts/DashboardBarChart';
import { ChartDataWithFullLabels } from '@clinintell/types/common';
import WidgetModal from '@clinintell/containers/dashboard/components/modal/WidgetModal';
import DrgConditionOpportunityModal from './DrgConditionOpportunityModal';

const DrgConditionsOpportunityWidget: React.FC = () => {
  const theme = useTheme();
  // Redux Data
  const { dashboardDefaultDates, drgConditionsByOpportunityWidget: widget, entity, groupName: group } = useDashboard2();
  const { isLoading, hasError, data } = widget as DashboardWidget;
  const widgetTableData = (data as DRGDataType).widgetTableData;

  // Component States
  const [chartData, setChartData] = useState<ChartDataWithFullLabels>({});
  const [chartTooltips, setChartTooltips] = useState<string[]>([]);
  const [currentStartDate, setCurrentStartDate] = useState('');
  const [currentEndDate, setCurrentEndDate] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedCondition, setselectedCondition] = useState<DrgConditionOpportunityData>();
  const [dataIsEmpty, setDataIsEmpty] = useState(false);

  const getOrgTitle = useMemo(() => {
    if (group && group !== 'All') return group;
    if (entity) return entity.name;
    return '';
  }, [entity, group]);

  const widgetTooltip = (
    <Box width={300} my={-1}>
      <p>{`This widget displays the top 5 conditions by opportunity at ${getOrgTitle} for ${currentStartDate} - ${currentEndDate}.`}</p>
    </Box>
  );

  const handleChartClick = (_?: globalThis.MouseEvent, elements?: unknown[]): void => {
    if (!elements || !Object.entries(elements).length) return;
    const { _index: index } = elements[0] as { _index: number; _datasetIndex: number };
    const slice = (widgetTableData as DrgConditionOpportunityData[])[index];
    setselectedCondition(slice);
    setOpenModal(true);
  };

  const WidgetModalMemo = useMemo(
    () => (
      <WidgetModal
        modalVisible={openModal}
        toggleVisible={() => setOpenModal(false)}
        redirectUserHandler={() => void 0}
        headerTitle={`${getOrgTitle} Clinical Condition by Opportunity `}
        isModalLoading={isLoading}
      >
        <DrgConditionOpportunityModal data={selectedCondition as DrgConditionOpportunityData} />
      </WidgetModal>
    ),
    [getOrgTitle, isLoading, openModal, selectedCondition]
  );

  useEffect(() => {
    if (isLoading || !data || !widgetTableData) return;
    const { isLoading: datesLoading, currentStart, currentEnd } = dashboardDefaultDates as DashboardDefaultDates;
    if (datesLoading || currentStart === '' || currentEnd === '') return;
    setCurrentStartDate(parseDateIntoPeriod(new Date(currentStart as string)));
    setCurrentEndDate(parseDateIntoPeriod(new Date(currentEnd as string)));

    if (widgetTableData && (widgetTableData as DrgConditionOpportunityData[]).length) {
      const drgData = widgetTableData as DrgConditionOpportunityData[];
      const chartLabels = drgData.map(elem => {
        return elem.name.length > 20 ? elem.name.slice(0, 20) + '...' : elem.name;
      });
      const fullTextLabels = drgData.map(elem => elem.name);
      const chartTooltipsTemp = drgData.map(elem => elem.name);
      const chartDataTemp = drgData.filter(elem => elem.opportunityRW > 0).map(elem => elem.opportunityRW);
      setDataIsEmpty(chartDataTemp.length === 0);
      setChartData({
        labels: chartLabels,
        fullTextLabels,
        datasets: [{ data: chartDataTemp, backgroundColor: theme.palette.blue.light3 }]
      });
      setChartTooltips(chartTooltipsTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetTableData, isLoading, dashboardDefaultDates, dataIsEmpty, theme.palette.blue.light3]);

  return (
    <>
      <Widget
        isLoading={isLoading}
        hasError={hasError || dataIsEmpty}
        headerTitle="Top 5 DRG Conditions by Opportunity"
        toolTip={widgetTooltip}
        displayTooltip={true}
        noPadding={true}
        graphStatus={dataIsEmpty ? 'No Data' : undefined}
        graphMessage={dataIsEmpty ? 'There are no DRG conditions for this entity with opportunity' : undefined}
      >
        <Box height={15} />
        <DashboardBarChart
          data={chartData}
          tooltips={chartTooltips}
          isHorizontal={true}
          axisLabel="Opportunity (RW)"
          customHeight={230}
          handleOnChartBarClick={handleChartClick}
          testId="top5DRGConditionsWidget"
        />
        <Box height={15} />
      </Widget>
      {WidgetModalMemo}
    </>
  );
};

export default DrgConditionsOpportunityWidget;
