import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LineTypes } from './types';

export interface LegendItemProps {
  color: string;
  label: string;
  type: keyof typeof LineTypes;
}

const useLegendItemStyles = makeStyles(theme => ({
  line: {
    borderBottom: '2.5px solid'
  },
  dashedLine: {
    borderBottom: '2.5px dashed'
  },
  bar: {
    height: '0.5rem',
    border: '1px solid'
  },
  slider: {
    height: '0.5rem',
    border: '1px solid'
  }
}));

export const LegendItem: React.FC<LegendItemProps> = ({ color, label, type }) => {
  const { line, dashedLine, bar, slider } = useLegendItemStyles();

  let lineClass: string;
  switch (type) {
    case 'Dashed':
      lineClass = dashedLine;
      break;
    case 'Bar':
      lineClass = bar;
      break;
    case 'Slider':
      lineClass = slider;
      break;
    case 'Solid':
    default:
      lineClass = line;
      break;
  }

  const style: { color: string; background?: string } = {
    color
  };

  if (type !== 'Dashed') {
    style.background = color;
  }

  if (type === 'Slider') {
    // Using 8 digit hex code for transparency of 15%
    style.background = `${color}15`;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box position="relative" marginRight="1rem" width="1.25rem" className={lineClass} style={style}></Box>
      <Box>
        <Box component="span" sx={{ typography: 'p2' }}>
          {label}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  legend: {
    '& > div:not(:last-child)': {
      marginRight: theme.spacing(4)
    }
  }
}));

interface LegendProps {
  items: LegendItemProps[];
  styles?: React.CSSProperties;
  width?: number;
  TestID?: string;
}

export const Legend: React.FC<LegendProps> = ({ items, styles, width = 75, TestID }): JSX.Element => {
  const { legend } = useStyles();

  return (
    <Box
      className={legend}
      flexDirection="row"
      display="flex"
      flexWrap="wrap"
      lineHeight="3rem"
      justifyContent="flex-start"
      width={`${width}rem`}
      style={styles}
      data-cy={TestID}
    >
      {items.map(item => (
        <LegendItem key={item.label} color={item.color} label={item.label} type={item.type} />
      ))}
    </Box>
  );
};
