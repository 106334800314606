import React from 'react';
import useIsMobileView from './useIsMobileView';

const DesktopView: React.FC = ({ children }) => {
  const isMobileView = useIsMobileView();

  return !isMobileView ? <>{children}</> : null;
};

export default DesktopView;
