import { AutoCompleteChoiceType } from '@clinintell/components/AutoComplete';
import { ApplicationAPI } from '@clinintell/utils/api';
import { useEffect, useState } from 'react';

type UseFetchOrgsBySearchProps = {
  searchValue: string;
  useSAF: boolean;
};

type UseFetchOrgsBySearchOutput = {
  orgs: AutoCompleteChoiceType[];
  isLoading: boolean;
};

const useFetchOrgsBySearch = ({ searchValue, useSAF }: UseFetchOrgsBySearchProps): UseFetchOrgsBySearchOutput => {
  const [orgs, setOrgs] = useState<AutoCompleteChoiceType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!searchValue) {
      return;
    }

    const fetchEntity = async (): Promise<void> => {
      setIsLoading(true);

      const result = await ApplicationAPI.get<AutoCompleteChoiceType[]>({
        endpoint: `org?search=${searchValue}`,
        useSAF
      });

      if (result.data) {
        setOrgs(result.data);
      }

      setIsLoading(false);
    };

    fetchEntity();
  }, [searchValue, useSAF]);

  return {
    orgs,
    isLoading
  };
};

export default useFetchOrgsBySearch;
